import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import React from 'react';
import './index.css';

class TextEditor extends React.Component {

    

    onEditorStateChange = (editorState) => {
         this.props.onChange(editorState)
    }
    onChange= (res)=>{
            let text = res.blocks[0].text;
            this.props.onChangeText(text);
    }

    render() {
        return(
            <div>
            <Editor
                editorState={this.props.editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                placeholder={this.props.placeholder}
                onEditorStateChange={this.onEditorStateChange}
                onChange={this.onChange}
                toolbar={this.props.toolbar}
            />
            
            </div>
        )
    }
}

export {TextEditor };