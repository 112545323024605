import React from 'react'

class NotesComponent extends React.Component {


	mapNotes = (item,index) => {
		return(
			<li style = {{fontSize:15,padding:'4px 0px',color:'#7F7AA2'}} key = {index} >{item}</li>
		)
	}


	render() {
		return (
			<div>
				<p style = {{fontSize:12,fontWeight:600,color:'#555555',marginTop:25}}>Notes:</p>
				<div style = {{backgroundColor:'#F6F6FF',padding:12,borderRadius:8,marginTop:5}}>
					<ul style = {{padding:"0px 20px",margin:'0'}}>
						{
							this.props.notes.map(this.mapNotes)
						}
					</ul>
				</div>
			</div>
		)
	}

}

export {
	NotesComponent
}