import React from 'react'
import {v4} from 'uuid'
import Delete from './delete.png'
import HotelSearch from './hotel search.png'
import {showToast} from "../../../libs/Toast/ToastCallback";
import Moment from 'moment'
import {CitySearch} from "../../Common/CitySearch";
import {NotesComponent} from "../../Common/NotesComponent";
import {DatesRangeReact} from '../../../libs/DateRangereact';


class SearchComponent extends React.Component {

	constructor(props) {
		super(props);
		let checkin = Moment();
		let checkout = Moment();
		checkout  = checkout.add(5, 'd');
		checkin = checkin.add(2, 'd');
		this.state = {
			rooms: [
				{
					key: "initial",
					count: 1
				}
			],
			city: {
				text: "",
				value: ""
			},
			checkin,
			checkout,
			notes: []
		}

	}


	_addRoom = () => {
		let rooms = this.state.rooms;
		rooms.push({
			key: v4(),
			count: 1
		})
		this.setState({
			rooms
		})
	}

	componentDidMount() {
		this.props.loader(r => {
			this.setState({
				...this.state,
				city: {
					value: r.cityName,
					id: r.cityId
				},
				requestId: r.requestId,
				checkin: Moment(r.checkIn, "DD-MM-YYYY"),
				checkout: Moment(r.checkOut, "DD-MM-YYYY"),
				notes: r.notes,
				options: r.options
			})
		})

	}

	mapItem = (item, i) => {
		return (
			<div key={item.key} style={{
				display: 'flex',
				marginBottom: 12,
				alignItems: 'center',
				flexDirection: 'row'
			}}>
				<p style={{
					fontSize: 13,
					fontWeight: 600,
					color: "#777777"
				}}>
					Room {i + 1}:
				</p>
				<div style={{
					marginLeft: 10,
					padding: "2px 10px",
					width: 80,
					flexDirection: 'row',
					display: 'flex',
					alignItems: 'center',
					border: '1px solid #6a48f2'
				}}>
					<span onClick={() => {
						let rooms = this.state.rooms;
						rooms = rooms.map(m => {
							if (item.key === m.key) {
								let count = item.count;
								if (count > 1) {
									count = count - 1;
								}
								m.count = count;
							}
							return m;
						})
						this.setState({
							rooms
						})
					}} style={{
						fontWeight: 600,
						color: "#6a48f2",
						fontSize: 17,
						lineHeight: "21px",
						cursor: 'pointer'
					}}>
						-
					</span>
					<p style={{
						flex: 1,
						textAlign: 'center',
						fontWeight: 600,
						color: "#6a48f2",
						fontSize: 13
					}}>
						{item.count}
					</p>
					<span onClick={() => {
						let rooms = this.state.rooms;
						rooms = rooms.map(m => {
							if (item.key === m.key) {
								let count = item.count;
								if (count < 3) {
									count = count + 1;
								}
								m.count = count;
							}
							return m;
						})
						this.setState({
							rooms
						})
					}} style={{
						fontWeight: 600,
						color: "#6a48f2",
						fontSize: 17,
						lineHeight: "21px",
						cursor: 'pointer'
					}}>
						+
					</span>
				</div>
				{
					item.key !== "initial" ?
						<img alt="delete" onClick={() => {
							let rooms = this.state.rooms;
							rooms = rooms.filter(m => item.key !== m.key)
							this.setState({
								rooms
							})
						}} src={Delete} style={{
							width: 16,
							height: 16,
							cursor: 'pointer',
							marginLeft: 8
						}}/> : null
				}
			</div>
		)
	}

	search = () => {
		let city = this.state.city;
		if (!city.id) {
			showToast({
				type: 'error',
				message: "Please select a valid city from drop down"
			})
			return;
		}
		let checkin = this.state.checkin;
		let checkout = this.state.checkout;

		if (!checkin || checkin === "") {
			showToast({
				type: 'error',
				message: "Please select a valid check in date"
			})
			return
		}

		if (!checkout || checkout === "") {
			showToast({
				type: 'error',
				message: "Please select a valid check out date"
			})
			return
		}

		checkin = Moment(checkin, "YYYY-MM-DD");

		checkout = Moment(checkout, "YYYY-MM-DD");


		if (checkin.valueOf() > checkout.valueOf()) {
			showToast({
				type: 'error',
				message: "Please select a valid checkout date"
			})
			return;
		}
		let TIME = Moment('12:00 PM', 'hh:mm A');

		if (checkin.valueOf() < TIME) {
			showToast({
				type: 'error',
				message: "Please select a valid check in date"
			})
			return;
		}


		let data = {
			cityId: city.id,
			checkIn: checkin.format("DD-MM-YYYY"),
			checkOut: checkout.format("DD-MM-YYYY"),
			rooms: this.state.rooms.map(item => item.count)
		}
		this.props.search(data)

	}

	render() {
		return (
			<div>
				<div style={{
					width: 325,
					overflowX: 'hidden',
					background: "white",
					padding: "25px 18px",
					height: '100vh',
					overflowY: 'auto',
					borderRight: '1px solid #dedede'
				}}>
					<div>
						<h5 className="prime-color" style={{
							fontSize: 27,
							fontWeight: 800,
							textAlign: 'center',
							letterSpacing: 1,
							lineHeight: "35px",
							marginTop: 15
						}}>
							Book Hotel Online
						</h5>
						<p style={{
							fontSize: 13,
							color: "#BABBCC",
							textAlign: 'center',
							lineHeight: "16px"
						}}>
							Please add below details and click on button below to search hotels.
						</p>
						<img src={HotelSearch} style={{
							width: 'calc(100% + 36px)',
							marginLeft: -18,
							marginRight: -18,
							marginTop: 30
						}} alt="hotel search"/>
						<div style={{
							position: 'relative',
						}}>
							<p style={{
								fontSize: 11,
								marginTop: 16,
								color: '#555555',
								fontWeight: 600
							}}>
								City
							</p>
							<CitySearch onCitySelected={city => {
								this.setState({
									...this.state,
									city
								})
							}} city={this.state.city}/>
						</div>
						<div style={{marginBottom: 16}}>
							<p style={{
								fontSize: 11,
								marginTop: 16,
								color: "#555555",
								fontWeight: 600,
								marginBottom: 10
							}}>
								Check In & Check out
							</p>

							<DatesRangeReact
								startText="Check in"
								endText="Check out"
								withPortal={true}
								startDate={this.state.checkin}
								endDate={this.state.checkout}
								handleDate={({startDate, endDate}) => {
									this.setState({
										checkin: startDate, checkout: endDate
									})
								}}
							/>
						</div>

						<div style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							marginBottom: 12,
							marginTop: 12
						}}>
							<p style={{
								fontSize: 12,
								marginTop: 16,
								color: "#555555",
								flex: 1,
								fontWeight: 600
							}}>
								Rooms
							</p>

							<p className="secondary-color" onClick={this._addRoom} style={{
								textAlign: 'right',
								fontSize: 11,
								cursor: "pointer",
								marginTop: 20,
								fontWeight: 700,
								flex: 1,
							}}>
								Add
							</p>
						</div>
						{
							this.state.rooms.map(this.mapItem)
						}

						<p className="prime-background" style={{
							textAlign: 'center',
							textTransform: 'uppercase',
							color: "white",
							fontWeight: 600,
							padding: '10px 20px',
							fontSize: 15,
							marginTop: 35,
							background: "#7558F6",
							cursor: 'pointer'
						}} onClick={this.search}>
							Start New Booking
						</p>
					</div>
					{
						this.state.notes.length > 0 ? <NotesComponent
							notes={this.state.options ? ["Employee does not require an option to choose from"
								, ...this.state.notes] : this.state.notes}/> : null
					}
				</div>


			</div>
		)
	}

}

export default SearchComponent