import { post } from "../../../../Model/Network/Config/Axios";

class Model {

	constructor() {
		this.Disposables = [];
	}

	destroy = () => {
		this.Disposables.forEach(item => {
			if (item.isCancellable())
				item.cancel()
		})
	}

	hold = (data, cb) => {
		this.Disposables.push(post(`/bus/hold`, data, cb));
	}

	book = (data, cb) => {
		this.Disposables.push(post(`/bus/book`, data, cb));
	}


}

export default Model