import { get } from "../../../Utils/Crypto";



const DEVELOPMENT_MODE = window.location.href.includes("localhosts") || window.location.href.includes("https://eka-v2-travel2.netlify.app/");
const DEV_MODE = window.location.href.includes("localhostd") || window.location.href.includes("https://travel.dev.eka.io/");

let api, luna, token, analytics;

if (DEVELOPMENT_MODE) {
    api = "https://heimdall.expanso.in/travel/";
    luna = "https://mantis.expanso.in/luna";
    analytics = "https://analytics.eka.io/travel/"
    token = () => get("refresh_token");
} else if(DEV_MODE) {
    api = "https://api.dev.eka.io/travel/";
    luna = "https://mantis.expanso.in/luna";
    analytics = "https://analytics.eka.io/travel/"
    token = () => get("refresh_token");
} else {
    api = "https://heimdall.eka.io/travel/";
    luna = "https://mantis.eka.io/luna";
    analytics = "https://analytics.eka.io/travel/"
    token = () => get("refresh_token");
}



export {
    api, luna, DEVELOPMENT_MODE, token, analytics
}


// export const luna = "https://mantis.expanso.in/luna";
// export const api = "https://heimdall.eka.io/travel/";
// export const analytics = "https://analytics.eka.io/travel/";
// export const token = () => get("refresh_token");

//https://heimdall.expanso.in/travel/