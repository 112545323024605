import React from 'react';


class EmptyState extends React.Component {

    render() {
        return(
            
            <div style = {{padding:'50px 0'}}>
                <img src = {this.props.image} alt = "eka" style = {{width:this.props.small?300:400,objectFit:'cover',height:'auto',margin:'30px auto',display:'block'}} />
                <p style = {{fontSize:this.props.small?23:30,textAlign:'center',fontWeight:700}}>{this.props.title}</p>
                <p style = {{textAlign:'center',color:'rgb(137,137,137)',marginTop:10,fontSize:this.props.small?15:20}}>{this.props.desc}</p>
            </div>
        )
    }
}


export {
    EmptyState
};