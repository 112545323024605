import React from 'react'
import { TitleComponent } from "../../Common/TitleComponent";
import { NormalTable } from "../../../Tables/NormalTable/NormalTable";
import { TableActions } from "../../../libs/Table/TableAction";
import { FilterNames } from '../../Common/Filter';

class View extends React.Component {

	constructor(props) {
		super(props);
		this.presentor = props.presentor;
		this.presentor.setView(this)
	}

	componentDidMount() {
		this.presentor.init()
	}

	state = {
		employees: [],
		name: '',
		employeeResult: [],
		names: [],
		list: []
	}

	load = r => {
		this.setState({
			...r
		})
	}

	handleFilter = () => {
		let employee = this.state.employees;
		let result = employee.filter(val => val.name.toLowerCase().includes(this.state.name.toLocaleLowerCase()));
		this.setState({
			...this.state,
			employeeResult: result
		})

	}

	componentWillUnmount() {
		this.presentor.destroy();
	}

	updateList = (item) => {
		let list = this.state.list;
		let isItem = false
		this.state.list.forEach(val => {
			if (val.code === item.code) {
				isItem = true
			}
		})
		if (!isItem) {
			list.push(item);
		}

		this.setState({
			list
		}, () => this.filterEmployee())
	}

	removeList = (item) => {
		let items = this.state.list;
		items = items.filter(i => i.name !== item.name)
		this.setState({
			list: items
		}, () => this.filterEmployee())
	}

	filterEmployee = () => {

		let employee = this.state.employees;
		if (this.state.list.length > 0) {

			return employee.filter(item1 => {
				let isFilter = false
				this.state.list.forEach(item2 => {

					if (item2.name === item1.name) {
						isFilter = true
					}
				})
				return isFilter;
			})
		}
		return employee;
	}


	item = item => {let click = () => {
		this.props.history.push(
			`/app/travellers/employee/${item.code}`
		)
	}
		return (
			<div key={item.id} style={{ paddingLeft: 0, paddingRight: 0 }}
				className="border-bottom fintrip-department-item table-item">
				<div className="linear-layout horizontal"
					style={{ flex: 1, cursor: 'pointer' }}>
					<div style={{ flex: 3, alignItems: 'center' }} className="linear-layout horizontal">
						<div>
							<img src={item.image} alt="employee" style={{
								width: 30, height: 30, borderRadius: 15,
								marginRight: 8
							}} />
						</div>
						<div>
							<p className="top">{item.name}</p>
							<p className="bottom">{item.email}</p>
						</div>
					</div>
				</div>
				<div style={{ width: 200 }} className="right-actions">
					<TableActions items={[
						{
							name: "Details",
							click
						},
						]} />
					<p onClick={click} className="hoverable">Details</p>
				</div>
			</div>
		)
	}

	render() {
		let data = this.filterEmployee();
		return (
			<div className="side-container">
				<TitleComponent subtitle="Travellers" title="Employees" />
				<div style={{
					marginTop: 50
				}}>
					<FilterNames removeList={this.removeList} list={this.state.list} employees={this.state.employees} filterEmployee={this.updateList} />
					<NormalTable headers={[
						{
							key: "employee",
							name: "Details",
							weight: 3
						},
						{
							key: "actions",
							name: "",
							width: 200
						}
					]} data={data} pagination={true} mapItem={this.item} />
				</div>
			</div>
		)
	}

}

export default View
