import React from 'react'
import EmptyState from './empty_state.png'

class SearchArea extends React.Component {


	render() {
		return (
			<div style={{
				flex: 1,
				height: '100vh',
				overflowY: 'auto'
			}}>
				<div style={{
					flex: 1,
					background: '#C9C8FC',
					height: '100vh',
					display: 'flex',
					alignItems: 'center',
					flexDirection: 'column',
					justifyContent: 'center'
				}}>
					<img src={EmptyState} alt="empty state" style={{
						width: 550,
					}}/>
					<p style={{
						fontSize: 35,
						fontWeight:800,
						marginTop: 15,
						color:'#250C63'
					}}>
						Book Hotel Online
					</p>
					<p style={{
						fontSize: 21,
						marginTop: 5,
						color:'#250C63'
					}}>
						Please enter your search criteria and click on
					</p>
					<p style={{
						fontSize: 21,
						color:'#250C63'
					}}>
						search button to find hotels that suits your requirement.
					</p>
				</div>
			</div>
		)
	
			}
}

export default SearchArea