import React from 'react'
import Props from 'prop-types'

class TitleComponent extends React.Component {

	render() {
		return (
			<div style={{
				position:'relative'
			}}>
				{
					this.props.subtitle?
						<p className="sub-title">
							{this.props.subtitle}
						</p>:null
				}
				<p className="title">
					{this.props.title}
				</p>
				{
					this.props.add?<img onClick={this.props.add} src={require('./plus-symbol.png')} alt="plus" style={{
						position:'absolute',
						right:0,
						width:44,
						height:44,
						cursor:'pointer',
						padding:12,
						background:"#331DBF",
						borderRadius:22,
						top:15,
						boxShadow:'2px 2px 1px rgba(1,1,1,.1)'
					}}/>:null
				}
			</div>
		)
	}

}

TitleComponent.propTypes={
	title:Props.string.isRequired,
	subtitle:Props.string,
	add:Props.func
}
class TitleComponent2 extends React.Component {

	render() {
		return (
			<div style={{
				marginBottom:25,
				...this.props.style
			}}>
				<p className="title2">
					{this.props.title}
				</p>
			</div>
		)
	}

}

TitleComponent2.propTypes={
	title:Props.string.isRequired,
	subtitle:Props.string
}

export {
	TitleComponent,TitleComponent2
}