import React from 'react'
import { Route, Switch } from 'react-router-dom';

import { Sidebar } from "../Common/Sidebar";
import CreateBooking from './CreateBooking';
import Bookings from './Bookings';
import TrainRefund from './TrainRefund';
import BookingRequest from './BookingRequest';


import ActiveBookingsIcon from './assets/bookings.png'
import InActiveBookingsIcon from './assets/bookings1.png'

import ActiveRefundIcon from './assets/icon1.png'
import InactiveRefundIcon from './assets/icon1a.png'
import ActiveWorkflowIcon from './assets/icon5a.png'
import InactiveWorkflowIcon from './assets/icon5.png'

class index extends React.Component {

	render() {
		return (
			<div id="fintrip-container" style={{
				flex: 1
			}}>
				<Sidebar history={this.props.history} location={this.props.location} title="Train" data={[

					{
						title: 'Workflow',
						items: [
							{
								title: 'Booking Request',
								active: InactiveWorkflowIcon,
								inactive: ActiveWorkflowIcon,
								path: '/app/train/request',
								style: {
									width: 42,
									height: 42,
									marginLeft: -8,
									marginRight: 8,
									marginBottom: -6,
									marginTop: -6
								}
							}
						]
					},

					{
						title: "Offline Booking",
						items: [
							{
								title: "Import Booking",
								active: InactiveWorkflowIcon,
								inactive: ActiveWorkflowIcon,
								path: "/app/train/offline",
								style: {
									width: 42,
									height: 42,
									marginLeft: -8,
									marginRight: 8,
									marginBottom: -6,
									marginTop: -6
								}
							},
							{
								title: 'Offline Bookings',
								active: ActiveBookingsIcon,
								inactive: InActiveBookingsIcon,
								path: '/app/train/booking',
								includes: true
							}
						]
					},
					{
						title: "Help & Support",
						items: [
							{
								title: "Refund & Cancellation",
								active: ActiveRefundIcon,
								inactive: InactiveRefundIcon,
								path: "/app/train/refunds",
								style: {
									width: 42,
									height: 42,
									marginLeft: -8,
									marginRight: 8,
									marginBottom: -6,
									marginTop: -6
								}
							},

						]
					}
				]} />
				<div style={{ flex: 1, overflowY: 'auto' }}>
					<Switch>
						<Route component={CreateBooking} path="/app/train/offline" />
						<Route component={Bookings} path="/app/train/booking" />
						<Route component={TrainRefund} path="/app/train/refunds" />
						<Route component={BookingRequest} path="/app/train/request" />
					</Switch>
				</div>
			</div>
		)
	}

}

export default index