import React from 'react';
import './index.css';

function TabSwtich(props) {

    const TabSize = 100/props.tabs.length;
    const renderTabs = (item, index)=>{
        let cls = '';

        if(item.active){
            cls = 'active'
        }else{
            cls = 'inactive'
        }
        return(
            <div key={index} onClick={item.onClick} className={`Tab-${cls}`} style={{width: `${TabSize}%`}}>{item.name}</div>
        )
    }

    return (<>
        <div className='TabSwitch' style={{...props.style}}>
            {props.tabs.map(renderTabs)}
        </div>
    </>);
}

export default TabSwtich;