import { showToast } from "../../../libs/Toast/ToastCallback";

class Presentor {

	constructor(Model) {
		this.Model = Model;
	}

	setView(View) {
		this.View = View;
	}



	destroy = () => {
		this.Model.destroy()
	}


	book = data => {
		this.Model.book(data, (e, r) => {
			if (e) {
				showToast({
					type: 'error',
					message: e.data.error
				})
				this.View.setState({
					...this.state,
					loading: false
				})
			} else {
				this.View.onBook(r)
			}
		})
	}

	continue = data => {
		this.Model.continue(data, (e, r) => {
			if (e) {
				showToast({
					type: 'error',
					message: e.data.error
				})
			} else {
				this.View.onHold(r)
			}
		})
	}

}


export default Presentor