import React from 'react'
import SearchArea from './SearchArea'
import PollingFlights from './PollingFlights'
import Search from "stringquery";
import _ from 'lodash';

class View extends React.Component {

	constructor(props) {
		super(props);
		this.presentor = props.presentor;
		this.presentor.setView(this)
		const params = Search(this.props.location.search);
		if (params.requestId) {
			this.state = {
				requestId: params.requestId,
				result: undefined,
				selected: "",
				filter: {
					price: {
						min: 0,
						max: 0,
						maxValue: 2000
					},
					departure: [
						{ id: 1, text: 'Before 6 am', value: "before6", isCheck: false },
						{ id: 2, text: '6 am to 12 pm', value: "6to12", isCheck: false },
						{ id: 3, text: '12 pm to 6 pm', value: "12to6", isCheck: false },
						{ id: 4, text: 'After 6 pm', value: "after5", isCheck: false },
					],
					arrival: [
						{ id: 1, text: 'Before 6 am', value: "before6", isCheck: false },
						{ id: 2, text: '6 am to 12 pm', value: "6to12", isCheck: false },
						{ id: 3, text: '12 pm to 6 pm', value: "12to6", isCheck: false },
						{ id: 4, text: 'After 6 pm', value: "after5", isCheck: false },
					],
					stop: [
						{ id: 1, text: 'Non Stop', isCheck: false },
						{ id: 2, text: 'Stop', isCheck: false }
					],
					airLines: [],
					badge: []
				}
			}
		} else {
			this.state = {
				requestId: undefined,
				result: undefined,
				selected: "",
				filter: {
					departure: [
						{ id: 1, text: 'Before 6 am', value: "before6", isCheck: false },
						{ id: 2, text: '6 am to 12 pm', value: "6to12", isCheck: false },
						{ id: 3, text: '12 pm to 6 pm', value: "12to6", isCheck: false },
						{ id: 4, text: 'After 6 pm', value: "after5", isCheck: false },
					],
					arrival: [
						{ id: 1, text: 'Before 6 am', value: "before6", isCheck: false },
						{ id: 2, text: '6 am to 12 pm', value: "6to12", isCheck: false },
						{ id: 3, text: '12 pm to 6 pm', value: "12to6", isCheck: false },
						{ id: 4, text: 'After 6 pm', value: "after5", isCheck: false },
					],
					price: {
						min: 0,
						max: 0,
						maxValue: 2000
					},
					stop: [
						{ id: 1, text: 'Non Stop', isCheck: false, filterType: 'Direct' },
						{ id: 2, text: 'Stop', isCheck: false, filterType: 'NotDirect' }
					],
					airLines: [

					],
					badge: []
				}
			}
		}
	}



	load = result => {
		if (result.polling) {
			const params = Search(this.props.location.search);
			setTimeout(() => {
				this.presentor.search(this.props.match.params.pollingId, params.sessionId)
			}, 1000)
			this.setState({
				...this.state,
				result
			})
		} else {
			let maxValue = 0;
			result.items.forEach(val => {
				if (val.fare.publishedFare > maxValue) {
					maxValue = val.fare.publishedFare
				}
			})
			let data = _.uniq(_.map(result.items, 'airline'));
			let airlines = data.map((val, index) => {
				return {
					id: index,
					value: val,
					isCheck: false
				}
			})

			let badge_list = _.uniq(_.map(result.items, "badge"));
			let badge = badge_list.map((val, index) => {
				return {
					id: index,
					isCheck: false,
					value: val
				}
			})
			this.setState({
				...this.state,
				result,
				filter: {
					...this.state.filter,
					price: {
						min: 0,
						max: maxValue,
						maxValue
					},
					airLines: [...airlines],
					badge: [...badge]
				}
			})
		}
	}

	_setHighlighted = (id) => {
		this.setState({
			...this.state,
			selected: id
		})
	}


	componentDidMount() {
		const params = Search(this.props.location.search);
		if (params.requestId) {
			this.presentor.getRequest(params.requestId)
		}
		this.presentor.search(this.props.match.params.pollingId, params.sessionId)
	}

	componentWillUnmount() {
		this.presentor.destroy()
	}

	filter = data => {
		this.setState({
			...this.state,
			filter: {
				...this.state.filter,
				...data
			}
		})
	}



	render() {
		const params = Search(this.props.location.search);
		return (
			<div className="fintrip-container" style={{
				display: 'flex',
				flexDirection: 'row',
				flex: 1
			}}>
				<PollingFlights filter={this.state.filter} changeFilter={this.filter} />
				<SearchArea filter={this.state.filter}
					sessionId={params.sessionId}
					searchId={this.props.match.params.pollingId}
					postSelection={this.presentor.postSelection}
					requestId={this.state.requestId}
					selected={this.state.selected}
					history={this.props.history}
					result={this.state.result} />
			</div>
		)
	}

}

export default View