import { get, post } from "../../../../Model/Network/Config/Axios";

class Model {

	constructor() {
		this.Disposables = [];
	}

	destroy = () => {
		this.Disposables.forEach(item => {
			if (item.isCancellable())
				item.cancel()
		})
	}

	search = (data, cb) => {
		this.Disposables.push(post(`/hotels/search`, data, cb));
	}

	getRequest = (id, cb) => {
		this.Disposables.push(get(`/hotels/request/${id}`, cb));
	}



}

export default Model