import { get, post } from "../../../../Model/Network/Config/Axios";
import { showToast } from "../../../libs/Toast/ToastCallback";

class Model {

	constructor() {
		this.Disposables = [];
	}

	destroy = () => {
		this.Disposables.forEach(item => {
			if (item.isCancellable())
				item.cancel()
		})
	}

	search = (id, cb) => {
		this.Disposables.push(get(`/bus/search/${id}`, cb))
	}

	getRequest = (id, cb) => {
		this.Disposables.push(get(`/bus/request/${id}`, cb));
	}

	postSelections = (id, selections) => {
		this.Disposables.push(post(`/bus/request/${id}/selections`, { selections }, (e, r) => {
			if (r) {
				showToast({
					type: 'success',
					message: "Selections posted successfully!"
				})

			} else {
				showToast({
					type: 'error',
					message: e.data.message
				})
			}
		}))
	}



}

export default Model