import React from 'react'
import Props from 'prop-types'

class KeyValue extends React.Component {

	render() {
		return (
			<div style={this.props.style?this.props.style:{
				alignItems:'center',
				marginRight:12,
				flex:1
			}}>
				<p style={{
					fontSize:10,
					color:"#898989",
					fontWeight:600,
					marginBottom:2
				}}>
					{this.props.title}
				</p>
				<p style={{
					fontWeight:400,
					color:"#454545",
					fontSize:15
				}}>
					{this.props.value}
				</p>
				<div style={{
					height:1,
					width:'100%',
					background:"#eee",
					marginTop:4
				}}/>
			</div>
		)
	}

}


KeyValue.propTypes={
	title:Props.string.isRequired,
	value:Props.string.isRequired
}

export {
	KeyValue
}