import React from 'react';
import { TitleComponent } from '../../Common/TitleComponent';
import Moment from 'moment';
import { FloatInput } from '../../../libs/FloatLabelInput';
import { ChooseTraveller } from '../../Common/ChooseTraveller';
import { showToast } from '../../../libs/Toast/ToastCallback';
import { Attachment } from '../../../libs/Attachment';
import { DateTimePickers } from '../../../libs/DateTimePicker';
import { post } from '../../../../Model/Network/Config/Axios';





class Index extends React.Component {

    constructor(props) {
        super(props);
        this.presentor = props.presentor;
        this.presentor.setView(this);
        this.state = {
            origin: {
                value: '',
                id: ''
            },
            destination: {
                value: '',
                id: ''
            },
            departureDate: new Moment(),
            arrivalDate: new Moment(),
            departureTime: new Moment().hours(0).minute(0),
            arrivalTime: new Moment().hours(0).minute(0),
            employees: [],
            chooseEmployee: false

        }
    }

    mapEmployee = (item, index) => {
        let active = false;
        if (index % 2 === 0) {
            active = true
        }

        return (
            <div key={index} style={{ marginTop: 15, border: '1px solid #eee', borderRadius: 22, background: '#fafafa', padding: '12px 20px', height: 40, display: 'flex', justifyContent: 'space-between', alignItems: 'center', ...active ? { marginRight: 15, width: '49%' } : { width: '49%' } }}>
                <p style={{ fontSize: 13, fontWeight: 600 }}>{item.name}</p>
                <p onClick={() => {
                    let list = this.state.employees.filter(val => val.reference !== item.reference);
                    this.setState({
                        ...this.state,
                        employees: list
                    })
                }} style={{
                    fontWeight: 300,
                    cursor: 'pointer'
                }}>Remove</p>
            </div>
        )
    }

    _newBooking = () => {

        let origin = this._origin.value();
        let destination = this._destination.value();
        let amount = this._amount.value();
        let departureTime = this._departureTime.getValue();
        let arrivalTime = this._arrivalTime.getValue();
        let trainName = this._trainName.value();
        let trainNumber = this._trainNumber.value();
        let pnr = this._pnr.value();
        let seat = this._seat.value();
        let employee = this.state.employees;

        if (!origin) {
            showToast({
                type: 'error',
                message: 'Please enter valid origin city'
            })
            return;
        }

        if (!destination) {
            showToast({
                type: 'error',
                message: 'Please enter valid destination city'
            })
            return;
        }

        if (!departureTime) {
            showToast({
                type: 'error',
                message: 'Please enter valid deparutre time'
            })
            return;
        }

        if (!arrivalTime) {
            showToast({
                type: 'error',
                message: 'Please enter valid arrival time'
            })
            return;
        }



        if (!trainName) {
            showToast({
                type: 'error',
                message: 'Please enter valid train Name'
            })
            return;
        }

        if (!trainNumber) {
            showToast({
                type: 'error',
                message: 'Please enter valid train Number'
            })
            return;
        }

        if (!pnr) {
            showToast({
                type: 'error',
                message: 'Please enter valid train pnr'
            })
            return;
        }

        if (!seat) {
            showToast({
                type: 'error',
                message: 'Please enter valid train seat'
            })
            return;
        }

        if (!amount) {
            showToast({
                type: 'error',
                message: 'Please enter valid amount'
            })
            return;
        }

        if (employee.length === 0) {
            showToast({
                type: 'error',
                message: 'Please enter a employee details'
            })
            return;
        }

        let guests = employee.map(val => val.email);
        let files = this.attachment.getValue();


        let data = {
            guests,
            amount: +amount,
            pnr,
            seat,
            train: {
                name: trainName,
                number: trainNumber
            },
            departure: {
                code: origin,
                time: departureTime
            },
            arrival: {
                code: destination,
                time: arrivalTime

            },
            files
        }

        post(`train/import`, data, (e, r) => {
            if (r) {
                showToast({
                    type: 'success',
                    message: 'Train ticket successfully booked'

                })
                window.location.reload('/')
            }
            if (e) {
                showToast({
                    type: 'error',
                    message: e.data.error
                })
            }
        })

    }



    render() {
        return (
            <div className="side-container">
                <TitleComponent title="Create Booking" subtitle="Train" />
                <div style={{ flex: 1, marginBottom: 15, marginTop: 20 }}>
                    <div style={{
                        position: 'relative'
                    }}>
                        <FloatInput style={{
                            height: 39
                        }} type="text" title="Origin Station Code" handleRef={ref => this._origin = ref} />
                    </div>
                </div>
                <div style={{ flex: 1, marginBottom: 8 }}>
                    <div style={{
                        position: 'relative'
                    }}>
                        <FloatInput style={{
                            height: 39
                        }} type="text" title="Destination Station code" handleRef={ref => this._destination = ref} />
                    </div>
                </div>




                <div style={{ flex: 1 }}>
                    <FloatInput style={{
                        height: 39
                    }} type="text" title="Amount" handleRef={ref => this._amount = ref} />
                </div>


                <div style={{
                    display: 'flex',
                    marginBottom: 15,
                    marginTop: 20
                }}>
                    <div style={{
                        flex: 1,
                        marginRight: 15
                    }}>

                        <DateTimePickers title="Departure Time"
                            handleRef={ref => {
                                this._departureTime = ref
                            }}
                        />
                    </div>
                    <div style={{
                        flex: 1
                    }}>
                        <DateTimePickers title="Arrival Time"
                            handleRef={ref => {
                                this._arrivalTime = ref
                            }}
                        />
                    </div>
                </div>

                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1, marginRight: 15 }}>
                        <FloatInput style={{
                            height: 39
                        }} type="text" title="Train Name" handleRef={ref => this._trainName = ref} />
                    </div>
                    <div style={{ flex: 1 }}>
                        <FloatInput style={{
                            height: 39
                        }} type="text" title="Train Number" handleRef={ref => this._trainNumber = ref} />
                    </div>
                </div>

                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1, marginRight: 15 }}>
                        <FloatInput style={{
                            height: 39
                        }} type="text" title="Train Pnr" handleRef={ref => this._pnr = ref} />
                    </div>
                    <div style={{ flex: 1 }}>
                        <FloatInput style={{
                            height: 39
                        }} type="text" title="Seat" handleRef={ref => this._seat = ref} />
                    </div>
                </div>


                <div style={{ display: 'flex', flexWrap: 'wrap' }}>

                    {
                        this.state.employees.map(this.mapEmployee)
                    }
                    <div onClick={() => {
                        this.setState({
                            ...this.state,
                            chooseEmployee: true
                        })
                    }} style={{
                        display: 'flex',
                        marginTop: 15,
                        alignItems: 'center',
                        background: "#fafafa",
                        border: '1px solid #eee',
                        borderRadius: 22,
                        position: 'relative',
                        padding: '12px 20px',
                        width: '49%',
                        cursor: 'pointer',
                        flexDirection: 'row'
                    }}>
                        <p className="label-back" style={{
                            fontWeight: 400,
                            color: "#555555",
                            fontSize: 13,
                            position: 'absolute',
                            top: -8,
                            zIndex: 11,
                            left: 20,
                            marginRight: 10
                        }}>
                            Traveller
                        </p>
                        <p style={{
                            fontWeight: 600,
                            color: "#454545",
                            fontSize: 15,
                        }}>{"Add Traveller"}</p>
                    </div>
                </div>


                <ChooseTraveller shown={this.state.chooseEmployee} close={() => this.setState({
                    ...this.state,
                    chooseEmployee: false
                })} callback={data => {
                    let arr = this.state.employees.filter(val => val.reference !== data.reference);

                    arr.push(data)
                    this.setState({
                        ...this.state,
                        employees: [...arr],
                        chooseEmployee: false
                    })

                }} />

                <Attachment
                    handleRef={ref => this.attachment = ref}
                />
                <div onClick={this._newBooking} className="btn btn-portlet" style={{
                    marginTop: 50,
                    height: 42,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }} >Create Booking</div>

            </div>
        );
    }
}

export default Index;