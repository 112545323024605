import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Store from '../Redux'
import {Provider} from 'react-redux';
import Login from './Login'
import ResetPassword from './ResetPassword'
import Redirect from './Dashboard'
import '../Components/libs/index.css'
import 'react-dates/lib/css/_datepicker.css';
import Pages from '../Components/Pages'

const ReduxStore = Store();


const Index =
	(
		<Provider store={ReduxStore}>
			<BrowserRouter>
				<Switch>
					<Route exact={true}  path="/" component={Login}/>
					<Route exact={true}  path="/login" component={ResetPassword}/>
					<Route  path="/dashboard" component={Redirect}/>
					<Route  path="/app" component={Pages}/>
				</Switch>
			</BrowserRouter>
		</Provider>
	);

export default Index;
