import React from 'react'
//import {get} from "../../../../Model/Network/Config/Axios";
import {BookingSmallItem} from "../../Common/BookingSmallItem/BookingSmallItem";
import {v4} from 'uuid';
import Model from './Model';

class Sidebar extends React.Component {

	constructor() {
		super();
		this.Model = new Model();
	}
	
	state = {
		requests:[]
	}

	componentDidMount(){
		this.Model.fetch((e,r) => {
			if(r) {
				this.setState({
					...r
				})
			}
		})
	}

	componentWillUnmount() {
		this.Model.destroy();
	}

	mapItem = item => {
		return(
			<BookingSmallItem history={this.props.history} key={v4()} data={item}/>
		)
	}

	render() {
		return (
			<div className="right-side-bar" style = {{overflowY:'scroll'}}>
				<p style={{
					fontSize:31
				}} className="title">
					Booking Requests
				</p>
				<p style={{
					fontSize: 15,
					color: "#898989"
				}}>
					All active booking requests are visible below.
				</p>
				<div style={{
					display:"flex",
					flexWrap:'wrap'
				}}>
					{
						this.state.requests.map(this.mapItem)
					}
					{
						this.state.requests.length===0?
							<p style={{
								marginTop:20
							}}>
								No active booking requests found in our repository.
							</p>:null
					}
				</div>
			</div>
		)
	}

}

export default Sidebar