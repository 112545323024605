

class Presentor {

    constructor(Model) {
        this.Model = Model;
    }

    setView(View) {
        this.View = View;
    }

    destroy = () => {
        this.Model.destroy()
    }




    init = () => {
      
        this.Model.fetchSupportTicket(this.View.getName(), r => {
            if (r) {
                this.View.load(r)
            }
        })
    }

}


export default Presentor;