
class Presentor {

    constructor(Model) {
        this.Model = Model;
    }

    setView(View) {
        this.View = View;
    }
    
    destroy = () => {
        this.Model.destroy()
    }

    init = () => {
        this.Model.fetch(this.View.id(), (e, r) => {
            if (r)
                this.View.load(r.ticket)
        })
    }

    sendEmail = (data) => {
        this.Model.sendEmail(data, this.View.id(), (e, r) => {
            if (r) {
                this.View.onSendEmail()
            }
        })
    }


    createSupportTicket = (data) => {
        this.Model.createSupportTicket(data, (e, r) => {
            this.View.onCreateSupportTicket(r);
        })
    }
}

export default Presentor;