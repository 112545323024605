import React from 'react'
import { Link } from "react-router-dom";

class TrainTicketItem extends React.Component {

    render() {
        let item = this.props.data;
        return (
            <div className="border-bottom" style={{ padding: 10 }}>
                <div className="linear-layout horizontal" style={{ flex: 1 }}>
                    <div style={{ flex: 1 }}>
                        <p style={{ fontWeight: 600, fontSize: 18 }}>{item.trainName}</p>
                        <p style={{ color: '#878787', fontSize: 12 }}>TrainNumber: {item.trainNumber}</p>

                        <p style={{ fontSize: 12, color: '#878787' }} className="top">Transaction Id:{item.bookingId}</p>

                    </div>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <p style={{ fontWeight: 600 }}>{item.allPassenger[0].name}</p>
                        <p style={{ fontSize: 12, color: '#7f7f7f' }}>{item.date}</p>
                    </div>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                        {
                            this.props.hide ?
                                <p style={{ fontWeight: 600 }}>{
                                    item.service
                                }</p> : null
                        }
                        <p style={{ color: '#7f7f7f' }}> Amount ₹{item.bookingAmount}</p>
                        <div style={{ display: 'flex' }}>
                            {
                                this.props.onCancel ? <p onClick={() => this.props.openCancel(item.transactionId)} className="btn btn-portlet" style={{
                                    color: '#fff',
                                    fontSize: 9,
                                    height: 25,
                                    cursor: 'pointer',
                                    width: 120,
                                    padding: '4px 6px',
                                    borderRadius: '18px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginTop: 8,
                                    background: '#f44336',
                                    borderColor: '#f44336',
                                    marginRight: 8
                                }}>Cancel Ticket</p>
                                    : null
                            }
                            <Link to={`/app/bus/tickets/${item.transactionId}`} className="btn btn-portlet" style={{
                                color: '#fff',
                                fontSize: 9,
                                height: 25,
                                cursor: 'pointer',
                                width: 100,
                                padding: '4px 6px',
                                borderRadius: '18px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: 8
                            }}>View Details</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export {
    TrainTicketItem
}