import React from 'react'
import Moment from "moment";

class BookingDetails extends React.Component {

	render() {
		let hotel = this.props.hotel;
		return (
			<div>
				<div style={{
					display: 'flex',
					flexDirection: 'row'
				}}>
					<img src={hotel.image} alt="hotel" style={{
						width: 60,
						height: 60,
						borderRadius: 30,
						background: "#A6A0FB",
						objectFit: 'cover',
						marginRight: 12
					}}/>
					<div>
						<p style={{
							fontSize: 27,
							color: "#454545",
							fontWeight: 500,
							marginTop: -4
						}}>
							{hotel.name}
						</p>
						<p style={{
							fontSize: 15,
							color: "#898989"
						}}>
							{hotel.address.address}
						</p>
					</div>
				</div>
				<p style={{
					fontSize: 21,
					fontWeight: 600,
					color: "#454545",
					marginTop: 28,
					marginBottom: 12
				}}>
					Booking Details
				</p>
				<p style={{
					marginBottom:12,
					fontSize:13,
					color:"#898989"
				}}>
					Details of selected hotel is shown below.
				</p>
				<div>
					<div style={{
						display: 'flex',
						alignItems: 'center',
						height: 35
					}}>
						<img src={require('./right.png')} alt="right" style={{
							width: 14,
							height: 14
						}}/>
						<p style={{
							width: 300,
							paddingLeft: 15,
							paddingRight: 15,
							fontWeight: 600
						}}>
							{Moment(this.props.checkIn, "DD-MM-YYYY").format("ddd, DD MMM YYYY")}
						</p>
						<p style={{
							width: 300,
							paddingLeft: 15,
							paddingRight: 15,
							color: "#898989",
							fontSize: 13
						}}>
							Check in Time: 12:00 PM
						</p>
					</div>
					<div style={{
						display: 'flex',
						alignItems: 'center',
						height: 35
					}}>
						<img src={require('./right.png')} alt="right" style={{
							width: 14,
							transform: "rotate(180deg)",
							height: 14
						}}/>
						<p style={{
							width: 300,
							paddingLeft: 15,
							paddingRight: 15,
							fontWeight: 600
						}}>
							{Moment(this.props.checkOut, "DD-MM-YYYY").format("ddd, DD MMM YYYY")}
						</p>
						<p style={{
							width: 300,
							paddingLeft: 15,
							paddingRight: 15,
							color: "#898989",
							fontSize: 13
						}}>
							Check out Time: 11:00 AM
						</p>
					</div>

					<div style={{
						display: 'flex',
						alignItems: 'center',
						height: 35
					}}>
						<img src={require('./user.png')} alt="right" style={{
							width: 14,
							height: 14
						}}/>
						<p style={{
							paddingLeft: 15,
							paddingRight: 15,
							fontWeight: 600
						}}>
							{this.props.rooms.length} X {hotel.room.name}
						</p>
					</div>
					<div style={{
						display: 'flex',
						alignItems: 'center',
						height: 35
					}}>
						<img src={require('./rupee.png')} alt="right" style={{
							width: 14,
							height: 14
						}}/>
						<p style={{
							paddingLeft: 15,
							paddingRight: 15,
							fontWeight: 600
						}}>
							INR {hotel.room.pricePerNight} Per Night / Per Room
						</p>
					</div>
				</div>
			</div>
		)
	}

}

export default BookingDetails