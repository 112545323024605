import React from 'react';
import { Modal } from '../../../../libs/Modal/Modal';
import { FloatInput } from '../../../../libs/FloatLabelInput/'
import { showToast } from '../../../../libs/Toast/ToastCallback';
import { NormalTable } from '../../../../Tables/NormalTable/NormalTable';
import { SupportItem } from "../../../Common/SupportItem";
import _ from 'lodash';
import axios from '../../../../../Model/Network/Config/Axios';
import { connect } from 'react-redux';

class View extends React.Component {

    constructor(props) {
        super(props);
        this.presentor = props.presentor;
        this.presentor.setView(this);
        this.state = {
            supportModal: true,
            tickets: [],
            service: '',
            services: {},
            unassing: [],
            images:[]
        }
    }

    getService() {
        return this.props.service;
    }

    componentDidMount() {
            //this.presentor.fetchList();
            //this.presentor.fetchPending(this.props.auth);

    }


    _upload = () => {
        let file = this._file.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            axios({
                method: 'post',
                url: 'https://file.eka.io/file',
                data: formData,
                headers: {
                    clientId: '422dc3e2-a960-4941-9a7a-af64732157b5',
                    clientSecret: "9cd4ce3e-6ea6-45d7-bf92-06c9c7a69873"
                }
            })
                .then(res => {
                    let images = this.state.images;
                    images.push({
                        type:'IMAGE',
                        url:res.data.url
                    })
                })

        }
    } 

    onFetchList = r => {
        this.setState({
            ...this.state,
            services:r,
            service:r[Object.keys(r)[0]]
        })
    }

    componentWillUnmount() {
        this.presentor.destroy()
    }



    load = (r) => {
        this.setState({
            tickets: r.tickets
        })
    }

    loadPending = (r) => {
        this.setState({
            unassing:r
        })
    }

    createSupport = () => {

        let subject = this.title.value();
        let description = this._description.value;

        if (!subject) {
            showToast({
                type: 'error',
                message: 'Title Value is not be Null'
            })
            return
        }

        if (!description) {
            showToast({
                type: 'error',
                message: 'Description Value is not be Null'
            })
            return
        }

        let type = _.invert(this.state.services)[this.state.service]

        let data = {
            subject,
            description,
            type,
            ticket_creator: {
                type: 'TDESK',
                user_email_id: `${this.props.auth.config.email}`,
                name: `${this.props.auth.config.name}`
            },

        }


        axios({
            method: 'POST',
            url: 'http://142.93.211.53:7001/api/ticket/new',
            headers: {
                'Content-type': 'application/json',
                'secret': 'a55c1326986d4de5ab2b3eafccf339a1e127ad5ecc6d41878c1b18fa629ec2169ddc39882b4a440f81ca8d4f30a51b563dacd643025644929144fe463b425235'
            },
            data: data
        })
            .then(res => {
                this.presentor.fetchPending(this.props.auth);
                this.props.closeModal()
            });

    }

    onCreateSupportTicket = () => {
        this.title.setValue("");
        this.description.setValue("");
        this.props.closeModal();
        this.presentor.init();
    }


    mapItem = (item, index) => {
        return (<SupportItem service={this.getService()} data={item} key={index} />)
    }


    mapServices = (key, value) => {
        return (
            <option key={key}>{value}</option>
        )
    }

    mapImages = (item,index) => {
        return (

            <img key = {index} src = {item.url} alt = "" style = {{
                width:100,
                height:100,
                objectFit:'contain'
            }} />
        )
    }




    render() {
        return (
            <div>
                <div style={{
                    marginTop: -30
                }}>
                    <NormalTable headerStyle={{
                        borderBottom: 'none'
                    }} headers={[
                        {
                            key: 'title',
                            name: '',
                            weight: 1
                        },
                        {
                            key: 'assign',
                            name: '',
                            weight: 1
                        },
                        {
                            key: 'status',
                            name: '',
                            weight: 1
                        },

                    ]} data={this.state.unassing} pagination={true} mapItem={this.mapItem} />
                </div>
                <Modal close={() => this.props.closeModal()}
                    title="Support Ticket"
                    des="Create your support Ticket"
                    onSave={this.createSupport}
                    style={{
                        save: {
                            width: '100%',
                            height: 40,
                            backgroundColor: '#1F2888',
                            border: '1px solid #1F2888',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }
                    }}
                    show={this.props.supportModal}>
                    <p style={{
                        color: '#454545',
                        fontSize: 13,
                        fontWeight: 500,
                        marginBottom: 6
                    }}>Select Service:</p>
                    <select style={{
                        width: '100%',
                        height: 46,
                        background: '#fff',
                        border: '1px solid #dedede',
                        borderRadius: 0,
                        fontSize: 17
                    }} value={this.state.service} onChange={e => this.setState({
                        service: e.target.value
                    })}>
                        {
                            _.map(this.state.services, (value, key) => {
                                return this.mapServices(key, value)
                            })
                        }
                    </select>
                    <FloatInput type="text" title="Title" handleRef={ref => this.title = ref} />
                    

                    <p style = {{
                        color:'#454545',
                        fontSize:13,
                        fontWeight:500,
                        margin:'5px 0'
                    }}>Description:</p>
                    <textarea placeholder = "Description" ref = {ref=>this._description = ref} style = {{
                        width:'100%',
                        border:'1px solid #dedede',
                        height:70,
                        padding:'8px 10px',
                        fontSize:16,
                        marginTop:5       
                    }} />

                   
                </Modal>
            </div>
        )
    }
}




export default connect(state=>{
	return{
		auth : state.auth
	}
})(View);