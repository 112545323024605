import React from 'react';


class FilterNames extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            result: [],
            list: []
        }
    }


    handleFilter = () => {
        let employee = this.props.employees;
        let result = employee.filter(val => val.name.toLowerCase().includes(this.state.name.toLocaleLowerCase()));
        this.setState({
            ...this.state,
            result
        })


    }

    

    render() {
        return (
            <div>
                <div style={{
                    width: 250,
                    height: 35,
                    border: '1px solid #dedede',
                    padding: '2px 8px',
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                    marginBottom: 10
                }}>
                    <input style={{
                        height: '85%',
                        fontSize: 15,
                        width: '95%',
                        border: 'none'
                    }}
                        type="text"
                        value={this.state.name} onChange={e => {
                            this.setState({
                                name: e.target.value
                            }, this.handleFilter)
                        }}
                        placeholder="Filter by name"
                    />

                    {this.state.name.length > 0 ?
                        (
                            <div style={{
                                position: 'absolute',
                                background: '#fff',
                                border: '1px solid  #dedede',
                                width: 250,
                                zIndex: 2,
                                maxHeight: 325,
                                top: 35,
                                left: 0,
                                overflowY: 'scroll'
                            }}>
                                {
                                    this.state.result.length > 0 ?
                                        (
                                            this.state.result.map((val, index) => {
                                                return (
                                                    <div onClick={() => {
                                                        let narr = val
                                                        let arr = [...this.state.list];
                                                        arr.push(val)
                                                        this.setState({
                                                            ...this.state,
                                                            list: arr,
                                                            result: [],
                                                            name: ''
                                                        },()=>this.props.filterEmployee(narr))
                                                    }} className="luna-hover" style={{
                                                        padding: '12px',
                                                        borderBottom: '1px solid #dedede',
                                                        cursor: 'pointer',
                                                    }} key={index}>{val.name}</div>
                                                )
                                            })
                                        )

                                        :
                                        (
                                            <div className="luna-hover" style={{
                                                padding: '12px',
                                                cursor: 'pointer'
                                            }}>No Result Found</div>
                                        )
                                }
                            </div>)
                        : null

                    }

                </div>
                <div style={{
                    display: 'flex',
                    marginRight: 5,
                    marginBottom: 30
                }}>
                    {
                        this.props.list.map((val, index) => {
                            return (
                                <div key = {index} style={{
                                    border: '2px solid #321CBF',
                                    padding: '4px 10px',
                                    height: 20,
                                    color: '#321CBF',
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginRight: 5,
                                    boxSizing: 'content-box',
                                    cursor: 'pointer'
                                }}>
                                    <span onClick={() => {
                                       this.props.removeList(val)
                                    }} style={{ marginRight: 5 }}>×</span>
                                    <span>NAME: {val.name}</span>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}

export { FilterNames }