import { get, post } from "../../../../Model/Network/Config/Axios";
import { showToast } from "../../../libs/Toast/ToastCallback";


class Model {

	constructor() {
		this.Disposables = [];
	}

	destroy = () => {
		this.Disposables.forEach(item => {
			if (item.isCancellable())
				item.cancel()
		})
	}

	search = (data, sessionId, cb) => {
		this.Disposables.push(get(`/flight/search/${data}`, cb, { sessionId }));
	}

	getRequest = (id, cb) => {
		this.Disposables.push(get(`/flight/request/${id}`, cb));
	}

	postSelection = (id, selections) => {
		this.Disposables.push(post(`/flight/request/${id}/selections`, selections, (e, r) => {
			if (r) {
				showToast({
					type: 'success',
					message: "Selections posted successfully!"
				})

			} else {
				showToast({
					type: 'error',
					message: e.data.message
				})
			}
		}))
	}

}

export default Model