import React from 'react'
import { EmptyState } from '../../../../libs/EmptyState/index';
import emptyimage from '.././k2.png';
import { NormalTable } from '../../../../Tables/NormalTable/NormalTable';
import { Modal } from '../../../../libs/Modal/Modal';
import { CabTicketItem } from '../../../Common/BookingItem/CabItem';

class View extends React.Component {

	constructor(props) {
		super(props);
		this.presentor = props.presentor;
		this.presentor.setView(this);
		this.state = {
			upcoming: [],
			emptystate: false,
			cancelTicketBus: false,
			cancelFare: false,
			transId: undefined,
			cancellationAmount: undefined
		}
	}

	componentDidMount() {
		this.presentor.init();
	}

	componentWillUnmount() {
		this.presentor.destroy();
	}

	load = r => {
		if (r.tickets.length > 0) {
			this.setState({
				upcoming: r.tickets,
				emptystate: false
			})
		} else {
			this.setState({
				...r,
				emptystate: true
			})
		}

	}

	Item = (item, index) => {
		return (
			<CabTicketItem openCancel={this.cancelTicketModal} onCancel={true} data={item} key={index} />
		)
	}

	cancelTicketModal = (transId) => {
		this.setState({
			...this.state,
			cancelTicketBus: true,
			transId
		}, () => this.presentor.cancellationFare(this.state.transId))

	}

	onfetchFare = r => {
		this.setState({
			...this.state,
			cancelFare: true,
			cancellationAmount: r.amount
		})
	}

	onCancelTicket = r => {
		this.setState({
			...this.state,
			cancelTicketBus: false,
			cancellationAmount: undefined,
			transId: undefined
		})
		this.presentor.init();
	}


	sort = (a, b) => {
		return b.bookedOn - a.bookedOn;
	}


	render() {

		if (this.state.emptystate) {
			return <EmptyState title="No Cab Upcoming Booking Found" desc="Please do some bookings" image={emptyimage} />
		}

		let booking = this.state.upcoming.sort(this.sort);

		return (
			<div>
				<NormalTable headerStyle={{ border: 'none' }} headers={[
					{
						key: 'name',
						name: '',
						weight: ''
					},
					{
						key: 'details',
						name: '',
						weight: ''
					},
					{
						key: 'amount',
						name: '',
						weight: ''
					}
				]}
					mapItem={this.Item}
					pagination={true}
					data={booking}
				/>
				<Modal
					show={this.state.cancelTicketBus}
					close={() => {
						this.setState({
							cancelTicketBus: false
						})
					}}
					title="Bus Ticket"
					des="Please see the cancellation ticket fees"
					hideButton={true}
					onSave={() => { }}
				>
					{
						this.state.cancelFare ?
							(
								<div>
									<p style={{
										fontWeight: 700,
									}}>Refundable Amount is {this.state.cancellationAmount}</p>
									<div onClick={() => {
										this.presentor.cancelTicket(this.state.transId)
									}} style={{
										height: 30,
										marginTop: 20,
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center'
									}} className="btn btn-portlet">Cancel</div>
								</div>
							) :
							(
								<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
									<div className="dot-pulse" />
								</div>
							)
					}
				</Modal>
			</div>
		)
	}

}

export default View