import React from 'react';
import {Link} from 'react-router-dom';

class FlightItem extends React.Component {


	render() {
		let item = this.props.data;
		return (
			<div className="border-bottom" style={{
				padding: 10
			}}>
				<div className="linear-layout horizontal" style={{ flex: 1 }}>
					<div style={{
						flex: 1,

					}}>
						<p style={{ fontWeight: 500, fontSize: 18 }}>{item.transactionId}</p>
						<p style={{ fontWeight: 600, fontSize: 18 }}>{item.airline}</p>
						<p style={{ fontSize: 12,color:'#878787' }} className="top">Booking Id:{item.bookingId ? item.bookingId : null}</p>
					</div>
					<div style={{
						flex: 1
					}}>
						<p style={{
							fontWeight: 600,
							fontSize: 14,
							textAlign: 'center'
						}} className="top">{item.travellers ? item.travellers[0].firstName : null}</p>
						<p className="bottom" style={{
							textAlign: 'center',
							fontSize: 13,
							color:'rgb(127,127,127)',
							marginTop:5
						}} >
							{item.origin} - {item.destination}
						</p>
						<p style={{
							fontSize: 12,
							textAlign: 'center',
							color:'rgb(127,127,127)'
						}}>{item.date}</p>
					</div>
					<div style={{
						flex: 1, display: 'flex', alignItems: 'flex-end', flexDirection: 'column'
					}}>
						{this.props.hide ? <p style = {{
							fontWeight:600,
						}}>Flight</p> : null}
						<p style={{ color: '#7f7f7f' }}> Amount ₹{item.amount}</p>
						<div style={{ display: 'flex' }}>
							{
								this.props.onCancel ? <p onClick={() => this.props.openCancel(item.transactionId)} className="btn btn-portlet" style={{
									color: '#fff',
									fontSize: 10,
									height: 25,
									cursor: 'pointer',
									width: 120,
									padding: '4px 6px',
									borderRadius: '18px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									marginTop: 8,
									background: '#f44336',
									borderColor: '#f44336',
									marginRight: 8
								}}>Cancel/Reschedule</p>
									: null
							}
							<Link to={`/app/flights/tickets/${item.transactionId}`} className="btn btn-portlet" style={{
								color: '#fff',
								fontSize: 9,
								height: 25,
								cursor: 'pointer',
								width: 100,
								padding: '4px 6px',
								borderRadius: '18px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								marginTop: 8
							}}>View Details</Link>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
export {
	FlightItem
}
