import { get } from '../../../../../Model/Network/Config/Axios'


class Model {

    constructor() {
        this.Disposables = [];
    }

    destroy = () => {
        this.Disposables.forEach(item => {
            if (item.isCancellable())
                item.cancel()
        })
    }

    fetch = (data, cb) => {
        this.Disposables.push(get(`bus/bookings.refund`, cb, data));
    }


}


export default Model;