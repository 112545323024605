import React from 'react'
import Spinner from "react-spinner-material";
import { Portlet } from '../Portlet';

function PageLoader() {
	return (
		<div style={{
			flex: 1,
			width: '100%',
			padding: 30
		}}>
			<div style={{
				margin: '0 auto',
				background: "var(--white-color)",
				borderRadius: 25,
				height: 50,
				width: 50,
				border: '1px solid var(--border-color)',
				padding: 7,
				boxShadow: "2px 2px 1px rgba(1,1,1,.2)",
			}}>
				<Spinner radius={36} visible={true} color="var(--primary-color)"/>
			</div>
		</div>
	)

}


function PageBlank({text}) {
	return (
	   <Portlet>
		<div style={{
			inset:0,
			background:'rgba(0,0,0,0.4)',
			width:'100%',
			zIndex:1000000,
			position:'fixed'
		}}>
		 <div style={{
			flex: 1,
			width: '100%',
			display:'flex',
			justifyContent:'center',
			marginTop:40,
			
		}}>
			<span style={{
				fontWeight:'bold',
				background:'#fff',
				padding:"8px 15px",
				borderRadius:4
			}}>{text}</span>
		 </div>
		</div>
		</Portlet>
	)

}
function LoadingPage() {
	return (
		<div style={{
			flex: 1,
			width: '100%',
			padding: 30
		}}>
			<div style={{
				margin: '0 auto',
				background: "var(--white-color)",
				borderRadius: 25,
				height: 50,
				width: 50,
				border: '1px solid var(--border-color)',
				padding: 7,
				boxShadow: "2px 2px 1px rgba(1,1,1,.2)",
			}}>
				<Spinner radius={36} visible={true} color="var(--primary-color)"/>
			</div>
		</div>
	)

}

export {
	PageLoader,LoadingPage,PageBlank
}
