import React from 'react';
import {TitleComponent} from '../../Common/TitleComponent';
import {FloatInput} from '../../../libs/FloatLabelInput';
import {showToast} from '../../../libs/Toast/ToastCallback';


class View extends React.Component {

    constructor(props) {
        super(props);
        this.presentor = props.presentor;
        this.presentor.setView(this);
        this.state = {
            message:''
        }
    }

    reportIssue = () => {
        let title = this._topic.value();
        let message = this.state.message;

        if(!title) {
            showToast({
                type:'error',
                message:'Please Enter Topic of Issue'
            })
            return;
        }
        if(!message) {
            showToast({
                type:'error',
                message:'Please Enter Message of Issue'
            })
            return;
        }

        let data = {
            title,
            message
        }

        this.presentor.issue(data)
    }

    onIssueCreate = ()=> {
        this._topic.setValue("");
        this.setState({
            message:''
        })
        showToast({
            type:'success',
            message:'we have received your query, we will get back to you soon.'
        })
        return;
    }

    render() {
        return(
            <div className = "side-container">
                  <TitleComponent subtitle="Traveller" title="Report an Issue"/>
                  <div style = {{marginTop:20}}>
                    <FloatInput title = "Topic" handleRef = { ref => this._topic = ref} />
                    <p style = {{
                        color:'#454545',
                        fontSize:13,
                        fontWeight:500,
                        marginBottom:8
                    }}>Message:</p>
                    <textarea style = {{
                        width:'100%',
                        height:'400px',
                        border:'1px solid #dedede',
                        padding:15,
                        fontSize:17,
                        color:'#333333'
                    }} type = "text" value = {this.state.message} onChange = {e => this.setState({
                        message:e.target.value
                    })} placeholder = "Message"  />
                  </div>
                  <div style = {{
                      fontSize:18,
                      marginTop:20
                  }} className = "btn btn-portlet" onClick = {this.reportIssue}>Report</div>
            </div>
        )
    }
}

export default View;