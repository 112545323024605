import { get } from "../../../../../../Model/Network/Config/Axios";


class Model {


    constructor() {
        this.Disposables = [];
    }

    destroy = () => {
        this.Disposables.forEach(item => {
            if (item.isCancellable())
                item.cancel()
        })
    }

    fetch = (code, cb) => {
        this.Disposables.push(get(`bookings/employee/${code}/upcoming`, cb));
    }

}

export default Model