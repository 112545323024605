import React from 'react'
import { EmptyState } from '../../../../libs/EmptyState/index';
import emptyimage from '.././k2.png';
import { NormalTable } from '../../../../Tables/NormalTable/NormalTable';
import { BusTicketItem } from "../../../Common/BookingItem/BusItem";
import { HotelTicketItem } from "../../../Common/BookingItem/HotelItem";
import { FlightItem } from '../../../Common/BookingItem/FlightItem'


class View extends React.Component {

	constructor(props) {
		super(props);
		this.presentor = props.presentor;
		this.presentor.setView(this);
		this.state = {
			upcoming: [],
			emptystate: false
		}
	}

	componentDidMount() {
		this.presentor.init();
	}

	componentWillUnmount() {
		this.presentor.destroy()
	}


	load = r => {
		if (r.tickets.length > 0) {
			this.setState({
				upcoming: r.tickets,
				emptystate: false
			})
		} else {
			this.setState({
				...r,
				emptystate: true
			})
		}

	}

	Item = (item, index) => {
		if(item) {
		if (item.service && item.service.toLowerCase() === "hotel")
			return (
				<HotelTicketItem hide={true} data={item} key={index} />
			)
		else if (item.service && item.service.toLowerCase() === "bus")
			return (
				<BusTicketItem hide={true} data={item} key={index} />
			)
		else {
			return (
				<FlightItem hide={true} data={item} key={index} />
			)
		}
	}
}

	render() {
		if (this.state.emptystate) {
			return <EmptyState title="No  Upcoming Booking Found" desc="Please do some bookings" image={emptyimage} />
		}

		return (
			<div>
				<NormalTable headerStyle={{ border: 'none' }} headers={[
					{
						key: 'name',
						name: '',
						weight: ''
					},
					{
						key: 'details',
						name: '',
						weight: ''
					},
					{
						key: 'amount',
						name: '',
						weight: ''
					}
				]}
					mapItem={this.Item}
					pagination={true}
					data={this.state.upcoming}
				/>
			</div>
		)
	}

}

export default View