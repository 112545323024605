
class Presentor {

    constructor(Model) {
        this.Model = Model;
    }

    setView(View) {
        this.View = View;
    }

    destroy = () => {
        this.Model.destroy()
    }


    init = (data) => {
        this.Model.fetch(data, (e, r) => {
            if (r) {
                this.View.load(r)
            }
        })
    }
}

export default Presentor;