import React from 'react'
import Moment from "moment";

class Sidebar extends React.Component {

	render() {
		let data = this.props.data;
		if(!data)
			return (
				<div>

				</div>
			)

		let bus = data.bus;
		return(
			<div>
				<p style={{
					fontSize:31
				}} className="title">
					{bus.name}
				</p>
				<p style={{
					fontSize: 15,
					color: "#898989"
				}}>
					{bus.label}
				</p>
				<p style={{
					fontSize: 21,
					color: "#454545",
					fontWeight:700,
					marginTop:22
				}}>
					Booking Details
				</p>
				<p style={{
					marginBottom:12,
					fontSize:14,
					color:"#898989"
				}}>
					Details of selected bus is shown below.
				</p>
				<div>
					<div style={{
						display: 'flex',
						alignItems: 'center',
						height: 35
					}}>
						<img src={require('./user.png')} alt="right" style={{
							width: 14,
							height: 14
						}}/>
						<p style={{
							paddingLeft: 15,
							paddingRight: 15,
							fontWeight: 600
						}}>
							{bus.seats.length} X Bus Seats
						</p>
					</div>
					<div style={{
						display: 'flex',
						alignItems: 'center',
						height: 35
					}}>
						<img src={require('./right.png')} alt="right" style={{
							width: 14,
							transform: "rotate(180deg)",
							height: 14
						}}/>
						<p style={{
							width: 200,
							paddingLeft: 15,
							paddingRight: 15,
							fontWeight: 600
						}}>
							{
								data.route.fromName
							}
						</p>
						<p style={{
							width: 300,
							paddingLeft: 15,
							paddingRight: 15,
							color: "#898989",
							fontSize: 13
						}}>

							{Moment(bus.departure).format("ddd, DD MMM HH:mm a")}
						</p>
					</div>
					<div style={{
						display: 'flex',
						alignItems: 'center',
						height: 35
					}}>
						<img src={require('./right.png')} alt="right" style={{
							width: 14,
							height: 14
						}}/>
						<p style={{
							width: 200,
							paddingLeft: 15,
							paddingRight: 15,
							fontWeight: 600
						}}>
							{
								data.route.toName
							}
						</p>
						<p style={{
							width: 300,
							paddingLeft: 15,
							paddingRight: 15,
							color: "#898989",
							fontSize: 13
						}}>
							{Moment(bus.arrival).format("ddd, DD MMM  HH:mm a")}
						</p>
					</div>
					<p style={{
						fontSize: 21,
						color: "#454545",
						fontWeight:700,
						marginTop:22
					}}>
						Bus Layout
					</p>
					<p style={{
						marginBottom:12,
						fontSize:14,
						color:"#898989"
					}}>
						Details of selected bus layout is shown below.
					</p>
					{/* <BusLayout layout={bus.layout} seats={bus.seats.map(item=>item.seatCode)}/> */}
				</div>
			</div>
		)
	}

}

export default Sidebar