import { post } from "../../../../Model/Network/Config/Axios";


class Model {

	constructor() {
		this.Disposables = [];
	}

	destroy = () => {
		this.Disposables.forEach(item => {
			if (item.isCancellable())
				item.cancel()
		})
	}


	continue = (data, cb) => {
		this.Disposables.push(post(`/flight/hold`, data, cb));
	}

	book = (data, cb) => {
		this.Disposables.push(post('/flight/book', data, cb));
	}

}

export default Model;