import React from 'react'
import {Link} from "react-router-dom";

class SupportItem extends React.Component {

	render() {
		let item = this.props.data;
		let service = null;
		if(this.props.service === "hotels") {
			service = "hotel"
		} else if(this.props.service === "all"){
			service = "home"
		}
		return(
			<div style = {{paddingLeft:0,paddingRight:0}} className = "border-bottom fintrip-department-item table-item">
				<div className = "linear-layout  horizontal" style = {{flex:1,cursor:'pointer'}}>
					<div style = {{flex:1,display:'flex',flexDirection:'column'}}>
						<p style = {{fontWeight:'600'}}>{item.subject}</p>
						<p style = {{color:'#7f7f7f',fontSize:12,marginTop:4}}>{item.updatedAt}</p>
					</div>
					<div style = {{flex:1,display:'flex',justifyContent:'center',alignItems:'center'}}>
						<div>
							{item.ticketStatus !== "UNASSIGNED" ?
								<div style = {{height:28,padding:'0px 10px',color:"#454545",background:"#eeeeee",fontWeight:600,display:'flex',justifyContent:'center',alignItems:'center',fontSize:14}}>{`${item.userAssignedTo ? item.userAssignedTo.firstName + " " + item.userAssignedTo.lastName : null}`}</div>
								:
								<div style = {{height:24,padding:'0px 10px',fontWeight:600,borderRadius:32,display:'flex',justifyContent:'center',alignItems:'center',fontSize:14,color:'#444'}}>Not Assign</div>}
						</div>
					</div>
					<div style = {{flex:1,display:'flex',justifyContent:'flex-end'}}>
						<div>
							<div style = {{fontWeight:700,fontSize:12}}>{item.status}</div>
							<div style = {{display:'flex'}}>
								{
									this.props.onCancel?

										<p onClick={this.props.onCancel} className = "btn btn-portlet" style = {{color:'#fff',fontSize:10,height:25,cursor:'pointer',width:80,padding:'4px 6px',borderRadius:'18px',display:'flex',alignItems:'center',justifyContent:'center',background:'#f44336',borderColor:'#f44336',marginRight:8,marginTop:4}}>Close Ticket</p>
										:
										null
								}
								<Link to = {`/app/${service ? service : this.props.service}/support/${item.ticketId}`} className = "btn btn-portlet" style = {{color:'#fff',fontSize:10,height:25,cursor:'pointer',width:80,padding:'4px 6px',borderRadius:'18px',display:'flex',alignItems:'center',justifyContent:'center',marginTop:4}}>View Ticket</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

}

export {
	SupportItem
}