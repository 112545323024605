import { get } from "../../../../../../Model/Network/Config/Axios";


class Model {

  constructor() {
    this.Disposables = [];
  }

  destroy = () => {
    this.Disposables.forEach(item => {
      if (item.isCancellable())
        item.cancel()
    })
  }


  fetch = (code, date, cb) => {
    this.Disposables.push(get(`bookings/guest/${code}/past`, cb, date));
  }
}

export default Model