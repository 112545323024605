import React from 'react'
import BusSeat from "./BusSeat";

class BusLayout extends React.Component {


	render() {
		let selected = this.props.selected;
		let lower = this.props.lower;
		let layout = lower?this.props.layout["lower"]:this.props.layout["upper"];
		let seats = layout.seats;
		return (
			<div style={{
				flex:1,
				height:275,
				width:540,
				padding:8,
				marginTop:-4,
				flexDirection:'column-reverse',
				flexWrap:'wrap',
				display: 'flex',
			}}>
				{
					seats.map((item,i)=><BusSeat selectSeat={this.props.selectSeat}
												 unSelectSeat={this.props.unSelectSeat}
												 width={515/layout.rows} height={255/layout.cols}
												 key={i} selected={selected} data={item}/>)
				}
			</div>
		)
	}

}

export default BusLayout