import React, { Component } from 'react';
import DateTimePicker from 'react-datetime-picker';
import Moment from 'moment';


class DateTimePickers extends Component {
    state = {
        date: new Date(),
    }


    componentDidMount() {
        if (this.props.handleRef) {
            this.props.handleRef(this);
        }
    }

    onChange = date => {
        this.setState({
            date
        })
    }

    getValue = () => {
        return Moment(this.state.date).valueOf()
    }

    getDate = () => {
        return this.state.date;
    }

    setValue = () => {
        this.setState({
            date: ''
        })
    }

    render() {
        return (
            <div className="datetimepicker" style={{
                position: 'relative'
            }}>

                <div style={{
                    marginTop: 20,
                    height: 45,
                    border: '1px solid #dedede',
                    position: 'relative',
                    borderRadius: 0,
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0px 15px'
                }}>
                    <p style={{
                        fontSize: 13,
                        position: 'absolute',
                        top: -28,
                        left: 0,
                        fontWeight: 500
                    }}>{this.props.title}</p>
                    <div style={{
                        position: 'relative',
                        width: '100%'
                    }}>
                        <DateTimePicker
                            onChange={this.onChange}
                            value={this.state.date}
                            clockClassName="hidden-clock"
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export { DateTimePickers };
