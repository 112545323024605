import { get } from "../../../../Model/Network/Config/Axios";

class Model {


	constructor() {
		this.Disposables = [];
	}

	destroy = () => {
		this.Disposables.forEach(item => {
			if (item.isCancellable())
				item.cancel()
		})
	}


	fetchDetails = (code, cb) => {
		this.Disposables.push(get(`/guests/${code}`, cb));
	}

}

export default Model