import React from 'react';
import {Link} from 'react-router-dom'
import './action.css'
import Props from 'prop-types'

class TableActions extends React.Component {


    constructor(props){
        super(props)
        this.state = {
            shown: false,
        }
        this.showMenu = this.showMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
    }

    showMenu(event) {
        event.preventDefault();
        this.setState({ shown: true }, () => {
            document.addEventListener('click', this.closeMenu);
        });
    }


    closeMenu(event) {
        if (this.dropdownMenu && !this.dropdownMenu.contains(event.target)) {
            this.setState({ shown: false }, () => {
                document.removeEventListener('click', this.closeMenu);
            });

        }
    }

    onClick=(click)=>{
        this.setState({ shown: false }, () => {
            document.removeEventListener('click', this.closeMenu);
        });
        click()
    }

    mapItem = (item) =>{
		if(item.src){
			return(
				<Link key={item.name} className={item.center?'center':""} to={item.src}>{item.name}</Link>
			)
		}else
			return(
				<p key={item.name} className={item.center?'center':""} onClick={this.onClick.bind(this,item.click)}>{item.name}</p>
			)
    }

    render() {
        return (
            <div  ref={ref=>this.dropdownMenu=ref} className="tableAction">
				<div className="view">
					<svg onClick={this.showMenu} width="32" height="32" viewBox="0 0 32 32"
						 className="mc-icon-template-actionable mc-overflow-button-icon">
						<g fill="none" fillRule="evenodd">
							<g fill="#637282">
								<circle cx="10.5" cy="16.5" r="1.5"/>
								<circle cx="15.5" cy="16.5" r="1.5"/>
								<circle cx="20.5" cy="16.5" r="1.5"/>
							</g>
						</g>
					</svg>
				</div>
                {
                    this.state.shown
                        ? (
                            <div className="menu">
                                {
                                    this.props.items.map(
                                        this.mapItem
                                    )
                                }
                            </div>
                        )
                        : (
                            null
                        )
                }
            </div>
        )
    }

}

TableActions.propTypes={
    items:Props.array.isRequired
}

export {
	TableActions
}