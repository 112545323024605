export default [
	{
		"featureType": "administrative",
		"elementType": "labels.text.fill",
		"stylers": [
			{
				"color": "#444444"
			}
		]
	},
	{
		"featureType": "landscape",
		"elementType": "all",
		"stylers": [
			{
				"color": "#f2f2f2"
			}
		]
	},
	{
		"featureType": "poi",
		"elementType": "all",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "road",
		"elementType": "all",
		"stylers": [
			{
				"saturation": -100
			},
			{
				"lightness": 45
			}
		]
	},
	{
		"featureType": "road.highway",
		"elementType": "all",
		"stylers": [
			{
				"visibility": "simplified"
			}
		]
	},
	{
		"featureType": "road.arterial",
		"elementType": "labels.icon",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "transit",
		"elementType": "all",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "water",
		"elementType": "all",
		"stylers": [
			{
				"color": "#4983fe"
			},
			{
				"visibility": "on"
			}
		]
	},

	{
		"featureType": "poi.park",
		"elementType": "geometry",
		"stylers": [
			{
				"saturation": 1
			},
			{
				"lightness": -15
			},
			{
				"visibility": "on"
			},
			{
				"hue": "#00ff44"
			}
		]
	},
	{
		"featureType": "poi.school",
		"elementType": "all",
		"stylers": [
			{
				"hue": "#d7e4e4"
			},
			{
				"saturation": -60
			},
			{
				"lightness": 23
			},
			{
				"visibility": "on"
			}
		]
	},
]