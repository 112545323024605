import React from 'react';
import { Map } from '../../Common/Map';
import { post } from "../../../../Model/Network/Config/Axios";
import ImageZoom from "react-medium-image-zoom";
import Search from "stringquery";
import { TitleComponent } from "../../Common/TitleComponent";

class HotelItemExtended extends React.Component {

	constructor() {
		super();
		this.Disposables = [];

	}

	componentDidMount() {
		const params = Search(this.props.location.search);
		this.Disposables.push(post(`/hotels/details`, {
			hotelType: params.type,
			code: params.code,
			searchId: params.searchId
		}, (e, result) => {
			if (result) {
				this.setState({
					result,
					image: result.image ? result.image : result.images[0]
				})
			}
		}))
	}

	bookItem = (room) => {
		const params = Search(this.props.location.search);
		let sessionId = params.sessionId;
		let item = this.state.result;
		this.Disposables.push(post(`/hotels/${sessionId}/session.create`, {
			hotel: {
				...item,
				room
			}
		}, (e, r) => {
			if (r) {
				let url = `/app/hotel/book?sessionId=${r.sessionId}&searchId=${params.searchId}`;
				if(params.requestId) {
					 url = `/app/hotel/book?sessionId=${r.sessionId}&searchId=${params.searchId}&requestId=${params.requestId}`;
				}
				this.props.history.push(
					url
				)
			}
		}))

	}

	componentWillUnmount() {
		this.Disposables.forEach(item => {
			if (item.isCancellable())
				item.cancel()
		})
	}

	state = {
		image: undefined,
		result: undefined
	}

	render() {
		let result = this.state.result;
		if (!result) {
			return (
				<div className="border-top">
					<div className="side-container" style={{ borderTop: 'none' }}>
						<TitleComponent subtitle="Hotels" title="Hotel Details" />
						<div style={{
							height: 450,
							marginTop: 30,
							background: "#C9C8FC",
							position: 'relative'
						}}>
							<img alt="Hotel" src={this.props.image} style={{
								width: '100%',
								height: 450,
								objectFit: 'cover'
							}} />
						</div>
						<div style={{
							alignItems: 'center',
							justifyContent: 'center',
							paddingTop: 20,
							paddingBottom: 10
						}}>
							<div className="loader" />
						</div>
					</div>
				</div>
			)
		}
		return (
			<div className="border-top">
				<div className="side-container" style={{ borderTop: 'none' }}>
					<TitleComponent subtitle="Hotels" title="Hotel Details" />
					<div style={{
						height: 450,
						marginTop: 30,
						background: "#C9C8FC",
						position: 'relative'
					}}>
						<img alt="Hotel" src={this.state.image} style={{
							width: '100%',
							height: 450,
							objectFit: 'cover'
						}} />
					</div>
					<div>
						<p style={{
							fontWeight: 800,
							fontSize: 24,
							color: "#454545",
							marginTop: 20
						}}>
							{result.name}
						</p>
						<p style={{
							fontSize: 15,
							color: "#898989",
							marginTop: 2
						}}>
							{result.address.address}
						</p>
						<p dangerouslySetInnerHTML={{ __html: result.description }} style={{
							marginTop: 15,
							fontSize: 14,
							color: "#676767"
						}} />

						<p style={{
							fontWeight: 700,
							fontSize: 25,
							color: "#454545",
							marginTop: 20
						}}>
							Hotel Images
						</p>
						<p style={{
							fontSize: 14,
							color: "#898989"
						}}>
							All images of hotel and rooms are visible below. Click on image below to zoom image.
						</p>
						<div style={{
							overflowX: 'auto',
							scrollbarWidth: 0,
							marginTop: 15,
							display: 'flex',
							flexWrap: 'wrap'
						}}>
							{
								result.images.map(item => {
									return (
										<div key={item} style={{
											marginRight: 8,
											marginBottom: 8,
											width: 'calc(14.22% - 8px)',
											float: 'left',
											background: "#C9C8FC",
											height: 105
										}}>
											<ImageZoom
												image={{
													src: item,
													alt: 'Golden Gate Bridge',
													style: {
														width: '100%',
														cursor: 'pointer',
														height: '100%',
														objectFit: 'cover'
													}
												}}
												zoomImage={{
													src: item,
													alt: 'Golden Gate Bridge',
													style: {
														width: '100%',
														cursor: 'pointer',
														height: '100%',
														objectFit: 'cover'
													}
												}}
											/>
										</div>
									)
								})
							}
						</div>
						<p style={{
							fontWeight: 700,
							fontSize: 25,
							color: "#454545",
							marginTop: 20
						}}>
							Hotel Rooms
						</p>
						<p style={{
							fontSize: 14,
							color: "#898989"
						}}>
							All possible choices of hotel rooms are visible below. Please click on book button to
							continue booking.
						</p>
						<div>
							{
								result.rooms.map((item) => {
									return (
										<div key={item.ratePlanCode} style={{
											display: 'flex',
											alignItems: 'center',
											marginTop: 10
										}}>
											<div style={{
												flex: 1
											}}>
												<p style={{
													fontSize: 19,
													fontWeight: 600,
													color: "#454545"
												}}>
													{item.name}
												</p>

												<p dangerouslySetInnerHTML={{ __html: item.description }} style={{
													fontSize: 14,
													fontWeight: 500,
													color: '#898989',
													marginTop: 2,
													marginBottom: 2
												}} />

												<p style={{
													fontSize: 13,
													color: "#898989",
													fontWeight: 400
												}}>
													INR {item.pricePerNight} per room /night
												</p>
											</div>
											<p onClick={this.bookItem.bind(this, item)} style={{
												fontSize: 11,
												cursor: 'pointer',
												fontWeight: 600,
												borderRadius: 20,
												background: "rgba(99,117,260,.1)",
												color: '#6375FA',
												padding: '9px 25px'
											}}>
												Book
											</p>
										</div>
									)
								})
							}
						</div>
						<p style={{
							fontWeight: 700,
							fontSize: 25,
							color: "#454545",
							marginTop: 20
						}}>
							Hotel Location
						</p>
						<p style={{
							fontSize: 14,
							color: "#898989",
							marginBottom: 15
						}}>
							Location of hotel is visible in google map below.
						</p>
						{
							result.address && result.address.lat && result.address.lng ?
								<Map coords={{
									lat: result.address.lat,
									lng: result.address.lng
								}} /> : <p>Unable to load map</p>
						}
					</div>
				</div>
			</div>
		)
	}
}


export default HotelItemExtended