import { get, post } from "../../../../Model/Network/Config/Axios";


class Model {

    constructor() {
        this.Disposables = [];
    }

    destroy = () => {
        this.Disposables.forEach(item => {
            if (item.isCancellable())
                item.cancel()
        })
    }


    fetch = (id, cb) => {
        this.Disposables.push(get(`/bookings/${id}`, cb));
    }

    sendEmail = (data, id, cb) => {
        this.Disposables.push(post(`/bookings/${id}/email`, data, cb));
    }

    createSupportTicket = (data, cb) => {
        this.Disposables.push(post(`/support/flights`, data, cb));
    }

}

export default Model