import { get, patch } from "../../../../../Model/Network/Config/Axios";

class Model {

	constructor() {
		this.Disposables = [];
	}

	destroy = () => {
		this.Disposables.forEach(item => {
			if (item.isCancellable())
				item.cancel()
		})
	}

	fetch = (service, cb) => {
		if (service === "all") {
			this.Disposables.push(get(`/requests/all.extended.opened`, cb))
		} else {
			this.Disposables.push(get(`/${service}/requests.opened`, cb))
		}
	}

	delete = (service, id, data, cb) => {
		patch(
			`/${service.toLowerCase()}/request/${id}/delete`, data, cb
		)
	}

	close = (service, id, data, cb) => {
		patch(
			`/${service.toLowerCase()}/request/${id}/close`, data, cb
		)
	}

}

export default Model