import React from 'react';
import Moment from 'moment';

class TravelRequestHotelChoicesCard extends React.Component {


    render() {
        let data = this.props.data;
        let content = data.message;
        return (
            <div>
                {this.props.date ?
                    <div style={{ display: 'flex', flex: 1, }}>
                        <div style={{ flex: 1, display: 'flex', justifyContent: 'center', margin: '0px -40px' }}>
                            <p style={{
                                padding: '5px 10px',
                                background: 'var(--primary-color)',
                                color: 'var(--sidebar-active-color)',
                                position: 'relative',
                                top: 5,
                                fontWeight: 600,
                                fontSize: 15
                            }}>{Moment(data.createdAt).format('dddd')},{Moment(data.createdAt).format('MMMM')},{Moment(data.createdAt).format('DD')}</p>
                        </div>
                    </div>
                    : <div />
                }
                <div style={{ flex: 1, paddingLeft: 12, paddingRight: 12, marginTop: 20, marginBottom: 20, display: 'flex', maxWidth: 400 }}>
                    <div>
                        <img src={data.sendor.image} alt="" style={{ width: 40, height: 40, objectFit: 'contain', marginRight: 10 }} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <p style={{ fontSize: 13, fontWeight: 600, color: 'var(--sidebar-active-color)' }}>{data.sendor.name}</p>
                        <div style={{ padding: 12, paddingLeft: 8, paddingRight: 8, marginTop: 6, border: '1px solid var(--border-color)', maxWidth: 440 }}>
                            <p style={{
                                fontWeight: 600,
                                fontSize: 15
                            }}>{content.title}</p>
                            <p style={{
                                fontSize: 13,
                                color: 'var(--inactive-color)',
                                marginBottom: 7
                            }}>{content.des}</p>
                            {
                                content.choices.items.map((i, index) => (
                                    <div key={index} style={{
                                        marginBottom: 7
                                    }}>
                                        <p style={{
                                            fontSize: 15,
                                            fontWeight: 600
                                        }}>{i.name}</p>
                                        <p style={{
                                            color: 'var(--inactive-color)',
                                            fontSize: 12
                                        }}>{i.address}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export { TravelRequestHotelChoicesCard };
