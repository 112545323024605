import React from 'react'
import { NormalTable } from '../../../../Tables/NormalTable/NormalTable';
import { EmptyState } from '../../../../libs/EmptyState/index';
import emptyBooking from '../k2.png';
import { HotelTicketItem } from "../../../Common/BookingItem/HotelItem";
import { BusTicketItem } from "../../../Common/BookingItem/BusItem";
import { FlightItem } from '../../../Common/BookingItem/FlightItem';

class View extends React.Component {


	constructor(props) {
		super(props);
		this.presentor = props.presentor;
		this.presentor.setView(this);
		this.state = {
			bookings: [],
			emptyState: false
		}
	}

	componentDidMount() {
		this.presentor.init();
	}

	componentWillUnmount() {
		this.presentor.destroy()
	}

	load = r => {
		if (r.tickets.length > 0) {
		
			this.setState({
				bookings: r.tickets,
				emptyState: false
			})
		} else {
			this.setState({
				boooking: r,
				emptyState: true
			})
		}
	}

	Item = (item, index) => {
		if(item) {
		if (item.service && item.service.toLowerCase() === "hotel")
			return (
				<HotelTicketItem hide={true} data={item} key={index} />
			)
		else if (item.service && item.service.toLowerCase() === "bus")
			return (
				<BusTicketItem hide={true} data={item} key={index} />
			)
		else {
			return (
				<FlightItem hide={true} data={item} key={index} />
			)
		}
	}
}

	render() {
		if (this.state.emptyState) {
			return <EmptyState small={true} image={emptyBooking} title="No Upcoming Booking Found" desc="Please Book the Tickets" />
		}
		return (
			<div>
				<NormalTable headerStyle={{
					border: 'none'
				}} headers={[
					{
						key: 'details',
						name: '',
						weight: ''
					},
					{
						key: 'name',
						name: '',
						weight: ''
					},
					{
						key: 'amount',
						name: '',
						weight: ''
					}
				]}
					data={this.state.bookings}
					pagination={true}
					mapItem={this.Item}
				/>
			</div>
		)
	}

}

export default View