import { get, post } from "../../../../Model/Network/Config/Axios";

class Model {

	constructor() {
		this.Disposables = [];
	}

	destroy = () => {
		this.Disposables.forEach(item => {
			if (item.isCancellable())
				item.cancel()
		})
	}


	search = (data, cb) => {
		this.Disposables.push(post(`/flight/search`, data, cb));
	}


	getRequest = ({id,type}, cb) => {
		this.Disposables.push(get(`/flight/request/${id}`, cb,{type}));
	}

}

export default Model
