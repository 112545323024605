// let sampleData = [
// 	{
// 		image:'http://cdn.travelpartnerweb.com/DesiyaImages/Image/3/nxd/mau/tyk/dbp/HO_TN.jpg',
// 		hotel:{
// 		  hotelName:'Hotel Shyam Palace Deluxe',
// 		  guests:2,
// 		  rooms:1
// 		},
// 		transactionId:'DSE0005342607',
// 		stayDate:{
// 		  checkIn:'2019-07-14',
// 		  checkOut:'2019-07-15',
// 		  'bookingDate':'2019-07-01'
// 		},
// 		amount:'400',
// 		userName:'Karan'
// 	  }
// ]


class Presentor {

	constructor(Model) {
		this.Model = Model;
	}

	setView(View) {
		this.View = View;
	}

	destroy = () => {
		this.Model.destroy()
	}

	init = (date) => {
		this.Model.fetch(date, (e, r) => {
			if (r) {
				this.View.load(r)
			}
		})
		// this.View.load(sampleData)
	}
}


export default Presentor