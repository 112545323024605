import React from 'react'
import Moment from "moment";

class BusDetails extends React.Component {

	render() {
		let data = this.props.data;
		if(!data)
			return (
				<div>

				</div>
			)

		let bus = data.bus;

		return(
			<div style={{
				marginTop: 30
			}}>
				<p style={{
					fontSize: 27,
					color: "#454545",
					fontWeight: 600,
				}}>
					{bus.name}
				</p>
				<p style={{
					fontSize: 15,
					color: "#898989"
				}}>
					{bus.label}
				</p>
				<p style={{
					fontSize: 21,
					color: "#454545",
					fontWeight:700,
					marginTop:22
				}}>
					Booking Details
				</p>
				<p style={{
					marginBottom:12,
					fontSize:14,
					color:"#898989"
				}}>
					Details of selected bus is shown below.
				</p>
				<div>
					<div style={{
						display: 'flex',
						alignItems: 'center',
						height: 35
					}}>
						<img src={require('./user.png')} alt="right" style={{
							width: 14,
							height: 14
						}}/>
						<p style={{
							paddingLeft: 15,
							paddingRight: 15,
							fontWeight: 600
						}}>
							{bus.seats.length} X Bus Seats
						</p>
					</div>
					<div style={{
						display: 'flex',
						alignItems: 'center',
						height: 35
					}}>
						<img src={require('./right.png')} alt="right" style={{
							width: 14,
							transform: "rotate(180deg)",
							height: 14
						}}/>
						<p style={{
							width: 200,
							paddingLeft: 15,
							paddingRight: 15,
							fontWeight: 600
						}}>
							{
								data.route.fromName
							}
						</p>
						<p style={{
							width: 300,
							paddingLeft: 15,
							paddingRight: 15,
							color: "#898989",
							fontSize: 13
						}}>

							{Moment(bus.departure).format("ddd, DD MMM HH:mm a")}
						</p>
					</div>
					<div style={{
						display: 'flex',
						alignItems: 'center',
						height: 35
					}}>
						<img src={require('./right.png')} alt="right" style={{
							width: 14,
							height: 14
						}}/>
						<p style={{
							width: 200,
							paddingLeft: 15,
							paddingRight: 15,
							fontWeight: 600
						}}>
							{
								data.route.toName
							}
						</p>
						<p style={{
							width: 300,
							paddingLeft: 15,
							paddingRight: 15,
							color: "#898989",
							fontSize: 13
						}}>
							{Moment(bus.arrival).format("ddd, DD MMM  HH:mm a")}
						</p>
					</div>
					<p style={{
						fontSize: 21,
						color: "#454545",
						fontWeight:700,
						marginTop:22
					}}>
						Traveller Details
					</p>
					<p style={{
						marginBottom:22,
						fontSize:14,
						color:"#898989"
					}}>
						All travellers selected for this booking are shown below.
					</p>
					{
						bus.seats.map((item,i)=>(
							<div key={i} style={{
								display:'flex',
								marginTop:15,
								alignItems:'center',
								background:"#fafafa",
								border:'1px solid #eee',
								borderRadius:22,
								position:'relative',
								padding:'12px 20px',
								width:350,
								cursor:'pointer',
								flexDirection:'row'
							}}>
								<p className="label-back" style={{
									fontWeight:400,
									color:"#555555",
									fontSize:13,
									position:'absolute',
									top:-8,
									zIndex:11,
									left:20,
									marginRight:10
								}}>
									Traveller {i+1}
								</p>
								<p style={{
									fontWeight:600,
									color:"#454545",
									fontSize:15,
								}}>{item.name}</p>
								<p style={{
									fontSize:12,
									color:"#999",
									right:15,
									top:15,
									position:'absolute'
								}}>
									# {item.seatCode}
								</p>
							</div>
						))
					}
				</div>
			</div>
		)
	}

}

export default BusDetails