class Presentor {

    constructor(Model) {
        this.Model = Model;
    }

    setView(View) {
        this.View = View;
    }

    destroy = () => {
        this.Model.destroy()
    }

    init  = () => {
        this.Model.fetchDetails(this.View.getId(),(r)=>{
            if(r) {
                this.View.load(r);
            }
        })
    }

    _addComment = (data) => {
        this.Model._addComment(data, (r)=>{
            if(r) {
                this.View.onAddComment()
            }
        })
    }

    closeTicket = () => {
        let data = {
            ticket_id : Number(this.View.getId())
        }
        this.Model.closeTicket(data,(r) =>{
          this.init();
        })
    }

   
}

export default Presentor;