import React from 'react';
import { EmptyState } from '../../../../libs/EmptyState/index';
import emptyBooking from '.././k2.png';
import { NormalTable } from '../../../../Tables/NormalTable/NormalTable';
import { FlightItem } from '../../../Common/BookingItem/FlightItem'
import { Modal } from '../../../../libs/Modal/Modal';
import { FloatInput } from '../../../../libs/FloatLabelInput';
import { showToast } from '../../../../libs/Toast/ToastCallback';

class View extends React.Component {

	constructor(props) {
		super(props);
		this.presentor = props.presentor;
		this.presentor.setView(this);
		this.state = {
			bookings: [],
			emptyState: false,
			cancelModal: false,
			loading:false,
		}
	}

	componentDidMount() {
		this.presentor.init();
	}

	componentWillUnmount() {
		this.presentor.destroy()
	}


	load = r => {
		if (r.tickets.length > 0) {
			this.setState({
				...this.state,
				bookings: r.tickets,
				emptyState: false
			})
		} else {
			this.setState({
				...this.state,
				bookings: r.tickets,
				emptyState: true
			})
		}
	}

	Item = (item, index) => {
		return (
			<FlightItem openCancel={this.flightCancel} onCancel={true} key={index} data={item} />
		)
	}

	flightCancel = (id) => {
		this.setState({
			...this.state,
			cancelModal: id
		})
	}

	onFlightCancel = () => {
		this.setState({
			...this.state,
			cancelModal: false
		})
		this.presentor.init();
	}


	render() {
		if (this.state.emptyState) {
			return <EmptyState image={emptyBooking} title="No Upcoming Booking Found" desc="Please Book the Tickets" />
		}



		return (

			<div>
				<NormalTable headerStyle={{
					border: 'none',
					marginTop: -15
				}} headers={[
					{
						key: 'details',
						name: '',
						weight: ''
					},
					{
						key: 'name',
						name: '',
						weight: ''
					},
					{
						key: 'amount',
						name: '',
						weight: ''
					}
				]}
					data={this.state.bookings}
					pagination={true}
					mapItem={this.Item}
				/>
				<Modal
					show={this.state.cancelModal}
					close={() => {
						this.setState({
							...this.state,
							cancelModal: false
						})
					}}
					title="Flight Ticket"
					des="Ticket cancellation"
					buttonName="Cancel"
					onSave={() => { 
						let reason = this.reason.value();
						if(!reason){
							showToast({
								type:'error',
								message:'Please enter reason!'
							})
							return;
						}
						this.setState({
							...this.state,
							loading: true
						})
						this.presentor.cancel(this.state.cancelModal,reason)
					}}
				>
					<FloatInput type="text" title="Reason" handleRef={ref => this.reason = ref} />
					{/* <p style={{
						fontWeight: "bold",
						fontSize: 16
					}}>Please contact 8448239151 / 9354356088 to reschedule or cancel your flight</p> */}
				</Modal>
			</div>
		)
	}

}

export default View