import React from 'react'
import Props from 'prop-types'

class Sidebar extends React.Component {

	state = {
		expanded : true
	}

	mapItem = item => {
		let path = this.props.location.pathname;
		let active = item.includes ? path.includes(item.path) : path === item.path;
		if (active) {
			return (
				<div key={item.title} style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					marginBottom: 18,
					cursor: 'pointer'
				}}>
					{
						item.active ? <img alt="icon" src={item.active} style={{
							width: 24,
							height: 24,
							marginRight: 10,
							...item.style
						}}/> : null
					}
					{
						item.active ? <p style={{
							fontSize: 16,
							letterSpacing: 1,
							color: "#ffffff"
						}}>
							{item.title}
						</p> : <p style={{
							fontSize: 14,
							letterSpacing: 1,
							fontWeight:700,
							color: "#ffffff"
						}}>
							{item.title}
						</p>
					}
				</div>
			)
		} else {
			return (
				<div onClick={() => {
					this.props.history.push(item.path)
				}} key={item.title} className="onhover" style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					marginBottom: 18,
					position: 'relative',
					cursor: 'pointer'
				}}>
					<div className="hoverable"/>
					{
						item.inactive ?
							<img alt="icon" src={item.inactive} style={{
								width: 24,
								height: 24,
								marginRight: 10,
								...item.style
							}}/> : null
					}
					<p style={{
						fontSize: 16,
						letterSpacing: 1,
						color: "#8d90c0"
					}}>
						{item.title}
					</p>
				</div>
			)
		}
	}


	mapItem2 = item => {
		let path = this.props.location.pathname;
		if(item.active){
			let active = item.includes ? path.includes(item.path) : path === item.path;
			if (active) {
				return (
					<div key={item.title} style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						marginBottom: 25,
						marginLeft:-10,
						cursor: 'pointer'
					}}>
						<img  data-tip={item.title}  alt="icon" src={item.active} style={{
							width: 30,
							height: 30,
							...item.style
						}}/>
					</div>
				)
			} else {
				return (
					<div  onClick={() => {
						this.props.history.push(item.path)
					}} key={item.title} className="onhover" style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						marginBottom: 25,
						marginLeft:-10,
						position: 'relative',
						cursor: 'pointer'
					}}>
						<img data-tip={item.title} alt="icon" src={item.inactive} style={{
							width: 30,
							height: 30,
							...item.style
						}}/>
					</div>
				)
			}
		}
	}

	mapCategory = item => {
		return (
			<div key={item.title} style={{marginTop: 35}}>
				<p style={{
					fontSize: 15,
					fontWeight: 500,
					color: "#8D90C0",
					marginBottom: 15
				}}>
					{item.title}
				</p>
				{
					item.items.map(this.mapItem)
				}
			</div>
		)
	}

	mapCategory2 = item => {
		return (
			<div key={item.title}>
				{
					item.items.map(this.mapItem2)
				}
			</div>
		)
	}

	render() {
		return (
			<div style={{
				width: this.state.expanded?325:60,
				backgroundImage: 'linear-gradient(to right,#1D2584,#202B89)',
				height: '100vh',
				overflowY: 'auto',
				padding: '45px 25px',
				position:'relative'
			}}>
				<div data-tip={"Collapse/Expand"} onClick={()=>{
					this.setState({
						...this.state,
						expanded:!this.state.expanded
					})
				}} style={{
					right:15,
					top:15,
					cursor:'pointer',
					width:30,
					height:30,
					position:'absolute',
					padding:6,
					borderRadius:15,
					background:'linear-gradient( 107deg,  rgba(72,81,86,.3) 11.2%, rgba(187,187,187,.3) 90.7% )',
				}}>
					<img src={require('./left-arrow.png')} alt="left arrow" style={{
						width:18,
						height:18,
						transition:'all .4s',
						transform : this.state.expanded?null:"rotate(180deg)"
					}}/>
				</div>

				{
					this.state.expanded?
						<div>
							<div style={{
								position: 'relative'
							}}>
								<h2 style={{
									color: "white",
									fontWeight: 700,
									fontSize: 27
								}}>
									{this.props.title}
								</h2>
							</div>
							<div style={{
								padding: 6
							}}>
								{
									this.props.data.map(this.mapCategory)
								}
							</div>
						</div>:
						<div style={{marginTop:40}}>
							{
								this.props.data.map(this.mapCategory2)
							}
						</div>
				}
			</div>
		)
	}

}

Sidebar.propTypes = {
	title: Props.string.isRequired,
	data: Props.array.isRequired
}

export {
	Sidebar
}