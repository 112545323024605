import React from 'react'
import { Route, Switch } from 'react-router-dom';

import SearhBuses from './SearchBuses'
import BusRefund from './BusRefund';
import Booking from './Bookings';
import PollingBus from './PollingBus';
import BookBus from './BookBus';
import { Sidebar } from "../Common/Sidebar";
import ViewTicket from './ViewTicket';
import Support from '../CommonPages/Support';
import AllSupport from '../CommonPages/AllSupport';
import CreateBooking from './CreateBooking';
import ActiveSearchIcon from './assets/search.png'
import InActiveSearchIcon from './assets/search1.png'
import ActiveBookingsIcon from './assets/bookings.png'
import InActiveBookingsIcon from './assets/bookings1.png'
import ActiveRefundIcon from './assets/icon1.png'
import InactiveRefundIcon from './assets/icon1a.png'
import ActiveWorkflowIcon from './assets/icon5a.png'
import InactiveWorkflowIcon from './assets/icon5.png'
import BusActive from './assets/busA.png';
import BusInactive from './assets/busI.png';
import { get } from "../../../Model/Network/Config/Axios";
import { BookingRequests } from "../CommonPages/BookingRequests";
import Offline from './Offline';
import {LunaPopUp} from "../Common/Luna";
import Search from "stringquery";

class index extends React.Component {

	state = {
		bookings: 0,
		requestId: undefined
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.location && this.props.location.search) {
			const params = Search(this.props.location.search);
			if (params.requestId && this.state.requestId !== params.requestId) {
				this.setState({
					...this.state,
					requestId: params.requestId
				})
			}
			if (!params.requestId && this.state.requestId) {
				this.setState({
					...this.state,
					requestId: undefined
				})
			}
		} else if (this.state.requestId) {
			this.setState({
				...this.state,
				requestId: undefined
			})
		}
	}

	componentDidMount() {
		this._interval = setInterval(this.poll, 10000);
		this.poll()
	}

	componentWillUnmount() {
		if (this._interval)
			clearInterval(this._interval)
	}

	poll = () => {
		get(`/bus/counts`, (e, r) => {
			if (r) {
				this.setState({
					...r
				})
			}
		})
	}

	render() {
		return (
			<div id="fintrip-container" style={{
				flex: 1
			}}>
				<Sidebar history={this.props.history} location={this.props.location} title="Buses" data={[
					{
						title: "Overview",
						items: [
							{
								title: "Search Buses",
								inactive: ActiveSearchIcon,
								active: InActiveSearchIcon,
								path: "/app/bus/search"
							},
							{
								title: "Bookings",
								active: ActiveBookingsIcon,
								inactive: InActiveBookingsIcon,
								path: "/app/bus/bookings",
								includes: true
							}
						]
					},
					{
						title: "Workflow",
						items: [
							{
								title: this.state.bookings > 0 ? `Booking Requests (${this.state.bookings})` : "Booking Requests",
								active: InactiveWorkflowIcon,
								inactive: ActiveWorkflowIcon,
								path: "/app/bus/requests",
								style: {
									width: 42,
									height: 42,
									marginLeft: -8,
									marginRight: 8,
									marginBottom: -6,
									marginTop: -6
								}
							}
						]
					},
					{
						title: 'Offline Booking',
						items: [
							{
								title: 'Import Booking',
								active: BusActive,
								inactive: BusInactive,
								path: '/app/bus/create'
							},
							{
								title: 'Offline Booking',
								active: ActiveBookingsIcon,
								inactive: InActiveBookingsIcon,
								path: '/app/bus/offline',

							}
						]
					},
					{
						title: "Help & Support",
						items: [
							{
								title: "Refunds",
								active: ActiveRefundIcon,
								inactive: InactiveRefundIcon,
								path: "/app/bus/refunds",
								style: {
									width: 42,
									height: 42,
									marginLeft: -8,
									marginRight: 8,
									marginBottom: -6,
									marginTop: -6
								}
							}
						]
					}
				]} />
				<div style={{ flex: 1, overflowY: 'auto' }}>
					<Switch>
						<Route component={SearhBuses} path="/app/bus/search" exact />
						<Route component={PollingBus} path="/app/bus/search/polling/:searchId" exact />
						<Route component={ViewTicket} path="/app/bus/tickets/:id" />
						<BookingRequests sort={true} all={true} exact={true} path="/app/bus/requests" history={this.props.history} service="bus" />
						<Support exact={true} path="/app/bus/support" history={this.props.history} service="bus" />
						<Route component={BusRefund} path="/app/bus/refunds" />
						<Route component={BookBus} path="/app/bus/book" />
						<Route component={Booking} path="/app/bus/bookings" />
						<Route component={AllSupport} path="/app/bus/support/:id" />
						<Route component={CreateBooking} path="/app/bus/create" />
						<Route component={Offline} path="/app/bus/offline" />
					</Switch>
				</div>
				{
					this.state.requestId ? <LunaPopUp requestId={this.state.requestId} /> : undefined
				}
			</div>
		)
	}

}

export default index
