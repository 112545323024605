import React from 'react';
import {TitleComponent} from '../../Common/TitleComponent';


class View extends React.Component {

    constructor(props) {
        super(props);
        this.presentor = props.presentor;
        this.presentor.setView(this);
    }

    render() {
        return (
            <div className="side-container">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <TitleComponent subtitle="All" title="Contact Us" />
                </div>
                <div style = {{
                    marginTop:30
                }}>
                   <h2 style = {{
                       fontWeight:600,
                       fontSize:20,
                       color:'#000'
                   }}>Incase of following issues:</h2>
                   <ul style = {{
                       marginTop:20,
                       fontSize:16,
                       padding:0,
                       paddingLeft:10
                   }}>
                        <li style = {{
                            marginBottom:7
                        }}>Inability to check-in at booked hotel</li>
                        <li style = {{
                            marginBottom:7
                        }}>Inability to board a booked bus</li>
                        <li style = {{
                            marginBottom:7
                        }}>Rescheduling flight, hotel & bus bookings</li>
                        <li style = {{
                            marginBottom:7
                        }}>Urgent flight, hotel & bus bookings (that admin is unable to attend to)</li>
                   </ul>
                </div>
                <div style = {{
                    border:'1px dashed #ccc',
                    padding:30,
                    width:'40%',
                    marginTop:30,
                }}>
                    <div style = {{
                        fontWeight:'bold',
                        marginBottom:10,
                        color:'#000'
                    }}>POSTAL ADDRESS</div>
                    <p style = {{
                        fontWeight:400,
                        marginBottom:3
                    }}>M: +91-8448239151 / +91-9354356088</p>
                    <p style = {{
                        fontWeight:400,
                        marginBottom:3
                    }}>E: hello@antrepriz.com</p>
                    <p style = {{
                        fontWeight:400,
                        marginBottom:3
                    }}>A: SKPL Antrepriz solution , Altf Success <br></br> Tower, Sector 65,Gurgoan</p>
                </div>
                    
                <h3 style = {{
                    fontSize:14,
                    fontWeight:400,
                    marginTop:10,
                    
                }}><span style = {{
                    padding:'5px 0'
                }}>**</span>Please use our support channel to raise any other issues regarding your travel booking through Eka</h3>


            </div>
        )
    }
}

export default View;