
class Presentor {

    constructor(Model) {
        this.Model = Model;
    }

    setView(View) {
        this.View = View;
    }

    issue = data => {
        this.Model.issue(data,(e,r) => {
            if(r) {
               this.View.onIssueCreate()
            }
        })
    }
}


export default Presentor;