import React from 'react'

class HotelShimmer extends React.Component {

	render() {
		return (
			<div style={{
				width:'calc(33.33% - 27px)',
				background:'white',
				boxShadow: '.5px 1px .5px rgba(0,0,0,0.1)',
				marginLeft:20,
				border:'1px solid #eeeeee',
				padding:8,
				marginTop:20,
			}}>
				<div style={{height:190,display:'flex',alignItems:'center',justifyContent:'center'}} className="shine">
					<div className="dot-pulse"/>
				</div>
				<div style={{padding:"12px 0 0"}}>
					<div style={{height:15,marginRight:30,borderRadius:5}} className="shine"/>
					<div style={{height:12,marginRight:90,borderRadius:5,marginTop:8}} className="shine"/>
					<div style={{height:12,marginRight:90,borderRadius:5,marginTop:8}} className="shine"/>
					<div style={{height:12,marginRight:90,borderRadius:5,marginTop:8}} className="shine"/>
					<div style={{height:45,borderRadius:5,marginTop:8}} className="shine"/>
				</div>
			</div>
		)
	}

}


class BusShimmer extends React.Component {

	render() {
		return (
			<div style={{
				width:'calc(33.33% - 27px)',
				background:'white',
				boxShadow: '.5px 1px .5px rgba(0,0,0,0.1)',
				marginLeft:20,
				border:'1px solid #eeeeee',
				padding:8,
				marginTop:20,
			}}>
				<div style={{height:190,display:'flex',alignItems:'center',justifyContent:'center'}} className="shine">
					<div className="dot-pulse"/>
				</div>
				<div style={{padding:"12px 0 0"}}>
					<div style={{height:15,marginRight:30,borderRadius:5}} className="shine"/>
					<div style={{height:12,marginRight:90,borderRadius:5,marginTop:8}} className="shine"/>
					<div style={{height:12,marginRight:90,borderRadius:5,marginTop:8}} className="shine"/>
					<div style={{height:12,marginRight:90,borderRadius:5,marginTop:8}} className="shine"/>
					<div style={{height:45,borderRadius:5,marginTop:8}} className="shine"/>
				</div>
			</div>
		)
	}

}


class FlightShimmer extends React.Component {

	render() {
		return (
			<div style={{
				width:'calc(100% - 36px)',
				background:'white',
				boxShadow: '.5px 1px .5px rgba(0,0,0,0.1)',
				marginLeft:20,
				border:'1px solid #eeeeee',
				padding:12,
				display:'flex',
				height:144,
				marginTop:20,
			}}>
				<div style={{
					flex:1
				}}>
					<div style={{display:'flex',alignItems:'center',justifyContent:'center',height:120}} className="shine">
						<div className="dot-pulse"/>
					</div>
				</div>

				<div style={{
					flex:3,
					marginLeft:15,
					marginRight:15
				}}>
					<div style={{height:18,marginRight:10,borderRadius:5}} className="shine"/>
					<div style={{height:18,marginRight:10,borderRadius:5,marginTop:12}} className="shine"/>
					<div style={{height:18,marginRight:10,borderRadius:5,marginTop:12}} className="shine"/>
					<div style={{height:18,marginRight:10,borderRadius:5,marginTop:12}} className="shine"/>
				</div>

				<div style={{
					flex:1.5,
					display:'flex',
					flexDirection:'column',
					alignItems:'center',
					justifyContent:'center'
				}}>
					<div style={{height:18,width:120,borderRadius:5,marginTop:12}} className="shine"/>
					<div style={{height:10,width:90,borderRadius:5,marginTop:12}} className="shine"/>
				</div>
			</div>
		)
	}

}

export {
	HotelShimmer,BusShimmer ,FlightShimmer
}