import React from 'react'
import {TitleComponent, TitleComponent2} from "../../Common/TitleComponent";

import Flight from './booking_flight.png'
import Hotel from './booking_hotel.png'
import Bus from './booking_bus.png'
import Sidebar from './Sidebar'
import Index from './UpcomingBookings/index'

class View extends React.Component {

	render() {
		return (
			<div className="fintrip-container" style={{
				display: 'flex',
				flexDirection: 'row',
				flex: 1
			}}>
				<div className="side-container" style={{borderTop: 'none'}}>
					<TitleComponent subtitle="HOME" title="Dashboard"/>
					<div style={{
						padding: 22,
						marginTop: 35,
						width: 544,
						borderRadius: 10,
						background: "#F6F5FF",
					}}>
						<p style={{
							fontSize: 17,
							color: "#321CBF",
							fontWeight: 700
						}}>
							Book a service
						</p>
						<div style={{
							display: 'flex',
							marginTop: 25,
							marginBottom: 12
						}}>
							<div onClick={event => {
								this.props.history.push("/app/flights/search")
							}} style={{
								flex: 1,
								display: 'flex',
								alignItems: 'center',
								flexDirection: "column",
								cursor: 'pointer',
								justifyContent: 'center',
								borderRight: '1px solid #dedede'
							}}>
								<img style={{
									width: 25,
									marginBottom: 12,
									height: 25
								}} src={Flight} alt="flight"/>
								<p style={{
									fontSize: 15,
									fontWeight: 600
								}}>
									Flight
								</p>
							</div>

							<div onClick={event => {
								this.props.history.push("/app/hotel/search")
							}} style={{
								flex: 1,
								display: 'flex',
								alignItems: 'center',
								flexDirection: "column",
								cursor: 'pointer',
								justifyContent: 'center',
								borderRight: '1px solid #dedede'
							}}>
								<img style={{
									width: 28,
									marginBottom: 12,
									height: 28
								}} src={Hotel} alt="flight"/>
								<p style={{
									fontSize: 15,
									fontWeight: 600
								}}>
									Hotel
								</p>
							</div>

							<div onClick={event => {
								this.props.history.push("/app/bus/search")
							}} style={{
								flex: 1,
								display: 'flex',
								cursor: 'pointer',
								alignItems: 'center',
								flexDirection: "column",
								justifyContent: 'center',
								borderRight: '1px solid #dedede'
							}}>
								<img style={{
									width: 28,
									marginBottom: 12,
									height: 28
								}} src={Bus} alt="flight"/>
								<p style={{
									fontSize: 15,
									fontWeight: 600
								}}>
									Bus
								</p>
							</div>
							<div style={{
								flex: 1,
								display: 'flex',
								alignItems: 'center',
								flexDirection: "column",
								justifyContent: 'center'
							}}/>
						</div>
					</div>
					<div style={{
						marginTop: 38
					}}>
						<TitleComponent2 title="Upcoming Bookings" subtitle="All upcoming bookings booked by you."/>
						<Index {...this.props}/>
					</div>
				</div>
				<Sidebar history={this.props.history}/>
			</div>
		)
	}

}

export default View