import React from 'react'
import { get } from "../../../Model/Network/Config/Axios";

import Props from 'prop-types'

class CitySearch extends React.Component {


	constructor() {
		super();
		this.Disposables = [];
	}

	state = {
		suggestions: [],

	}


	componentWillUnmount() {
		this.Disposables.forEach(item => {
			if (item.isCancellable())
				item.cancel()
		})
	}


	selectCity = item => {
		this._cityInput.value = "";
		this.setState({
			suggestions: [],

		})
		this.props.onCitySelected({
			value: item.name,
			id: item.id
		})
	}


	onChange = (e) => {
		let value = this._cityInput.value;
		this.props.onCitySelected({
			value,
			id: undefined
		})
		if (this.props.airport) {
			this.Disposables.push(get(`/search/airports`, (e, r) => {
				if (r) {
					this.setState({
						...this.state,
						suggestions: r.airports.map(item => (
							{
								id: item.code,
								name: `${item.airport},${item.name}`
							}
						))
					})
				}
			}, { q: value }))
		} else {
			this.Disposables.push(get(`/search/cities`, (e, r) => {
				if (r) {
					this.setState({
						...this.state,
						suggestions: r.cities
					})
				}
			}, { q: value }))
		}
	}


	render() {
		return (
			<div>
				<input className="citysearch"
					ref={ref => this._cityInput = ref} onChange={this.onChange} value={this.props.city.value}
					placeholder={this.props.placeholder ? this.props.placeholder : "Where do you want to book?"} type="text" style={{
						width: '100%',
						height: 36,
						border: '1px solid #ddd',
						padding: "8px 12px",
						marginTop: 6,
						color: "#676767",
						...this.props.style
					}} />
				{
					this.state.suggestions.length > 0 && this._cityInput.value.length > 0 ?
						<div style={{
							position: 'absolute',
							top: 50,
							width: '100%',
							background: "#ffffff",
							zIndex: 11,
							border: '1px solid #dedede',
							maxHeight: 300,
							overflowY: 'auto',
							minHeight: 50,
							boxShadow: '1px 1px 1px rgba(1,1,1,.05)'
						}}>
							{
								this.state.suggestions.map(item => {
									return (
										<div key={item.id} onClick={this.selectCity.bind(this, item)} style={{
											width: '100%',
											padding: '10px 12px',
											color: "#676767",
											cursor: 'pointer',
											borderBottom: '1px solid #dedede',
											fontSize:13
										}}>
											{
												item.name
											}
											{!this.props.airport ?
											<p>State:{item.state}</p>
											:null}
										</div>
									)
								})
							}
						</div> : null
				}
			</div>
		)
	}

}


CitySearch.propTypes = {
	onCitySelected: Props.func.isRequired,
	city: Props.object.isRequired
}

export {
	CitySearch
}