import React from 'react';
import {TitleComponent} from '../../Common/TitleComponent';
import PastBookings from './PastBookings'
import UpcomingBookings from './UpcomingBookings'
import {DatesRangeReact} from '../../../libs/DateRangereact';
import Moment from 'moment';
import {Route,Switch} from  'react-router-dom';
import TopBar from '../../Common/TopBar';


class Index extends React.Component {

    state = {
		upcoming : true,
		startDate : new Moment().subtract(60,'d'),
		endDate : new Moment().add(30,'d')
    }

    render() {
		let route = this.props.location.pathname;
        return(
			<div className = "side-container">
				<div style = {{display:'flex',justifyContent:'space-between'}}>
					<TitleComponent subtitle = "All" title = "Booking" />
					{route === "/app/home/bookings" ? null :
						(<DatesRangeReact startDate = {this.state.startDate} endDate = {this.state.endDate}
						handleDate = {({startDate,endDate}) => {
							this.setState({
								startDate,endDate
							})
						}}
					/>)
					}
				</div>
				<TopBar {...this.props} item = {[
					{
						path:"/app/home/bookings",
						active:route === "/app/home/bookings",
						name:'Upcoming Booking'
					},
					{
						path:"/app/home/bookings/past",
						active:route === "/app/home/bookings/past",
						name:'Past Booking'
					}
				]} />
				<Switch>
					<Route path = "/app/home/bookings" component = {UpcomingBookings} exact />
					<Route path = "/app/home/bookings/past" render= {(props)=><PastBookings {...props} startDate = {this.state.startDate} endDate = {this.state.endDate} />} />
				</Switch>
				
            </div>
        )
    }
}

export default Index;