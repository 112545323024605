import { showToast } from "../../../libs/Toast/ToastCallback";


class Presentor {

	constructor(Model) {
		this.Model = Model;
	}

	setView(View) {
		this.View = View;
	}

	destroy = () => {
		this.Model.destroy()
	}

	hold = data => {
		this.Model.hold(data, (e, r) => {
			if (r) {
				this.View.onHold(r)
			}
		})
	}

	book = data => {
		this.Model.book(data, (e, r) => {
			if (r) {
				this.View.onBook(r)
			} else {
				showToast({
					type: 'error',
					message: e.data.error
				})
				this.View.setState({
					...this.state,
					loading: false
				})
			}
		})
	}

}


export default Presentor