import React from 'react'

class Portlet extends React.Component {

	render() {
		return (
			<div className="fintrip-portlet" style={this.props.style}>
				{
					this.props.children
				}
			</div>
		)
	}

}


Portlet.defaultProps={
	style:{

	}
}

export {
	Portlet
}