import React from 'react';
import InputRange from 'react-input-range';
import '../../../../../../node_modules/react-input-range/lib/css/index.css';
import './index.css';
import HotelSearch from "./hotel search.png";
import {AutoComplete} from "../../../Common/Autocomplete";

class PollingHotels extends React.Component {


	state = {
		hotelName: '',
		result: [],
		clear: false
	}


	handleAmenities = (index) => {
		let amenities = this.props.filter.amenities;
		amenities.forEach(val => {
			if (val.id === index + 1) {
				if (val.isCheck) {
					val.isCheck = false
				} else {
					val.isCheck = true
				}
			}
		})
		this.props.changeFilter({
			amenities
		})
	}

	handleHotelType = (index) => {
		let type = this.props.filter.type;
		type.forEach(val=>{
			if(val.id === index) {
				if(val.isActive) {
					val.isActive = false
				} else {
					val.isActive = true
				}
			}
		})
		this.props.changeFilter({
			type
		})
	}

	handleRating = (index) => {
		let Rating = this.props.filter.Rating;
		Rating.forEach(val => {
			if (val.id === index) {
				if (val.isActive) {
					val.isActive = false
				} else {
					val.isActive = true
				}
			}
		})
		this.props.changeFilter({
			Rating
		})
	}

	handleFilterData = () => {
		let data = {
			amenities: this.props.amenities,
			rating: this.props.Rating,
			distance: this.props.distance,
			price: this.props.price
		}

		this.props.filter(data);
	}


	_hotelResult = () => {

		let items = this.props.result.items;
		let result = items.filter(val => val.name.toLowerCase().includes(this.state.hotelName.toLocaleLowerCase()));
		this.setState({
			...this.state,
			result
		})
	}

	_searchResult = () => {
		return (
			<div>
				{this.state.hotelName.length > 0  && this.state.result.length > 0?
					(
						<div style={{
							position: 'absolute',
							background: '#fff',
							border: '1px solid  #dedede',
							width: 250,
							zIndex: 2,
							maxHeight: 325,
							top: 35,
							left: 0,
							overflowY: 'scroll'
						}}>
							{
								this.state.result.length > 0 ?
									(
										this.state.result.map((val, index) => {
											return (
												<div onClick={() => {
													this.setState({
														...this.state,
														hotelName: val.name,
														result: [],
														clear: true
													}, () => this.props.onHotelSelect(val.code))
												}} className="luna-hover" style={{
													padding: '12px',
													borderBottom: '1px solid #dedede',
													cursor: 'pointer',
													fontSize:14
												}} key={index}>{val.name}</div>
											)
										})
									)

									:
									(
										null
									)
							}
						</div>)
					: null

				}
			</div>
		)
	}

	render() {
		return (
			<div style={{
				width: 275,
				overflowX: 'hidden',
				background: "white",
				padding: "20px 15px",
				height: '100vh',
				overflowY: 'auto',
				borderRight: '1px solid #dedede'
			}}>
				<h5 style={{
					fontSize: 27,
					fontWeight: 800,
					letterSpacing: 1,
					lineHeight: "35px",
				}}>
					Filters
				</h5>
				<p style={{
					fontSize: 13,
					color: "#BABBCC",
					lineHeight: "16px"
				}}>
					Please select the filters below to filter the search result.
				</p>

				<img src={HotelSearch} style={{
					width: 'calc(100% + 36px)',
					marginLeft: -18,
					marginRight: -18,
					marginTop: 30
				}} alt="hotel search" />
				<div style={{
					display: 'flex',
					justifyContent: 'space-between'
				}}>
					<p style={{
						fontWeight: 700, fontSize: 15, padding: '20px 0 5px'
					}}>Search By Name</p>
					{this.state.clear ? <div style={{
						padding: '20px 0 5px',
					}}><p onClick={() => {
						this.setState({
							...this.state,
							hotelName: '',
							clear:false
						})
						this.props.onHotelClear()

					}
					} style={{
						color: '#fff',
						fontSize: 9,
						height: 25,
						cursor: 'pointer',
						width: 90,
						padding: '4px 6px',
						borderRadius: '18px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						background: '#f44336',
						borderColor: '#f44336',
					}}>Remove</p></div> : null}
				</div>
				<p style={{
					fontSize: 11,
					color: "#676767",
					marginBottom: 15
				}}>
					Please search for area for which you want to filter result.
				</p>
				<div style={{
					width: '100%',
					height: 35,
					borderBottom: '1px solid #dedede',
					display: 'flex',
					alignItems: 'center',
					position: 'relative',
					marginBottom: 10
				}}>
					<input style={{
						height: '85%',
						fontSize: 12,
						width: '95%',
						border: 'none'
					}} type="text" placeholder="Search by hotel name" value={this.state.hotelName} onChange={e => {
						this.setState({
							hotelName: e.target.value
						}, () => this._hotelResult())
					}} />
					{
						this._searchResult()
					}
				</div>
				{!this.props.filter.distance.name ? <div style={{ padding: '20px 0' }}>
					<p style={{ fontWeight: 700, fontSize: 15, padding: '0 0 5px' }}>Distance</p>
					<p style={{
						fontSize: 11,
						color: "#676767",
						marginBottom: 18
					}}>
						Please search for area for which you want to filter result.
					</p>
					<AutoComplete local={data => {
						this.props.onPlaceChanged(data)
					}} />
				</div> : <div style={{ padding: '20px 0' }}>
						<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
							<p style={{ fontWeight: 700, fontSize: 15, padding: '0 0 5px' }}>Distance</p>
							<p onClick={() => this.props.onPlaceClear()} style={{ fontSize: 12, cursor: 'pointer' }}>Clear</p>
						</div>
						<p style={{
							fontSize: 11,
							color: "#676767",
							marginBottom: 18
						}}>
							You are currently filtering data for <b>{this.props.filter.distance.name}</b>.  Please select the radius you want to filter result.
					</p>
						<InputRange
							maxValue={50}
							minValue={0}
							formatLabel={value => `${value} Km`}
							value={this.props.filter.distance.value}
							onChange={value => this.props.changeFilter({
								distance: {
									...this.props.filter.distance,
									value
								}
							})}
						/>
					</div>}
				<div style={{ padding: '40px 0px' }}>
					<p style={{ fontWeight: 700, fontSize: 15, padding: '0 0 5px' }}>Price Range Per Night</p>
					<p style={{
						fontSize: 11,
						color: "#676767",
						marginBottom: 18
					}}>
						Please select the price range you want to filter result.
					</p>
					<InputRange maxValue={this.props.filter.price.maxValue} minValue={0} value={this.props.filter.price}
						formatLabel={price => `₹ ${price}`}
						onChange={value => this.props.changeFilter({
							price: {
								...value,
								maxValue: this.props.filter.price.maxValue
							}
						})} />
				</div>

			<div style = {{padding:'20px 0px'}}>
				<p style={{fontWeight:700,fontSize:15,padding:'0 0 5px'}}>Hotel Type</p>
				<p style = {{
					fontSize:11,
					color:'#676767',
					marginBottom:18
				}}>Please select the hotel type you want to filter result</p>
				<ul style = {{listStyle:'none',padding:0,margin:0,display:'flex',flexDirection:'column'}}>
					{
						this.props.filter.type.map((val,index)=>{
							return(
								<li key = {index} style = {{
									display:'flex',
									fontSize:14,
									marginBottom:5,
									alignItems:'center'
								}}>
									<div onClick = {()=>this.handleHotelType(index)} style = {val.isActive?{
										height:14,
										width:14,
										background:'#3f51b5',
										marginTop:2,
										cursor:'pointer'
									}:{
										height:14,
										width:14,
										border:'1px solid #dedede',
										cursor:'pointer',
										marginTop:2
									}} />
									<div style = {{marginLeft:8,fontSize:17,fontWeight:400}}>{val.item}</div>
								</li>
							)
						})
					}
				</ul>
			</div>

				<div style={{ padding: '20px 0' }}>
					<p style={{ fontWeight: 700, fontSize: 15, padding: '0 0 5px' }}>Amenities</p>
					<p style={{
						fontSize: 11,
						color: "#676767",
						marginBottom: 18
					}}>
						Please select the amenities you want to filter result.
					</p>
					<ul style={{ listStyle: 'none', padding: 0, margin: 0, display: 'flex', flexDirection: 'column' }}>
						{
							this.props.filter.amenities.map((val, index) => {
								return (
									<li key={index} style={{
										display: 'flex',
										fontSize: '14px',
										marginBottom: '5px',
										alignItems: 'center'
									}}>
										<div
											onClick={() => this.handleAmenities(index)}
											style={
												!val.isCheck ? {
													width: 14,
													height: 14,
													cursor: 'pointer',
													marginTop: 2,
													border: '1px solid #dedede'
												} : {
														background: "#3f51b5",
														cursor: 'pointer',
														marginTop: 2,
														width: 14,
														height: 14
													}
											}
										/>
										<div style={{ fontWeight: 400, fontSize: 17, marginLeft: '8px' }}>{val.text}</div>
									</li>
								)
							})
						}
					</ul>
				</div>

			

				<div style={{ padding: '20px 0' }}>
					<p style={{ fontWeight: 700, fontSize: 15, padding: '0 0 5px' }}>Star Rating</p>
					<p style={{
						fontSize: 11,
						color: "#676767",
						marginBottom: 18
					}}>
						Please select the star rating you want to result filtered.
					</p>
					<div style={{ display: 'flex', flexWrap: 'wrap' }}>
						{
							this.props.filter.Rating.map((val, index) => {
								return (
									<button key={index} style={{
										padding: '5px 10px',
										width: '30%',
										boxSizing: 'border-box',
										border: '1px solid #ccc',
										margin: '0 3px 5px',
										cursor: 'pointer',
										color: '#444',
										background: '#fff'
									}} onClick={() => this.handleRating(index)}
										className={`${val.isActive ? "rateactive" : null}`}>
										{val.item}
									</button>
								)
							})
						}
					</div>
				</div>
			</div>
		)
	}
}

export default PollingHotels;
