import React from 'react';
import { get } from '../../../../../Model/Network/Config/Axios';
import { NormalTable } from '../../../../Tables/NormalTable/NormalTable';
import CabRequest from '../CabRequest';
import Filter from '../../../Common/Filter/index';
import { DatePicker1 } from '../../../../libs/DatePicker/DateRangePicker1';
import ButtonOutline from '../../../../libs/ButtonOutline';
import _ from 'lodash';
import moment from 'moment';

class Index extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            requests: [],
            open: false,
            requestId: false,
            filters: [],
            type: {
                label: "By Name",
                value: "NAME",
            },
            dateFilter: false,
            range: false,
        };
    }


    componentDidMount() {
        get(`/cab/requests.closed`, (e, r) => {
            if (r) {
                this.setState({
                    ...this.state,
                    ...r
                })
            }
        })
    }
    
    mapItem = (item, index) => {
        return (
            <CabRequest
                key={index}
                item={item}
                open={this.state.open}
                requestId={this.state.requestId}
                onCloseRequestId={() => {
                    this.setState({
                        requestId: false
                    });
                }}
                onSetRequestId={(requestId) => {
                    this.setState({
                        requestId
                    });
                }}
                onSetOpen={(open) => {
                    this.setState({
                        open
                    });
                }}
                onCloseOpen={() => {
                    this.setState({
                        open: undefined
                    });
                }}
                />
            );
        }

    addFilter = (value) => {
        if (value) {
            let types = this.state.type.value;
            let filter = this.state.filters.filter(item => {
                return !(item.type === types && item.value === value);
            });
            filter.push({
                type: types, value
            });
            this.setState({
                filters: filter
            });
        }
    };

    deleteSelectValue = item => {
        let filter = this.state.filters;
        filter = filter.filter(mItem => {
            return !(item.type === mItem.type && item.value === mItem.value);
        });
        this.setState({
            filters: filter
        });
    };

    filter = data => {
        if (!Array.isArray(data)) {
            return [];
        }
        
        let filters = this.state.filters;
        let filterData = {};
        filters.forEach(f => {
            let items = filterData[f.type];
            items = items ? items : [];
            items.push(f.value);
            filterData[f.type] = items;
        });

        if (this.state.range) {
            data = data.filter(el => {
                if (el.departureDate) {
                    let date = moment(el.departureDate, "DD-MM-YYYY").valueOf();
                    return date >= moment(this.state.range.startDate).valueOf() && date <= moment(this.state.range.endDate).valueOf();
                } else if (el.checkIn) {
                    let date = moment(el.checkIn, "DD-MM-YYYY").valueOf();
                    return date >= moment(this.state.range.startDate).valueOf() && date <= moment(this.state.range.endDate).valueOf();
                }
                return false;
            });
        }

        data = data.filter(item => {
            let nameFilter = filterData["NAME"];
            if (nameFilter && !_.includes(nameFilter, item.employee.name)) {
                return false;
            }
            let empCodeFilter = filterData["EMPCODE"];
            if (empCodeFilter && !_.includes(empCodeFilter, item.employee.code)) {
                return false;
            }
            let destinationFilter = filterData["DESTINATION"];
            if (destinationFilter && !_.includes(destinationFilter, item.destinationName)) {
                return false;
            }
            return true;
        });

        return data;
    };



    render() {
        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Filter 
                        data={this.state.requests} 
                        filters={this.state.filters} 
                        type={this.state.type} 
                        addFilter={this.addFilter} 
                        removeFilter={this.deleteSelectValue}
                        onTypeChange={type => {
                            this.setState({ type });
                        }}
                        types={[
                            { label: "By Name", value: "NAME" },
                            { label: 'By Employee Code', value: 'EMPCODE' },
                            { label: 'By Destination', value: 'DESTINATION' }
                        ]}
                    />
                    <div>
                        {
                            this.state.range ?
                                <span className="btn btn-portlet-invert"
                                    style={{ marginRight: 5, display: 'inline', width: 230, color: '#777', borderColor: "var(--outline-color)", height: 35, padding: '4px 10px' }}>
                                    {moment(this.state.range.startDate).format('DD-MMM-YYYY')} - {moment(this.state.range.endDate).format('DD-MMM-YYYY')}
                                    <span onClick={() => {
                                        this.setState({
                                            range: false
                                        });
                                    }} style={{
                                        marginRight: 5,
                                        marginLeft: 10,
                                        marginTop: 4,
                                        fontSize: 13,
                                        cursor: 'pointer'
                                    }}>×</span>
                                </span>
                                :
                                <ButtonOutline title="Add Date Filter" onClick={() => {
                                    this.setState({
                                        dateFilter: true
                                    });
                                }} />
                        }
                    </div>
                </div>
                <div>
                    <NormalTable
                        headerStyle={{
                            border: 'none'
                        }}
                        headers={[]}
                        data={this.filter(this.state.requests)}
                        mapItem={this.mapItem}
                        pagination={true}
                    />
                </div>
                {
                    this.state.dateFilter ?
                        <DatePicker1
                            apply={(range) => {
                                this.setState({
                                    dateFilter: false,
                                    range
                                });
                            }}
                            handle={() => { }}
                            onClose={() => {
                                this.setState({
                                    dateFilter: false
                                });
                            }}
                        /> : null
                }
            </div>
        );
    }
}

export default Index;