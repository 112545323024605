import React from 'react'
import { TitleComponent } from "../../Common/TitleComponent";
import Sidebar from './Sidebar'
import Bookings from './Bookings'

class View extends React.Component {

	state = {
		name: "",
		email: "",
		code: "",
		mobile: "",
		image: ""
	}

	constructor(props) {
		super(props)
		this.presentor = props.presentor;
		this.presentor.setView(this)
	}

	componentDidMount() {
		this.presentor.init()
	}

	componentWillUnmount() {
		this.presentor.destroy();
	}

	load = data => {
		this.setState({
			...data
		})
	}

	getCode = () => {
		return this.props.match.params.code
	}

	render() {
		return (
			<div className="fintrip-container" style={{
				display: 'flex',
				flexDirection: 'row',
				flex: 1
			}}>
				<div className="side-container" style={{ borderTop: 'none' }}>
					<TitleComponent title={this.state.name} subtitle="Employee" />

					<div style={{
						padding: 22,
						width: 450,
						marginTop: 35,
						borderRadius: 10,
						background: "#F6F5FF",
					}}>
						<div style={{
							display: 'flex',
						}}>
							<img src={this.state.image} alt="" style={{
								width: 75,
								height: 75,
								background: "#888888",
								borderRadius: 37,
								marginRight: 12
							}} />
							<div>
								<p style={{
									fontSize: 19,
									color: "#232323",
									fontWeight: 700
								}}>
									{this.state.name}
								</p>
								<p style={{
									fontSize: 12,
									color: "#676767",
									fontWeight: 400
								}}>
									{this.state.email}
								</p>
								<p style={{
									fontSize: 12,
									color: "#676767",
									fontWeight: 400
								}}>
									{this.state.mobile}
								</p>
								<p style={{
									fontSize: 12,
									color: "#676767",
									fontWeight: 400
								}}>
									{this.state.code}
								</p>
							</div>
						</div>
					</div>
					<Bookings history={this.props.history} code={this.getCode()} />
				</div>
				<Sidebar history={this.props.history} code={this.getCode()} />
			</div>
		)
	}

}

export default View