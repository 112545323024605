import React from 'react'
import { get, post } from "../../../../Model/Network/Config/Axios";
import { getLuna } from "./Luna";
import Moment from "moment";
import moment from "moment";
import TextCard from './Messages/TextCard'
import { CenterImageFormCard } from './Messages/CenterImageFormCard'
import { TravelRequestFlightChoicesCard } from './Messages/TravelRequestFlightChoicesCard'
import { TravelRequestBusChoicesCard } from './Messages/TravelRequestBusChoicesCard';
import { TravelRequestHotelChoicesCard } from './Messages/TravelRequestHotelChoicesCard';
import { showToast } from "../../../libs/Toast/ToastCallback";

class Container extends React.Component {

	convertAndUpdate = (messages) => {
		this.setState({ ...this.state, ...messages })
		setTimeout(this.scrollToBottom, 200)
	}

	scrollToBottom = () => {
		if (this.messagesEnd)
			this.messagesEnd.scrollIntoView({ behavior: 'smooth' })
	}



	initRoom = roomId => {
		getLuna().getRoom(roomId, ref => {
			this._room = ref;
			this._room.on('value', sp => {
				let data = sp.val()
				if (data) {
					let messages = [];
					Object.values(data).forEach(value => {
						messages.push(value)
					})
					messages.sort((a, b) => {
						return a.createdAt - b.createdAt;
					})
					this.convertAndUpdate({
						messages
					})
				}
			});
		})
		getLuna().getDesk(roomId, ref => {
			this._desk = ref;
			this._desk.on('value', sp => {
				let data = sp.val()
				if (data) {
					this._desk.onDisconnect().set({
						...data,
						connected: false,
						count: 0
					})
				}
				this._desk.set({
					...data,
					count: 0,
					connected: true
				})
			})
		})
		getLuna().getMembers(roomId, ref => {
			this._user = ref;
			this._user.on('value', sp => {
				let data = sp.val()
				if (data) {
					this.setState({
						...this.state,
						connected: data.connected,
						lastSeen: data.lastSeen
					})
				}
			});
		})
	}

	componentWillUnmount() {
		if (this._room) {
			this._room.off('value')
		}
		if (this._user) {
			this._user.off('value')
		}
		if (this._desk) {
			this._desk.off('value')
			this._desk.onDisconnect().cancel()
			this._desk.once('value').then(sp => {
				let data = sp.val()
				if (data) {
					this._desk.set({
						...data,
						connected: false,
						count: 0
					})
				}
			})
		}
	}


	mapItem = (item, index) => {
		let length = this.state.messages.length;
		let hidden = false;
		let date = true;
		if (length > 2 && index > 1) {
			hidden = this.state.messages[index].sendor && this.state.messages[index].sendor.claim === this.state.messages[index - 1].sendor.claim;
		}
		if (length > 1 && index > 1 && index < length) {
			let date1 = Moment(this.state.messages[index - 1].createdAt).format('YYYY MM DD');
			let date2 = Moment(this.state.messages[index].createdAt).format('YYYY MM DD');
			let one = Moment(date1).isSame(date2, 'day')
			date = !one;
		} else if (index === 0) {
			date = false
		}



		switch (item.messageType) {
			case 'CenterImageFormCard':
				return <CenterImageFormCard key={index} data={item} hidden={hidden} date={date} />
			case 'Text':
				return <TextCard key={index} data={item} hidden={hidden} date={date} />
			case "TravelRequestFlightChoicesCard":
				return <TravelRequestFlightChoicesCard key={index} data={item} hidden={hidden} date={date} />
			case "TravelRequestHotelChoicesCard":
				return <TravelRequestHotelChoicesCard key={index} data={item} hidden={hidden} date={date} />
			case "TravelRequestBusChoicesCard":
				return <TravelRequestBusChoicesCard key={index} data={item} hidden={hidden} date={date} />
			default:
				return (<div key={index} />)
		}
	}

	state = {
		title: undefined,
		connected: false,
		lastSeen: undefined,
		messages: []
	}

	componentDidMount() {
		get(`/luna/${this.props.id}`, (e, r) => {
			if (r) {
				this.initRoom(r.room)
				this.setState({
					...this.state,
					title: r.title
				})
			}
		})
	}

	_handleEnter = (e) => {
		if (e.key === "Enter") {
			let message = this._msg.value;
			if (message.length > 1) {
				post(`/luna/${this.props.id}/chat`, { message }, (e, r) => { })
				this._msg.value = "";
			} else {
				showToast({
					type: 'error',
					message: 'Please enter valid message'
				})
			}
		}
	}

	render() {
		return (
			<div style={{
				marginRight: 20,
				width: 376,
				height: 550,
				right: 0,
				bottom: 0,
				zIndex: 12,
				overflow: 'hidden',
				display: 'flex',
				flexDirection: 'column',
				borderRadius: 8,
				backgroundColor: 'white',
				boxShadow: 'rgba(0, 0, 0, 0.16) 0px 5px 40px',
			}}>
				<div style={{
					height: 90,
					padding: 15,
					display: 'flex',
					flexDirection: 'row',
					background: "#7558F6"
				}}>
					<img onClick={this.props.close} src={require('./close.png')} alt="close" style={{
						width: 13,
						height: 13,
						marginRight: 12,
						marginTop: 6,
						cursor: 'pointer',
					}} />
					<div style={{
						display: 'flex',
						flexDirection: 'column'
					}}>
						<p style={{
							color: 'white',
							fontSize: 18,
							letterSpacing: .1
						}}>
							Travel Request
						</p>
						<p style={{
							color: 'white',
							fontSize: 9,
							marginTop: 4,
							opacity: .7
						}}>
							Conversation of selected request appears below.
						</p>
						<div style={{
							marginTop: 10,
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center'
						}}>
							<div style={{
								width: 6,
								height: 6,
								borderRadius: 3,
								background: this.state.connected ? "#ffffff" : "#bcbcbc"
							}} />
							<p style={{
								marginLeft: 5,
								fontSize: 11,
								color: "White"
							}}>
								{
									this.state.connected ? "Active right now" : this.state.lastSeen ? `Last active on ${moment(this.state.lastSeen).format("dd MM HH:MM A")}` : "Inactive"
								}
							</p>
						</div>
					</div>
				</div>
				<div style={{
					display: 'flex',
					flexDirection: 'column',
					flex: 1,

				}}>
					<div style={{
						height: 410,
						overflowY: 'auto',
						paddingBottom: 20
					}}>
						{this.state.messages.map(this.mapItem)}
						<div style={{ float: "left", clear: "both" }} ref={ref => {
							this.messagesEnd = ref;
						}} />
					</div>
					<div style={{
						borderTop: '1px solid #dedede',
						height: 50
					}}>
						<input type="text"
							ref={ref => this._msg = ref}
							onKeyPress={this._handleEnter}
							placeholder="Enter your message"
							style={{
								borderRadius: 4,
								padding: "12px 10px",
								fontSize: 15,
								backgroundColor: 'var(--primary-color)',
								color: 'var(--sidebar-active-color)',
								border: 'none',
								width: '100%'
							}} />
					</div>
				</div>
			</div>
		)
	}

}

export default Container
