import firebase from "firebase/app"
import "firebase/auth"
import "firebase/database"
import {get} from "../../../../Model/Network/Config/Axios";

class LunaUtils {

	tenant = undefined;

	init() {
		getFirebase().init()
		get("luna/tenant", (e, r) => {
			this.tenant = r.tenant;
			getFirebase().initializeApp()
		})
	}

	getTenant() {
		return this.tenant;
	}

	getRoom(path, cb) {
		path = `luna/${getLuna().getTenant()}/rooms/${path}`;
		return getFirebase().fetchDb(database => {
			let ref = database.ref(path);
			cb(ref)
		})
	}

	getMembers(room, cb) {
		let path = `luna/${getLuna().getTenant()}/members/${room}`;
		return getFirebase().fetchDb(database => {
			let ref = database.ref(path);
			ref.once('value').then(d=>{
				let data = d.val();
				if(data){
					let user = Object.keys(data)[0];
					path = `luna/${getLuna().getTenant()}/users/${user}/${room}`
					let ref = database.ref(path);
					cb(ref)
				}
			})
		})
	}

	getDesk(path, cb) {
		path = `luna/${getLuna().getTenant()}/desk/${path}`;
		return getFirebase().fetchDb(database => {
			let ref = database.ref(path);
			cb(ref)
		})
	}


	getAll(cb) {
		let path = `luna/${getLuna().getTenant()}/desk`;
		return getFirebase().fetchDb(database => {
			let ref = database.ref(path);
			cb(ref)
		})
	}

}

class Firebase {

	firebaseConfig = {
		apiKey: "AIzaSyBsy8egl_xrhbj7ZFHS52kc1cO5zED58eQ",
		authDomain: "fintrip-47824.firebaseapp.com",
		databaseURL: "https://fintrip-47824.firebaseio.com",
		projectId: "fintrip-47824",
		storageBucket: "fintrip-47824.appspot.com",
		messagingSenderId: "462305025824",
		appId: "1:462305025824:web:75b1b299f9191e776ac145",
		measurementId: "G-T1X9BD0SZ2"
	}

	authenticated = false;
	cb = undefined;
	inited = false;
	databaseCb = [];

	init() {
		if (this.inited) {
			return;
		}
		this.inited = true;
		firebase.initializeApp(this.firebaseConfig);
	}


	fetchDb(cb) {
		if (this.database) {
			cb(this.database)
		} else {
			this.databaseCb.push(cb);
		}
	}


	signout() {
		firebase.auth().signOut();
		this.cb = undefined;
	}

	initializeApp() {
		let self = this;
		if (!self.authenticated) {
			self.authenticated = true;
			self.cb = function (user) {
				if (user) {
					self.database = firebase.database();
					self.databaseCb.forEach(cb => {
						cb(this.database)
					})
					self.databaseCb = [];
				} else {
					get(`/luna/token`, (e, r) => {
						if (r) {
							firebase.auth().signInWithCustomToken(r.token).then(succes => {
								user = firebase.auth().currentUser;
							}).catch(console.log)
						}
					})
				}
			}
			firebase.auth().onAuthStateChanged(function (user) {
				if (self.cb != null) {
					self.cb(user)
				}
			});
		}
	}

}


let instance = undefined;

const getLuna = () => {
	if (!instance) {
		instance = new LunaUtils();
	}
	return instance;
}


let fn = undefined;

const getFirebase = () => {
	if (!fn) {
		fn = new Firebase();
	}
	return fn;
}

export {
	getLuna
}
