import React from 'react';
import { TitleComponent } from '../../Common/TitleComponent';
import { FloatInput } from '../../../libs/FloatLabelInput';
import { showToast } from '../../../libs/Toast/ToastCallback';
import Moment from 'moment';


class View extends React.Component {




    submit = () => {
        let name = this.name.value();
        let father_name = this.father.value();

        if (!name) {
            showToast({
                type: 'error',
                message: 'Please enter your name'
            })
            return;
        }

        if (!father_name) {
            showToast({
                type: 'error',
                message: 'Please enter your father name'
            })
            return;
        }

        let mother = this.mother.value();
        let dob = this.dob.value();

        if (!mother) {
            showToast({
                type: 'error',
                message: 'Please enter your mother name'
            })
            return;
        }

        if (!dob) {
            showToast({
                type: 'error',
                message: 'Please enter your date of birth'
            })
            return;
        }


        let birth_city = this.birth_city.value();
        let birth_country = this.birth_country.value();

        if (!birth_city) {
            showToast({
                type: 'error',
                message: 'Please enter your birth city'
            })
            return;
        }

        if (!birth_country) {
            showToast({
                type: 'error',
                message: 'Please enter your birth country name'
            })
            return;
        }


        let address = this.address.value();
        let city = this.city.value();

        if (!address) {
            showToast({
                type: 'error',
                message: 'Please enter your address'
            })
            return;
        }

        if (!city) {
            showToast({
                type: 'error',
                message: 'Please enter your city name'
            })
            return;
        }

        let pincode = this.pincode.value();
        let passport = this.passport.value();

        if (!pincode) {
            showToast({
                type: 'error',
                message: 'Please enter a pincode number'
            })
            return;
        }

        if (!passport) {
            showToast({
                type: 'error',
                message: 'Please enter a passport number'
            })
            return;
        }

        let placeofissue = this.place.value();
        let dateofissue = this.date_of_issue.value();

        if (!placeofissue) {
            showToast({
                type: 'error',
                message: 'Please enter your place of issue of passport'
            })
            return;
        }

        if (!dateofissue) {
            showToast({
                type: 'error',
                message: 'Please enter date of issue of passport'
            })
            return;
        }


        let expire_date = this.expire_date.value();
        let phone = this.phone.value();

        if (!expire_date) {
            showToast({
                type: 'error',
                message: 'Please enter the passport expire date'
            })
            return;
        }

        if (!phone) {
            showToast({
                type: 'error',
                message: 'Please enter a phone number'
            })
            return;
        }


        let data = {
            name,
            father_name,
            mother,
            dob: Moment(dob).format('DD-MM-YYYY'),
            birth_city,
            birth_country,
            address,
            city,
            pincode,
            passport,
            placeofissue,
            dateofissue: Moment(dateofissue).format('DD-MM-YYYY'),
            expire_date: Moment(expire_date).format('DD-MM-YYYY'),
            phoneNumber: phone

        }
        console.log(data);
    }

    render() {
        return (
            <div className="side-container" >
                <TitleComponent title="Visa Request" subtitle="Details" />
                <div style={{
                    marginTop: 30
                }} />
                <div style={{
                    display: 'flex',
                    flex: 1,

                }}>
                    <div style={{
                        flex: 1,
                        marginRight: 20
                    }}>
                        <FloatInput title="Name" type="text" handleRef={ref => this.name = ref} />
                    </div>

                    <div style={{
                        flex: 1
                    }}>
                        <FloatInput title="Father Name" type="text" handleRef={ref => this.father = ref} />

                    </div>
                </div>

                <div style={{
                    display: 'flex',
                    flex: 1,

                }}>
                    <div style={{
                        flex: 1,
                        marginRight: 20
                    }}>
                        <FloatInput title="Mother Name" type="text" handleRef={ref => this.mother = ref} />
                    </div>

                    <div style={{
                        flex: 1
                    }}>
                        <FloatInput title="DOB" type="date" handleRef={ref => this.dob = ref} />

                    </div>
                </div>

                <div style={{
                    display: 'flex',
                    flex: 1
                }}>
                    <div style={{
                        flex: 1,
                        marginRight: 20
                    }}>
                        <FloatInput title="Birth City" type="text" handleRef={ref => this.birth_city = ref} />

                    </div>
                    <div style={{
                        flex: 1
                    }}>
                        <FloatInput title="Birth  Country" type="text" handleRef={ref => this.birth_country = ref} />

                    </div>
                </div>


                <div style={{
                    display: 'flex',
                    flex: 1
                }}>
                    <div style={{
                        flex: 1,
                        marginRight: 20
                    }}>
                        <FloatInput title="Address" type="text" handleRef={ref => this.address = ref} />

                    </div>
                    <div style={{
                        flex: 1
                    }}>
                        <FloatInput title="City" type="text" handleRef={ref => this.city = ref} />

                    </div>
                </div>


                <div style={{
                    display: 'flex',
                    flex: 1
                }}>
                    <div style={{
                        flex: 1,
                        marginRight: 20
                    }}>
                        <FloatInput title="Pin Code" type="text" handleRef={ref => this.pincode = ref} />

                    </div>
                    <div style={{
                        flex: 1
                    }}>
                        <FloatInput title="Passport Number" type="text" handleRef={ref => this.passport = ref} />

                    </div>
                </div>


                <div style={{
                    display: 'flex',
                    flex: 1
                }}>
                    <div style={{
                        flex: 1,
                        marginRight: 20
                    }}>
                        <FloatInput title="Place of issue" type="text" handleRef={ref => this.place = ref} />

                    </div>
                    <div style={{
                        flex: 1
                    }}>
                        <FloatInput title="Date of issue" type="date" handleRef={ref => this.date_of_issue = ref} />

                    </div>
                </div>

                <div style={{
                    display: 'flex',
                    flex: 1
                }}>
                    <div style={{
                        flex: 1,
                        marginRight: 20
                    }}>
                        <FloatInput title="Expiry Date" type="date" handleRef={ref => this.expire_date = ref} />

                    </div>
                    <div style={{
                        flex: 1
                    }}>
                        <FloatInput title="Phone Number" type="text" handleRef={ref => this.phone = ref} />

                    </div>
                </div>

                <div className="btn btn-portlet" style={{
                    marginTop: 40
                }} onClick={this.submit}>
                    Submit Request
                </div>

            </div>
        )
    }
}

export default View;