import React from 'react';
import PastBookings from './PastBookings'
import UpcomingBookings from './UpcomingBookings'
import {DatesRangeReact} from '../../../../libs/DateRangereact';
import Moment from 'moment';

class Index extends React.Component {

    state = {
		upcoming : true,
		startDate : new Moment().subtract('d',30),
		endDate : new Moment()
    }

    render() {
        return(
			<div>
				<div style = {{display:'flex',justifyContent:'space-between',marginTop:35}}>
					<p style={{
						marginBottom:12,
						color:"#321CBF",
						fontWeight:700,
						fontSize:25
					}}>
						Bookings
					</p>
					{this.state.upcoming ? null :
						(<DatesRangeReact startDate = {this.state.startDate} endDate = {this.state.endDate}
						handleDate = {({startDate,endDate}) => {
							this.setState({
								startDate,endDate
							})
						}}
					/>)
					}
				</div>
				<div
					style={{borderBottom: '2px solid rgb(241,241,241)', display: 'flex', marginBottom: 10}}>
					<p onClick={() => {
						this.setState({
							upcoming: true
						})
					}} style={{
						padding: '0px 0 12px',
						marginRight: 32,
						fontSize: 15,
						color: 'inherit',
						fontWeight:600,
						textTransform: 'uppercase',
						cursor: 'pointer',
						position: 'relative',
						top: 3
					}} className={this.state.upcoming ? "active-line" : null}>
						Upcoming Bookings
					</p>
					<p onClick={() => {
						this.setState({
							upcoming: false
						})
					}} style={{
						padding: '0px 0 12px',
						cursor: 'pointer',
						marginRight: 32,
						fontWeight:600,
						fontSize: 15,
						color: 'inherit',
						textTransform: 'uppercase',
						position: 'relative',
						top: 3
					}} className={!this.state.upcoming ? "active-line" : null}>
						Past Bookings
					</p>
				</div>
                {
                    this.state.upcoming?<UpcomingBookings {...this.props}/>:<PastBookings startDate = {this.state.startDate} endDate = {this.state.endDate} {...this.props}/>
                }
            </div>
        )
    }
}

export default Index;