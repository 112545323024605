import React from 'react'
import { SearchableSelect } from "../../../libs/Select/SearchableSelec2";
import { get } from "../../../../Model/Network/Config/Axios";

class Guest extends React.Component {


	constructor() {
		super();
		this.Disposables = [];

	}

	state = {
		guest: undefined,
		items: []
	}

	_guestSelect = guest => {
		this.setState({
			...this.state,
			guest
		})
	}


	onChange = q => {
		this.Disposables.push(get(`/search/guest`, (e, r) => {
			if (r) {
				this.setState({
					...this.state,
					items: r.guests.map(item => ({
						label: item.name,
						des: item.email,
						value: item.id
					}))
				})
			}
		}, {
				q
			}))
	}

	componentWillUnmount() {
		this.Disposables.forEach(item => {
			if (item.isCancellable())
				item.cancel()
		})
	}

	render() {
		return (
			<div>
				<SearchableSelect
					items={this.state.items}
					onChange={this.onChange}
					label={"Guest"}
					placeholder={"Select Guest"} select={this._guestSelect} value={this.state.guest} />
				<p style={{
					width: '100%',
					textAlign: 'center',
					fontSize: 17,
					color: "#fff",
					cursor: 'pointer',
					background: "#7458F6",
					border: "2px solid #7458F6",
					marginTop: 30,
					padding: "12px 20px"
				}} onClick={() => {
					if (this.state.guest)
						this.props.callback(this.state.guest)
				}} className="save">
					SELECT GUEST
				</p>
			</div>
		)
	}

}

export default Guest