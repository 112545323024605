import React, {useRef} from 'react';
import './index.css';

function ToggleSwitch(props) {

	let Toggle = useRef(null);

	return (
		<>
			<div style={{display: 'flex', justifyContent: 'space-between', width: '100%', paddingLeft: 10, paddingRight: 10,...props.style}}>
				<div style={{alignSelf: 'end', paddingBottom: 7, fontSize: '1rem'}}>
					{props.label}
				</div>
				<div style={{marginLeft: 20}}>
					<label style={{cursor:'pointer'}}  className={props.size==='S'?"switch-mini":"switch"}>
						<input  type="checkbox"
							   ref={ref => {
								   Toggle.current = ref;
								   if (props.handleRef) {
									   props.handleRef({
										   ref,
										   toggle: val => ref.checked = !ref.checked,
										   isChecked: () => ref.checked
									   })
								   }
							   }}
							   onChange={()=>{
								   if(props.callback){
									   props.callback(Toggle.current.checked);
								   }
							   }}
						checked={props.status}
						/>
						<span className={`${props.size==='S'?'togglerSlider-mini':'togglerSlider'} round`}/>
					</label>
				</div>
			</div>

		</>
	)
}

function StateSwitch(props) {
	let Toggle = useRef(null);

	return (
		<>
			<div style={{display: 'flex', justifyContent: 'space-between', paddingLeft: 10, paddingRight: 10, ...props.style}}>
				<div style={{alignSelf: 'end', paddingBottom: 7, fontSize: '0.8rem'}}>
					{Toggle.current?Toggle.current.checked?props.onlabel:props.offlabel:props.offlabel}
				</div>
				<div style={{marginLeft: 20}}>
					<label className="switch">
						<input style={{display: 'none'}} type="checkbox"
							   ref={ref => {
								   Toggle.current = ref;
								   if (props.handleRef) {
									   props.handleRef({
										   ref,
										   toggle: val => ref.checked = !ref.checked,
										   isChecked: () => ref.checked
									   })
								   }
							   }}
							   onChange={()=>{
								   if(props.callback){
									   props.callback(Toggle.current.checked);
								   }
							   }}
							   checked={props.checked?props.checked:false}
							   />
						<span className="togglerSlider round"/>
					</label>
				</div>
			</div>

		</>
	)
}

export default ToggleSwitch;
export {StateSwitch};
