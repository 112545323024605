import React from 'react'
import {SearchableSelect} from "../../../libs/Select/SearchableSelec2";
import {get} from "../../../../Model/Network/Config/Axios";

class Employee extends React.Component {



	state = {
		employee : undefined,
		items :[]
	}

	_employeeSelect = employee => {
		this.setState({
			...this.state,
			employee
		})
	}


	onChange = q => {
		get(`/search/employees`,(e,r)=>{
			if(r){
				this.setState({
					...this.state,
					items:r.employees.map(item=>({
						label : item.name,
						des   : item.email,
						value : item.code,
						des1: `Grade: ${item.grade}`
					}))
				})
			}
		},{
			q
		})
	}

	render() {
		return (
			<div>
				<SearchableSelect
					items={this.state.items}
					onChange={this.onChange}
					label={"Employee"}
					placeholder={"Select Employee"} select={this._employeeSelect} value={this.state.employee}/>
					<p style={{
						width: '100%',
						textAlign: 'center',
						fontSize: 17,
						cursor:'pointer',
						color:"#fff",
						background: "#7458F6",
						border: "2px solid #7458F6",
						marginTop: 30,
						padding: "12px 20px"
					}} onClick={()=>{
						if(this.state.employee)
							this.props.callback(this.state.employee)
					}} className="save">
						SELECT EMPLOYEE
					</p>
			</div>
		)
	}

}

export default Employee