import React from 'react';
import { NormalTable } from '../../../../Tables/NormalTable/NormalTable';
import Moment from 'moment';
import HotelRefund from '../../../Common/RefundItems/HotelRefund';


class View extends React.Component {

    constructor(props) {
        super(props);
        this.presentor = props.presentor;
        this.presentor.setView(this);
        this.state = {
            refunds: []
        }
    }

    componentDidMount() {
        let { startDate, endDate } = this.props;

        let date = {
            startDate: Moment(startDate).valueOf(),
            endDate: Moment(endDate).valueOf()
        }

        this.presentor.init(date);
    }

    componentWillUnmount() {
        this.presentor.destroy()
    }


    componentWillReceiveProps(props) {
        if (this.props.startDate !== props.startDate || this.props.endDate !== props.endDate) {
            let { startDate, endDate } = props;
            let date = {
                startDate: Moment(startDate).valueOf(),
                endDate: Moment(endDate).valueOf()
            }
            this.presentor.init(date)
            return true
        } else {
            return false
        }
    }

    mapItem = (item, index) => {
        return (
            <HotelRefund item={item} key={index} />
        )
    }

    load = r => {
        this.setState({
            refunds: r.tickets
        })
    }

    render() {
        return (
            <div>

                <NormalTable headerStyle={{
                    border: 'none'
                }} headers={[
                    {
                        key: 'title',
                        name: '',
                        weight: 1
                    },
                    {
                        key: 'status',
                        name: '',
                        weight: 1
                    },
                    {
                        key: 'time',
                        name: '',
                        weight: 1
                    },

                ]}
                    data={this.state.refunds} pagination={true} mapItem={this.mapItem}
                />
            </div>
        )
    }
}

export default View;