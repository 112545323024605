import React from 'react';
import { EmptyState } from '../../../../libs/EmptyState/index';
import emptyBooking from '.././k2.png';
import { NormalTable } from '../../../../Tables/NormalTable/NormalTable';
import { FlightItem } from '../../../Common/BookingItem/FlightItem'
import { Modal } from '../../../../libs/Modal/Modal';

class View extends React.Component {

	constructor(props) {
		super(props);
		this.presentor = props.presentor;
		this.presentor.setView(this);
		this.state = {
			bookings: [],
			emptyState: false,
			cancelModal: false
		}
	}

	componentDidMount() {
		this.presentor.init();
	}

	componentWillUnmount() {
		this.presentor.destroy()
	}


	load = r => {
		if (r.tickets.length > 0) {
			this.setState({
				bookings: r.tickets,
				emptyState: false
			})
		} else {
			this.setState({
				bookings: r.tickets,
				emptyState: true
			})
		}
	}

	Item = (item, index) => {
		return (
			<FlightItem openCancel={this.flightCancel} onCancel={true} key={index} data={item} />
		)
	}

	flightCancel = (id) => {
		this.setState({
			cancelModal: true
		})
	}


	render() {
		if (this.state.emptyState) {
			return <EmptyState image={emptyBooking} title="No Upcoming Booking Found" desc="Please Book the Tickets" />
		}



		return (

			<div>
				<NormalTable headerStyle={{
					border: 'none',
					marginTop: -15
				}} headers={[
					{
						key: 'details',
						name: '',
						weight: ''
					},
					{
						key: 'name',
						name: '',
						weight: ''
					},
					{
						key: 'amount',
						name: '',
						weight: ''
					}
				]}
					data={this.state.bookings}
					pagination={true}
					mapItem={this.Item}
				/>
				<Modal
					show={this.state.cancelModal}
					close={() => {
						this.setState({
							cancelModal: false
						})
					}}
					title="Flight Ticket"
					des="Ticket cancellation details "
					hideButton={true}
					onSave={() => { }}
				>
					<p style={{
						fontWeight: "bold",
						fontSize: 16
					}}>Please contact 8448239151 / 9354356088 to reschedule or cancel your flight</p>
				</Modal>
			</div>
		)
	}

}

export default View