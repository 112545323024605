import React from 'react';
import Moment from 'moment'
import { FlightItemExtended } from "./FlightItemExtended";
import _ from 'lodash';
import Tick from './tick.png'
import ActiveTick from './tick (1).png'

class FlightItem extends React.Component {

	state = {
		result: undefined
	}


	mapItem = (item, index) => {
		let departure = parseInt(item.departureTime, 10);
		let arrival = parseInt(item.arrivalTime, 10);
		let duration = Moment.duration(Moment(Number(arrival)).diff(Moment(Number(departure))));
		let durationValue = `${duration.hours()} hrs ${duration.minutes()} minutes`
		return (
			<div key={index} style={{ marginBottom: 30 }}>
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
					<div style={{ flex: 1 }}>
						<p style={{ color: '#000', textAlign: 'center' }}>{Moment(Number(item.departureTime)).format("HH:mm a")}</p>
					</div>
					<div style={{ flex: 1.5 }}>
						<p style={{
							fontSize: 13,
							marginBottom: 5,
							textAlign: 'center',
							color: "#676767"
						}}>
							{item.airline.name}
						&nbsp;- &nbsp;
						{item.airline.code}
                        &nbsp;
						{item.airline.number}
						</p>
						<div style={{
							borderBottom: '2px solid #ccc'
						}} />
						<p style={{
							fontSize: 15,
							marginTop: 5,
							textAlign: 'center',
							color: "#232323"
						}}>
							{durationValue}
						</p>
					</div>
					<div style={{ flex: 1, color: '#000' }}>
						<p style={{ textAlign: 'center' }}>{Moment(Number(item.arrivalTime)).format("HH:mm a")}</p>
					</div>
				</div>
				<div style={{ display: 'flex' }}>
					<div style={{ flex: 1, textAlign: 'center', color: '#777', fontSize: 14 }}>{item.origin.code}</div>
					<div style={{ flex: 1.5 }} />
					<div style={{ flex: 1, textAlign: 'center', color: '#777', fontSize: 14 }}>{item.destination.code}</div>
				</div>
			</div>
		)
	}

	render() {
		let { item } = this.props;
		let { requestId } = this.props;
		if (this.props.open) {
			if (this.props.open === item.flightCode)
				return (
					<FlightItemExtended {...this.props} />
				)
			else
				return (null)
		}

		let active = this.props.preferred === item.key ? true : false
		return (
			<div style={{
				width: '100%',
				marginLeft: 20,
				marginTop: 20,
				background: "white",
				boxShadow: '.5px 1px .5px rgba(0,0,0,0.1)',
				padding: '30px 30px',
				border: '1px solid #eee',
				marginRight: 20,
				position: 'relative',
				overflow: 'hidden',
				...active ?
					{
						border: '1px solid #7558F6'
					} : null
			}}>
				<div style={{ position: 'absolute', left: -25, top: 10, transform: 'rotate(-45deg)' }}>
					<div style={{
						background: '#7558F6',
						height: 25,
						width: 100,
						color: '#fff',
						padding: '2px 5px',
						fontSize: 10,
						fontWeight: 600,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}>{item.badge}</div>
				</div>
				<div style={{ display: 'flex', }}>
					<div style={{ width: 120, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
						<img src={item.logo} style={{
							width: 28,
							height: 28,
							marginBottom: 6
						}} alt="logo" />
						<p style={{
							textAlign: 'center',
							fontSize: 14,
							fontWeight: 600,
							color: "#676767"
						}}>
							{item.airline}
						</p>
					</div>
					<div style={{ flex: 2.5 }}>
						{
							item.segments.map(this.mapItem)
						}
					</div>
					<div style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'flex-end' }}>
						<p style={{ textAlign: 'right', fontWeight: 700, fontSize: 16, color: '#000' }}>Rs {item.fare ? parseInt(item.fare.publishedFare, 10) : null}</p>
						<p style={{ textAlign: 'right', fontSize: 14, cololr: '#444', fontWeight: 600 }}>Price per passenger</p>
						<div style={{
							zIndex: 2,
							display: 'flex',
							alignItems: 'center'
						}}>
							<p onClick={this.props.onOpen.bind(this, item.flightCode)} style={{
								textAlign: 'center',
								color: '#fff',
								fontWeight: 600,
								marginTop: 12,
								padding: '8px 75px',
								background: 'rgb(117,88,246)',
								cursor: 'pointer',
								fontSize: 13,
								borderRadius: 2,

							}}>Book</p>
							{
								requestId ?
									<div style={_.includes(this.props.selected, `${item.flightType}|${item.key}`) ? {
										width: 32,
										height: 32,
										marginLeft: 6,
										border: "2px solid #7558F6",
										background: "#7558F6",
										cursor: 'pointer',
										padding: 9,
										marginTop: 12,
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center'
									} : {
											width: 32,
											height: 32,
											marginLeft: 6,
											cursor: 'pointer',
											border: "2px solid #7558F6",
											padding: 9,
											marginTop: 12,
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center'
										}} onClick={this.props.onSelect.bind(this, `${item.flightType}|${item.key}`)}>
										<img alt="eka" src={_.includes(this.props.selected, `${item.flightType}|${item.key}`) ? ActiveTick : Tick} style={{
											width: 14,
											height: 14
										}} />
									</div> :
									null
							}
						</div>
					</div>
				</div>

			</div>
		)
	}

}

export { FlightItem };