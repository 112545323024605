import React from 'react';
import {Link} from 'react-router-dom';
import Moment from 'moment';

const HotelRefund = ({item} ) => {
    return (
        <div className="border-bottom" style={{ padding: '10px' }}>
            <div className="linear-layout horizontal" style={{ flex: 1 }}>
                <div style={{ flex: 1 }} className="linear-layout horizontal">
                    <div>
                        <img src={item.image} alt="eka" style={{ width: '80px', height: '80px', borderRadius: '50%' }} />
                    </div>
                    <div style={{ marginLeft: 15 }}>
                        <p style={{ fontWeight: "bold" }}>{item.hotel ? item.hotel.name : null}</p>
                        <p style={{ marginTop: 5, color: "#7f7f7f", fontSize: 12 }}>{item.hotel ? item.hotel.guests : null} Guests - {item.hotel ? item.hotel.rooms : null} Rooms</p>
                        <p style={{ color: "#7f7f7f", fontSize: 12, lineHeight: '20px' }}>{item.stayDate ? item.stayDate.checkIn : null} - {item.stayDate ? item.stayDate.checkOut : null}</p>
                    </div>
                </div>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <p style={{ fontWeight: 'bold' }}>{item.userName}</p>
                    <p style={{ marginTop: 5, color: '#7f7f7f' }}>{item.transactionId}</p>
                    <p style={{ color: '#7f7f7f', fontSize: 12, lineHeight: '20px' }}>{item.stayDate ? Moment(item.stayDate.bookingDate).format('DD MMM YYYY') : null}</p>
                </div>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    <p style={{ marginTop: 5, color: '#7f7f7f' }}>Amount Rs {item.amount}</p>
                    <p>Refund Amount : {item.refundAmount}</p>
                    <div style={{ display: 'flex' }}>
                        <Link to={`/app/hotel/tickets/${item.transactionId}`} className="btn btn-portlet" style={{ color: '#fff', fontSize: 9, height: 25, cursor: 'pointer', width: 100, padding: '4px 6px', borderRadius: '18px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 8 }}>View Details</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default  HotelRefund
