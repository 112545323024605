import React from 'react'
import SearchComponent from './SearchComponent'
import SearchArea from './SearchArea';
import Search from "stringquery";

class View extends React.Component {

	constructor(props) {
		super(props);
		this.presentor = props.presentor;
		this.presentor.setView(this)
	}

	state = {
		result: undefined
	}


	componentDidMount() {
		const params = Search(this.props.location.search);
		if (params.requestId) {
			this.presentor.getRequest(params.requestId, params.type)
		}
	}

	componentWillUnmount() {
		this.presentor.destroy();
	}

	load = result => {
		const params = Search(this.props.location.search);
		var url = ""
		if (params.requestId) {
			url = `/app/bus/search/polling/${result.searchId}?type=${[params.type ? params.type : "onwards"]}&requestId=${params.requestId}&sessionId=${result.sessionId}`
		} else {
			url = `/app/bus/search/polling/${result.searchId}?sessionId=${result.sessionId}`
		}
		this.props.history.push(url)
	}

	render() {
		return (
			<div className="fintrip-container" style={{
				display: 'flex',
				flexDirection: 'row',
				flex: 1
			}}>
				<SearchComponent loader={r => this._loader = r} result={this.state.result} search={data => {
					this.presentor.search(data)
				}} />
				<SearchArea />
			</div>
		)
	}

}

export default View