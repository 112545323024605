import React from 'react'
import SearchComponent from './SearchComponent'

class View extends React.Component {

	constructor(props) {
		super(props);
		this.presentor = props.presentor;
		this.presentor.setView(this)
	}

	state = {
		result : undefined
	}

	load = result => {
		this.setState({
			...this.state,
			result
		})
		let data = this.data;
		data.sessionId = result.sessionId;
		if(result.polling){
			setTimeout(()=>{
				this.presentor.search(data)
			},1000)
		}
	}

	render() {
		return (
			<div className="fintrip-container" style={{
				display:'flex',
				flexDirection:'row',
				flex:1
			}}>
				<SearchComponent reset={()=>{
					this.setState({
						...this.state,
						result:undefined
					})
				}} result={this.state.result} search={data=>{
					this.data = data;
					this.setState({
						result:{
							polling : true
						}
					})
					this.presentor.search(data)
				}}/>
			</div>
		)
	}

}

export default View