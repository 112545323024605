import React from 'react';
import {TitleComponent} from '../../Common/TitleComponent';
import PastBookings from './PastBookings'
import UpcomingBookings from './UpcomingBookings'
import {DatesRangeReact} from '../../../libs/DateRangereact';
import Moment from 'moment';
import { Route,Switch} from  'react-router-dom';
import TopBar from '../../Common/TopBar';


class Index extends React.Component {

    state = {
		upcoming : true,
		startDate: new Moment().subtract(30,'d'),
		endDate: new Moment()
    }

    render() {
		let route = this.props.location.pathname;
		let upcoming = "/app/hotel/bookings";
		let past = "/app/hotel/bookings/past"
        return(
			<div className = "side-container">
				<div style = {{display:'flex',justifyContent:'space-between'}}>
					<TitleComponent subtitle = "Hotel" title = "Booking" /> 
					{route === upcoming ? null :
						(<DatesRangeReact startDate = {this.state.startDate} endDate = {this.state.endDate}
						handleDate = {({startDate,endDate}) => {
							this.setState({
								startDate,endDate
							})
						}}
					/>)
					}
				</div>

				<TopBar {...this.props} item = {[
					{
						name:'upcoming booking',
						path:'/app/hotel/bookings',
						active :route === upcoming
					},
					{
						name:'past booking',
						path:'/app/hotel/bookings/past',
						active :route === past
					}
				]} />
				<Switch>
					<Route path = {upcoming} component = {UpcomingBookings} exact/>
					<Route path = {past} render = {(props) =><PastBookings startDate = {this.state.startDate} endDate = {this.state.endDate} {...props} />} />
				</Switch>
				
               
            </div>
        )
    }
}

export default Index;