import React from 'react'
import Moment from 'moment';
import { EmptyState } from '../../../../libs/EmptyState/index';
import emptyBooking from '.././k2.png';
import { NormalTable } from '../../../../Tables/NormalTable/NormalTable';
import { FlightItem } from '../../../Common/BookingItem/FlightItem'

class View extends React.Component {

	constructor(props) {
		super(props);
		this.presentor = props.presentor;
		this.presentor.setView(this);
		this.state = {
			pastBooking: [],
			emptyState: false
		}
	}


	componentDidMount() {
		let date = {
			startDate: Moment(this.props.startDate).valueOf(),
			endDate: Moment(this.props.endDate).valueOf()
		}
		this.presentor.init(date);
	}

	componentWillUnmount() {
		this.presentor.destroy()
	}


	componentWillReceiveProps(props) {
		if (this.props.startDate !== props.startDate || this.props.endDate !== props.endDate) {
			let { startDate, endDate } = props;
			let date = {
				startDate: Moment(startDate).valueOf(),
				endDate: Moment(endDate).valueOf()
			}
			this.presentor.init(date)
			return true
		} else {
			return false
		}
	}


	load = r => {
		if (r.tickets.length > 0) {


			this.setState({
				pastBooking: r.tickets,
				emptyState: false
			})
		} else if (r.tickets.length === 0) {
			this.setState({
				pastBooking: r.tickets,
				emptyState: true
			})
		}
	}




	Item = (item, index) => {

		return (
			<FlightItem data={item} key={index} />
		)
	}



	render() {
		if (this.state.emptyState) {
			return <EmptyState image={emptyBooking} title="No Past Booking are Found" desc="Please Book Some Ticket" />
		}


		return (
			<div>
				<NormalTable headerStyle={{
					border: 'none'
				}} headers={[
					{
						key: 'details',
						name: '',
						weight: ''
					},
					{
						key: 'name',
						name: '',
						weight: ''
					},
					{
						key: 'amount',
						name: '',
						weight: ''
					}
				]}
					data={this.state.pastBooking}
					pagination={true}
					mapItem={this.Item}
				/>
			</div>
		)
	}

}

export default View