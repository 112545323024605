import React from 'react';
import { TitleComponent } from '../../Common/TitleComponent';
import { TextEditor } from '../../../libs/TextEditor/TextEditor';
import './index.css';
import { NormalTable } from '../../../Tables/NormalTable/NormalTable';
import { showToast } from "../../../libs/Toast/ToastCallback";
import axios from '../../../../Model/Network/Config/Axios';
import { Modal } from '../../../libs/Modal/Modal';
import ImageZoom from 'react-medium-image-zoom'




class View extends React.Component {

    constructor(props) {
        super(props);
        this.presentor = props.presentor;
        this.presentor.setView(this);
    }

    state = {
        editorState: '',
        chats: [],
        all: {
            subject: null,
            description: null,
            ticketStatus: 'DONE'
        },
        upload: false,
        loading: false,
        stateLoading: false,
        attachment: []
    }

    getId = () => {
        return this.props.match.params.id;
    }

    componentDidMount() {
        //this.presentor.init();
    }

    componentWillUnmount() {
        this.presentor.destroy();
    }

    load = r => {
        this.setState({
            chats: r.conversations.reverse(),
            all: r
        })
    }


    _upload = () => {
        let file = this._file.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('file', file);

            this.setState({
                ...this.state,
                stateLoading: true
            })

            axios({
                method: 'post',
                url: 'https://file.eka.io/file',
                data: formData,
                headers: {
                    clientId: '422dc3e2-a960-4941-9a7a-af64732157b5',
                    clientSecret: "9cd4ce3e-6ea6-45d7-bf92-06c9c7a69873"
                }
            })
                .then(res => {

                    let attachment = this.state.attachment;
                    let attachments = {
                        type: 'IMAGE',
                        url: res.data.url
                    }
                    attachment.push(attachments);

                    this.setState({
                        upload: false,
                        stateLoading: false,
                        attachment
                    })

                })

        } else {
            showToast({
                type: 'error',
                message: 'Please select valid image'
            })
        }
    }


    mapItem = (item, index) => {
        return (
            <div key={index} style={{ paddingLeft: 0, paddingRight: 0 }} className="border-bottom fintrip-department-item table-item">
                <div className="linear-layout horizontal" style={{ flex: 1, cursor: 'pointer' }}>
                    <div className="inline inline-padding-right flex-1" style = {{display:'flex'}}>
                        <div>
                            {
                                item.conversationAttachments.length > 0 ?
                                    <ImageZoom
                                        image={{
                                            src:item.conversationAttachments[0].url,
                                            alt: 'Golden Gate Bridge',
                                            className: 'img',
                                            style: {width:50,height:50 }
                                        }}
                                        zoomImage={{
                                            src: 'bridge-big.jpg',
                                            alt: 'Golden Gate Bridge'
                                        }}
                                    />
                                    : null
                            }

                        </div>
                        <div style = {{marginLeft:8}}>
                            <p className="top">{item.text}</p>
                            <p className="bottom">
                                {}
                            </p>
                            <p className="bottom">
                                {item.createdAt}
                            </p>
                        </div>
                    </div>
                    <div className="right-actions">
                        <p style={{
                            height: 20,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: 4,
                            fontSize: 10,
                            fontWeight: 500,
                            background: '#7558F6',
                            color: '#fff',
                            padding: '5px'
                        }}>{item.isSupport ? "Support" : "ADMIN"}</p>
                    </div>
                </div>

            </div>
        )
    }

    onAddComment = () => {
        this.setState({
            noteText: '',
            editorState: '',
            attachments:[]
        })
        this.presentor.init();
    }





    _addComment = () => {
        let ticket_id = Number(this.getId());
        let text = this.state.noteText;
        if (!text) {
            showToast({
                type: 'error',
                message: 'Please enter valid content'
            })
            return;
        }

        let data = {
            text,
            ticket_id,
            attachments:this.state.attachment
        }

        this.presentor._addComment(data)

    }

    render() {
        return (
            <div className="side-container">
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <TitleComponent subtitle="All Details" title="Support" />
                    {
                        this.state.all.ticketStatus !== "DONE" ?
                            <div onClick={this.presentor.closeTicket} className="btn btn-portlet" style={{
                                width: 100,
                                padding: '13px 0',
                                height: 10,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 4,
                                background: '#7E57C2',
                                border: 'none'

                            }}>Close Ticket</div> : null
                    }
                </div>
                {
                    this.state.all.ticketStatus !== "DONE" ?
                        <div>
                            <div style={{ marginTop: 30 }} />
                            <TextEditor
                                editorState={this.state.editorState}
                                onChange={(editorState) => this.setState({
                                    ...this.state,
                                    editorState
                                })}
                                onChangeText={
                                    (text) => {
                                        this.setState({ noteText: text })
                                    }
                                }

                                placeholder='Start Typing...'
                                toolbar={
                                    {
                                        options: ['inline', 'blockType', 'fontSize', 'fontFamily'], // This is where you can specify what options you need in
                                        //the toolbar and appears in the same order as specified
                                        inline:
                                        {
                                            options: ['bold', 'italic', 'underline'] // this can be specified as well, toolbar wont have
                                            //strikethrough, 'monospace', 'superscript', 'subscript'
                                        },
                                        image:
                                        {
                                            alignmentEnabled: false,
                                            uploadCallback: this.UploadImageCallBack,
                                            alt: { present: true, mandatory: false },
                                            previewImage: true
                                        }
                                    }
                                } />
                            <div style={{
                                width: '100%',
                                display: 'flex',
                            }}>
                                <p onClick={
                                    () => this._addComment()
                                } style={{
                                    marginTop: 15,
                                    width: 120,
                                    height: 30,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: 4,
                                    fontSize: 11,
                                    fontWeight: 500
                                }} className="btn btn-portlet">Add Comment</p>

                                <p
                                    onClick={
                                        () => {
                                            this.setState({
                                                ...this.state,
                                                upload: true
                                            })
                                        }
                                    }
                                    style={{
                                        marginTop: 15,
                                        width: 130,
                                        height: 30,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: 4,
                                        fontSize: 11,
                                        fontWeight: 500,
                                        marginLeft: 10,
                                        paddingLeft: 2,
                                        paddingRight: 2
                                    }} className="btn btn-portlet">

                                    Add Attachment</p>
                            </div>
                        </div> : null}

                <div style={{ marginTop: 10 }}>
                    <p style={{ fontSize: 14, fontWeight: 600 }}>Subject:<span style={{ marginLeft: 10, fontWeight: 400, fontSize: 14 }}>{this.state.all.subject}</span></p>
                    <p style={{ fontSize: 14, fontWeight: 600 }}>Description:<span style={{ marginLeft: 10, fontWeight: 400, fontSize: 14 }}>{this.state.all.description}</span></p>
                </div>
                <div style={{
                    marginTop: 10
                }}>
                    <NormalTable
                        headerStyle={{
                            border: 'none'
                        }}
                        headers={[

                            {
                                key: 'title',
                                name: '',
                                weight: 1
                            },
                            {
                                key: 'action',
                                name: '',
                                width: 120
                            }
                        ]}
                        pagination={true}
                        data={this.state.chats}
                        mapItem={this.mapItem}
                    />
                </div>

                <Modal show={this.state.upload}
                    title="Upload File"
                    des="Please attach the file that you want to upload "
                    hideButton={false}
                    close={() => this.setState({
                        ...this.state,
                        upload: false
                    })}
                    stateLoading={this.state.stateLoading}
                    onSave={this._upload}
                    buttonName="Upload File"
                    style={{
                        save: {
                            marginTop: 10,
                            width: '100%',
                            height: 40,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: '#321CBF',
                            borderColor: '#321CBF',
                            ...this.state.stateLoading ?
                                {
                                    background: '#fff'
                                } : null
                        }
                    }}
                >
                    <div style={{
                        height: 45,
                        width: '100%',
                        border: '1px solid #dedede',
                        borderRadius: 4,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <div className="custom-file-button" style={{
                            width: '100%'
                        }}>
                            <input type="file" ref={ref => this._file = ref} />
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }


}

export default View;
