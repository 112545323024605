import axios from "../../../../../Model/Network/Config/Axios";


class Model {

    constructor() {
        this.Disposables = [];
    }

    destroy = () => {
        this.Disposables.forEach(item => {
            if (item.isCancellable())
                item.cancel()
        })
    }


    fetchSupportTicket = (name,cb) => {
        axios({
            method: 'post',
            url: 'http://142.93.211.53:7001/api/ticket/all',
            data: {
                ticket_status: 'DONE',
                creator_type: 'TDESK',
                creator_id: `${name}`
            },
            headers: {
                'Content-type': 'application/json',
                'secret': 'a55c1326986d4de5ab2b3eafccf339a1e127ad5ecc6d41878c1b18fa629ec2169ddc39882b4a440f81ca8d4f30a51b563dacd643025644929144fe463b425235'
            },
        })
            .then(res => cb(res.data))

    }


}


export default Model;