import React from 'react'
import Moment from "moment";

class BookingDetails extends React.Component {

	render() {
		let data = this.props.data;
		if(!data) {
			return(
				<div>
				
				</div>
			)
		}
		let tickets  = data.details ? data.details : {};


		let basic = tickets.segments ? tickets.segments : {};
		let destination = tickets.segments ? (  tickets.segments.length > 1  ? tickets.segments[tickets.segments.length-1].destination : tickets.segments[0].destination ) :null ;
		let origin = tickets.segments ? tickets.segments[0].origin  : null ;
		

		let departureTime = basic[0] ? Moment(Number(basic[0].departureTime)).format('ddd, DD MMM HH:mm a') : {};
		let arrivalTime = basic[0] ? Moment(Number(basic[0].arrivalTime)).format('ddd, DD MMM HH:mm a') : {}
		
		let originCity = origin ? origin.cityName : null
		let destinationCity = destination ? destination.cityName : null

		
     


		return(
			<div style = {{marginTop:30}}>
				<p style={{
					fontSize: 27,
					color: "#454545",
					fontWeight: 600,
				}}>
					{data.details.airline}
				</p>
				<p style={{
					fontSize: 15,
					color: "#898989"
				}}>
					{data.label}
				</p>

				<p style={{
					fontSize: 21,
					color: "#454545",
					fontWeight:700,
					marginTop:22
				}}>
					Booking Details
				</p>
				<p style={{
					marginBottom:12,
					fontSize:14,
					color:"#898989"
				}}>
					Details of selected Flight is shown below.
				</p>

				<div>
				<div style={{
					display: 'flex',
					alignItems: 'center',
					height: 35
				}}>
					<img src={require('./user.png')} alt="right" style={{
						width: 14,
						height: 14
					}}/>
					<p style={{
						paddingLeft: 15,
						paddingRight: 15,
						fontWeight: 600
					}}>
						{data ? data.counts : null} Person
					</p>
				</div>
				<div style={{
					display: 'flex',
					alignItems: 'center',
					height: 35
				}}>
					<img src={require('./right.png')} alt="right" style={{
						width: 14,
						transform: "rotate(180deg)",
						height: 14
					}}/>
					<p style={{
						width: 200,
						paddingLeft: 15,
						paddingRight: 15,
						fontWeight: 600
					}}>
						{
						originCity
						}
					</p>
					<p style={{
						width: 300,
						paddingLeft: 15,
						paddingRight: 15,
						color: "#898989",
						fontSize: 13
					}}>

						{departureTime}
					</p>
				</div>
				<div style={{
					display: 'flex',
					alignItems: 'center',
					height: 35
				}}>
					<img src={require('./right.png')} alt="right" style={{
						width: 14,
						height: 14
					}}/>
					<p style={{
						width: 200,
						paddingLeft: 15,
						paddingRight: 15,
						fontWeight: 600
					}}>
						{
							destinationCity
						}
					</p>
					<p style={{
						width: 300,
						paddingLeft: 15,
						paddingRight: 15,
						color: "#898989",
						fontSize: 13
					}}>
						{arrivalTime}
					</p>
				</div>
				<div style={{
					display: 'flex',
					alignItems: 'center',
					height: 35
				}}>
					<img src={require('./rupee.png')} alt="right" style={{
						width: 14,
						height: 14
					}}/>
					<p style={{
						paddingLeft: 15,
						paddingRight: 15,
						fontWeight: 600
					}}>
						INR {tickets ? tickets.publishedFare : null}
					</p>
				</div>
				</div>
			</div>
		)	
	}

}

export default BookingDetails