import React from 'react'
import { post } from "../../../../../Model/Network/Config/Axios";
import _ from 'lodash'
import Tick from './tick.png'
import ActiveTick from './tick (1).png'
import BusItemExtended from './BusItemExtended/BusItemExtended';
import './index.css';
import Moment from 'moment';

class HotelItem extends React.Component {

	constructor() {
		super();
		this.Disposables = [];

	}

	state = {
		result: undefined,
		image: undefined,
		points: undefined,

	}



	openItem = () => {
		let item = this.props.data;
		this.props.onOpen(item.tripId)
		let result = this.state.result
		let points = this.state.points
		this.setState({
			...this.state,
			image: item.image
		})
		if (!result) {
			this.Disposables.push(post(`/bus/layout`, {
				tripId: item.tripId,
				busType: item.busType,
				searchId: this.props.searchId
			}, (e, r) => {
				if (r) {
					this.setState({
						...this.state,
						result: r
					})
				}
			}))
		}
		if (!points) {
			this.Disposables.push(post(`/bus/points`, {
				tripId: item.tripId,
				busType: item.busType,
				searchId: this.props.searchId
			}, (e, r) => {
				if (r) {
					this.setState({
						...this.state,
						points: r
					})
				}
			}))
		}
	}

	componentWillUnmount() {
		this.Disposables.forEach(item => {
			if (item.isCancellable())
				item.cancel()
		})
	}

	render() {
		let item = this.props.data;
		let requestId = this.props.requestId;
		let arrivalTime = Moment(item.arrival).format();
		let departureTime = Moment(item.departure).format();
		let duration = Moment.duration(Moment(arrivalTime).diff(Moment(departureTime)));
		if (this.props.open) {
			if (this.props.open === item.tripId)
				return <BusItemExtended
					history={this.props.history}
					searchId={this.props.searchId}
					sessionId={this.props.sessionId} points={this.state.points}
					data={item} result={this.state.result} onOpen={this.props.onOpen} />
			else
				return null
		}
		let preferred = this.props.preferred === `${item.busType}|${item.tripId}`;
		return (
			<div style={{
				width: 'calc(33.33% - 27px)',
				background: preferred ? "#fafafa" : 'white',
				marginLeft: 20,
				border: preferred ? '3px solid #9398ec' : '1px solid #eeeeee',
				marginTop: 20,
				padding: 8,
				boxShadow: '.5px 1px .5px rgba(0,0,0,0.1)'
			}}>

				<div style={{
					position: 'relative',
					marginTop: 12
				}}>
					<div style={{ display: 'flex', }}>
						<div className="single-line" style={{
							fontSize: 17,
							height: 25,
							fontWeight: 600,
							display: 'flex',
							color: "#454545"
						}}>
							{item.companyName}
						</div>
						<div style={{ fontSize: 11, display: 'flex', alignItems: 'center', marginLeft: 'auto', color: '#999999', marginBottom: 5 }}>
							{duration._data.hours} hours {duration._data.minutes} minutes
						</div>
					</div>
					<div>
						<p className="twolines" style={{
							fontSize: 11,
							fontWeight: 400,
							color: "#898989",
						}}>
							{
								item.busLabel
							}
						</p>
						<div style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between'
						}}>
							<p style={{ fontSize: 12, fontWeight: 400, marginBottom: 5 }}>
								Date : {" "}
								<span style={{ color: '#898989' }}>{Moment(item.departure).format('DD-MM-YYYY')}</span>
							</p>
							<p style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>
								Timing: {" "}
								<span style={{ color: '#898989' }}>
									{Moment(item.departure).format('hh:mm A')}
									{"-"}
									{Moment(item.arrival).format('hh:mm A')}
								</span>
							</p>
							<p style={{
								fontSize: 12,
								fontWeight: 500,
								marginBottom: 5
							}}>
								Price : <span style={{ color: '#898989' }}>Rs {
									item.price
								} </span>
							</p>

						</div>
					</div>
					<div style={{
						display: 'flex',
						flexDirection: "row",
						marginTop: 18
					}}>
						<p onClick={this.openItem} style={{
							background: "#6376FA",
							padding: '10px 9px',
							fontSize: 13,
							height: 36,
							flex: 1,
							textAlign: 'center',
							color: 'white',
							cursor: 'pointer'
						}}>
							Review Bus
						</p>
						{
							requestId ?
								<div style={_.includes(this.props.selected, `${item.busType}|${item.tripId}`) ? {
									width: 36,
									height: 36,
									marginLeft: 6,
									border: "2px solid #7558F6",
									background: "#7558F6",
									cursor: 'pointer',
									padding: 9
								} : {
										width: 36,
										height: 36,
										marginLeft: 6,
										cursor: 'pointer',
										border: "2px solid #7558F6",
										padding: 9
									}} onClick={this.props.onSelect.bind(this, `${item.busType}|${item.tripId}`)}>
									<img alt="eka" src={_.includes(this.props.selected, `${item.busType}|${item.tripId}`) ? ActiveTick : Tick} style={{
										width: 14,
										height: 14
									}} />
								</div> :
								null
						}
					</div>
				</div>
			</div>
		)
	}

}

export default HotelItem