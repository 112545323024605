import React from 'react';
import BusLayout from "./BusLayout";
import { ChooseTraveller } from "../../../../Common/ChooseTraveller";
import { showToast } from "../../../../../libs/Toast/ToastCallback";
import Moment from 'moment'
import Checkbox from './check.png'
import _ from 'lodash';
import { post } from "../../../../../../Model/Network/Config/Axios";

class BusItemExtended extends React.Component {


	constructor() {
		super();
		this.Disposables = [];

	}

	state = {
		lower: true,
		seat: undefined,
		seatAmount: undefined,
		travellers: [],
		boarding: true,
		boardingPoint: undefined,
		droppingPoint: undefined,
		seats: [],
		seatOwners: [],
		loading: false
	}

	_bookBus = () => {
		let boardingPoint = this.state.boardingPoint;
		let droppingPoint = this.state.droppingPoint;
		if (!boardingPoint) {
			showToast({
				type: 'error',
				message: "Please select a valid boarding point"
			})
			return;
		}
		if (!droppingPoint) {
			showToast({
				type: 'error',
				message: "Please select a valid dropping point"
			})
			return;
		}
		let seats = this.state.seatOwners;
		if (seats.length === 0) {
			showToast({
				type: 'error',
				message: "Please select atleast one seat."
			})
			return;
		}
		let item = this.props.data;

		let data = {
			name: item.companyName,
			label: item.busLabel,
			busType: item.busType,
			tripId: item.tripId,
			departure: item.departure,
			arrival: item.arrival,
			boardingPoint,
			droppingPoint,
			seats,
			layout: this.props.result
		}
		this.setState({
			...this.state,
			loading: true
		})
		let sessionId = this.props.sessionId;
		this.Disposables.push(post(`/bus/${sessionId}/session.create`, {
			bus: data
		}, (e, r) => {
			if (r) {
				let url = `/app/bus/book?sessionId=${r.sessionId}&searchId=${this.props.searchId}`;
				this.props.history.push(
					url
				)
			} else {
				this.setState({
					...this.state,
					loading: false
				})
			}
		}))

	}

	componentWillUnmount() {
		this.Disposables.forEach(item => {
			if (item.isCancellable())
				item.cancel()
		})
	}

	_onSelectSeat = data => {
		let travellers = this.state.travellers;
		if (travellers.length > 5) {
			showToast({
				type: 'error',
				message: "Only 6 traveller can be booked at one time"
			})
			return;
		}
		if (_.includes(travellers, `${data.type}||${data.reference}`)) {
			this.setState({
				...this.state,
				seat: undefined
			})
			showToast({
				type: 'error',
				message: "Employee already present"
			})
			return;
		}
		travellers.push(`${data.type}||${data.reference}`);

		let seats = this.state.seats;
		let seatOwners = this.state.seatOwners;

		seats.push(this.state.seat)
		seatOwners.push({
			seatCode: this.state.seat,
			...data,
			amount: this.state.seatAmount
		})

		this.setState({
			...this.state,
			seats,
			seatOwners,
			seat: undefined,
			seatAmount: undefined,
			travellers
		})
	}

	_selectSeat = (seat) => {
		this.setState({
			...this.state,
			seat: seat.seatCode,
			seatAmount: seat.price
		})
	}

	_unSelectSeat = seat => {
		let travellers = this.state.travellers;
		let seatOwners = this.state.seatOwners;
		let mSeat = undefined;
		seatOwners = seatOwners.filter(value => {
			if (value.seatCode === seat.seatCode) {
				mSeat = value;
				return false;
			} else {
				return true;
			}
		});

		travellers = travellers.filter(item => item !== `${mSeat.type}||${mSeat.reference}`)
		let seats = this.state.seats;
		seats = seats.filter(value => value !== seat.seatCode);
		this.setState({
			...this.state,
			seats, seatOwners,
			travellers
		})
	}

	_onSelect = seat => {
		if (seat.lower) {
			let seats = this.state.seats["lower"];
			if (seats[seat.seatCode]) {
				delete seats[seat.seatCode];
			} else {
				seats[seat.seatCode] = seat;
			}
			this.setState({
				...this.state,
				seats: {
					...this.state.seats,
					lower: seats
				}
			})
		} else {
			let seats = this.state.seats["upper"];
			if (seats[seat.seatCode]) {
				delete seats[seat.seatCode];
			} else {
				seats[seat.seatCode] = seat;
			}
			this.setState({
				...this.state,
				seats: {
					...this.state.seats,
					upper: seats
				}
			})
		}
	}

	_points = () => {
		let boarding = this.state.boarding;
		if (!this.props.points) {
			return (
				<div style={{
					alignItems: 'center',
					justifyContent: 'center',
					padding: 20
				}}>
					<div className="loader" />
				</div>
			)
		}
		if (boarding)
			return (
				<div>
					{
						this.props.points.boarding.map(item => {
							return (
								<div key={item.code} onClick={() => {
									this.setState({
										...this.state,
										boardingPoint: item
									})
								}} style={{
									display: 'flex',
									alignItems: 'center',
									marginBottom: 12,
									cursor: 'pointer'
								}}>
									<div style={{
										marginRight: 15
									}}>
										{
											this.state.boardingPoint && this.state.boardingPoint.code === item.code ?
												<img alt="checkbox" src={Checkbox} style={{
													width: 16,
													height: 16
												}} /> : <div style={{
													width: 16,
													height: 16,
													borderRadius: 9,
													background: "#dedede"
												}} />
										}
									</div>
									<div>
										<p style={{
											fontSize: 19,
											fontWeight: 600,
											color: "#454545"
										}}>
											{
												item.name
											}
										</p>
										<p style={{
											fontSize: 13,
											fontWeight: 400,
											color: "#898989"
										}}>
											{
												Moment(item.time).format("ddd DD, MMM YYYY - HH:mm a")
											}
										</p>
									</div>
								</div>
							)
						})
					}
				</div>
			)
		else
			return (
				<div>
					{
						this.props.points.dropping.map(item => {
							return (
								<div key={item.code} onClick={() => {
									this.setState({
										...this.state,
										droppingPoint: item
									})
								}} style={{
									display: 'flex',
									alignItems: 'center',
									marginBottom: 12,
									cursor: 'pointer'
								}}>
									<div style={{
										marginRight: 15
									}}>
										{
											this.state.droppingPoint && this.state.droppingPoint.code === item.code ?
												<img alt="checkbox" src={Checkbox} style={{
													width: 16,
													height: 16
												}} /> : <div style={{
													width: 16,
													height: 16,
													borderRadius: 9,
													background: "#dedede"
												}} />
										}
									</div>
									<div>
										<p style={{
											fontSize: 19,
											fontWeight: 600,
											color: "#454545"
										}}>
											{
												item.name
											}
										</p>
										<p style={{
											fontSize: 13,
											fontWeight: 400,
											color: "#898989"
										}}>
											{
												Moment(item.time).format("ddd DD, MMM YYYY - HH:mm a")
											}
										</p>
									</div>
								</div>
							)
						})
					}
				</div>
			)
	}

	render() {
		let result = this.props.result;
		let item = this.props.data;
		if (!result) {
			return (
				<div style={{
					width: "100%"
				}}>
					<div style={{
						padding: "18px 18px 0",
						display: 'flex',
						marginBottom: -4,
						alignItems: 'center'
					}}>
						<p onClick={() => {
							this.props.onOpen(undefined)
						}} style={{
							fontSize: 19,
							cursor: 'pointer',
							fontWeight: 600,
							color: "#9379F9"
						}}>
							Show all buses
						</p>
					</div>
					<div style={{
						width: 'calc(100% - 40px)',
						background: 'white',
						marginLeft: 20,
						border: '1px solid #dedede',
						marginTop: 20,
						padding: 12
					}}>
						<div style={{
							alignItems: 'center',
							justifyContent: 'center',
							padding: 20
						}}>
							<div className="loader" />
						</div>
					</div>
				</div>
			)
		}
		return (
			<div style={{
				width: "100%"
			}}>
				<div style={{
					padding: "18px 18px 0",
					display: 'flex',
					marginBottom: -4,
					alignItems: 'center'
				}}>
					<p onClick={() => {
						this.props.onOpen(undefined)
					}} style={{
						fontSize: 19,
						cursor: 'pointer',
						fontWeight: 600,
						color: "#9379F9"
					}}>
						Show all buses
					</p>
				</div>
				<div style={{
					width: 'calc(100% - 40px)',
					background: 'white',
					marginLeft: 20,
					border: '1px solid #dedede',
					marginTop: 20,
					padding: 25
				}}>
					<div className="single-line" style={{
						fontSize: 29,
						fontWeight: 800,
						display: 'flex',
						color: "#454545"
					}}>
						{item.companyName}
					</div>
					<div className="single-line" style={{
						fontSize: 17,
						height: 25,
						fontWeight: 400,
						display: 'flex',
						color: "#898989"
					}}>
						{item.busLabel}
					</div>

					<p style={{
						marginTop: 25,
						fontWeight: 700,
						color: "#454545",
						fontSize: 21
					}}>
						Bus Layout
					</p>
					<p style={{
						fontWeight: 400,
						color: "#898989",
						fontSize: 13
					}}>
						Please select seats which you want to book.
					</p>
					{
						result.upper ?
							<div
								style={{ borderBottom: '2px solid rgb(241,241,241)', display: 'flex', marginTop: 12, marginBottom: 25 }}>
								<p onClick={() => {
									this.setState({
										...this.state,
										lower: true
									})
								}} style={{
									padding: '0px 0 12px',
									marginRight: 32,
									fontSize: 15,
									color: 'inherit',
									fontWeight: 600,
									textTransform: 'uppercase',
									cursor: 'pointer',
									position: 'relative',
									top: 3
								}} className={this.state.lower ? "active-line" : null}>
									Lower Deck
								</p>
								<p onClick={() => {
									this.setState({
										...this.state,
										lower: false
									})
								}} style={{
									padding: '0px 0 12px',
									cursor: 'pointer',
									marginRight: 32,
									fontWeight: 600,
									fontSize: 15,
									color: 'inherit',
									textTransform: 'uppercase',
									position: 'relative',
									top: 3
								}} className={!this.state.lower ? "active-line" : null}>
									Upper Deck
								</p>
							</div>
							: null
					}
					<div style={{
						height: 275,
						width: 600,
						marginTop: 18,
						display: 'flex',
						flexDirection: 'row',
						border: '1px solid #dedede',
						borderLeft: "4px solid rgb(99, 118, 250)"
					}}>
						<div style={{
							positon: 'relative'
						}}>
							<img src={require('./steering1.png')} alt="steering" style={{
								width: 30,
								height: 30,
								margin: "25px 12px 0",
								transform: 'rotate(-90deg)'
							}} />
						</div>
						<div style={{
							borderRight: "1px solid #dedede"
						}} />
						<div>
							<BusLayout selectSeat={this._selectSeat} unSelectSeat={this._unSelectSeat} lower={this.state.lower}
								selected={this.state.seats} onSelect={this._onSelect} layout={result} />
						</div>
					</div>
					<p style={{
						marginTop: 25,
						fontWeight: 700,
						color: "#454545",
						fontSize: 21
					}}>
						Boarding & Dropping Points
					</p>
					<p style={{
						fontWeight: 400,
						color: "#898989",
						fontSize: 13
					}}>
						Please select boarding and dropping points for your booking.
					</p>

					<div
						style={{ borderBottom: '2px solid rgb(241,241,241)', display: 'flex', marginTop: 12, marginBottom: 25 }}>
						<p onClick={() => {
							this.setState({
								...this.state,
								boarding: true
							})
						}} style={{
							padding: '0px 0 12px',
							marginRight: 32,
							fontSize: 15,
							color: 'inherit',
							fontWeight: 600,
							textTransform: 'uppercase',
							cursor: 'pointer',
							position: 'relative',
							top: 3
						}} className={this.state.boarding ? "active-line" : null}>
							Boarding Points
						</p>
						<p onClick={() => {
							this.setState({
								...this.state,
								boarding: false
							})
						}} style={{
							padding: '0px 0 12px',
							cursor: 'pointer',
							marginRight: 32,
							fontWeight: 600,
							fontSize: 15,
							color: 'inherit',
							textTransform: 'uppercase',
							position: 'relative',
							top: 3
						}} className={!this.state.boarding ? "active-line" : null}>
							Dropping Points
						</p>
					</div>
					{
						this._points()
					}
					{
						this.state.loading ?
							<p style={{
								marginTop: 50,
								marginBottom: 30,
								width: 350,
								height: 45,
								boxShadow: "1px 1px 1px rgba(1,1,1,.05)",
								background: "#1ab64f",
								padding: '10px 30px',
								color: "#fff",
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								fontSize: 15,
								cursor: 'pointer',
								textTransform: 'uppercase',
							}}>
								Wait....
							</p> :
							<p onClick={this._bookBus} style={{
								marginTop: 50,
								marginBottom: 30,
								width: 350,
								height: 45,
								boxShadow: "1px 1px 1px rgba(1,1,1,.05)",
								background: "#1ab64f",
								padding: '10px 30px',
								color: "#fff",
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								fontSize: 15,
								cursor: 'pointer',
								textTransform: 'uppercase',
							}}>
								Proceed To Book
							</p>
					}
				</div>
				<ChooseTraveller callback={this._onSelectSeat} close={() => {
					this.setState({
						...this.state,
						seat: undefined
					})
				}} shown={this.state.seat !== undefined} />
			</div>
		)
	}
}


BusItemExtended.propTypes = {

}

export default BusItemExtended