import React from 'react';
import {TitleComponent} from '../../Common/TitleComponent';
import {DatesRangeReact} from '../../../libs/DateRangereact';
import Moment from 'moment';
import Refund from './Refund';

class View extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate: new Moment().subtract('d',30),
            endDate:new Moment().add('d',30)
        }
    }

    

 

    render() {
        return(
            <div className = "side-container">
                 <div style = {{
                     display:'flex',
                     justifyContent:'space-between'
                 }}>
                      <TitleComponent subtitle = "Bus" title = "Refunds" />
                      <DatesRangeReact startDate = {this.state.startDate} endDate = {this.state.endDate}
                      handleDate = {({startDate,endDate}) => {
                          this.setState({
                              startDate,endDate
                          })
                      }}
                  />
                 </div>
                 <div style = {{marginTop:50}}>
                      <div
					style={{borderBottom: '2px solid rgb(241,241,241)', display: 'flex', marginTop:25, marginBottom: 10}}>
					<p  style={{
						padding: '0px 0 12px',
						marginRight: 32,
						fontSize: 15,
						color: 'inherit',
						fontWeight:600,
						textTransform: 'uppercase',
						cursor: 'pointer',
						position: 'relative',
						top: 3
					}} className={ "active-line" }>
						Refunds
					</p>
				</div>
                  <Refund startDate = {this.state.startDate} endDate = {this.state.endDate} />
                 </div>
            </div>
        )
    }
}


export default View;