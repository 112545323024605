import { get, post } from "../../../../../Model/Network/Config/Axios";


class Model {

    constructor() {
        this.Disposables = [];
    }

    destroy = () => {
        this.Disposables.forEach(item => {
            if (item.isCancellable())
                item.cancel()
        })
    }

    fetch = (cb) => {
        this.Disposables.push(get(`train/bookings.upcoming.imported`, cb));
    }

    fetchFare = (id, cb) => {
        this.Disposables.push(get(`bus/cancel/${id}`, cb));
    }

    cancelTicket = (id, cb) => {
        this.Disposables.push(post(`bus/cancel/${id}`, {}, cb));
    }

}

export default Model