import React from 'react';
import { TitleComponent } from '../../Common/TitleComponent';
import { PDFExport } from '@progress/kendo-react-pdf';
import Moment from 'moment';
import { Modal } from '../../../libs/Modal/Modal';
import { FloatInput } from '../../../libs/FloatLabelInput';
import { showToast } from '../../../libs/Toast/ToastCallback';


class View extends React.Component {

	constructor(props) {
		super(props);
		this.presentor = props.presentor;
		this.presentor.setView(this);
		this.state = {
			data: {},
			emailModal: false,
			issueModal: false

		}
	}

	id = () => {
		return this.props.match.params.id
	}

	componentDidMount() {
		this.presentor.init();
	}

	componentWillUnmount() {
		this.presentor.destroy()
	}

	load = r => {
		this.setState({
			data: r
		})
	}

	downloadPdf = () => {
		this.pdf.save()
	}

	mapItem = (item, index) => {
		return (
			<div key={index} style={{ display: 'flex', padding: '9px', borderBottom: '1px dotted #ccc' }}>
				<div style={{ flex: 1 }}>
					<div style={{
						fontSize: 18,
						fontWeight: 700,
						color: 'rgba(0,0,0,0.7)',
						letterSpacing: 0.3,
						paddingLeft: 5
					}}>{item ? item.name : null}</div>
				</div>
				<div style={{ flex: 1 }}>
					<div style={{
						fontSize: 18,
						fontWeight: 700,
						color: 'rgba(0,0,0,0.7)',
						letterSpacing: 0.3
					}}>{item ? item.mobile : null}</div>
				</div>
				<div style={{ flex: 1 }}>
					<div style={{
						fontSize: 18,
						fontWeight: 700,
						color: 'rgba(0,0,0,0.7)',
						letterSpacing: 0.3
					}}>{item ? item.email : null}</div>
				</div>
			</div>
		)
	}

	sendEmail = () => {
		let email = this._email.value();
		if (!email) {
			showToast({
				type: 'error',
				message: 'Please enter a valid email'
			})
			return
		}

		let data = {
			email
		}

		this.presentor.sendEmail(data);
	}

	onSendEmail = () => {
		this.setState({
			...this.state,
			emailModal: false
		})
		showToast({
			type: 'success',
			message: 'Ticket sent successfully to given email Address'
		})
	}

	_createIssue = () => {
		let title = `#${this.id()}`;
		let description = this._des.value();

		if (!description) {
			showToast({
				type: 'error',
				message: 'Please Enter Valid Description'
			})
		}

		let data = {
			title,
			description
		}
		this.presentor.createSupportTicket(data)

	}

	onCreateSupportTicket = () => {
		showToast({
			type: 'success',
			message: 'Your support ticket created successfully'
		})
		this._des.setValue("");
		this.setState({
			...this.state,
			issueModal: false,
		})
	}

	ViewDetails = () => {
		return (
			<div>
				<p style={{
					fontSize: 17,
					fontWeight: "bold",
					margin: 0,
					paddingTop: 20
				}}>Important Information</p>
				<p style={{
					margin: 0,
					marginTop: 8,
					color: '#777777',
					width: '100%',
					fontSize: 12
				}}
				>
					<strong>Checkin Information</strong> <br />
					It is mandatory for guests to present valid photo identification at the time of check-in.
			</p>
				<p style={{
					margin: 0,
					marginTop: 8,
					color: '#777777',
					width: '100%',
					fontSize: 12
				}}
				>
					<strong>Other Information</strong> <br />
					If your hotel includes a complimentary car transfer, you will need to call the hotel directly to let them know of your travel details.
			</p>
			</div>
		)
	}

	render() {
		let { data } = this.state;
		let guests = data.guests ? (data.guests.primary ? data.guests.primary : {}) : {};
		let allguest = data.guests ? (data.guests.all ? data.guests.all : []) : [];

		//let support = data.support ? data.support : {};
		let count = data.guests ? data.guests : {}
		let rooms = data.rooms ? data.rooms : {};
		let dates = data.stayDates ? data.stayDates : {};
		let inDate = dates.in ? Moment(dates.in, "DD-MM-YYYY").format('MMM DD') : null;
		let outDate = dates.out ? Moment(dates.out, "DD-MM-YYYY").format('MMM DD') : null;
		return (
			<div className="fintrip-container" style={{
				display: 'flex',
				flexDirection: 'row',
				flex: 1
			}}>
				<div className="side-container" style={{ borderTop: 'none' }}>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<div style={{
							width: 700,
							alignItems: 'center',
							display: 'flex',
							justifyContent: 'space-between'
						}}>
							<TitleComponent subtitle="Hotels" title="Booking Details" />
							<div style={{
								display: 'flex'
							}}>
								<div onClick={() => this.setState({
									...this.state,
									issueModal: true
								})} className="btn btn-portlet" style={{
									width: 120,
									height: 36,
									borderRadius: 18,
									display: 'flex',
									fontSize: 10,
									marginRight: 12,
									alignItems: 'center',
									justifyContent: 'center',
									background: '#f44336',
									borderColor: '#f44336',
                                    padding: '10px 5px',
								}}>
									Raise Issue
							</div>
								<div onClick={this.downloadPdf} className="btn btn-portlet" style={{
									width: 120,
									height: 36,
									borderRadius: 18,
									display: 'flex',
									fontSize: 10,
									marginRight: 12,
									alignItems: 'center',
									justifyContent: 'center',
                                    padding: '10px 5px',
								}}>
									Download Ticket
							</div>
								<div onClick={() => {
									this.setState({
										...this.state,
										emailModal: true
									})
								}} className="btn btn-portlet" style={{
									width: 120,
									height: 36,
									borderRadius: 18,
									display: 'flex',
									fontSize: 10,
									background: "#1ab64f",
									border: "1px solid #1ab64f",
									alignItems: 'center',
									justifyContent: 'center',
                                    padding: '10px 5px',
								}}>
									Email Ticket
							</div>
							</div>
						</div>
					</div>
					<PDFExport fileName={`${this.id()}`} ref={r => this.pdf = r}>
						<div style={{ border: '1px solid #dedede', width: 700, marginTop: 35, padding: 25 }}>
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<div>
									<div style={{ fontSize: 14, color: 'rgba(0,0,0,0.75)', marginBottom: 2 }}>Booking ID : <span style={{ fontWeight: 700 }}>{data.bookingId}</span></div>
								</div>
								<div style={{ fontSize: 14, color: 'rgba(0,0,0,0.75)' }}>Transaction ID: # <span style={{ fontWeight: 700 }}>{this.id()}</span></div>
							</div>
							<div style={{ border: '1px solid #dedede', marginLeft: -25, marginRight: -25, marginTop: 20, marginBottom: 20 }} />
							<div style={{ fontSize: 33, marginBottom: 20, fontWeight: 700 }}>{data.name}</div>
							<div style={{ display: 'flex' }}>
								<div>
									<img src={data.image} style={{ width: 100, height: 100, borderRadius: '50%' }} alt="Eka" />
								</div>
								<div style={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									marginLeft: 20,
									marginTop: -12
								}}>
									<div style={{ color: '#6a6c6c', fontSize: 23, fontWeight: 600, }}>{data.name}</div>
									<div style={{
										color: 'rgba(0,0,0,0.7)',
										fontSize: 12,
										marginTop: 7
									}}>{data.address ? data.address.address : null}</div>
									<div style={{
										color: 'rgba(0,0,0,0.7)',
										fontSize: 12,
										marginBottom: 25
									}}>{data.address ? data.address.cityName : null},{data.address ? data.address.countryName : null}</div>
								</div>


							</div>
							<div style={{ border: '1px solid rgba(0,0,0,0.1)', margin: '20px 0' }} />
							<div style={{ display: 'flex' }}>
								<div style={{ flex: 2, borderRight: '1px solid #ccc', display: 'flex' }}>
									<div>
										<div style={{
											fontSize: 20,
											fontWeight: 400,
											letterSpacing: 1
										}}>{dates.in ? inDate : null}</div>
										<div style={{ margin: '5px 0', fontSize: 12, color: 'rgba(0,0,0,0.4)' }}>CheckIn</div>
									</div>
									<div className="calendar" style={{ marginTop: 16, marginLeft: 30 }} />
									<div style={{ marginLeft: 30 }}>
										<div style={{
											fontSize: 20,
											fontWeight: 400,
											letterSpacing: 1
										}}>{dates.out ? outDate : null}</div>
										<div style={{ margin: '5px 0', fontSize: 12, color: 'rgba(0,0,0,0.4)' }}>Check out
									</div>
									</div>
								</div>
								<div style={{ flex: 1, borderRight: '1px solid #ccc', marginLeft: 30 }}>
									<div style={{
										fontSize: 20,
										fontWeight: 400,
										letterSpacing: 1
									}}>{count.total ? count.total : null} Guests
								</div>
								</div>
								<div style={{ flex: 2, marginLeft: 30 }}>
									<div style={{
										fontSize: 16,
										fontWeight: 400,
										letterSpacing: 1
									}}>{rooms.count ? rooms.count : null} X {rooms.name ? rooms.name : null}</div>
								</div>
							</div>
							<div style={{ display: 'flex', marginTop: 30 }}>
								<div style={{ flex: 1 }}>
									<div style={{
										fontSize: 12,
										marginBottom: 2,
										color: 'rgba(0,0,0,0.5)',
										letterSpacing: 0.4
									}}>Primary Guest
								</div>
									<div style={{
										fontSize: 18,
										fontWeight: 700,
										color: 'rgba(0,0,0,0.7)',
										letterSpacing: 0.3
									}}>{guests ? guests.name : null}</div>
								</div>
								<div style={{ flex: 1 }}>
									<div style={{
										fontSize: 12,
										marginBottom: 2,
										color: 'rgba(0,0,0,0.5)',
										letterSpacing: 0.4
									}}>Mobile Number
								</div>
									<div style={{
										fontSize: 18,
										fontWeight: 700,
										color: 'rgba(0,0,0,0.7)',
										letterSpacing: 0.3
									}}>{guests ? guests.mobile : null}</div>
								</div>
								<div style={{ flex: 1 }}>
									<div style={{
										fontSize: 12,
										marginBottom: 2,
										color: 'rgba(0,0,0,0.5)',
										letterSpacing: 0.4
									}}>Email ID
								</div>
									<div style={{
										fontSize: 18,
										fontWeight: 700,
										color: 'rgba(0,0,0,0.7)',
										letterSpacing: 0.3
									}}>{guests ? guests.email : null}</div>
								</div>
							</div>

							<div style={{ border: '1px solid #ccc', marginTop: 30 }}>
								<div style={{
									display: 'flex',
									borderBottom: '1px dotted #ccc',
									padding: '6px 9px',
									alignItems: 'center'
								}}>
									<div style={{ flex: 1 }}>
										<div style={{
											fontSize: 12,
											marginBottom: 2,
											color: 'rgba(0,0,0,0.5)',
											letterSpacing: 0.4,
											paddingLeft: 5
										}}>Guest
									</div>
									</div>
									<div style={{ flex: 1 }}>
										<div style={{
											fontSize: 12,
											marginBottom: 2,
											color: 'rgba(0,0,0,0.5)',
											letterSpacing: 0.4
										}}>Mobile Number
									</div>
									</div>
									<div style={{ flex: 1 }}>
										<div style={{
											fontSize: 12,
											marginBottom: 2,
											color: 'rgba(0,0,0,0.5)',
											letterSpacing: 0.4
										}}>Email ID
									</div>
									</div>
								</div>
								<div>
									{
										allguest.map(this.mapItem)
									}
								</div>
							</div>
							<div style={{
								background: '#f9fafc',
								border: '1px solid rgba(0,0,0,0.2)',
								marginTop: 25,
								padding: '16px 20px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center'
							}}>
								<div style={{ flex: 2 }}>
									<div><strong>Total Amount</strong></div>
									<div style={{ fontSize: 12, marginTop: 5 }}>(Inclusive all the taxes)</div>
								</div>
								<div><strong>INR {data ? data.bookingAmount : null}</strong>
								</div>
							</div>
							<div style={{ fontSize: 12, color: '#6a6c6c', padding: '33px 0 20px 0' }}>
								If you have any issues related to your booking, Please call us
							at <span style={{ fontWeight: 700 }}>+91-8448239151/+91-9354356088</span> . You can also raise a ticket
							on <span style={{ fontWeight: 700 }}>hello@antrepriz.com</span>
							</div>
						</div>
					</PDFExport>
					<Modal show={this.state.emailModal}
						title="Ticket"
						des="Enter the mail ID to which the e-ticket needs to be sent to:"
						onSave={this.sendEmail}
						close={() => {
							this.setState({
								...this.state,
								emailModal: false
							})
						}}
						buttonName="Send Ticket"
						style={{
							save: {
								width: '100%',
								textAlign: 'center'
							}
						}}
					>
						<FloatInput title="Email Address" handleRef={ref => this._email = ref} />
					</Modal>
					<Modal show={this.state.issueModal}
						title="Raise Issue"
						des="You can Raise Ticket against this Ticket"
						onSave={this._createIssue}
						close={() => {
							this.setState({
								...this.state,
								issueModal: false
							})
						}}
						buttonName="Create Issue"
						style={{
							save: {
								width: '100%',
								textAlign: 'center',
								padding: '8px 10px'
							}
						}}
					>   <p style={{
						color: '#454545',
						fontSize: 13,
						fontWeight: 500,
					}}>Ticket Id</p>
						<p style={{
							fontSize: 15,
							marginTop: 5,
							marginBottom: 15

						}}>Support ticket against Transaction id <span style={{
							fontWeight: 600,
						}}>#{`${this.id()}`}</span></p>
						<FloatInput type="text" title="Description" handleRef={ref => this._des = ref} />
					</Modal>

				</div>
				<div className="right-side-bar" style={{ overflowY: 'scroll', width: 250 }} >
					{this.ViewDetails()}
				</div>
			</div>
		)
	}
}


export default View