import React from 'react'
import Props from 'prop-types'
import Validator from 'validator'

class FloatInput extends React.Component {

    state = {
        label: '',
        className: ""
    }


    onBlur = () => {
        if (this.props.onBlur)
            this.props.onBlur()
        let className = "";
        let required = this.props.required;
        let value = this.mRef.value;
        switch (this.props.type) {
            case "email":
                if (!value) {
                    if (required)
                        className = "invalid";
                    break
                }
                if (!Validator.isEmail(value)) {
                    className = "invalid";
                    break
                } else {
                    className = "valid";
                    break
                }
            case "password": {
                if (value && value.length > 4) {
                    className = "valid";
                } else {
                    className = "invalid";
                }
                break
            }
            case "text":
            default:
                if (required) {
                    if (value)
                        if(this.props.customValidator){
                            if( this.props.customValidator(value))
                                className = "valid";
                            else
                                className = "invalid"
                        }else{
                            className = "valid";
                        }
                    else
                        className = "invalid";
                }else{
                    if(value){
                        className = "valid";
                    }
                }
                break
        }
        this.setState({
            ...this.state,
            className
        })
    }

    updator=val=>{
        this.mRef.value = val;
		this.setState({
			...this.state,
			className:""
		})
    }


    render() {
        return (
            <div className="full-width">
				<label className="floating-top-label" style={this.props.labelStyle}>
					{
						this.props.title
					}:
				</label>
				<div style={this.props.style} className={`floating-label ${this.state.className}`}>
					<input value={this.props.value} disabled={this.props.disabled} {...this.props.inputProps}  style={this.props.inputStyle} onBlur={() => {
						this.onBlur()
					}} ref={ref => {
						this.mRef = ref;
						if (this.props.handleRef) {
							this.props.handleRef({
								ref,
								setValue:this.updator,
								float:true,
								value:()=>ref.value
							})
						}
					}} autoComplete='off' type={this.props.type} placeholder={this.props.title} onChange={e => {
						this.onBlur()
						if (this.props.onChange) {
							this.props.onChange(e)
						}
					}}/>
				</div>
            </div>
        )
    }
}

FloatInput.propTypes = {
    title: Props.string.isRequired,
    type: Props.string.isRequired,
    handleRef:Props.func.isRequired,
    customValidator:Props.func,
    inputProps:Props.object,
    disabled: Props.bool,
    value: Props.any
}

FloatInput.defaultProps = {
    required: true,
	inputProps:{

	}
}

export {
    FloatInput
}
