import React from 'react';
import { NormalTable } from '../../../../Tables/NormalTable/NormalTable';
import Moment from 'moment';
import { BusRefund } from '../../../Common/RefundItems/BusRefund';


class View extends React.Component {

    constructor(props) {
        super(props);
        this.presentor = props.presentor;
        this.presentor.setView(this);
        this.state = {
            refunds: [],

        }
    }

    componentDidMount() {
        let { startDate, endDate } = this.props;

        let date = {
            startDate: Moment(startDate).valueOf(),
            endDate: Moment(endDate).valueOf()
        }

        this.presentor.init(date);
    }

    componentWillReceiveProps(props) {
        if (this.props.startDate !== props.startDate || this.props.endDate !== props.endDate) {
            let { startDate, endDate } = props;
            let date = {
                startDate: Moment(startDate).valueOf(),
                endDate: Moment(endDate).valueOf()
            }
            this.presentor.init(date)
            return true
        } else {
            return false
        }
    }


    componentWillUnmount() {
        this.presentor.destroy();
    }



    load = r => {
        this.setState({
            refunds: r.tickets
        })
    }



    mapItem = (item, index) => {
        return (
            <BusRefund item={item} key={index} />
        )
    }

    render() {
        return (
            <div style={{ position: 'relative' }}>
                <div>
                    <NormalTable headerStyle={{
                        border: 'none'
                    }} headers={[
                        {
                            key: 'title',
                            name: '',
                            weight: 1
                        },
                        {
                            key: 'status',
                            name: '',
                            weight: 1
                        },
                        {
                            key: 'time',
                            name: '',
                            weight: 1
                        },

                    ]}
                        data={this.state.refunds} pagination={true} mapItem={this.mapItem}
                    />
                </div>
            </div>
        )
    }
}


export default View;