class Presentor {

	constructor(Model) {
		this.Model = Model;
	}

	setView(View) {
		this.View = View;
	}

	destroy = () => {
		this.Model.destroy()
	}


	search = (token, sessionId) => {
		this.Model.search(token, sessionId, (e, r) => {
			if (r) {
				this.View.load(r)
			}
		})
	}

	getRequest = (id) => {
		this.Model.getRequest(id, (e, r) => {
			r.requestId = id;
			if (this.View._loader) {
				this.View._loader(r)
			}
			this.View._setHighlighted(r.selected)
		})
	}

	postSelection = (id, selection) => {
		this.Model.postSelection(id, selection);
	}



}


export default Presentor