import React from 'react'
import Search from "stringquery";
import PollingHotels from "./PollingHotels";
import SearchArea from './SearchArea'

class View extends React.Component {

	constructor(props) {
		super(props);
		this.presentor = props.presentor;
		this.presentor.setView(this)
		const params = Search(this.props.location.search);
		if (params.requestId) {
			this.state = {
				result: undefined,
				requestId: params.requestId,
				selected: "",
				selectedHotelId:undefined,
				filter: {
					hotelCode: '',

					distance: {
						value: 10,
						name: undefined,
						lat: undefined,
						lng: undefined
					},
					price: {
						min: 0,
						max: 0,
						maxValue: 2000
					},
					amenities: [
						{ id: 1, text: 'Ac', value: "ac", isCheck: false },
						{ id: 2, text: 'Parking', value: "parking", isCheck: false },
						{ id: 3, text: 'Tv', value: "tv", isCheck: false },
						{ id: 4, text: 'Wifi', value: "wifi", isCheck: false },
						{ id: 5, text: 'Swimming Pool', value: "pool", isCheck: false },
					],
					Rating: [
						{ id: 0, item: "1 star", value: '1', isActive: false },
						{ id: 1, item: "2 star", value: '2', isActive: false },
						{ id: 2, item: "3 star", value: '3', isActive: false },
						{ id: 3, item: "4 star", value: '4', isActive: false },
						{ id: 4, item: "5 star", value: '5', isActive: false }
					],
					type: [
						{ id: 0, item: 'Oyo', value: 'OYO', isActive: false },
						{ id: 1, item: 'Yatra', value: 'YATRA', isActive: false },
						{ id: 2, item: 'ClearTrip', value: 'CLEARTRIP', isActive: false },
						{ id: 3, item: 'YATRA and ClearTrip', value: 'CLEARTRIP&YATRA', isActive: false },
						{ id: 4, item: 'Ease my Trip', value: 'EASEMYTRIP', isActive: false },
						{ id: 5, item: 'Grn connect', value: 'GRNCONNECT', isActive: false },


					]
				}
			}
		} else {
			this.state = {
				result: undefined,
				requestId: undefined,
				selected: "",
				filter: {
					hotelCode: '',

					distance: {
						value: 10,
						name: undefined,
						lat: undefined,
						lng: undefined
					},
					price: {
						min: 0,
						max: 0,
						maxValue: 2000,
					},
					amenities: [
						{ id: 1, text: 'Ac', value: "ac", isCheck: false },
						{ id: 2, text: 'Parking', value: "parking", isCheck: false },
						{ id: 3, text: 'Tv', value: "tv", isCheck: false },
						{ id: 4, text: 'Wifi', value: "wifi", isCheck: false },
						{ id: 5, text: 'Swimming Pool', value: "pool", isCheck: false },
					],
					Rating: [
						{ id: 0, item: "1 star", value: '1', isActive: false },
						{ id: 1, item: "2 star", value: '2', isActive: false },
						{ id: 2, item: "3 star", value: '3', isActive: false },
						{ id: 3, item: "4 star", value: '4', isActive: false },
						{ id: 4, item: "5 star", value: '5', isActive: false }
					],
					type: [
						{ id: 0, item: 'Oyo', value: 'OYO', isActive: false },
						{ id: 1, item: 'Yatra', value: 'YATRA', isActive: false },
						{ id: 2, item: 'ClearTrip', value: 'CLEARTRIP', isActive: false },
						{ id: 3, item: 'YATRA and ClearTrip', value: 'CLEARTRIP&YATRA', isActive: false },
						{ id: 4, item: 'Ease my Trip', value: 'EASEMYTRIP', isActive: false },
						{ id: 5, item: 'Grn connect', value: 'GRNCONNECT', isActive: false },



					]
				}
			}
		}

	}

	_loader = (res)=>{
		this.setState({
			...this.state,
			selectedHotelId:res.hotelId
		})
	}

	load = result => {
		if (result.polling) {
			setTimeout(() => {
				this.presentor.search(this.props.match.params.pollingId)
			}, 1000)
			this.setState({
				...this.state,
				result
			})
		} else {
			let maxValue = 0;
			result.items.forEach(item => {
				if (item.pricePerNight > maxValue)
					maxValue = item.pricePerNight;
			})
			this.setState({
				...this.state,
				result,
				filter: {
					...this.state.filter,
					price: {
						min: 0,
						max: maxValue,
						maxValue
					}
				}
			})
		}
	}


	_setHighlighted = (id) => {
		this.setState({
			...this.state,
			selected: id
		})
	}

	componentDidMount() {
		const params = Search(this.props.location.search);
		if (params.requestId) {
			this.presentor.getRequest(params.requestId)
		}
		this.presentor.search(this.props.match.params.pollingId)
	}

	componentWillUnmount() {
		this.presentor.destroy();
	}


	filter = data => {
		this.setState({
			...this.state,
			filter: {
				...this.state.filter,
				...data
			}
		})
	}




	render() {
		const params = Search(this.props.location.search);
		return (
			<div className="fintrip-container" style={{
				display: 'flex',
				flexDirection: 'row',
				flex: 1
			}}>
				<PollingHotels result={this.state.result} onPlaceChanged={data => {
					let newState = {
						...this.state,
						filter: {
							...this.state.filter,
							distance: {
								value: this.state.filter.distance.value,
								...data
							}
						}
					}
					this.setState(newState)
				}}
					onHotelSelect={hotelCode => {
						let newState = {
							...this.state,
							filter: {
								...this.state.filter,
								hotelCode
							}
						}
						this.setState(newState)
					}}
					onHotelClear={
						() => {
							let newState = {
								...this.state,
								filter: {
									...this.state.filter,
									hotelCode: ''
								}
							}
							this.setState(newState)
						}
					}
					onPlaceClear={() => {
						let newState = {
							...this.state,
							filter: {
								...this.state.filter,
								distance: {
									...this.state.filter.distance,
									name: undefined,
									lat: undefined,
									lng: undefined
								}
							}
						}
						this.setState(newState)
					}}
					filter={this.state.filter} changeFilter={this.filter} />
				<SearchArea 
					filter={this.state.filter}
					sessionId={params.sessionId}
					postSelection={this.presentor.postSelection}
					requestId={this.state.requestId}
					selected={this.state.selected}
					history={this.props.history}
					result={this.state.result}
					selectedHotelId={this.state.selectedHotelId}
					 />
			</div>
		)
	}

}

export default View