import { get, post, Delete } from "../../../../Model/Network/Config/Axios";

class Model {

	constructor() {
		this.Disposables = [];
	}

	destroy = () => {
		this.Disposables.forEach(item => {
			if (item.isCancellable())
				item.cancel()
		})
	}


	fetch = cb => {
		this.Disposables.push(get(`/guests`, cb))
	}

	post = (data, cb) => {
		this.Disposables.push(post('/guests', data, cb))
	} 

	delete = (id,cb) => {
		this.Disposables.push(Delete(`/guests/deleteGuest/${id}`,{},cb));
	}

}

export default Model