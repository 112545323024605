import React from 'react'
import Up from './up.png'
import Down from './down.png'

class SortArea extends React.Component {

	render() {
		let sort = this.props.sort;
		return (
			<div style={{
				background:"#fff",
				padding:12,
				height:60,
				border:'1px solid #dedede',
				marginLeft:20,
				marginRight:20,
				marginTop:20
			}}>
				<div style = {{display:'flex'}}>
					<div style={{width:120}}>
						<p style={{
							padding:8,
							fontWeight:600,
							color:"#676767",
							fontSize:15
						}}>
							Sort By:
						</p>
					</div>

					<div style ={{flex:2.5,display:'flex',alignSelf:'stretch'}}>
						<div onClick={this.props.onSort.bind(this,"departure")} style={{
							flex :1,
							display:'flex',
							alignItems:'center',
							justifyContent:'center',
							alignSelf:'stretch',
							cursor:'pointer'
						}}>
							<p style={{
								padding:8,
								fontWeight:600,
								color:sort.type==="departure"?"#232323":"#bcbcbc",
								fontSize:16
							}}>
								Departure
							</p>
							{
								sort.type==="departure"?<img style={{
									width:14,height:14,marginLeft:-4,marginTop:2,
								}} alt = "" src={sort.up?Up:Down}/>:null
							}
						</div>
						<div onClick={this.props.onSort.bind(this,"duration")} style={{
							flex :1.5,
							display:'flex',
							alignItems:'center',
							justifyContent:'center',
							alignSelf:'stretch',
							cursor:'pointer'
						}}>
							<p style={{
								padding:8,
								fontWeight:600,
								color:sort.type==="duration"?"#232323":"#bcbcbc",
								fontSize:16
							}}>
								Duration
							</p>
							{
								sort.type==="duration"?<img style={{
									width:14,height:14,marginLeft:-4,marginTop:2,
								}} alt = "" src={sort.up?Up:Down}/>:null
							}
						</div>
						<div onClick={this.props.onSort.bind(this,"arrival")} style={{
							flex :1,
							display:'flex',
							alignItems:'center',
							justifyContent:'center',
							alignSelf:'stretch',
							cursor:'pointer'
						}}>
							<p style={{
								padding:8,
								fontWeight:400,
								color:sort.type==="arrival"?"#111111":"#bcbcbc",
								fontSize:16
							}}>
								Arrival
							</p>
							{
								sort.type==="arrival"?<img style={{
									width:14,height:14,marginLeft:-4,marginTop:2,
								}} alt = "" src={sort.up?Up:Down}/>:null
							}
						</div>
					</div>
					<div onClick={this.props.onSort.bind(this,"price")} style={{
						flex:1,display:'flex',
						alignItems:'center',
						marginRight:12,
						justifyContent:'flex-end',
						alignSelf:'stretch',
						cursor:'pointer'
					}}>
						<p style={{
							padding:8,
							fontWeight:600,
							color:sort.type==="price"?"#111111":"#bcbcbc",
							fontSize:16
						}}>
							Price
						</p>
						{
							sort.type==="price"?<img style={{
							 width:14,height:14,marginLeft:-4,marginTop:2,
							}} alt = "" src={sort.up?Up:Down}/>:null
						}
					</div>
				</div>
			</div>
		)
	}

}

export default SortArea