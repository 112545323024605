import { useEffect, useMemo } from 'react';
import ReactDom from 'react-dom';

const modal = document.getElementById('modal-root');

const Portal = (props) => {
	const el = useMemo(() => document.createElement('div'), []);
	useEffect(() => {
		modal.appendChild(el);
		return () => {
			modal.removeChild(el);
		}
	}, [el])

	return ReactDom.createPortal(props.children, el);
}

export {
	Portal
}

export default Portal
