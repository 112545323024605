import React from 'react'
import { TitleComponent } from "../../Common/TitleComponent";
import Bookings from './Bookings'

class View extends React.Component {

	state = {
		name: "",
		email: "",
		mobile: "",
	}

	constructor(props) {
		super(props)
		this.presentor = props.presentor;
		this.presentor.setView(this)
	}

	componentDidMount() {
		this.presentor.init()
	}

	componentWillUnmount() {
		this.presentor.destroy();
	}

	load = data => {
		this.setState({
			...data
		})
	}

	getCode = () => {
		return this.props.match.params.id
	}

	render() {
		return (
			<div className="side-container" style={{ borderTop: 'none' }}>
				<TitleComponent title={this.state.name} subtitle="Guest" />
				<Bookings history={this.props.history} code={this.getCode()} />
			</div>
		)
	}

}

export default View