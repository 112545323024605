
class Presentor {

    constructor(Model) {
        this.Model = Model;
    }

    setView(View) {
        this.View = View;
    }

    destroy = () => {
        this.Model.destroy()
    }


    init = (date) => {
        this.Model.fetch(date, (e, r) => {
            if (r) {
                this.View.load(r);
            }
        })
    }


}

export default Presentor;