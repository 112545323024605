import {post} from "../../Model/Network/Config/Axios";

class Model {

	changePassword = (data,cb) => {
		post(`/auth/password`,data,cb)
	}

}

export default Model;