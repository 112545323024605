import React from 'react';
import GoogleMapReact from 'google-map-react';
import Style from './Style';

const  MapsKey = "AIzaSyBxR9CdStF-Ql5DJ2Urwm1YWF7Gc0XLMeY";

const Marker = () => <div style={{
	background:"#9379F9",
	height:24,
	width:24,
	borderRadius:12,
	display:'flex',
	alignItems:'center',
	justifyContent:'center'
}}>
	<div style={{
		width:8,
		height:8,
		background:'#ffffff',
		borderRadius:4
	}}/>
</div>;

class Map extends React.Component {



	createMapOptions = (maps) => {
		return {
			panControl: false,
			mapTypeControl: false,
			scrollwheel: false,
			styles: Style,
			draggable: false,
			zoomControl: false,
			disableDoubleClickZoom: true,
			fullscreenControl: false,

		}
	}

	render() {
		return (
			<div id="map_container_2" style={{height:300}}>
				<GoogleMapReact bootstrapURLKeys={{
					key:MapsKey,
					language: 'en'
				}} options={this.createMapOptions}
								center={this.props.coords}
								defaultZoom={14}
				>
					<Marker  lat={this.props.coords.lat}
							 lng={this.props.coords.lng}/>
				</GoogleMapReact>
			</div>
		)
	}

}


export {
	Map
}