import React from 'react';
import { PDFExport } from '@progress/kendo-react-pdf';
import { TitleComponent } from '../../Common/TitleComponent';
import Moment from 'moment';
import { Modal } from '../../../libs/Modal/Modal';
import { FloatInput } from '../../../libs/FloatLabelInput';
import { showToast } from '../../../libs/Toast/ToastCallback';
import { post } from '../../../../Model/Network/Config/Axios';


class View extends React.Component {

    constructor(props) {
        super(props);
        this.presentor = props.presentor;
        this.presentor.setView(this);
        this.state = {
            tickets: [],
            emailModal: false,
            issueModal: false,
            status: null,
            cancel:false,
            loading:false
        }
    }

    getId = () => {
        if (this.props && this.props.match.params.id) {
            return this.props.match.params.id;
        }
    }

    componentDidMount() {
        this.presentor.init();
    }

    componentWillUnmount() {
        this.presentor.destroy()
    }

    load = r => {
        this.setState({
            ...r,
            tickets: r.ticket
        })
    }

    mapItem = (item, index) => {
        return (
            <div key={index} style={{ display: 'flex', padding: '9px', borderBottom: '1px dotted #ccc' }}>
                <div style={{ flex: 1 }}>
                    <div style={{
                        fontSize: 18,
                        fontWeight: 700,
                        color: 'rgba(0,0,0,0.7)',
                        letterSpacing: 0.3,
                        paddingLeft: 5
                    }}>{item ? item.name : null}</div>
                </div>
                <div style={{ flex: 1 }}>
                    <div style={{
                        fontSize: 16,
                        fontWeight: 700,
                        color: 'rgba(0,0,0,0.7)',
                        letterSpacing: 0.3
                    }}>{item ? item.mobile : null}</div>
                </div>
                <div style={{ flex: 1 }}>
                    <div style={{
                        fontSize: 16,
                        fontWeight: 700,
                        color: 'rgba(0,0,0,0.7)',
                        letterSpacing: 0.3
                    }}>{item ? item.email : null}</div>
                </div>
            </div>
        )
    }

    downloadPdf = () => {
        this.pdf.save();
    }


    sendEmail = () => {
        let email = this._email.value();
        if (!email) {
            showToast({
                type: 'error',
                message: 'Please enter a valid email'
            })
            return
        }

        let data = {
            email
        }

        this.presentor.sendEmail(data);
    }

    onSendEmail = () => {
        this.setState({
            ...this.state,
            emailModal: false
        })
        showToast({
            type: 'success',
            message: 'Ticket sent successfully to given email Address'
        })
    }

    _createIssue = () => {
        let title = `#${this.getId()}`;
        let description = this._des.value();

        if (!description) {
            showToast({
                type: 'error',
                message: 'Please Enter Valid Description'
            })
            return
        }

        let data = {
            title,
            description
        }
        this.presentor.createSupportTicket(data)

    }

    onCreateSupportTicket = () => {
        this._des.setValue("");
        showToast({
            type: 'success',
            message: 'Your support ticket created successfully'
        })
        this.setState({
            ...this.state,
            issueModal: false,
        })
    }

    ViewDetails = () => {
        return (
            <div>
                <p
                    style={{
                        fontSize: 17,
                        fontWeight: 'bold',
                        margin: 0,
                        paddingTop: 20
                    }}

                >Important Information</p>
                <p
                    style={{
                        margin: 0,
                        marginTop: 8,
                        color: '#777777',
                        width: '100%',
                        fontSize: 12
                    }}
                >
                    <strong>Checkin Information</strong><br />
                    We recommend you check-in AT LEAST 2 Hours prior to departure for domestic sectors   and ATLEAST 3 Hours prior to departure for international sectors.
            </p>
                <p
                    style={{
                        margin: 0,
                        marginTop: 8,
                        color: '#777777',
                        width: '100%',
                        fontSize: 12
                    }}
                >
                    <strong>Passports and ID</strong><br />
                    For domestic travel, Please carry a valid Photo identity proof like driving license, voter id,   passport etc issued by Government of India. For all International travel Passport is mandatory.For infant   passengers, it is mandatory to carry the Date of Birth certificate.
            </p>
                <p
                    style={{
                        margin: 0,
                        marginTop: 8,
                        color: '#777777',
                        width: '100%',
                        fontSize: 12
                    }}
                >
                    <strong>Baggage Information</strong><br />
                    Hand Baggage(Also applicable for cabin baggage) of maximum 7 kg (which would include Laptop and duty free shopping bags) having maximum overall dimensions of: 115 cms (L+W+H) on Boeing flights and 108 cms (L+W+H) on Bombardier flights, is allowed to be carried per passenger, free of cost. Due to airport security regulations, no cabin baggage is allowed on flights originating from Jammu or Srinagar airports.
            </p>
                <p
                    style={{
                        margin: 0,
                        marginTop: 8,
                        color: '#777777',
                        width: '100%',
                        fontSize: 12
                    }}
                >
                    <strong>Cancellation</strong><br />
                    In the event of any cancellation/rescheduling, the respective airline charges will be applicable which may vary by the airline. For all cancellations done online, Eka will levy a charge of Rs 1000 per passenger per segment and Rs.2500 per passenger per segment for cancellations done through our call center.
            </p>
                <p
                    style={{
                        margin: 0,
                        marginTop: 8,
                        color: '#777777',
                        width: '100%',
                        fontSize: 12
                    }}

                >
                    <strong>PNR Information</strong><br />
                    All times indicated are in 24 hours format and we recommend that you check-in 2 hours prior to departure.
            </p>
                <p
                    style={{
                        margin: 0,
                        marginTop: 8,
                        color: '#777777',
                        width: '100%',
                        fontSize: 12
                    }}
                >
                    <strong>No Show Policy</strong><br />
                    Any change to a confirmed ticket - including cancellation or rescheduling- must be done at least 6 hours prior to flight departure. In case a passenger becomes a no show only the applicable taxes will be refunded to the passenger as per the airline policy.
            </p>
            </div>
        )
    }


    render() {
        let tickets = this.state.tickets
        let destination = tickets.segments ? (tickets.segments.length > 1 ? tickets.segments[tickets.segments.length - 1].destination : tickets.segments[0].destination) : null;
        let origin = tickets.segments ? tickets.segments[0].origin : null;


        let AirLineName = tickets.segments ? tickets.segments[0].airline ? tickets.segments[0].airline.name : null : null;
        let originAirport = origin ? origin.name : null;
        let originCode = origin ? origin.code : null;
        let originTime = tickets.segments ? Moment(Number(tickets.segments[0].departureTime)).format('hh:mm a') : null
        // let originTerminal = origin ? origin.Airport.Terminal : null;
        let destinationAirport = destination ? destination.name : null;
        let destinationCode = destination ? destination.code : null;
        let destinationTime = tickets.segments ? Moment(Number(tickets.segments[0].arrivalTime)).format('hh:mm a') : null;
        // let destinationTerminal = destination ? destination.Airport.Terminal : null;
        let date = tickets.date ? tickets.date : null;

        let passenger = tickets.travellers ? tickets.travellers : [];


        return (
            <div className="fintrip-container" style={{
                display: 'flex',
                flexDirection: 'row',
                flex: 1
            }}>
                <div className="side-container" style={{ borderTop: 'none' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{
                            width: 700,
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <TitleComponent subtitle="Flight" title="Booking Details" />
                            <div style={{
                                display: 'flex'
                            }}>


                                <div onClick={() => this.setState({
                                    ...this.state,
                                    issueModal: true
                                })} className="btn btn-portlet" style={{
                                    width: 120,
                                    height: 36,
                                    borderRadius: 18,
                                    display: 'flex',
                                    fontSize: 10,
                                    marginRight: 12,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    background: '#f44336',
                                    borderColor: '#f44336',
                                    padding: '10px 5px',
                                }}>
                                    Raise Issue
                            </div>



                                <div onClick={this.downloadPdf} className="btn btn-portlet" style={{
                                    width: 120,
                                    height: 36,
                                    borderRadius: 18,
                                    display: 'flex',
                                    fontSize: 10,
                                    marginRight: 12,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '10px 2px',
                                }}>
                                    Download Ticket
                             </div>
                             {
                                this.state.status !== 'FAILED' ?
                             
                             <div style={{
                                    width: 120,
                                    height: 36,
                                    borderRadius: 18,
                                    display: 'flex',
                                    fontSize: 10,
                                    marginRight: 12,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    background: '#f44336',
                                    borderColor: '#f44336',
                                    padding: '10px 5px',
                                    color:"#fff"
                             }} onClick={()=>{

                                this.setState({
                                    ...this.state,
                                    cancel:true
                                })
                             }}>
                                 Cancel Ticket
                             </div> :null}
                                <div onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        emailModal: true
                                    })
                                }} className="btn btn-portlet" style={{
                                    width: 120,
                                    height: 36,
                                    borderRadius: 18,
                                    display: 'flex',
                                    fontSize: 10,
                                    background: "#1ab64f",
                                    border: "1px solid #1ab64f",
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '10px 5px',
                                }}>
                                    Email Ticket
                              </div>

                            </div>
                        </div>
                    </div>
                    <div>
                    </div>

                    <PDFExport fileName={this.getId()} ref={r => this.pdf = r}>
                        <div style={{ border: '1px solid #dedede', width: 700, marginTop: 35, padding: 25 }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                                <div style={{ fontSize: 14, color: 'rgba(0,0,0,0.75)', marginBottom: 2 }}>
                                    Booking Id: &nbsp;{tickets.bookingId}
                                </div>
                                <div style={{ fontSize: 14, color: 'rgba(0,0,0,0.75)' }}>
                                    Transaction Id : {this.getId()}
                                </div>
                            </div>
                            <div style={{ margin: '20px -25px', border: '1px solid #dedede' }}></div>
                            <div>
                                <div style={{ fontWeight: 600, fontSize: 16 }}>{AirLineName}</div>
                                <div style={{ fontSize: 12, marginTop: 5 }}>{tickets.AirlineCode}</div>
                            </div>
                            <div style={{ border: '1px solid rgba(0,0,0,0.1)', margin: '20px 0' }} />
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1.5, display: 'flex', justifyContent: 'space-between', borderRight: '1px solid #eee' }}>
                                    <div>
                                        <div style={{
                                            fontSize: 16,
                                            fontWeight: 400,
                                            letterSpacing: 1
                                        }}> {originTime}</div>
                                        <div style={{ marginTop: 5, fontSize: 12, color: 'rgba(0,0,0,0.4)' }}>{`Terminal ${1}`}</div>
                                        <div style={{ fontSize: 14, fontWeight: 400 }}>{originCode} </div>
                                        <div style={{ fontSize: 14, fontWeight: 700 }}>{originAirport}</div>
                                    </div>
                                    <div style={{ marginRight: 25 }}>
                                        <div style={{
                                            fontSize: 16,
                                            fontWeight: 400,
                                            letterSpacing: 1
                                        }}>{destinationTime}</div>
                                        <div style={{ marginTop: 5, fontSize: 12, color: 'rgba(0,0,0,0.4)' }}>{`Terminal ${1}`}</div>
                                        <div style={{ fontSize: 14, fontWeight: 400 }}>{destinationCode}  </div>
                                        <div style={{ fontSize: 14, fontWeight: 700 }}>{destinationAirport}</div>
                                    </div>
                                </div>
                                <div style={{ flex: 1, borderRight: '1px solid #eee', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 18, fontWeight: 500 }}>
                                    {tickets.pnrNo}
                                </div>
                                <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 18, fontWeight: 500 }}>
                                    {date}
                                </div>
                            </div>

                            <div style={{ border: '1px solid #ccc', marginTop: 30 }}>
                                <div style={{
                                    display: 'flex',
                                    borderBottom: '1px dotted #ccc',
                                    padding: '6px 9px',
                                    alignItems: 'center'
                                }}>
                                    <div style={{ flex: 1 }}>
                                        <div style={{
                                            fontSize: 12,
                                            marginBottom: 2,
                                            color: 'rgba(0,0,0,0.5)',
                                            letterSpacing: 0.4,
                                            paddingLeft: 5
                                        }}>Name
                                </div>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <div style={{
                                            fontSize: 12,
                                            marginBottom: 2,
                                            color: 'rgba(0,0,0,0.5)',
                                            letterSpacing: 0.4
                                        }}>Mobile Number
                                </div>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <div style={{
                                            fontSize: 12,
                                            marginBottom: 2,
                                            color: 'rgba(0,0,0,0.5)',
                                            letterSpacing: 0.4
                                        }}>
                                            EmailId
								</div>
                                    </div>
                                </div>
                                <div>
                                    {
                                        passenger.map(this.mapItem)
                                    }
                                </div>
                            </div>

                            <div style={{
                                background: '#f9fafc',
                                border: '1px solid rgba(0,0,0,0.2)',
                                marginTop: 25,
                                padding: '16px 20px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <div style={{ flex: 2 }}>
                                    <div><strong>Total Amount</strong></div>
                                    <div style={{ fontSize: 12, marginTop: 5 }}>(Inclusive all the taxes)</div>
                                </div>
                                <div><strong>INR {tickets.amount ? tickets.amount : null}</strong>
                                </div>
                            </div>

                            <div style={{ fontSize: 12, color: '#6a6c6c', padding: '33px 0 20px 0' }}>
                                If you have any issues related to your booking, Please call us
                    at <span style={{ fontWeight: 700, fontSize: 14 }}>+91-8448239151/+91-9354356088</span> . You can also raise a ticket
                    on  <span style={{ fontWeight: 700, fontSize: 14 }}>{`hello@antrepriz.com`}</span>
                            </div>
                        </div>
                    </PDFExport>
                    <Modal show={this.state.emailModal}
                        title="Ticket"
                        des="Enter the mail ID to which the e-ticket needs to be sent to:"
                        onSave={this.sendEmail}
                        close={() => {
                            this.setState({
                                ...this.state,
                                emailModal: false
                            })
                        }}
                        buttonName="Send Ticket"
                        style={{
                            save: {
                                width: '100%',
                                textAlign: 'center'
                            }
                        }}
                    >
                        <FloatInput type="text" title="Email Address" handleRef={ref => this._email = ref} />
                    </Modal>
                    <Modal show={this.state.issueModal}
                        title="Raise Issue"
                        des="You can Raise Ticket against this Ticket"
                        onSave={this._createIssue}
                        close={() => {
                            this.setState({
                                ...this.state,
                                issueModal: false
                            })
                        }}
                        buttonName="Create Issue"
                        style={{
                            save: {
                                width: '100%',
                                textAlign: 'center',
                                padding: '8px 10px'
                            }
                        }}
                    >   <p style={{
                        color: '#454545',
                        fontSize: 13,
                        fontWeight: 500,
                    }}>Ticket Id</p>
                        <p style={{
                            fontSize: 15,
                            marginTop: 5,
                            marginBottom: 15
                        }}>Support ticket against Transaction id <span style={{
                            fontWeight: 600,
                        }}>#{`${this.getId()}`}</span></p>
                        <FloatInput type="text" title="Description" handleRef={ref => this._des = ref} />
                    </Modal>
                </div>
                <div className="right-side-bar" style={{ overflowY: 'scroll', width: 250 }} >
                    {this.ViewDetails()}
                </div>
                <Modal show={this.state.cancel}
                        title="Cancel Ticket"
                        des="Are You sure want to cancel this ticket"
                        stateLoading={this.state.loading}
                        onSave={()=>{
                            let reason = this.reason.value();

                            if(!reason) {
                                showToast({
                                    type:'error',
                                    message:"Please enter the reason"
                                })
                                return;
                            }
                            this.setState({
                                ...this.state,
                                loading:true
                            })
                            
                            let importedBooking = this.getId().startsWith("M");
                            let URL = `/flight/${this.getId()}/cancel`
                            if(importedBooking) {
                                URL = `/flight/${this.getId()}/cancel.imported`
                            }

                            post(URL,{reason},(e,r)=>{
                                if(r) {
                                    showToast({
                                        type:'success',
                                        message:"Flight cancel successfully"
                                    })
                                    this.presentor.init();
                                    this.setState({
                                        ...this.state,
                                        cancel:false,
                                        loading:false
                                    })
                                }
                            })

  
                        }}
                        close={() => {
                            this.setState({
                                ...this.state,
                                cancel: false
                            })
                        }}
                        buttonName="Cancel Ticket"
                        style={{
                            save: {
                                width: '100%',
                                textAlign: 'center'
                            }
                        }}
                        
                    >
                        <FloatInput title="Enter the Reason" type="text" handleRef={ref=>this.reason=ref} />
                        <p>Are you sure want to cancel this ticket ?</p>
                    </Modal>
            </div>
        )
    }
}

export default View;