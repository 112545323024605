import View from './View';
import Presentor from './Presentor';
import Model from './Model'
import { inject } from '../../../../Utils/Injector';



export default inject(View,Presentor,Model);


