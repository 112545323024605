import React from 'react'
import { NormalTable } from '../../../../Tables/NormalTable/NormalTable';
import { EmptyState } from '../../../../libs/EmptyState/index';
import emptyBooking from '.././k2.png';
import { HotelTicketItem } from "../../../Common/BookingItem/HotelItem";
import { Modal } from '../../../../libs/Modal/Modal';
import { FloatInput } from '../../../../libs/FloatLabelInput';
import { showToast } from '../../../../libs/Toast/ToastCallback';

class View extends React.Component {


	constructor(props) {
		super(props);
		this.presentor = props.presentor;
		this.presentor.setView(this);
		this.state = {
			bookings: [],
			emptyState: false,
			cancelTicketHotel: false,
			transId: undefined,
			cancellationAmount: undefined
		}
	}

	componentDidMount() {
		this.presentor.init();
	}


	componentWillUnmount() {
		this.presentor.destroy();
	}

	load = r => {
		if (r.tickets.length > 0) {
			this.setState({
				bookings: r.tickets,
				emptyState: false
			})
		} else {
			this.setState({
				boooking: r,
				emptyState: true
			})
		}
	}

	Item = (item, index) => {
		return (
			<HotelTicketItem openCancel={this.cancelTicketModal} onCancel={true} data={item} key={index} />
		)
	}


	cancelTicketModal = (transId) => {
		this.setState({
			...this.state,
			cancelTicketHotel: true,
			transId
		}, () => this.presentor.cancellationFare(this.state.transId))

	}

	onFetchFare = r => {
		this.setState({
			...this.state,
			cancelFare: true,
			cancellationAmount: r.amount
		})
	}

	onCancelTicket = r => {
		this.setState({
			...this.state,
			cancelTicketHotel: false,
			cancellationAmount: undefined,
			transId: undefined
		})
		this.presentor.init();
	}

	render() {
		if (this.state.emptyState) {
			return <EmptyState image={emptyBooking} title="No Upcoming Booking Found" desc="Please Book the Tickets" />
		}
		return (
			<div>
				<NormalTable headerStyle={{
					border: 'none'
				}} headers={[
					{
						key: 'details',
						name: '',
						weight: ''
					},
					{
						key: 'name',
						name: '',
						weight: ''
					},
					{
						key: 'amount',
						name: '',
						weight: ''
					}
				]}
					data={this.state.bookings}
					pagination={true}
					mapItem={this.Item}
				/>

				<Modal
					show={this.state.cancelTicketHotel}
					close={() => {
						this.setState({
							cancelTicketHotel: false
						})
					}}
					title="Refund Ticket"
					des="Please see the cancellation ticket fees"
					hideButton={true}
					onSave={() => { }}
				>
					{
						this.state.cancelFare ?
							(
								<div>
									<FloatInput type="text" title="Reason" handleRef={ref => this.reason = ref} />
									<p style={{
										textAlign: 'center',
										fontWeight: 700,
									}}>Refundable Amount is {this.state.cancellationAmount}</p>
									<div onClick={() => {
										let reason = this.reason.value();
										if(!reason){
											showToast({
												type:'error',
												message:'Please enter reason!'
											})
											return;
										}
										this.presentor.cancelTicket(this.state.transId,reason)
									}} style={{
										height: 30,
										marginTop: 20,
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center'
									}} className="btn btn-portlet">Cancel</div>
								</div>
							) :
							(
								<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
									<div className="dot-pulse" />
								</div>
							)
					}
				</Modal>
			</div>
		)
	}

}

export default View