import React from 'react'
import { Sidebar } from "../Common/Sidebar";
import ActiveBookingsIcon from "../Hotels/assets/bookings.png";
import InActiveBookingsIcon from "../Hotels/assets/bookings1.png";
import InactiveWorkflowIcon from "../Hotels/assets/icon5.png";
import ActiveWorkflowIcon from "../Hotels/assets/icon5a.png";
import ActiveRefundIcon from "../Hotels/assets/icon1.png";
import InactiveRefundIcon from "../Hotels/assets/icon1a.png";
//import ActiveSupportIcon from "../Hotels/assets/icon3a.png";
//import InactiveSupportIcon from "../Hotels/assets/icon3.png";
import { Route, Switch } from "react-router-dom";

import Support from "../CommonPages/Support";
import Bookings from './Bookings'
import ContactUs from './ContactUs';
import Home from './Home'
import { get } from "../../../Model/Network/Config/Axios";
import { BookingRequests } from "../CommonPages/BookingRequests";
import AllSupport from '../CommonPages/AllSupport';

class index extends React.Component {

	constructor() {
		super();
		this.Disposables = [];

	}

	state = {
		bookings: 0
	}



	componentDidMount() {
		this._interval = setInterval(this.poll, 10000);
		this.poll()
	}

	componentWillUnmount() {
		this.Disposables.forEach(item => {
			if (item.isCancellable())
				item.cancel()
		})
		if (this._interval)
			clearInterval(this._interval)
	}

	poll = () => {
		this.Disposables.push(get(`/requests/counts`, (e, r) => {
			if (r) {
				this.setState({
					...r
				})
			}
		}))
	}

	render() {
		return (
			<div id="fintrip-container" style={{
				flex: 1
			}}>
				<Sidebar history={this.props.history} location={this.props.location} title="Dashboard" data={[
					{
						title: "",
						items: [
							{
								title: "Home",
								path: "/app/home"
							}
						]
					}, {
						title: "Overview",
						items: [
							{
								title: "Bookings",
								active: ActiveBookingsIcon,
								inactive: InActiveBookingsIcon,
								path: "/app/home/bookings",
								includes: true,
								style: {
									width: 30,
									height: 30,
									marginLeft: -2,
									marginRight: 8,
									marginBottom: -6,
									marginTop: -6
								}
							},

							{
								title: this.state.bookings > 0 ? `Booking Requests (${this.state.bookings})` : "Booking Requests",
								active: InactiveWorkflowIcon,
								inactive: ActiveWorkflowIcon,
								path: "/app/home/requests",
								style: {
									width: 42,
									height: 42,
									marginLeft: -8,
									marginRight: 8,
									marginBottom: -6,
									marginTop: -6
								}
							}
						]
					},
					{
						title: "Help & Support",
						items: [
							{
								title: "Contact Us",
								active: ActiveRefundIcon,
								inactive: InactiveRefundIcon,
								path: "/app/home/contactus",
								style: {
									width: 42,
									height: 42,
									marginLeft: -8,
									marginRight: 8,
									marginBottom: -6,
									marginTop: -6
								}
							},
							// {
							// 	title: "Support",
							// 	active: ActiveSupportIcon,
							// 	inactive: InactiveSupportIcon,
							// 	path: "/app/home/support",
							// 	style: {
							// 		width: 42,
							// 		height: 42,
							// 		marginLeft: -8,
							// 		marginRight: 8,
							// 		marginBottom: -6,
							// 		marginTop: -6
							// 	}
							// }
						]
					}
				]} />
				<div style={{ flex: 1 }}>
					<Switch>
						<Route component={Home} exact={true} path="/app/home" />
						<Route component={Bookings} path="/app/home/bookings" />
						<Route component={ContactUs} exact={true} path="/app/home/contactus" />
						<BookingRequests all={true} exact={true} path="/app/home/requests" history={this.props.history} service="all" />
						<Support all={true} exact={true} path="/app/home/support" history={this.props.history} service="all" />
						<Route path="/app/home/support/:id" component={AllSupport} />
					</Switch>
				</div>
			</div>
		)
	}

}

export default index
