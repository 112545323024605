import React from 'react';
import { TitleComponent } from '../../Common/TitleComponent';
import { PDFExport } from '@progress/kendo-react-pdf';
import Moment from 'moment';
import { Modal } from '../../../libs/Modal/Modal';
import { FloatInput } from '../../../libs/FloatLabelInput';
import { showToast } from '../../../libs/Toast/ToastCallback';


class View extends React.Component {

    constructor(props) {
        super(props);
        this.presentor = props.presentor;
        this.presentor.setView(this);
        this.state = {
            ticket: {},
            emailModal: false,
            issueModal: false
        }
    }

    id = () => {
        if (this.props && this.props.match.params.id) {
            return this.props.match.params.id
        }
    }

    componentDidMount() {
        this.presentor.init();
    }


    componentWillUnmount() {
        this.presentor.destroy();
    }

    load = r => {
        this.setState({
            ...r,
            ticket: r.ticket,

        })
    }

    downloadPdf = () => {
        this.pdf.save()
    }

    mapItem = (item, index) => {
        return (
            <div key={index} style={{ display: 'flex', padding: '9px', borderBottom: '1px dotted #ccc' }}>
                <div style={{ flex: 1 }}>
                    <div style={{
                        fontSize: 18,
                        fontWeight: 700,
                        color: 'rgba(0,0,0,0.7)',
                        letterSpacing: 0.3,
                        paddingLeft: 5
                    }}>{item ? item.name : null}</div>
                </div>
                <div style={{ flex: 1 }}>
                    <div style={{
                        fontSize: 18,
                        fontWeight: 700,
                        color: 'rgba(0,0,0,0.7)',
                        letterSpacing: 0.3
                    }}>{item ? item.mobile : null}</div>
                </div>
                <div style={{ flex: 1 }}>
                    <div style={{
                        fontSize: 18,
                        fontWeight: 700,
                        color: 'rgba(0,0,0,0.7)',
                        letterSpacing: 0.3
                    }}>{item ? item.code : null}</div>
                </div>
            </div>
        )
    }

    sendEmail = () => {
        let email = this._email.value();
        if (!email) {
            showToast({
                type: 'error',
                message: 'Please enter a valid email'
            })
            return
        }

        let data = {
            email
        }

        this.presentor.sendEmail(data);
    }

    onSendEmail = () => {
        this.setState({
            ...this.state,
            emailModal: false
        })
        showToast({
            type: 'success',
            message: 'Ticket sent successfully to given email Address'
        })
    }
    _createIssue = () => {
        let title = `#${this.id()}`;
        let description = this._des.value();

        if (!description) {
            showToast({
                type: 'error',
                message: 'Please Enter Valid Description'
            })
        }

        let data = {
            title,
            description
        }
        this.presentor.createSupportTicket(data)

    }

    onCreateSupportTicket = () => {
        showToast({
            type: 'success',
            message: 'Your support ticket created successfully'
        })
        this._des.setValue("");
        this.setState({
            ...this.state,
            issueModal: false,
        })
    }

    ViewDetails = () => {
        return (
            <div>
                <p
                    style={{
                        fontSize: 17,
                        fontWeight: 'bold',
                        margin: 0,
                        paddingTop: 20
                    }}
                >Important Information</p>
                <p
                    style={{
                        margin: 0,
                        marginTop: 8,
                        color: "#777777",
                        width: '100%',
                        fontSize: 12
                    }}
                >
                    <strong>Ticket Information</strong> <br />
                    Passengers are required to furnish the following at the time of boarding the bus:<br />
                    (1) A copy of the ticket (A print out of the ticket or the print out of the ticket e-mail).<br />
                    (2) A valid identity proof<br />
                    Failing to do so, they may not be allowed to board the bus.<br />
                </p>
                <p
                    style={{
                        margin: 0,
                        marginTop: 8,
                        color: '#777',
                        width: '100%',
                        fontSize: 12
                    }}

                >
                    <strong>Change of bus</strong> <br />
                    In case the bus operator changes the type of bus due to some reason, eka will refund the differential amount
                    to the customer upon being intimated by the customers in 24 hours of the journey.
            </p>
                <p
                    style={{
                        margin: 0,
                        marginTop: 8,
                        color: '#777',
                        width: '100%',
                        fontSize: 12
                    }}

                >
                    <strong>Time Information</strong> <br />
                    The departure time mentioned on the ticket are only tentative timings. However the bus will not leave the
                    source before the time that is mentioned on the ticket.
            </p>
                <p
                    style={{
                        margin: 0,
                        marginTop: 8,
                        color: '#777',
                        width: '100%',
                        fontSize: 12
                    }}

                >
                    <strong>Baggage Information</strong> <br />
                    Please note the following regarding the luggage policy for your journey: <br />
                    (1) Each passenger is allowed to carry one bag of upto 10 kgs and one personal item such as a laptop bag,
                handbag, or briefcase of upto 5 kgs. <br />
                    (2) Passengers should not carry any goods like weapons, inflammable, firearms, ammunition, drugs, liquor,
                smuggled goods etc and any other articles that are prohibited under law. <br />
                    (3) Bus Operator reserves the right to deny boarding or charge additional amount in case passenger is
                travelling with extra luggage than what is mentioned above. <br />
                </p>
                <p
                    style={{
                        margin: 0,
                        marginTop: 8,
                        color: '#777',
                        width: '100%',
                        fontSize: 12
                    }}
                >
                    <strong>Cancellation</strong> <br />
                    In the event of any cancellation/rescheduling, the respective cancellation charges will be applicable which
                    may vary by the buses. For all cancellations done online, Eka will levy a charge of Rs 100 per passenger per
                    segment and Rs.250 per passenger per segment for cancellations done through our call center.
            </p>
            </div>
        )
    }

    render() {

        let { ticket } = this.state;
        let travellers = ticket.travellers ? ticket.travellers : [];
        let departure = ticket.departure ? ticket.departure : {};
        let arrival = ticket.arrival ? ticket.arrival : {};

        let departureTime = departure.time ? Moment(departure.time).format('hh:mm A') : null;
        let arrivalTime = arrival.time ? Moment(arrival.time).format('hh:mm A') : null;
        return (
            <div className="fintrip-container" style={{
                display: 'flex',
                flexDirection: "row",
                flex: 1
            }}>
                <div className="side-container" style={{ borderTop: 'none' }} >
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{
                            width: 700,
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <TitleComponent subtitle="Bus" title="Booking Details" />
                            <div style={{
                                display: 'flex'
                            }}>
                                <div onClick={() => this.setState({
                                    ...this.state,
                                    issueModal: true
                                })} className="btn btn-portlet" style={{
                                    width: 120,
                                    height: 36,
                                    borderRadius: 18,
                                    display: 'flex',
                                    fontSize: 10,
                                    marginRight: 12,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    background: '#f44336',
                                    borderColor: '#f44336',
                                    padding: '10px 5px',
                                }}>
                                    Raise Issue
                    </div>
                                <div onClick={this.downloadPdf} className="btn btn-portlet" style={{
                                    width: 120,
                                    height: 36,
                                    borderRadius: 18,
                                    display: 'flex',
                                    fontSize: 10,
                                    marginRight: 12,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '10px 5px',
                                }}>
                                    Download Ticket
                    </div>
                                <div onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        emailModal: true
                                    })
                                }} className="btn btn-portlet" style={{
                                    width: 120,
                                    height: 36,
                                    borderRadius: 18,
                                    display: 'flex',
                                    fontSize: 10,
                                    background: "#1ab64f",
                                    border: "1px solid #1ab64f",
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '10px 5px',
                                }}>
                                    Email Ticket
                    </div>
                            </div>
                        </div>
                    </div>
                    <PDFExport fileName={this.id()} ref={r => this.pdf = r}>
                        <div style={{ border: '1px solid #dedede', marginTop: 35, padding: 25, width: 700 }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ fontSize: 14, color: 'rgba(0,0,0,0.75)', marginBottom: 2 }}>
                                    Pnr No.: &nbsp;
                        {ticket.pnrNo}
                                </div>
                                <div style={{ fontSize: 14, color: 'rgba(0,0,0,0.75)' }}>
                                    Transaction Id : {this.id()}
                                </div>
                            </div>
                            <div style={{ marginLeft: -25, marginRight: -25, border: '1px solid #dedede', marginBottom: 20, marginTop: 20 }} />
                            <div style={{ display: 'flex' }}>
                                <div className="bus1" />
                                <div style={{ marginLeft: 8 }}>
                                    <div style={{ fontWeight: 600, fontSize: 16 }}>{ticket.companyName ? ticket.companyName : null}</div>
                                    <div style={{ fontSize: 12, marginTop: 5 }}>{ticket.busName ? ticket.busName : null}</div>
                                </div>
                            </div>
                            <div style={{ border: '1px solid rgba(0,0,0,0.1)', margin: '20px 0' }} />
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 2, display: 'flex', borderRight: '1px solid #eee' }}>
                                    <div>
                                        <div style={{
                                            fontSize: 16,
                                            fontWeight: 400,
                                            letterSpacing: 1
                                        }}> {departure.time ? departureTime : null}</div>
                                        <div style={{ marginTop: 5, fontSize: 12, color: 'rgba(0,0,0,0.4)' }}>Boarding Point</div>
                                        <div style={{ fontSize: 14, fontWeight: 400 }}>{departure.point ? departure.point : null} , <span style={{ fontWeight: 700 }}>{departure.name ? departure.name : null}</span></div>
                                    </div>
                                    <div className="bus" style={{ marginTop: 16, marginLeft: 30 }} />
                                    <div style={{ marginLeft: 30 }}>
                                        <div style={{
                                            fontSize: 16,
                                            fontWeight: 400,
                                            letterSpacing: 1
                                        }}>{arrival.time ? arrivalTime : null}</div>
                                        <div style={{ marginTop: 5, fontSize: 12, color: 'rgba(0,0,0,0.4)' }}>Drop Point</div>
                                        <div style={{ fontSize: 14, fontWeight: 400 }}>{arrival.point ? arrival.point : null} ,<span style={{ fontWeight: 700 }}>{arrival.name ? arrival.name : null}</span> </div>
                                    </div>
                                </div>
                                <div style={{ flex: 1, borderRight: '1px solid #eee', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 18, fontWeight: 500 }}>
                                    {ticket.travellers ? ticket.travellers.length : null} Seats
                </div>
                                <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 18, fontWeight: 500 }}>
                                    {ticket.date ? ticket.date : null}
                                </div>
                            </div>

                            <div style={{ border: '1px solid #ccc', marginTop: 30 }}>
                                <div style={{
                                    display: 'flex',
                                    borderBottom: '1px dotted #ccc',
                                    padding: '6px 9px',
                                    alignItems: 'center'
                                }}>
                                    <div style={{ flex: 1 }}>
                                        <div style={{
                                            fontSize: 12,
                                            marginBottom: 2,
                                            color: 'rgba(0,0,0,0.5)',
                                            letterSpacing: 0.4,
                                            paddingLeft: 5
                                        }}>Name
                                </div>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <div style={{
                                            fontSize: 12,
                                            marginBottom: 2,
                                            color: 'rgba(0,0,0,0.5)',
                                            letterSpacing: 0.4
                                        }}>Email ID
                                </div>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <div style={{
                                            fontSize: 12,
                                            marginBottom: 2,
                                            color: 'rgba(0,0,0,0.5)',
                                            letterSpacing: 0.4
                                        }}>
                                            Seat No.
								</div>
                                    </div>
                                </div>
                                <div>
                                    {
                                        travellers.map(this.mapItem)
                                    }
                                </div>
                            </div>



                            <div style={{
                                background: '#f9fafc',
                                border: '1px solid rgba(0,0,0,0.2)',
                                marginTop: 25,
                                padding: '16px 20px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <div style={{ flex: 2 }}>
                                    <div><strong>Total Amount</strong></div>
                                    <div style={{ fontSize: 12, marginTop: 5 }}>(Inclusive all the taxes)</div>
                                </div>
                                <div><strong>INR {ticket.amount ? ticket.amount : null}</strong>
                                </div>
                            </div>
                            <div style={{ fontSize: 12, color: '#6a6c6c', padding: '33px 0 20px 0' }}>
                                If you have any issues related to your booking, Please call us
                    at <span style={{ fontWeight: 700, fontSize: 14 }}>+91-8448239151/+91-9354356088</span> . You can also raise a ticket
                    on <span style={{ fontWeight: 700, fontSize: 14 }}>{`hello@antrepriz.com`}</span>
                            </div>
                        </div>
                        <Modal show={this.state.emailModal}
                            title="Ticket"
                            des="Enter the mail ID to which the e-ticket needs to be sent to:"
                            onSave={this.sendEmail}
                            close={() => {
                                this.setState({
                                    ...this.state,
                                    emailModal: false
                                })
                            }}
                            buttonName="Send Email"
                            style={{
                                save: {
                                    width: '100%',
                                    textAlign: 'center',
                                    padding: '5px 10px'
                                }
                            }}
                        >
                            <FloatInput type="text" title="Email Address" handleRef={ref => this._email = ref} />
                        </Modal>
                        <Modal show={this.state.issueModal}
                            title="Raise Issue"
                            des="You can Raise Ticket against this Ticket"
                            onSave={this._createIssue}
                            close={() => {
                                this.setState({
                                    ...this.state,
                                    issueModal: false
                                })
                            }}
                            buttonName="Create Issue"
                            style={{
                                save: {
                                    width: '100%',
                                    textAlign: 'center',
                                    padding: '8px 10px'
                                }
                            }}
                        >   <p style={{
                            color: '#454545',
                            fontSize: 13,
                            fontWeight: 500,
                        }}>Ticket Id</p>
                            <p style={{
                                fontSize: 15,
                                marginTop: 5,
                                marginBottom: 15

                            }}>Support ticket against Transaction id <span style={{
                                fontWeight: 600,
                            }}>#{`${this.id()}`}</span></p>
                            <FloatInput type="text" title="Description" handleRef={ref => this._des = ref} />
                        </Modal>
                    </PDFExport>
                </div>
                <div className="right-side-bar" style={{ overflowY: 'scroll', width: 250 }} >
                    {this.ViewDetails()}
                </div>
            </div>
        )
    }
}


export default View;