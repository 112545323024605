import React from 'react'
import _ from 'lodash'

class BusSeat extends React.Component {

	render() {
		let data = this.props.data;
		let size = {
			height : ((data.width * this.props.height) - 8),
			margin:4,
			width: ((data.height * this.props.width) - 8),
			display:'flex',
			alignItems:'center',
			justifyContent:'center'
		}

		if(!data.seatCode){
			return (
				<div style={{
					...size,
				}}>

				</div>
			)
		}
		if(data.status===0 || data.status === 1){
			if(!_.includes(this.props.selected,data.seatCode)){
				return (
					<div onClick={this.props.selectSeat.bind(this,data)} style={{
						...size,
						border:'1px solid #989898',
						cursor:'pointer',
					}}>
						<p style={{
							fontSize:12,
							color:"#454545"
						}}>
							{data.price}
						</p>
					</div>
				)
			}else{
				return (
					<div onClick={this.props.unSelectSeat.bind(this,data)}  style={{
						...size,
						border:'1px solid #989898',
						cursor:'pointer',
						background:"#57B678"
					}}>
						<p style={{
							fontSize:12,
							color:"#ffffff"
						}}>
							{data.price}
						</p>
					</div>
				)
			}
		}
		else if(data.status===2 || data.status === -2){
			return (
				<div style={{
					...size,
					border:'1px solid #989898',
					background:"#F1A9A0"
				}}>
					<p style={{
						fontSize:12,
						color:"#ffffff"
					}}>
						{data.price}
					</p>
				</div>
			)
		}
		else if(data.status === -1){
			return (
				<div style={{
					...size,
					border:'1px solid #989898',
					background:"#434343"
				}}>
					<p style={{
						fontSize:12,
						color:"#ffffff"
					}}>
						{data.price}
					</p>
				</div>
			)
		}

	}

}

export default BusSeat