import React from 'react'
import {get} from "../../../Model/Network/Config/Axios";

class AutoComplete extends React.Component {

	state = {
		text:'',
		suggestion:[],
		autoSuggestion:[],
	}

	

	handleChange = () => {
		let autocomplete = this._autocomplete.value;

		this.setState({
			text:autocomplete
		})

		get(`search/location`,(e,r)=>{
			if(r) {
				this.setState({
					autoSuggestion:r.places,
				})
			}
		},{
			q:autocomplete
		})

	}

	selectPalace = (item) => {
		this.setState({
			autoSuggestion:[],
			text:item
		})
		get(`search/geocoding.reverse`,(e,r) => {
			if(r) {
				let data = {
					lat:r.lat,
					lng:r.lng,
					name:this.state.text
				}
				this.props.local(data)
			}
		},{
			name:item
		})
	}

	item = (item,index) => {
		return(
			<div onClick = {()=>this.selectPalace(item)}  style = {{fontSize:12,borderBottom:'1px solid #ccc',cursor:'pointer',display:'flex',alignItems:'center',padding:'10px 0px 10px 5px'}} key = {index}>
				{item}
			</div>
		)
	}

	render() {
		return(
			<div>

				<input value = {this.state.text} ref = {ref => this._autocomplete = ref} onChange = {this.handleChange}
						placeholder="Enter place search criteria"
					   style = {{ width:230,height:30,outline:'none',fontSize:13,border:'none',borderBottom:'1px solid #dedede'}} type = "text"  />
				{
					this.state.autoSuggestion.length > 0 ?
						(<div style = {{border:'1px solid #ccc'}}>
							{this.state.autoSuggestion.map(this.item)}
						</div>)
						: null
				}
			</div>
		)
	}
}



export {
	AutoComplete
}