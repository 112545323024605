import React from 'react'
import { Route, Switch } from 'react-router-dom';

import SearchHotels from './SearchFlights'
import { Sidebar } from "../Common/Sidebar";
import CreateBooking from './CreateBooking';
import Bookings from './Bookings';
import CabRefund from './CabRefund';
import BookingRequest from './BookingRequest';

import ActiveBookingsIcon from './assets/bookings.png'
import InActiveBookingsIcon from './assets/bookings1.png'

import ActiveRefundIcon from './assets/icon1.png'
import InactiveRefundIcon from './assets/icon1a.png'
import ActiveWorkflowIcon from './assets/icon5a.png'
import InactiveWorkflowIcon from './assets/icon5.png'

class index extends React.Component {

	render() {
		return (
			<div id="fintrip-container" style={{
				flex: 1
			}}>
				<Sidebar history={this.props.history} location={this.props.location} title="Cabs" data={[

					{
						title: 'Workflow',
						items: [
							{
								title: 'Booking Request',
								active: InactiveWorkflowIcon,
								inactive: ActiveWorkflowIcon,
								path: '/app/cabs/request',
								style: {
									width: 42,
									height: 42,
									marginLeft: -8,
									marginRight: 8,
									marginBottom: -6,
									marginTop: -6
								}
							}
						]
					},


					{
						title: "Offline Booking",
						items: [
							{
								title: "Import Booking",
								active: InactiveWorkflowIcon,
								inactive: ActiveWorkflowIcon,
								path: "/app/cabs/offline",
								style: {
									width: 42,
									height: 42,
									marginLeft: -8,
									marginRight: 8,
									marginBottom: -6,
									marginTop: -6
								}
							},
							{
								title: 'Offline Bookings',
								active: ActiveBookingsIcon,
								inactive: InActiveBookingsIcon,
								path: '/app/cabs/booking',
								includes: true
							}
						]
					},
					{
						title: "Help & Support",
						items: [
							{
								title: "Refund & Cancellation",
								active: ActiveRefundIcon,
								inactive: InactiveRefundIcon,
								path: "/app/cabs/refunds",
								style: {
									width: 42,
									height: 42,
									marginLeft: -8,
									marginRight: 8,
									marginBottom: -6,
									marginTop: -6
								}
							},

						]
					}
				]} />
				<div style={{ flex: 1, overflowY: 'auto' }}>
					<Switch>
						<Route component={SearchHotels} path="/app/flights/search" />
						<Route component={CreateBooking} path="/app/cabs/offline" />
						<Route component={Bookings} path="/app/cabs/booking" />
						<Route component={CabRefund} path="/app/cabs/refunds" />
						<Route component={BookingRequest} path="/app/cabs/request" />
					</Switch>
				</div>
			</div>
		)
	}

}

export default index