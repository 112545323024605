class Presentor {

	constructor(Model) {
		this.Model = Model;
	}

	setView(View) {
		this.View = View;
	}

	search = data => {
		this.Model.search(data,(e,r)=>{
			if(r){
				this.View.load(r)
			}
		})
	}

}


export default Presentor