import React from 'react';
import '../../../../../../node_modules/react-input-range/lib/css/index.css';
import './index.css';
import InputRange from 'react-input-range';
import HotelSearch from "./hotel search.png";


class PollingHotels extends React.Component {

	handleDeparture = (index) => {
		let departure = this.props.filter.departure;
		departure.forEach(val => {
			if (val.id === index + 1) {
				if (val.isCheck) {
					val.isCheck = false
				} else {
					val.isCheck = true
				}
			}
		})
		this.props.changeFilter({
			departure
		})
	}

	handleArrival = (index) => {
		let arrival = this.props.filter.arrival;
		arrival.forEach(val => {
			if (val.id === index + 1) {
				if (val.isCheck) {
					val.isCheck = false
				} else {
					val.isCheck = true
				}
			}
		})
		this.props.changeFilter({
			arrival
		})
	}

	handleStop = (index) => {
		let stop = this.props.filter.stop;
		stop.forEach(val => {
			if (val.id === index + 1) {
				if (val.isCheck) {
					val.isCheck = false
				} else {
					val.isCheck = true
				}
			}
		})
		this.props.changeFilter({
			stop
		})
	}

	handleFlighName = (index) => {
		let airLines = this.props.filter.airLines;
		airLines.forEach(val => {
			if (val.id === index) {
				if (val.isCheck) {
					val.isCheck = false
				} else {
					val.isCheck = true
				}
			}
		})
		this.props.changeFilter({
			airLines
		})
	}

	handleBadgeName = (index) => {
		let badge = this.props.filter.badge;
		badge.forEach(val=>{
			if(val.id === index) {
				if(val.isCheck) {
					val.isCheck = false
				} else {
					val.isCheck = true
				}
			}
		})
		this.props.changeFilter({
			badge
		})
	}


	render() {
		return (
			<div style={{
				width: 275,
				overflowX: 'hidden',
				background: "white",
				padding: "20px 15px",
				height: '100vh',
				overflowY: 'auto',
				borderRight: '1px solid #dedede'
			}}>
				<h5 style={{
					fontSize: 27,
					fontWeight: 800,
					letterSpacing: 1,
					lineHeight: "35px",
				}}>
					Filters
				</h5>
				<p style={{
					fontSize: 13,
					color: "#BABBCC",
					lineHeight: "16px"
				}}>
					Please select the filters below to filter the search result.
				</p>
				<img src={HotelSearch} style={{
					width: 'calc(100% + 36px)',
					marginLeft: -18,
					marginRight: -18,
					marginTop: 30
				}} alt="hotel search"/>
				<div style={{padding: '40px 0px'}}>
					<p style={{fontWeight: 700, fontSize: 15, padding: '0 0 5px'}}>Price Range</p>
					<p style={{
						fontSize: 11,
						color: "#676767",
						marginBottom: 18
					}}>
						Please select the price range you want to filter result.
					</p>
					<InputRange maxValue={this.props.filter.price.maxValue} minValue={0} value={this.props.filter.price}
								formatLabel={price => `₹ ${price}`}
								onChange={value => this.props.changeFilter({
									price: {
										...value,
										maxValue: this.props.filter.price.maxValue
									}
								})}/>
				</div>

				<div style={{padding: '10px 0'}}>
					<p style={{fontWeight: 700, fontSize: 19, padding: '0 0 5px'}}>Stop</p>
					<p style={{
						fontSize: 13,
						color: "#676767",
						marginBottom: 18
					}}>
						Please select the arrival time for which you want to filter result.
					</p>
					<ul style={{listStyle: 'none', padding: 0, margin: 0, display: 'flex', flexDirection: 'column'}}>
						{
							this.props.filter.stop.map((val, index) => {
								return (
									<li key={index} style={{
										display: 'flex',
										fontSize: '14px',
										marginBottom: '5px',
										alignItems: 'center'
									}}>
										<div
											onClick={() => this.handleStop(index)}
											style={
												!val.isCheck ? {
													width: 14,
													height: 14,
													cursor: 'pointer',
													marginTop: 2,
													border: '1px solid #dedede'
												} : {
													background: "#3f51b5",
													cursor: 'pointer',
													marginTop: 2,
													width: 14,
													height: 14
												}
											}
										/>
										<div style={{
											fontWeight: 400,
											fontSize: 15,
											color: "#454545",
											marginLeft: '8px'
										}}>{val.text}</div>
									</li>
								)
							})
						}
					</ul>
				</div>
				<div style={{padding: '10px 0'}}>
					<p style={{fontWeight: 700, fontSize: 19, padding: '0 0 5px'}}>Departure Time</p>
					<p style={{
						fontSize: 13,
						color: "#676767",
						marginBottom: 18
					}}>
						Please select the departure time for which you want to filter result.
					</p>
					<ul style={{listStyle: 'none', padding: 0, margin: 0, display: 'flex', flexDirection: 'column'}}>
						{
							this.props.filter.departure.map((val, index) => {
								return (
									<li key={index} style={{
										display: 'flex',
										fontSize: '14px',
										marginBottom: '5px',
										alignItems: 'center'
									}}>
										<div
											onClick={() => this.handleDeparture(index)}
											style={
												!val.isCheck ? {
													width: 14,
													height: 14,
													cursor: 'pointer',
													marginTop: 2,
													border: '1px solid #dedede'
												} : {
													background: "#3f51b5",
													cursor: 'pointer',
													marginTop: 2,
													width: 14,
													height: 14
												}
											}
										/>
										<div style={{
											fontWeight: 400,
											fontSize: 15,
											color: "#454545",
											marginLeft: '8px'
										}}>{val.text}</div>
									</li>
								)
							})
						}
					</ul>
				</div>
				<div style={{padding: '10px 0'}}>
					<p style={{fontWeight: 700, fontSize: 19, padding: '0 0 5px'}}>Arrival Time</p>
					<p style={{
						fontSize: 13,
						color: "#676767",
						marginBottom: 18
					}}>
						Please select the arrival time for which you want to filter result.
					</p>
					<ul style={{listStyle: 'none', padding: 0, margin: 0, display: 'flex', flexDirection: 'column'}}>
						{
							this.props.filter.arrival.map((val, index) => {
								return (
									<li key={index} style={{
										display: 'flex',
										fontSize: '14px',
										marginBottom: '5px',
										alignItems: 'center'
									}}>
										<div
											onClick={() => this.handleArrival(index)}
											style={
												!val.isCheck ? {
													width: 14,
													height: 14,
													cursor: 'pointer',
													marginTop: 2,
													border: '1px solid #dedede'
												} : {
													background: "#3f51b5",
													cursor: 'pointer',
													marginTop: 2,
													width: 14,
													height: 14
												}
											}
										/>
										<div style={{
											fontWeight: 400,
											fontSize: 15,
											color: "#454545",
											marginLeft: '8px'
										}}>{val.text}</div>
									</li>
								)
							})
						}
					</ul>
				</div>
				<div style={{padding: '10px 0'}}>
					<p style={{fontWeight: 700, fontSize: 19, padding: '0 0 5px'}}>AirLines</p>
					<p style={{
						fontSize: 13,
						color: "#676767",
						marginBottom: 18
					}}>
						Please select the AirLines for which you want to filter result.
					</p>
					<ul style={{listStyle: 'none', padding: 0, margin: 0, display: 'flex', flexDirection: 'column'}}>
						{
							this.props.filter.airLines.map((val, index) => {
								return (
									<li key={index} style={{
										display: 'flex',
										fontSize: '14px',
										marginBottom: '5px',
										alignItems: 'center'
									}}>
										<div
											onClick={() => this.handleFlighName(index)}
											style={
												!val.isCheck ? {
													width: 14,
													height: 14,
													cursor: 'pointer',
													marginTop: 2,
													border: '1px solid #dedede'
												} : {
													background: "#3f51b5",
													cursor: 'pointer',
													marginTop: 2,
													width: 14,
													height: 14
												}
											}
										/>
										<div style={{
											fontWeight: 400,
											fontSize: 15,
											color: "#454545",
											marginLeft: '8px'
										}}>{val.value}</div>
									</li>
								)
							})
						}
					</ul>
				</div>

				<div style={{padding: '10px 0'}}>
					<p style={{fontWeight: 700, fontSize: 19, padding: '0 0 5px'}}>Flight Fare Type</p>
					<p style={{
						fontSize: 13,
						color: "#676767",
						marginBottom: 18
					}}>
						Please select the AirLines for which you want to filter result.
					</p>
					<ul style={{listStyle: 'none', padding: 0, margin: 0, display: 'flex', flexDirection: 'column'}}>
						{
							this.props.filter.badge.map((val, index) => {
								return (
									<li key={index} style={{
										display: 'flex',
										fontSize: '14px',
										marginBottom: '5px',
										alignItems: 'center'
									}}>
										<div
											onClick={() => this.handleBadgeName(index)}
											style={
												!val.isCheck ? {
													width: 14,
													height: 14,
													cursor: 'pointer',
													marginTop: 2,
													border: '1px solid #dedede'
												} : {
													background: "#3f51b5",
													cursor: 'pointer',
													marginTop: 2,
													width: 14,
													height: 14
												}
											}
										/>
										<div style={{
											fontWeight: 400,
											fontSize: 15,
											color: "#454545",
											marginLeft: '8px'
										}}>{val.value}</div>
									</li>
								)
							})
						}
					</ul>
				</div>
				
			</div>
		)
	}
}

export default PollingHotels;