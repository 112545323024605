import React from 'react'
import EmptySpace from "./hotel empty space.png";

class NoHotels extends React.Component {

	render() {
		return (
			<div>
			  <div style = {{
				  position:'relative',
				  height:60
			  }}>
				<div style={{
					background: 'white',
					height: 60,
					position: 'absolute',
					top: 7,
					left: 0,
					zIndex: 11,
					paddingLeft: 6,
					right: 0,
					display: 'flex',
					borderBottom: '1px solid #cdcdcd'
				}}>

				</div>
				</div>
				<div style={{
					paddingBottom: 20,
					display: 'flex',
					flexWrap: 'wrap',
					height:'100vh',
					overflowY:'auto',
					alignItems:'center',
					justifyContent:'center'
				}}>
					<div style={{
						width:500,
						height:520,
						background:'white',
						border:'1px solid #dedede'
					}}>
						<img src={EmptySpace} alt="hotel empty state" style={{
							width:'100%',
							objectFit:'contain',
							background:"#CDCEFC"
						}}/>
						<div style={{
							display:'flex',
							flexDirection:'column',
							alignItems:'center',
							padding:30
						}}>
							<p style={{
								fontWeight:800,
								color:"#565656",
								fontSize:31,

							}}>
								No Hotels Found
							</p>
							<p style={{
								fontSize:15,
								color:"#454545"
							}}>
								Sorry no hotels found for your search criteria.
							</p>
							<p onClick={this.props.history.goBack} style={{
								fontWeight: 600,
								height: 45,
								margin: 4,
								textAlign: 'center',
								color: "white",
								marginTop:22,
								borderRadius:22,
								background: "#7558F6",
								padding: '12px 45px',
								fontSize: 13,
								letterSpacing: '1px',
								textTransform: 'uppercase',
								lineHeight: '20px',
								cursor: 'pointer'
							}}>
								Reset Search Criteria
							</p>
						</div>
					</div>
				</div>
			</div>
		)
	}

}

export default NoHotels