import React from 'react';
import { TitleComponent } from '../../Common/TitleComponent';
import { get } from '../../../../Model/Network/Config/Axios';
import Search from "stringquery";
import { showToast } from '../../../libs/Toast/ToastCallback';
import BookingDetails from './BookingDetails';
import { ChooseTraveller } from '../../Common/ChooseTraveller';
import { NotesComponent } from '../../Common/NotesComponent';


class View extends React.Component {

	constructor(props) {
		super(props);
		this.presentor = props.presentor;
		this.presentor.setView(this);
		this.init();
	}


	state = {
		flight: undefined,
		flightHold: undefined,
		loading: false,
		selectTraveller: undefined,
		travellers: {

		}
	}

	componentWillUnmount() {
		this.presentor.destroy()
	}



	init = () => {
		
		const params = Search(this.props.location.search);
		if (params.sessionId) {
			get(`/config/${params.sessionId}/session`, (e, r) => {
				if (r) {
					this.setState({
						...this.state,
						count: r.counts,
						flight: r
					})

				} else {
					showToast({
						type: 'error',
						message: e
					})
				}
			})
		}
		if (params.requestId) {
			get(`/flight/request/${params.requestId}`, (e, r) => {
				if (r) {
					this.setState({
						...this.state,
						travellers: {
							"1": {
								name: r.employee.name,
								type: 'EMPLOYEE',
								reference: r.employee.code
							}
						}
					})
				}
			})
		}
	}

	mapTravellers = () => {
		let count = this.state.count;
		let data = []
		for (var i = 1; i <= count; i++) {
			data.push(
				i
			)
		}
		return (
			<div>
				{data.map(value => {
					return (
						<div onClick={() => {
							if (!this.state.hotelHold) {
								this.setState({
									...this.state,
									selectTraveller: value,

								})
							}
						}} key={value} style={{
							display: 'flex',
							marginTop: 15,
							alignItems: 'center',
							background: "#fafafa",
							border: '1px solid #eee',
							borderRadius: 22,
							position: 'relative',
							padding: '12px 20px',
							width: 350,
							cursor: 'pointer',
							flexDirection: 'row'
						}}>
							<p className="label-back" style={{
								fontWeight: 400,
								color: "#555555",
								fontSize: 13,
								position: 'absolute',
								top: -8,
								zIndex: 11,
								left: 20,
								marginRight: 10
							}}>
								Traveller {value}
							</p>
							<p style={{
								fontWeight: 600,
								color: "#454545",
								fontSize: 15,
							}}>{this.state.travellers[value] ? this.state.travellers[value].name : "Add Traveller"}</p>
						</div>
					)
				})}
			</div>
		)
	}


	_continue = () => {
		const params = Search(this.props.location.search);
		let count = this.state.count;
		let travellers = this.state.travellers;
		let data = []
		for (var i = 1; i <= count; i++) {
			if (!travellers[i]) {
				showToast({
					type: "error",
					message: `Please select traveller ${i}`
				})
				return;
			} else {
				data.push(travellers[i])
			}
		}
		data = {
			travellers: data,
			sessionId: params.sessionId,
			searchId: params.searchId
		}


		this.setState({
			...this.state,
			loading: true
		})
		this.presentor.continue(data)
	}

	onHold = flightHold => {
		this.setState({
			...this.state,
			flightHold,
			loading: false
		})
	}

	_book = () => {
		const params = Search(this.props.location.search);

		let data = {
			sessionId: params.sessionId,
			searchId: params.searchId
		}
		this.setState({
			...this.state,
			loading: true
		})
		this.presentor.book(data)
	}

	onBook = r => {
		this.props.history.push(`/app/flights/tickets/${r.transactionId}`)
	}


	render() {
		let flight = this.state.flight;
		return (
			<div className="border-top">
				<div className="side-container" style={{ borderTop: 'none' }}>
					<TitleComponent subtitle="Flight" title="Complete Booking" />
					<BookingDetails data={flight} />
					<p style={{
						fontSize: 21,
						color: "#454545",
						fontWeight: 700,
						marginTop: 22
					}}>
						Traveller Details
					</p>
					<p style={{
						marginBottom: 22,
						fontSize: 14,
						color: "#898989"
					}}>
						All travellers selected for this booking are shown below.
					</p>
					{
						this.mapTravellers()
					}

					{
						this.state.loading ? <p style={{
							marginTop: 30,
							boxShadow: "1px 1px 1px rgba(1,1,1,.05)",
							width: 280,
							height: 45,
							borderRadius: 22,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							color: "white",
							cursor: 'pointer',
							textTransform: 'uppercase',
							background: "#7558F6"
						}}>
							Loading....
						</p> : this.state.flightHold ?
								<p onClick={this._book} style={{
									marginTop: 30,
									boxShadow: "1px 1px 1px rgba(1,1,1,.05)",
									width: 280,
									height: 45,
									borderRadius: 22,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									color: "white",
									cursor: 'pointer',
									textTransform: 'uppercase',
									background: "#7558F6"
								}}>
									Pay Rs {this.state.flightHold.amount} to Book
							</p> :
								<p onClick={this._continue} style={{
									marginTop: 30,
									boxShadow: "1px 1px 1px rgba(1,1,1,.05)",
									width: 280,
									height: 45,
									borderRadius: 22,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									color: "white",
									cursor: 'pointer',
									textTransform: 'uppercase',
									background: "#1ab64f"
								}}>
									CONTINUE TO BOOK
							</p>
					}
					<div style={{
						marginTop: 60
					}}>
						<NotesComponent notes={[
							"Traveller One is primary traveller. Booking will happen in name of primary travel and booking details will be triggered to primary traveller."
						]} />
					</div>
				</div>
				<div className="right-side-bar">
					{
						flight ?
							<BookingDetails data={flight} /> : null
					}
					<ChooseTraveller callback={data => {
						let key = this.state.selectTraveller;
						let travellers = this.state.travellers;
						travellers[key] = data;
						this.setState({
							...this.state,
							travellers,
							selectTraveller: undefined
						})
					}} close={() => {
						this.setState({
							...this.state,
							selectTraveller: undefined
						})
					}} shown={this.state.selectTraveller !== undefined} />


				</div>

			</div>
		)
	}
}

export default View;