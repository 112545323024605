import React from 'react'
import { TitleComponent } from "../../Common/TitleComponent";
import Search from "stringquery";
import { get } from "../../../../Model/Network/Config/Axios";
import Sidebar from "./Sidebar";
import BusDetails from "./BusDetails";
import { NotesComponent } from "../../Common/NotesComponent";

class View extends React.Component {

	constructor(props) {
		super(props)
		this.presentor = props.presentor;
		this.presentor.setView(this)
		this.init()
	}

	state = {
		bus: undefined,
		bookHold: undefined,
		loading: false
	}


	componentWillUnmount() {
		this.presentor.destroy();
	}

	init = () => {
		const params = Search(this.props.location.search);
		if (params.searchId) {
			get(`/config/${params.sessionId}/session`, (e, r) => {
				if (r) {
					this.setState({
						...this.state,
						bus: r
					})
				}
			})
		}
	}

	onHold = busHold => {
		this.setState({
			...this.state,
			busHold,
			loading: false
		})
	}


	onBook = r => {
		this.props.history.push(`/app/bus/tickets/${r.transactionId}`)
	}


	_continue = () => {
		const params = Search(this.props.location.search);
		this.presentor.hold(params)
		this.setState({
			...this.state,
			loading: true
		})
	}

	_book = () => {
		const params = Search(this.props.location.search);
		this.presentor.book(params)
		this.setState({
			...this.state,
			loading: true
		})
	}

	render() {
		return (
			<div className="border-top">
				<div className="side-container" style={{ borderTop: 'none' }}>
					<TitleComponent subtitle="Bus" title="Complete Booking" />
					<BusDetails data={this.state.bus} />
					{
						this.state.loading ? <p style={{
							marginTop: 30,
							boxShadow: "1px 1px 1px rgba(1,1,1,.05)",
							width: 280,
							height: 45,
							borderRadius: 22,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							color: "white",
							cursor: 'pointer',
							textTransform: 'uppercase',
							background: "#7558F6"
						}}>
							Loading....
						</p> : this.state.busHold ?
								<p onClick={this._book} style={{
									marginTop: 30,
									boxShadow: "1px 1px 1px rgba(1,1,1,.05)",
									width: 280,
									height: 45,
									borderRadius: 22,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									color: "white",
									cursor: 'pointer',
									textTransform: 'uppercase',
									background: "#7558F6"
								}}>
									Pay Rs {this.state.busHold.amount} to Book
							</p> : <p onClick={this._continue} style={{
									marginTop: 30,
									boxShadow: "1px 1px 1px rgba(1,1,1,.05)",
									width: 280,
									height: 45,
									borderRadius: 22,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									color: "white",
									cursor: 'pointer',
									background: "#1ab64f"
								}}>
									CONTINUE TO BOOK
							</p>
					}
					<div style={{
						marginTop: 60
					}}>
						<NotesComponent notes={[
							"Traveller One is primary traveller. Booking will happen in name of primary travel and booking details will be triggered to primary traveller."
						]} />
					</div>
				</div>
				<div className="right-side-bar">
					<Sidebar data={this.state.bus} />
				</div>
			</div>
		)
	}

}

export default View