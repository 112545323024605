import { showToast } from "../../../libs/Toast/ToastCallback";

class Presentor {

	constructor(Model) {
		this.Model = Model;
	}

	setView(View) {
		this.View = View;
	}

	init = () => {
		this.Model.fetch((e, r) => {
			if (r) {
				this.View.load(r)
			}
		})
	}

	delete = id => {
		this.Model.delete(id, (e, r) => {
			if (r) {
				showToast(
					{
						type: 'success',
						message: 'Guest deleted successfully'
					}
				)
				this.View.clearAndInit()
			} else {
				showToast(
					{
						type: 'error',
						message: e.data.error
					}
				)
			}
		})
	}

	destroy = () => {
		this.Model.destroy()
	}


	addGuest = data => {
		this.Model.post(data, (e, r) => {
			if (r) {
				this.View.clearAndInit()
			} else {
				showToast(
					{
						type: 'error',
						message: e.data.error
					}
				)
			}
		})
	}


}


export default Presentor