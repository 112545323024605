import React from 'react'

class BookingSmallItem extends React.Component {

	render() {
		let item = this.props.data;
		if(item.service.toLowerCase() === "bus"){
			return(
				<div key={item.id}  onClick={()=>{
					if(item.type && item.type==="return"){
						this.props.history.push(
							`/app/bus/search?requestId=${item.id}&type=return`
						)
					}else{
						this.props.history.push(
							`/app/bus/search?requestId=${item.id}`
						)
					}
				}} style={{
					padding:16,
					background:"#ffffff",
					border : '1px solid #dedede',
					marginTop:12,
					width:145,
					marginRight:12,
					cursor:'pointer'
				}}>
					<img src={require('./booking_bus.png')} alt="bus" style={{
						width:18,
						height:18,
					}}/>
					<p style={{
						fontSize:16,
						color:"#232323",
						fontWeight:700,
						marginTop:8
					}}>
						Bus
					</p>
					<p style={{
						fontSize:13,
						color:"#232323",
						fontWeight:500,
						marginBottom:8,
					}}>
						{item.employee.name}
					</p>
					<p style={{
						fontSize:11,
						color:"#676767"
					}}>
						{
							item.line1
						}
					</p>
					<p style={{
						fontSize:11,
						color:"#676767"
					}}>
						{
							item.line2
						}
					</p>
					<p style={{
						fontSize:11,
						color:"#676767"
					}}>
						{
							item.line3
						}
					</p>
				</div>
			)
		}else if(item.service.toLowerCase() === "hotel"){
			return(
				<div  key={item.id} onClick={()=>{
					this.props.history.push(
						`/app/hotel/search?requestId=${item.id}`
					)
				}}  style={{
					padding:16,
					background:"#ffffff",
					border : '1px solid #dedede',
					marginTop:12,
					width:145,
					marginRight:12,
					cursor:'pointer'
				}}>
					<img src={require('./booking_hotel.png')} alt="bus" style={{
						width:18,
						height:18,
					}}/>
					<p style={{
						fontSize:16,
						color:"#232323",
						fontWeight:700,
						marginTop:8
					}}>
						Hotel
					</p>
					<p style={{
						fontSize:13,
						color:"#232323",
						fontWeight:500,
						marginBottom:8,
					}}>
						{item.employee.name}
					</p>
					<p style={{
						fontSize:11,
						color:"#676767"
					}}>
						{
							item.line1
						}
					</p>
					<p style={{
						fontSize:11,
						color:"#676767"
					}}>
						{
							item.line2
						}
					</p>
					<p style={{
						fontSize:11,
						color:"#676767"
					}}>
						{
							item.line3
						}
					</p>
				</div>
			)
		}else if(item.service.toLowerCase() === "flight"){
			return(
				<div key={item.id}  onClick={()=>{
					if(item.type && item.type==="return"){
						this.props.history.push(
							`/app/flights/search?requestId=${item.id}&type=return`
						)
					}else{
						this.props.history.push(
							`/app/flights/search?requestId=${item.id}`
						)
					}
				}}  style={{
					padding:16,
					background:"#ffffff",
					border : '1px solid #dedede',
					marginTop:12,
					marginRight:12,
					width:145,
					cursor:'pointer'
				}}>
					<img src={require('./booking_flight.png')} alt="bus" style={{
						width:18,
						height:18,
					}}/>
					<p style={{
						fontSize:16,
						color:"#232323",
						fontWeight:700,
						marginTop:8
					}}>
						Flight
					</p>
					<p style={{
						fontSize:13,
						color:"#232323",
						fontWeight:500,
						marginBottom:8,
					}}>
						{item.employee.name}
					</p>
					<p style={{
						fontSize:11,
						color:"#676767"
					}}>
						{
							item.line1
						}
					</p>
					<p style={{
						fontSize:11,
						color:"#676767"
					}}>
						{
							item.line2
						}
					</p>
					<p style={{
						fontSize:11,
						color:"#676767"
					}}>
						{
							item.line3
						}
					</p>

				</div>
			)
		}
	}

}

export {
	BookingSmallItem
}