import React from 'react';
import '../../../../../../node_modules/react-input-range/lib/css/index.css';
import './index.css';
import HotelSearch from "./bus.png";
import InputRange from 'react-input-range';

class PollingHotels extends React.Component {

	handleDeparture = (index) => {
		let departure = this.props.filter.departure;
		departure.forEach(val => {
			if (val.id === index + 1) {
				if (val.isCheck) {
					val.isCheck = false
				} else {
					val.isCheck = true
				}
			}
		})
		this.props.changeFilter({
			departure
		})
	}

	handleArrival = (index) => {
		let arrival = this.props.filter.arrival;
		arrival.forEach(val => {
			if (val.id === index + 1) {
				if (val.isCheck) {
					val.isCheck = false
				} else {
					val.isCheck = true
				}
			}
		})
		this.props.changeFilter({
			arrival
		})
	}

	handleHotelType = (index) => {
		let type = this.props.filter.type;
		type.forEach(val => {
			if (val.id === index) {
				if (val.isActive) {
					val.isActive = false
				} else {
					val.isActive = true
				}
			}
		})
		this.props.changeFilter({
			type
		})
	}


	handleBusType = (index) => {
		let busType = this.props.filter.busType;
		busType.forEach(val => {
			if (val.id === index + 1) {
				if (val.isCheck) {
					val.isCheck = false
				} else {
					val.isCheck = true
				}
			}
		})
		this.props.changeFilter({
			busType
		})
	}

	render() {
		return (
			<div className="scrollbar" style={{
				width: 275,
				overflowX: 'hidden',
				background: "white",
				padding: "20px 15px",
				height: '100vh',
				overflowY: 'auto',
				borderRight: '1px solid #dedede',

			}}>
				<h5 style={{
					fontSize: 27,
					fontWeight: 800,
					letterSpacing: 1,
					lineHeight: "35px",
				}}>
					Filters
				</h5>
				<p style={{
					fontSize: 13,
					color: "#BABBCC",
					lineHeight: "16px"
				}}>
					Please select the filters below to filter the search result.
				</p>
				<img src={HotelSearch} style={{
					width: 'calc(100% + 36px)',
					marginLeft: -18,
					marginRight: -18,
					marginTop: 30,
					marginBottom: 12
				}} alt="hotel search" />
				<div style={{ padding: '10px 0' }}>
					<p style={{ fontWeight: 700, fontSize: 19, padding: '0 0 5px' }}>Departure Time</p>
					<p style={{
						fontSize: 13,
						color: "#676767",
						marginBottom: 18
					}}>
						Please select the departure time for which you want to filter result.
					</p>
					<ul style={{ listStyle: 'none', padding: 0, margin: 0, display: 'flex', flexDirection: 'column' }}>
						{
							this.props.filter.departure.map((val, index) => {
								return (
									<li key={index} style={{
										display: 'flex',
										fontSize: '14px',
										marginBottom: '5px',
										alignItems: 'center'
									}}>
										<div
											onClick={() => this.handleDeparture(index)}
											style={
												!val.isCheck ? {
													width: 14,
													height: 14,
													cursor: 'pointer',
													marginTop: 2,
													border: '1px solid #dedede'
												} : {
														background: "#3f51b5",
														cursor: 'pointer',
														marginTop: 2,
														width: 14,
														height: 14
													}
											}
										/>
										<div style={{ fontWeight: 400, fontSize: 15, color: "#454545", marginLeft: '8px' }}>{val.text}</div>
									</li>
								)
							})
						}
					</ul>
				</div>
				<div style={{ padding: '40px 0px' }}>
					<p style={{ fontWeight: 700, fontSize: 15, padding: '0 0 5px' }}>Price Range</p>
					<p style={{
						fontSize: 11,
						color: "#676767",
						marginBottom: 18
					}}>
						Please select the price range you want to filter result.
					</p>
					<InputRange maxValue={this.props.filter.price.maxValue} minValue={0} value={this.props.filter.price}
						formatLabel={price => `₹ ${price}`}
						onChange={value => this.props.changeFilter({
							price: {
								...value,
								maxValue: this.props.filter.price.maxValue
							}
						})} />
				</div>
				<div style={{ padding: '10px 0' }}>
					<p style={{ fontWeight: 700, fontSize: 19, padding: '0 0 5px' }}>Arrival Time</p>
					<p style={{
						fontSize: 13,
						color: "#676767",
						marginBottom: 18
					}}>
						Please select the arrival time for which you want to filter result.
					</p>
					<ul style={{ listStyle: 'none', padding: 0, margin: 0, display: 'flex', flexDirection: 'column' }}>
						{
							this.props.filter.arrival.map((val, index) => {
								return (
									<li key={index} style={{
										display: 'flex',
										fontSize: '14px',
										marginBottom: '5px',
										alignItems: 'center'
									}}>
										<div
											onClick={() => this.handleArrival(index)}
											style={
												!val.isCheck ? {
													width: 14,
													height: 14,
													cursor: 'pointer',
													marginTop: 2,
													border: '1px solid #dedede'
												} : {
														background: "#3f51b5",
														cursor: 'pointer',
														marginTop: 2,
														width: 14,
														height: 14
													}
											}
										/>
										<div style={{ fontWeight: 400, fontSize: 15, color: "#454545", marginLeft: '8px' }}>{val.text}</div>
									</li>
								)
							})
						}
					</ul>
				</div>
				<div style={{ padding: '20px 0px' }}>
					<p style={{ fontWeight: 700, fontSize: 15, padding: '0 0 5px' }}>Bus Type</p>
					<p style={{
						fontSize: 11,
						color: '#676767',
						marginBottom: 18
					}}>Please select the Bus type you want to filter result</p>
					<ul style={{ listStyle: 'none', padding: 0, margin: 0, display: 'flex', flexDirection: 'column' }}>
						{
							this.props.filter.type.map((val, index) => {
								return (
									<li key={index} style={{
										display: 'flex',
										fontSize: 14,
										marginBottom: 5,
										alignItems: 'center'
									}}>
										<div onClick={() => this.handleHotelType(index)} style={val.isActive ? {
											height: 14,
											width: 14,
											background: '#3f51b5',
											marginTop: 2,
											cursor: 'pointer'
										} : {
												height: 14,
												width: 14,
												border: '1px solid #dedede',
												cursor: 'pointer',
												marginTop: 2
											}} />
										<div style={{ marginLeft: 8, fontSize: 17, fontWeight: 400 }}>{val.item}</div>
									</li>
								)
							})
						}
					</ul>
				</div>
				{/*	<div style={{padding: '10px 0'}}>
					<p style={{fontWeight: 700, fontSize: 19, padding: '0 0 5px'}}>Bus Type</p>
					<p style={{
						fontSize: 13,
						color: "#676767",
						marginBottom: 18
					}}>
						Please select the bus type for which you want to filter result.
					</p>
					<ul style={{listStyle: 'none', padding: 0, margin: 0, display: 'flex', flexDirection: 'column'}}>
						{
							this.props.filter.busType.map((val, index) => {
								return (
									<li key={index} style={{
										display: 'flex',
										fontSize: '14px',
										marginBottom: '5px',
										alignItems: 'center'
									}}>
										<div
											onClick={() => this.handleBusType(index)}
											style={
												!val.isCheck ? {
													width: 14,
													height: 14,
													cursor: 'pointer',
													marginTop: 2,
													border: '1px solid #dedede'
												} : {
													background: "#3f51b5",
													cursor: 'pointer',
													marginTop: 2,
													width: 14,
													height: 14
												}
											}
										/>
										<div style={{fontWeight: 400, fontSize: 15, color :"#454545", marginLeft: '8px'}}>{val.text}</div>
									</li>
								)
							})
						}
					</ul>
				</div>
					*/}
			</div>
		)
	}
}

export default PollingHotels;