import React from 'react';
import OpenBooking from './OpenBooking';
import CloseBooking from './ClosedBooking'
import {TitleComponent} from '../../Common/TitleComponent';

class BookingRequests extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			open: true,
		}
	}


	render() {
		return (
			<div className="side-container">
				<div style={{display: 'flex', justifyContent: 'space-between'}}>
					<TitleComponent subtitle={this.props.service.toUpperCase()} title={`Booking Request`}/>
					{
						!this.props.all && this.state.open ?
							(<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
								<div onClick={() => this.setState({...this.state, supportModal: true})} style=
									{{
										height: 45,
										width: 45,
										borderRadius: 22,
										backgroundColor: '#1F2888',
										boxShadow: '1px 1px .4px rgba(1,1,1,.1)',
										cursor: 'pointer',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center'
									}}>
									<div className="hotelsupport"/>
								</div>
							</div>) : null
					}
				</div>
				<div
					style={{
						borderBottom: '2px solid rgb(241,241,241)',
						display: 'flex',
						marginTop: 25,
						marginBottom: 25
					}}>
					<p onClick={() => {
						this.setState({
							open: true
						})
					}} style={{
						padding: '0px 0 12px',
						marginRight: 32,
						fontSize: 15,
						color: 'inherit',
						fontWeight: 600,
						textTransform: 'uppercase',
						cursor: 'pointer',
						position: 'relative',
						top: 3
					}} className={this.state.open ? "active-line" : null}>
						Open Booking
					</p>
					<p onClick={() => {
						this.setState({
							open: false
						})
					}} style={{
						padding: '0px 0 12px',
						cursor: 'pointer',
						marginRight: 32,
						fontWeight: 600,
						fontSize: 15,
						color: 'inherit',
						textTransform: 'uppercase',
						position: 'relative',
						top: 3
					}} className={!this.state.open ? "active-line" : null}>
						Closed Booking
					</p>
				</div>
				<div style={{
					marginTop:-15
				}}>
					{
						this.state.open ? <OpenBooking  {...this.props}/> :	<CloseBooking {...this.props}/>
					}
				</div>
			</div>
		)
	}
}

export {
	BookingRequests
}