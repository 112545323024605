import React from 'react'
import {v4} from 'uuid'
import Delete from './delete.png'
import {get} from "../../../../Model/Network/Config/Axios";
import HotelSearch from './hotelsearch.png'
import {showToast} from "../../../libs/Toast/ToastCallback";
import Moment from 'moment'

class SearchComponent extends React.Component {

	state = {
		rooms : [
			{
				key:"initial",
				count:1
			}
		],
		city:{
			text:"",
			value:""
		},
		suggestions : []
	}

	_addRoom = () => {
		let rooms = this.state.rooms;
		rooms.push({
			key:v4(),
			count:1
		})
		this.setState({
			rooms
		})
	}

	mapItem = (item,i) => {
		return(
			<div key={item.key} style={{
				display:'flex',
				marginBottom:12,
				alignItems:'center',
				flexDirection:'row'
			}}>
				<p style={{
					fontSize:13,
					fontWeight:600,
					color:"#777777"
				}}>
					Room {i+1}:
				</p>
				<div style={{
					marginLeft:10,
					padding:"2px 10px",
					width:80,
					flexDirection:'row',
					display:'flex',
					alignItems:'center',
					border:'1px solid #7558F6'
				}}>
					<span onClick={()=>{
						let rooms = this.state.rooms;
						rooms = rooms.map(m=>{
							if(item.key===m.key){
								let count = item.count;
								if(count>1){
									count= count-1;
								}
								m.count = count;
							}
							return m;
						})
						this.setState({
							rooms
						})
					}} style={{
						fontWeight:600,
						color:"#7558F6",
						fontSize:17,
						lineHeight:"21px",
						cursor:'pointer'
					}}>
						-
					</span>
					<p style={{
						flex:1,
						textAlign:'center',
						fontWeight:600,
						color:"#7558F6",
						fontSize:13
					}}>
						{item.count}
					</p>
					<span onClick={()=>{
						let rooms = this.state.rooms;
						rooms = rooms.map(m=>{
							if(item.key===m.key){
								let count = item.count;
								if(count<3){
									count= count+1;
								}
								m.count = count;
							}
							return m;
						})
						this.setState({
							rooms
						})
					}} style={{
						fontWeight:600,
						color:"#7558F6",
						fontSize:17,
						lineHeight:"21px",
						cursor:'pointer'
					}}>
						+
					</span>
				</div>
				{
					item.key!=="initial"?
						<img alt="delete" onClick={()=>{
							let rooms = this.state.rooms;
							rooms = rooms.filter(m=>item.key!==m.key)
							this.setState({
								rooms
							})
						}} src={Delete} style={{
							width:16,
							height:16,
							cursor:'pointer',
							marginLeft:8
						}}/>:null
				}
			</div>
		)
	}

	searching = () => {
		if(this.props.result.polling){
			return(
				<div>
					<p className="prime-background" style={{
						textAlign:'center',
						textTransform:'uppercase',
						color:"white",
						fontWeight:600,
						padding:'10px 20px',
						fontSize:15,
						background:"#7558F6",
						marginTop:35,
						cursor:'pointer'
					}} >
						Finding Result...
					</p>
					<div className="loader"/>
				</div>
			)
		}else{
			return(
				<p className="prime-background" style={{
					textAlign:'center',
					textTransform:'uppercase',
					color:"white",
					fontWeight:600,
					padding:'10px 20px',
					fontSize:15,
					background:"#19B64F",
					marginTop:35,
					cursor:'pointer'
				}} onClick={this.props.reset}>
					Reset Search
				</p>
			)
		}
	}

	onChange = (e) => {
		let value = this._cityInput.value;
		this.setState({
			...this.state,
			city:{
				value,
				id:undefined
			}
		})
		get(`/cities`,(e,r)=>{
			if(r){
				this.setState({
					...this.state,
					suggestions:r.cities
				})
			}
		},{q:value})
	}

	selectCity = item => {
		this.setState({
			...this.state,
			city:{
				value:item.name,
				id:item.id
			},
			suggestions:[]
		})
	}

	search = () => {
		let city = this.state.city;
		if(!city.id){
			showToast({
				type:'error',
				message:"Please select a valid city from drop down"
			})
			return;
		}
		let checkin = this._checkIn.value;
		let checkout = this._checkOut.value;

		if(!checkin || checkin === ""){
			showToast({
				type:'error',
				message:"Please select a valid check in date"
			})
			return
		}

		if(!checkout || checkout === ""){
			showToast({
				type:'error',
				message:"Please select a valid check out date"
			})
			return
		}

		checkin = Moment(checkin,"YYYY-MM-DD");

		checkout = Moment(checkout,"YYYY-MM-DD");

		if(checkin.valueOf()>checkout.valueOf()){
			showToast({
				type:'error',
				message:"Please select a valid checkout date"
			})
			return;
		}

		if(checkin.valueOf()<Moment().valueOf()){
			showToast({
				type:'error',
				message:"Please select a valid check in date"
			})
			return;
		}

		let data = {
			cityId:city.id,
			checkIn:checkin.format("DD-MM-YYYY"),
			checkOut:checkout.format("DD-MM-YYYY"),
			rooms:this.state.rooms.map(item=>item.count)
		}
		this.props.search(data)

	}

	render() {
		return (
			<div style={{
				width:300,
				overflowX:'hidden',
				background:"white",
				padding:"25px 18px",
				borderTop:'6px solid #7558F6',
				height:'100vh',
				overflowY:'auto',
				borderRight:'1px solid #dedede'
			}}>
				<h5 className="prime-color" style={{
					fontSize:25,
					fontWeight:800,
					textAlign:'center',
					letterSpacing:1,
					lineHeight:"35px",
					marginTop:15
				}}>
					Book Flight Online
				</h5>
				<p style={{
					fontSize:13,
					color:"#BABBCC",
					textAlign:'center',
					lineHeight:"16px"
				}}>
					Please add below details and click on button below to search flights.
				</p>
				<div style={{
					width:'calc(100% + 36px)',
					marginLeft:-18,
					marginRight:-18,
					marginTop:30,
				}}>
					<img src={HotelSearch} alt="hotel search" style={{
						width:'100%'
					}}/>
				</div>
				<div style={{
					position:'relative',
					marginTop:-10,
					marginBottom:30
				}}>
					<input ref={ref => this._cityInput = ref} onChange={this.onChange} value={this.state.city.value}  placeholder="Where do you want to book?" type="text" style={{
						width:'100%',
						height : 36,
						padding: "8px 12px",
						border:'1px solid #dedede',
						color:"#676767"
					}}/>
					{
						this.state.suggestions.length>0?
							<div style={{
								position:'absolute',
								top:36,
								width:'100%',
								background:"#ffffff",
								zIndex:11,
								border:'1px solid #dedede',
								maxHeight:300,
								overflowY:'auto',
								minHeight:50,
								boxShadow:'1px 1px 1px rgba(1,1,1,.05)'
							}}>
								{
									this.state.suggestions.map(item=>{
										return(
											<div key={item.id} onClick={this.selectCity.bind(this,item)} style={{
												width:'100%',
												padding:'8px 12px',
												color:"#676767",
												cursor:'pointer',
												borderBottom:'1px solid #dedede'
											}}>
												{
													item.name
												}
											</div>
										)
									})
								}
							</div>:null
					}
				</div>

				<p style={{
					fontSize:11,
					marginTop:16,
					color:"#555555",
					fontWeight:600
				}}>
					Check In
				</p>
				<input ref={ref=>this._checkIn = ref} placeholder="Check-In" type="date" style={{
					width:'100%',
					height : 36,
					padding: "8px 12px",
					border:'1px solid #dedede',
					marginTop:4,
					color:"#676767"
				}}/>
				<p style={{
					fontSize:11,
					marginTop:16,
					color:"#555555",
					fontWeight:600
				}}>
					Check Out
				</p>
				<input ref={ref=>this._checkOut = ref} placeholder="Check-Out" type="date" style={{
					width:'100%',
					height : 36,
					padding: "8px 12px",
					border:'1px solid #dedede',
					marginTop:4,
					color:"#676767"
				}}/>
				<div style={{
					display:'flex',
					flexDirection:'row',
					alignItems:'center',
					marginBottom:12,
					marginTop:12
				}}>
					<p style={{
						fontSize:12,
						marginTop:16,
						color:"#555555",
						flex:1,
						fontWeight:600
					}}>
						Rooms
					</p>
					{this.props.result?null:<p onClick={this._addRoom} style={{
						textAlign:'right',
						fontSize:11,
						color:"#7558F6",
						cursor:"pointer",
						marginTop:20,
						fontWeight:700,
						flex:1,
					}}>
						Add
					</p>}
				</div>
				{
					this.state.rooms.map(this.mapItem)
				}
				{this.props.result?this.searching():
					<p className="prime-background" style={{
						textAlign:'center',
						textTransform:'uppercase',
						color:"white",
						fontWeight:600,
						padding:'10px 20px',
						fontSize:15,
						marginTop:35,
						background:"#7558F6",
						cursor:'pointer'
					}} onClick={this.search}>
						Search Flights
					</p>}
			</div>
		)
	}

}

export default SearchComponent