import { showToast } from "../../../../libs/Toast/ToastCallback";

class Presentor {

	constructor(Model) {
		this.Model = Model;
	}

	setView(View) {
		this.View = View;
	}

	destroy = () => {
		this.Model.destroy()
	}

	init = () => {
		this.Model.fetch((e, r) => {
			if (r) {
				this.View.load(r)
			}
		})
	}

	cancel = (id,reason) => {
		this.Model.cancelFlight(id,reason, (e, r) => {
			if (r) {
				showToast({
					type: 'success',
					message: "Flight cancelled successfully"
				})
				this.View.onFlightCancel()
			} else {
				showToast({
					type: 'error',
					message: e.data.error
				})
				this.View.setState({
					...this.state,
					loading: false
				})
			}
		})
	}
}


export default Presentor