

class Presentor {

	constructor(Model) {
		this.Model = Model;
	}

	setView(View) {
		this.View = View;
	}

	init = () => {
		this.Model.fetch((e, r) => {
			if (r) {
				this.View.load(r)
			}
		})
	}

	destroy = () => {
		this.Model.destroy()
	}

	cancellationFare = (id) => {
		this.Model.fetchFare(id, (e, r) => {
			if (r) {
				this.View.onFetchFare(r)
			}
		})
	}

	cancelTicket = (id,reason) => {
		this.Model.cancelTicket(id,reason, (e, r) => {
			if (r) {
				this.View.onCancelTicket(r)
			}
		})
	}
}


export default Presentor