import React, {useEffect, useState} from "react";
import Props from "prop-types";
import Moment from 'moment'
import {DateRangePicker} from 'react-date-range';
import './index.css';
import { showToast } from "../Toast/ToastCallback";
import 'react-dates/lib/css/_datepicker.css'

function DatePicker1(props) {
	let s = props.s ? props.s : -21;
	let e = props.e ? props.e : 14;
	let [range,setRange] = useState({
		startDate: Moment().add(s, 'd').toDate(),
		endDate: Moment().add(e, 'd').toDate(),
		key: 'selection',
	})
	let [selected,setSelected] = useState({
		startDate: Moment().add(s, 'd'),
		endDate: Moment().add(e, 'd'),
	})
	useEffect(()=>{
		props.handle({
			start: Moment().add(s, 'd').toDate(),
			end: Moment().add(e, 'd').toDate(),
		})
	},[])
	let handleSelect = (ranges) => {
		let start = Moment(ranges.selection.startDate);
		let end = Moment(ranges.selection.endDate);
		let days = end.diff(start, 'days');
		if (days > props.days) {
			showToast({
				type: 'error',
				message: 'Please select the date range of 91 days'
			})
			return;
		}
		setRange({
			startDate: Moment(ranges.selection.startDate).toDate(),
			endDate: Moment(ranges.selection.endDate).toDate(),
			key: 'selection'
		})
		setSelected({
			startDate: Moment(ranges.selection.startDate),
			endDate: Moment(ranges.selection.endDate)
		})
		if (props.handle) {
			props.handle({
				start: Moment(ranges.selection.startDate),
				end: Moment(ranges.selection.endDate)
			})
		}
	}
	return (
		<div style={{
			position:'fixed',
			inset:0,
			backgroundColor:"rgba(0,0,0,0.2)",
			zIndex:111111

		}}>
			<div  style={{
				height:'100vh',
				display:'flex',
				alignItems:'center',
				justifyContent:'center',
			}}>
				<div style={{
					backgroundColor:'white',
					...props.container }} className="daterange1">
					<DateRangePicker
						color="#0062C5"
						ranges={[range]}
						onChange={handleSelect}
					/>

					<div style  ={{
						display:'flex',
						alignItems:'center'
					}}>
						<div className = "btn btn-portlet" style={{
							maxWidth:'95%',
							margin:10

						}} onClick={()=>{
							props.apply(selected);
						}}>Apply Date Filter</div>

						<div className="btn btn-portlet" style={{
							backgroundColor:'#ff6464',
							borderColor:'#ff6464',
							marginRight:10
						}} onClick={props.onClose}>
							Close
						</div>
					</div>

				</div> : null

			</div>

		</div>
	)

}

DatePicker1.propTypes = {
	handle: Props.func,
}

DatePicker1.defaultProps = {
	days: 91
}

export {
	DatePicker1
}
