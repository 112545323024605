import React from 'react'
import {get} from "../../../../Model/Network/Config/Axios";
import {BookingSmallItem} from "../../Common/BookingSmallItem/BookingSmallItem";
import {v4} from "uuid";

class Sidebar extends React.Component {

	state = {
		requests : []
	}

	componentDidMount(){
		get(`requests/employee/${this.props.code}`,(e,r)=>{
			if(r){
				this.setState({
					...r
				})
			}
		})
	}

	mapItem = item => {
		return(
			<BookingSmallItem history={this.props.history} key={v4()} data={item}/>
		)
	}

	render() {
		return (
			<div className="right-side-bar">
				<p style={{
					fontSize:31
				}} className="title">
					Booking Requests
				</p>
				<p style={{
					fontSize: 15,
					color: "#898989"
				}}>
					All active booking requests are visible below.
				</p>
				<div style={{
					display:"flex",
					flexWrap:'wrap'
				}}>
					{
						this.state.requests.map(this.mapItem)
					}
					{
						this.state.requests.length===0?
							<p style={{
								marginTop:20
							}}>
								No active booking requests found for the selected employee.
							</p>:null
					}
				</div>
			</div>
		)
	}

}

export default Sidebar