import React from 'react'
import {BusShimmer} from "../../Common/Shimmer";

class ShimmerArea extends React.Component {

	render() {
		let items = [];
		for (var i = 0; i < 20; i++) {
			items.push(i)
		}
		return (
			<div>
			  <div style = {{
				  position:'relative'
			  }}>
				<div style={{
					background: 'white',
					height: 60,
					position: 'absolute',
					top: 7,
					left: 0,
					zIndex: 11,
					paddingLeft: 6,
					right: 0,
					display: 'flex',
					borderBottom: '1px solid #cdcdcd'
				}}>

				</div>
			</div>
				<div style={{
					paddingBottom: 20,
					display: 'flex',
					flexWrap: 'wrap',
					paddingTop:70
				}}>
					{
						items.map(i => <BusShimmer key={i}/>)
					}
				</div>
			</div>
		)
	}

}

export default ShimmerArea