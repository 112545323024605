import React from 'react';
import { Modal } from '../Modal/Modal';
import axios from 'axios';
import Zoom from 'react-medium-image-zoom';

class Attachment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            attachment: [],
            url: undefined,
            uploadmodal: false

        }
    }

    componentDidMount() {
        if (this.props.handleRef) {
            this.props.handleRef(this);
        }
    }


    getValue = () => {
        return this.state.attachment;
    }


    setValue = () => {
        this.setState({
            attachment: []
        })
    }


    upload = () => {
        let file = this._file.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            this.setState({
                ...this.state,
                stateLoading: true
            })
            axios({
                method: 'post',
                url: 'https://file.eka.io/file',
                data: formData,
                headers: {
                    clientId: '422dc3e2-a960-4941-9a7a-af64732157b5',
                    clientSecret: "9cd4ce3e-6ea6-45d7-bf92-06c9c7a69873"
                }
            })
                .then(res => {
                    this.setState({
                        ...this.state,
                        url: res.data.url,
                        stateLoading: false
                    })
                }).catch(err => {
                    alert("Unable to upload file")
                    this.setState({
                        ...this.state,
                        stateLoading: false
                    })
                })

        }
    }

    
    render() {
        return (
            <div>
                <p style={{
                    color: "#454545",
                    fontSize: 17,
                    fontWeight: 500,
                    marginTop: 33,
                    marginBottom: 12
                }}>
                    {this.props.title ? this.props.title :'Attachments'}
				</p>

                <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {
                            this.state.attachment.map((item, index) => {
                                return (
                                    <div key={index}>
                                        {
                                            item ? item.includes("pdf") ?
                                                <a rel="noopener noreferrer" href={item}
                                                    target="_blank"><img
                                                        src={require('./pdf.png')}
                                                        alt="pdf icon" style={{
                                                            width: 120,
                                                            height: 120,
                                                            marginRight: 12,
                                                            border: '1px solid var(--border-color)'
                                                        }} /></a>
                                                : item.includes("csv") ?
                                                    <a rel="noopener noreferrer" href={item}
                                                        target="_blank"><img
                                                            src={require('./excel.png')}
                                                            alt="pdf icon" style={{
                                                                width: 120,
                                                                height: 120,
                                                                marginRight: 12,
                                                                border: '1px solid var(--border-color)'
                                                            }} /></a>
                                                    : <Zoom image={{
                                                        src: item,
                                                        alt: 'Golden Gate Bridge',
                                                        className: 'img',
                                                        style: { height: 120, width: 120, marginRight: 12 }
                                                    }}>
                                                        <img
                                                            alt="that wanaka tree"
                                                            src={item}
                                                            style={{
                                                                height: 120,
                                                                width: 120
                                                            }}
                                                        />
                                                    </Zoom> : null
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div>
                        <div onClick={() => {
                            this.setState({
                                uploadmodal: true
                            })
                        }} style={{
                            width: 120,
                            height: 120,
                            borderRadius: 6,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            border: '1px dashed #dedede',
                            cursor: 'pointer',
                            marginLeft: 4,
                        }}>
                            <p style={{
                                fontSize: 40,
                                textAlign: 'center',
                                color: 'var(--light-color)'
                            }}>+</p>
                            <p style={{
                                fontSize: 11,
                                textAlign: 'center',
                                color: "var(--light-color)"
                            }}>
                                Add Attachment
											</p>
                        </div>
                    </div>
                </div>
                <Modal
                    style={{
                        save: {
                            width: '100%',
                            height: '45px',
                            display: 'flex',
                            justifyContent: 'center',
                            textTransform: 'uppercase',
                            alignItems: 'center'
                        }
                    }}
                    buttonName={this.state.stateLoading ? "Loading..." : "Upload File"}
                    des="Please select the file to upload"
                    show={this.state.uploadmodal} onSave={() => {
                        if (this.state.url) {
                            this._file.value = "";
                            let url = this.state.url;
                            let attachment = this.state.attachment;
                            if(this.props.singleFileAttachment) attachment=[]
                            attachment.push(url)
                            this.setState({
                                ...this.state,
                                url: undefined,
                                uploadmodal: undefined,
                                attachment
                            })
                        }
                    }} title="Upload File" close={() => {
                        this.setState({
                            ...this.state,
                            uploadmodal: undefined,
                            url: undefined
                        })
                    }}>
                    <div style={{ marginTop: 22 }}>
                        <input type="file" onChange={() => this.upload()} ref={ref => this._file = ref} style={{
                            height: '100%',
                            width: '100%',
                            padding: '10px 20px',
                            border: '1px solid var(--border-color)'
                        }} />
                    </div>
                </Modal>
            </div>
        )
    }
}

export { Attachment }