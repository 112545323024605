import { post } from "../../../../Model/Network/Config/Axios";



class Model {

    issue = (data,cb) => {
        post(`/support/issue`,data,cb)
    }

}

export default Model;