import {post} from "../../../../Model/Network/Config/Axios";

class Model {

	search = (data,cb) => {
		post(`/flights/search`,data,cb)
	}

}

export default Model