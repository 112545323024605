import React from 'react'
import {Bookings} from "../../Sidebar/data";
import {get} from "../../../Model/Network/Config/Axios";


class MiniSidebar extends React.Component {

	state = {
		profile:false,
		email:'',
		name:''
	}

	constructor(props) {
		super(props);
		this.init()
	}



	componentDidMount(){
		this.interval = setInterval(this.init,10000)
		document.addEventListener('click',this.change)
	}

	change = (event) => {
		if (this.status && !this.status.contains(event.target)) {
			this.setState({
				profile:false
			})
		  }

	}


	componentWillUnmount(){

		if(this.interval)
			clearInterval(this.interval)
	}

	init = () => {
		get(`/config/counts`,(e,r)=>{
			if(r){
				this.setState({
					...r
				})
			}
		})
	}

	item = item => {
		let active = this.props.location.pathname.includes(item.path)
		let count  = this.state[item.value]?this.state[item.value]:0;
		return(
			<div data-tip={item.value} key={item.link} style={{
				backgroundImage:active?item.active:item.inactive,
				position:'relative'
			}}  className="icon">
				<img onClick={()=>{
					if(!active)
						this.props.history.push(item.link)
				}} alt="sidebar icon" key={item.path} src={item.icon}/>
				{
					count && count>0?
						<p className="top-badge">
							{count}
						</p>:null
				}
			</div>
		)
	}

	profile = () => {
		return(
			<div className = {`profileshow ${this.state.profile ? "show" : null}`}
			style = {{
				position:'absolute',
				width:230,height:200,
				background:'#3949AB',
				zIndex:1,
				left:80,
				bottom:30,
				display:'none',
				borderRadius:2,
				padding:20,

			}}>
		   <div style = {{display:'flex',flexDirection:'column',height:160,justifyContent:'space-between'}}>
				 <div style = {{height:80}}>
					<div style = {{position:'absolute',width:40,height:40,background:'radial-gradient( circle farthest-corner at 10% 20%, rgba(58,67,190,1) 0%, rgba(109,115,236,1) 90.1% ) ',color:'#fff',borderRadius:'50%',top:-14,display:'flex',alignItems:'center',justifyContent:'center'}}>AD</div>
					<div style = {{fontSize:16,marginTop:10,fontWeight:600}}>{this.state.name}</div>
					<div style = {{fontSize:12,fontWeight:400}}>{this.state.email}</div>
				 </div>
				 <div style = {{borderTop:'0.5px solid #ccc',paddingTop:'15px',width:230,margin:'0px -20px'}}>
			 		<div onClick = {()=>{
						 window.localStorage.clear()
						 window.location.reload('/')
						}} style = {{fontSize:14,position:'absolute',right:10,bottom:10,fontWeight:100}}>Logout</div>
			 	</div>
		  </div>
		</div>
		)
	}

	render() {
		return (
			<div id="minisidebar" style = {{display:'flex',flexDirection:'column',justifyContent:'space-between'}}>
				<div>
				{
					Bookings.map(this.item)
				}
				</div>
				<div>
					<div ref = { ref => this.status = ref} onClick = {()=> this.setState({
						profile: !this.state.profile
					})} style = {{
						backgroundImage:'linear-gradient( 107deg,  rgba(72,81,86,.3) 11.2%, rgba(187,187,187,.3) 90.7% )',
						marginBottom:30,
						color:'#fff',
						letterSpacing:1
					}} className = {`icon profile ${this.state.profile ? "active" : null}`}>
						AD
						{this.profile()}
					</div>
				</div>
			</div>
		)
	}

}


export {
	MiniSidebar
}
