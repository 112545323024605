import React from 'react'
import SearchComponent from './SearchComponent'
import SearchArea from './SearchArea'
import Search from "stringquery";

class View extends React.Component {

	constructor(props) {
		super(props);
		this.presentor = props.presentor;
		this.presentor.setView(this)
	}

	state = {
		result: {
			polling: false
		}
	}


	componentDidMount() {
		const params = Search(this.props.location.search);
		if (params.requestId) {
			this.presentor.getRequest(params.requestId, params.type)
		}
	}

	componentWillUnmount() {
		this.presentor.destroy()
	}


	load = (result) => {
		const params = Search(this.props.location.search);
		var url = ""
		if (params.requestId) {
			if (params.type === 'return') {
				url = `/app/flights/search/polling/${result.searchId}?requestId=${params.requestId}&sessionId=${result.sessionId}&type=return`
			} else {
				url = `/app/flights/search/polling/${result.searchId}?requestId=${params.requestId}&sessionId=${result.sessionId}`

			}
		} else {
			url = `/app/flights/search/polling/${result.searchId}?sessionId=${result.sessionId}`
		}
		this.props.history.push(url)
	}

	render() {
		let returnFlight = false;
		const params = Search(this.props.location.search);
		if (params.type === "return") {
			returnFlight = true;
		}

		return (
			<div className="fintrip-container" style={{
				display: 'flex',
				flexDirection: 'row',
				flex: 1
			}}>
				<SearchComponent {...this.props} loader={r => this._loader = r} reset={() => {
					this.setState({
						...this.state,
						result: undefined
					})
				}} result={this.state.result} search={data => {
					this.data = data;
					this.setState({
						result: {
							polling: true
						}
					})
					this.presentor.search(data)
				}} returnFlight={returnFlight} />
				<SearchArea />
			</div>
		)
	}

}

export default View
