import React from 'react'
import { EmptyState } from '../../../../../libs/EmptyState/index';
import emptyimage from '.././k2.png';
import Moment from 'moment';
import { NormalTable } from '../../../../../Tables/NormalTable/NormalTable';
import { BusTicketItem } from "../../../../Common/BookingItem/BusItem";
import { HotelTicketItem } from "../../../../Common/BookingItem/HotelItem";
import { FlightItem } from '../../../../Common/BookingItem/FlightItem';

class View extends React.Component {

	constructor(props) {
		super(props);
		this.presentor = props.presentor;
		this.presentor.setView(this);
		this.state = {
			tickets: [],
			emptystate: false
		}
	}

	getCode = () => {
		return this.props.code
	}


	componentDidMount() {
		let { startDate, endDate } = this.props;
		let date = {
			startDate: Moment(startDate).valueOf(),
			endDate: Moment(endDate).valueOf()
		}
		this.presentor.init(date);
	}


	componentWillReceiveProps(props) {
		if (this.props.startDate !== props.startDate || this.props.endDate !== props.endDate) {
			let { startDate, endDate } = props;
			let date = {
				startDate: Moment(startDate).valueOf(),
				endDate: Moment(endDate).valueOf()
			}
			this.presentor.init(date)
			return true
		} else {
			return false
		}
	}


	componentWillUnmount() {
		this.presentor.destroy();
	}

	load = r => {
		if (r.tickets.length > 0) {
			this.setState({
				tickets: r.tickets,
				emptystate: false
			})
		} else {
			this.setState({
				emptystate: true
			})
		}
	}


	Item = (item, index) => {
		if (item.service && item.service.toLowerCase() === "hotel")
			return (
				<HotelTicketItem hide={true} data={item} key={index} />
			)
		else if (item.service && item.service.toLowerCase() === "bus")
			return (
				<BusTicketItem hide={true} data={item} key={index} />
			)
		else {
			return (
				<FlightItem hide={true} data={item} key={index} />
			)
		}
	}


	render() {
		if (this.state.emptystate) {
			return <EmptyState small={true} desc="No Past booking are found" title="No Past Booking are found" image={emptyimage} />
		}
		return (
			<div>
				<NormalTable headerStyle={{ border: 'none' }} headers={[
					{
						key: 'name',
						name: '',
						weight: ''
					},
					{
						key: 'details',
						name: '',
						weight: ''
					},
					{
						key: 'amount',
						name: '',
						weight: ''
					}
				]}
					mapItem={this.Item}
					pagination={true}
					data={this.state.tickets}
				/>
			</div>
		)
	}

}

export default View