import React from 'react'
import Search from "stringquery";
import SearchArea from './SearchArea'
import PollingBuses from "./PollingBuses";

class View extends React.Component {

	constructor(props) {
		super(props);
		this.presentor = props.presentor;
		this.presentor.setView(this)
		const params = Search(this.props.location.search);
		if (params.requestId) {
			this.state = {
				result: undefined,
				requestId: params.requestId,
				selected: "",
				filter: {
					price: {
						min: 0,
						max: 0,
						maxValue: 2000
					},
					departure: [
						{ id: 1, text: 'Before 6 am', value: "before6", isCheck: false },
						{ id: 2, text: '6 am to 12 pm', value: "6to12", isCheck: false },
						{ id: 3, text: '12 pm to 6 pm', value: "12to6", isCheck: false },
						{ id: 4, text: 'After 6 pm', value: "after5", isCheck: false },
					],
					arrival: [
						{ id: 1, text: 'Before 6 am', value: "before6", isCheck: false },
						{ id: 2, text: '6 am to 12 pm', value: "6to12", isCheck: false },
						{ id: 3, text: '12 pm to 6 pm', value: "12to6", isCheck: false },
						{ id: 4, text: 'After 6 pm', value: "after5", isCheck: false },
					],
					busType: [
						{ id: 1, text: 'Ac', value: "ac", isCheck: false },
						{ id: 2, text: 'Seater', value: "seater", isCheck: false }
					],
					type: [
						{ id: 0, item: 'RedBus', value: 'REDBUS', isActive: false },
						{ id: 1, item: 'Travel yaari', value: 'YAARI', isActive: false },


					]
				}
			}
		} else {
			this.state = {
				result: undefined,
				requestId: undefined,
				selected: "",
				filter: {
					departure: [
						{ id: 1, text: 'Before 6 am', value: "before6", isCheck: false },
						{ id: 2, text: '6 am to 12 pm', value: "6to12", isCheck: false },
						{ id: 3, text: '12 pm to 6 pm', value: "12to6", isCheck: false },
						{ id: 4, text: 'After 6 pm', value: "after5", isCheck: false },
					],
					arrival: [
						{ id: 1, text: 'Before 6 am', value: "before6", isCheck: false },
						{ id: 2, text: '6 am to 12 pm', value: "6to12", isCheck: false },
						{ id: 3, text: '12 pm to 6 pm', value: "12to6", isCheck: false },
						{ id: 4, text: 'After 6 pm', value: "after5", isCheck: false },
					],
					busType: [
						{ id: 1, text: 'Ac', value: "ac", isCheck: false },
						{ id: 2, text: 'Seater', value: "seater", isCheck: false }
					],
					type: [
						{ id: 0, item: 'RedBus', value: 'REDBUS', isActive: false },
						{ id: 1, item: 'Travel yaari', value: 'YAARI', isActive: false },


					],
					price: {
						min: 0,
						max: 0,
						maxValue: 2000
					}
				}
			}
		}

	}

	load = result => {
		if (result.polling) {
			setTimeout(() => {
				this.presentor.search(this.props.match.params.searchId)
			}, 1000)
			this.setState({
				...this.state,
				result
			})
		} else {
			let maxValue = 0;
			result.items.forEach(item => {
				if (item.price > maxValue)
					maxValue = item.price;
			})
			this.setState({
				...this.state,
				result,
				filter: {
					...this.state.filter,
					price: {
						min: 0,
						max: maxValue,
						maxValue
					}
				}
			})
		}
	}


	_setHighlighted = (id) => {
		this.setState({
			...this.state,
			selected: id
		})
	}

	componentDidMount() {
		const params = Search(this.props.location.search);
		if (params.requestId) {
			this.presentor.getRequest(params.requestId)
		}
		this.presentor.search(this.props.match.params.searchId)
	}

	componentWillUnmount() {
		this.presentor.destroy();
	}

	filter = data => {
		this.setState({
			...this.state,
			filter: {
				...this.state.filter,
				...data
			}
		})
	}


	render() {
		const params = Search(this.props.location.search);
		return (
			<div className="fintrip-container" style={{
				display: 'flex',
				flexDirection: 'row',
				flex: 1
			}}>
				<PollingBuses filter={this.state.filter} changeFilter={this.filter} />
				<SearchArea sessionId={params.sessionId}
					searchId={this.props.match.params.searchId}
					postSelection={this.presentor.postSelection}
					requestId={this.state.requestId}
					selected={this.state.selected}
					filter={this.state.filter}
					history={this.props.history}
					result={this.state.result} />
			</div>
		)
	}

}

export default View