import React from 'react';
import {Redirect} from 'react-router-dom'

class View extends React.Component {

    render() {
        return (
            <Redirect to="/"/>
        )
    }

}

export default View;