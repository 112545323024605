import React from 'react'
import Search from 'stringquery';
import { Modal } from "../../../../libs/Modal/Modal";
import _ from "lodash";
import Moment from "moment";
import NoBus from "../NoHotels";
import { FlightItem } from "./FlightItem";
import { KeyValue } from "../../../Common/KeyValue";
import { showToast } from '../../../../libs/Toast/ToastCallback';
import SortArea from "./SortArea";


class index extends React.Component {

	state = {
		open: undefined,
		selected: [],
		sort: {
			type: 'price',
			up: true
		}
	}

	onOpen = flight => {
		this.setState({
			...this.state,
			open: flight
		})
	}

	_sendSelections = () => {
		let result = this.props.result;
		let selected = this.state.selected;
		if (this.state.selected.length > 0) {
			let selections = []
			result.items.forEach(item => {
				if (_.includes(selected, `${item.flightType}|${item.key}`)) {
					let departure = Moment(Number(item.segments[0].departureTime)).format('hh:mm A');
					let arrival = Moment(item.segments.length > 1 ? Number(item.segments[item.segments.length - 1].arrivalTime) : Number(item.segments[0].arrivalTime)).format('hh:mm A');
					let origin = item.segments[0].origin.cityName;
					let destination = item.segments.length > 1 ? item.segments[item.segments.length - 1].destination.cityName : item.segments[0].destination.cityName;

					selections.push({
						image: item.logo,
						airline: item.airline,
						key: item.key,
						price: item.fare.publishedFare,
						departureTime: departure,
						arrivalTime: arrival,
						origin,
						destination,
					})
				}
			})
			let requestId = this.props.requestId;
			let data = {};
			let params = Search(this.props.history.location.search);
			if (params.type === 'return') {
				data = {
					selections,
					type: 'return'
				}
			} else {
				data.selections = selections;
			}
			this.props.postSelection(requestId, data)


			this.setState({
				...this.state,
				selectionModal: false
			})
		}
	}

	timeLogic = (val, time) => {
		let format = 'hh:mm A';
		let sixAM = Moment('06:00 AM', format);
		let twelveless = Moment('11:59PM', format);
		let twelveAM = Moment('12:00 AM', format);
		let sixPM = Moment('06:00 PM', format);
		let twelvePM = Moment("12:00 PM", format);

		switch (val) {
			case 'before6':
				return Moment(time, 'hh:mm A').isBetween(Moment(twelveAM, 'hh:mm A'), Moment(sixAM, 'hh:mm A'));
			case '6to12':
				return Moment(time, 'hh:mm A').isBetween(Moment(sixAM, 'hh:mm A'), Moment(twelvePM, 'hh:mm A'), null, []);
			case '12to6':
				return Moment(time, 'hh:mm A').isBetween(Moment(twelvePM, 'hh:mm A'), Moment(sixPM, 'hh:mm A'), null, []);
			case 'after5':
				return Moment(time, 'hh:mm A').isBetween(Moment(sixPM, 'hh:mm A'), Moment(twelveless, 'hh:mm A'));
			default:
				return false
		}
	}

	handleStop = (text, segments) => {

		switch (text) {
			case 'Non Stop':
				return segments.length === 1
			case 'Stop':
				return segments.length > 1
			default:
				return true
		}

	}




	filterData = (items) => {
		let filter = this.props.filter;

		let isChecked = 0;
		let isDeparture = 0;
		let isArrival = 0;
		let isBadge = 0;

		let checkFlightStatus = () => {
			filter.airLines.forEach(val => {
				if (val.isCheck) {
					isChecked++
				}
			})
		}

		let checkDepartureStatus = () => {
			filter.departure.forEach(val => {
				if (val.isCheck) {
					isDeparture++;
				}
			})
		}

		let checkArrivalStatus = () => {
			filter.arrival.forEach(val => {
				if (val.isCheck) {
					isArrival++;
				}
			})
		}

		let checkBadgeStatus = () => {
			filter.badge.forEach(val => {
				if (val.isCheck) {
					isBadge++;
				}
			})
		}

		checkFlightStatus();
		checkDepartureStatus();
		checkArrivalStatus();
		checkBadgeStatus();

		return items.filter(val => {
			let isAvail = true;
			let departure = Moment(Number(val.segments[0].departureTime)).format('hh:mm A');
			let arrival = Moment(val.segments.length > 1 ? Number(val.segments[val.segments.length - 1].arrivalTime) : Number(val.segments[0].arrivalTime)).format('hh:mm A');
			if (val.fare.publishedFare < filter.price.min || val.fare.publishedFare > filter.price.max)
				isAvail = false;


			if (isAvail) {
				let isCheck1 = false;
				if (isDeparture > 0) {
					filter.departure.forEach(fill => {
						if (fill.isCheck && this.timeLogic(fill.value, departure)) {
							isCheck1 = true
						}
						isAvail = isCheck1;
					})
				}
			}

			if (isAvail) {
				let isCheck2 = false;
				if (isArrival > 0) {
					filter.arrival.forEach(fill => {
						if (fill.isCheck && this.timeLogic(fill.value, arrival)) {
							isCheck2 = true
						}
						isAvail = isCheck2;
					})
				}
			}

			if (isAvail) {
				let count = 0
				filter.stop.forEach(fill => {
					if (fill.isCheck) {
						count++;
						let result = this.handleStop(fill.text, val.segments);
						isAvail = result
					}
				})
				if (count === 2) {
					isAvail = true;
				}
			}

			if (isAvail) {
				let isFlight = false;
				if (isChecked > 0) {
					filter.airLines.forEach(fill => {
						if (fill.isCheck && fill.value === val.airline) {
							isFlight = true
						}
						isAvail = isFlight
					})
				}
			}

			if (isAvail) {
				let badge = false;
				if (isBadge > 0) {
					filter.badge.forEach(fill => {
						if (fill.isCheck && fill.value === val.badge) {
							badge = true
						}
						isAvail = badge
					})
				}
			}


			return isAvail;
		})



	}

	_onSort = type => {
		let current = this.state.sort;
		if (current.type === type) {
			this.setState({
				...this.state,
				sort: {
					type,
					up: !current.up
				}
			})
		} else {
			this.setState({
				...this.state,
				sort: {
					type,
					up: true
				}
			})
		}
	}

	_sortResult = (filter) => {
		let sort = this.state.sort;
		if (sort.type === "price") {
			if (sort.up) {
				return filter.sort((a, b) => {
					return parseFloat(a.fare.publishedFare) - parseFloat(b.fare.publishedFare)
				})
			} else {
				return filter.sort((a, b) => {
					return parseFloat(b.fare.publishedFare) - parseFloat(a.fare.publishedFare)
				})
			}
		} else if (sort.type === "departure") {
			if (sort.up) {
				return filter.sort((a, b) => {
					let aDeparture = parseInt(a.segments[0].departureTime, 10);
					let bDeparture = parseInt(b.segments[0].departureTime, 10);
					return aDeparture - bDeparture
				})
			} else {
				return filter.sort((a, b) => {
					let aDeparture = parseInt(a.segments[0].departureTime, 10);
					let bDeparture = parseInt(b.segments[0].departureTime, 10);
					return bDeparture - aDeparture
				})
			}
		} else if (sort.type === "arrival") {
			if (sort.up) {
				return filter.sort((a, b) => {
					let aArrival = parseInt(a.segments[a.segments.length - 1].arrivalTime, 10);
					let bArrival = parseInt(b.segments[b.segments.length - 1].arrivalTime, 10);
					return aArrival - bArrival
				})
			} else {
				return filter.sort((a, b) => {
					let aArrival = parseInt(a.segments[a.segments.length - 1].arrivalTime, 10);
					let bArrival = parseInt(b.segments[b.segments.length - 1].arrivalTime, 10);
					return bArrival - aArrival
				})
			}
		} else if (sort.type === "duration") {
			if (sort.up) {
				return filter.sort((a, b) => {
					let aDeparture = parseInt(a.segments[0].departureTime, 10);
					let bDeparture = parseInt(b.segments[0].departureTime, 10);
					let aArrival = parseInt(a.segments[a.segments.length - 1].arrivalTime, 10);
					let bArrival = parseInt(b.segments[b.segments.length - 1].arrivalTime, 10);
					return (aArrival - aDeparture) - (bArrival - bDeparture)
				})
			} else {
				return filter.sort((a, b) => {
					let aDeparture = parseInt(a.segments[0].departureTime, 10);
					let bDeparture = parseInt(b.segments[0].departureTime, 10);
					let aArrival = parseInt(a.segments[a.segments.length - 1].arrivalTime, 10);
					let bArrival = parseInt(b.segments[b.segments.length - 1].arrivalTime, 10);
					return (bArrival - bDeparture) - (aArrival - aDeparture)
				})
			}
		}
		return filter;
	}

	render() {
		let filter = this.filterData(this.props.result.items);
		filter = this._sortResult(filter);
		if (this.props.selected) {
			filter.forEach((val, index) => {
				if (val.key === this.props.selected) {
					let item = null;
					item = filter[0];
					filter[0] = filter[index];
					filter[index] = item;
				}
			})
		}
		let request = this.props.request;
		return (
			<div>
				<div style={{ height: 60, position: 'relative' }}>
					<div style={{
						background: 'white',
						height: 60,
						position: 'absolute',
						top: 7,
						left: 0,
						zIndex: 11,
						paddingLeft: 12,
						right: 0,
						display: 'flex',
						alignItems: 'center',
						flexDirection: 'row',
						borderBottom: '1px solid #cdcdcd'
					}}>
						<KeyValue style={{
							marginRight: 18,
							flex: 1
						}} title="Origin" value={request.route.fromCode} />
						<KeyValue style={{
							marginRight: 18,
							flex: 1
						}} title="Destination" value={request.route.toCode} />
						<KeyValue style={{
							marginRight: 18,
							flex: 1
						}} title="Adults" value={`${request.counts} Adults`} />
						<KeyValue style={{
							marginRight: 18,
							flex: 1
						}} title="Date" value={request.date} />
						<p onClick={() => {
							if (this.state.selected.length === 0)
								this.props.history.goBack()
							else {
								this.setState({
									...this.state,
									selectionModal: true
								})
							}
						}} style={{
							fontWeight: 600,
							height: 45,
							margin: 4,
							width: 300,
							textAlign: 'center',
							color: "white",
							borderRadius: this.state.selected.length > 0 ? 22 : 0,
							background: this.state.selected.length > 0 ? "#7558F6" : "#6376FA",
							padding: '12px 18px',
							fontSize: 15,
							letterSpacing: '1px',
							textTransform: 'uppercase',
							lineHeight: '20px',
							cursor: 'pointer'
						}}>
							{
								this.state.selected.length > 0 ? `Send ${this.state.selected.length} Selections` : "Reset Search Criteria"
							}
						</p>
					</div>
				</div>
				<Modal

					buttonName="Send Options"
					des="By clicking on button below selected option will be sent to employee."
					style={{
						save: {
							width: '100%',
							textAlign: 'center',
							fontSize: 17,
							padding: "12px 20px",
							background: "#7558F6",
							border: "1px solid #7558F6"
						},
					}} show={this.state.selectionModal} onSave={this._sendSelections} title="Send Selection Options" close={() => {
						this.setState({
							...this.state,
							selectionModal: false
						})
					}}>
					<div>
						{
							this.props.result.items.map(i => {
								let departure = Moment(Number(i.segments[0].departureTime)).format('hh:mm A');
								let arrival = Moment(i.segments.length > 1 ? Number(i.segments[i.segments.length - 1].arrivalTime) : Number(i.segments[0].arrivalTime)).format('hh:mm A');
								let origin = i.segments[0].origin.cityName;
								let destination = i.segments.length > 1 ? i.segments[i.segments.length - 1].destination.cityName : i.segments[0].destination.cityName;

								if (_.includes(this.state.selected, `${i.flightType}|${i.key}`)) {
									return (
										<div key={departure} style={{
											display: 'flex',
											height: 60,
											marginBottom: 8,
											alignItems: 'center',

										}}>
											<img src={i.logo} alt="" style={{ width: 40, height: 40 }} />
											<div style={{
												flex: 1,
												marginLeft: 10
											}}>
												<p style={{
													fontSize: 15,
													fontWeight: 600,
													color: "#454545"
												}}>
													{i.airline}
												</p>
												<p style={{
													fontSize: 12,
													color: '#898989'
												}}>{origin} - {destination}</p>
												<p style={{
													fontSize: 12,
													color: '#898989'
												}}>{departure} - {arrival}</p>
											</div>
										</div>
									)
								}
								return null;
							})
						}
					</div>
				</Modal>
				<div style={{ height: 'calc( 100vh - 60px)', overflowY: 'auto' }}>
					<SortArea onSort={this._onSort} sort={this.state.sort} />
					{
						filter.length > 0 ? <div style={{
							paddingBottom: 20,
							display: 'flex',
							flexWrap: 'wrap'
						}}>
							{
								filter.map(i => <FlightItem
									{...this.props}
									preferred={this.props.selected}
									selected={this.state.selected}
									onSelect={this._onSelect}
									requestId={this.props.requestId}
									history={this.props.history}
									searchId={this.props.searchId}
									sessionId={this.props.sessionId}
									open={this.state.open}
									onOpen={this.onOpen}
									item={i}
									key={i.flightCode}  />)
							}
						</div> : <NoBus {...this.props} />}
				</div>
			</div>
		)
	}

	_onSelect = code => {
		let selected = this.state.selected;



		if (_.includes(selected, code)) {
			selected = selected.filter(value => value !== code);
		} else {
			if (selected.length > 4) {
				showToast({
					type: 'error',
					message: 'message:"Only 5 selection is possible.'
				})
			} else {
				selected.push(code)
			}


		}



		this.setState({
			...this.state,
			selected
		})


	}

}

export default index
