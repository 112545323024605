import React, { useState } from 'react'
import DownArrow from "./down-arrow.png"
import {v4} from 'uuid'
import _ from 'lodash'
import { ShownSelect2 } from '../../../libs/Select/index4'

function index(props) {
    let [suggestions,setSuggestions] = useState([])
	let FILTER = React.createRef();

    let addFilter = () => {
		let value = FILTER.current.value;
		if (value) {
			FILTER.current.value = "";
			props.addFilter(value)
		}
	}

    let onChange = e => {
		let value = (FILTER.current.value).toLowerCase();
		if (value) {
			let key = props.type.autoPopulate ? props.type.autoPopulate : props.type.value;
			// let suggestions = props.suggestion ? props.suggestion[key] ? props.suggestion[key] : [] : [];
            let suggestion = [];
			props.data.forEach(item => {
                switch(key){
                    case "NAME":
                        if(item.employee.name.toLowerCase().includes(value) && !_.includes(suggestion, item.employee.name)){
                            suggestion.push(item.employee.name)
                        }
                        break;
                    case "EMPCODE":
                        if(item.employee.code.toLowerCase().includes(value) && !_.includes(suggestion, item.employee.code)){
                            suggestion.push(item.employee.code)
                        }
                        break;
                    case "DESTINATION":
                        if(item.destinationName && item.destinationName.toLowerCase().includes(value) && !_.includes(suggestion, item.destinationName)){
                            suggestion.push(item.destinationName)
                        }
                        break;
                    case "TRIPID":
                        if(item.tripId && item.tripId.toLowerCase().includes(value) && !_.includes(suggestion, item.tripId)){
                            suggestion.push(item.tripId)
                        }
                        break;
                    default:
                        break;
                }
			})
            console.log('suggestion', suggestion)
			setSuggestions(suggestion)
		}
	}

    let handleEnter = (e) => {
		let value = FILTER.current.value;
		if (e.key === "Enter") {
			if (value) {
				FILTER.current.value = "";
                setSuggestions([])
				props.addFilter(value)
			}
		}
	}

    let suggestionItem = item => {
        console.log('item', item)
		return (
			<div key={v4()}>
				<p onClick={() => {
					FILTER.current.value = item;
					props.addFilter(FILTER.current.value);
					FILTER.current.value = "";
					setSuggestions( [])
				}} className="hover-background" style={{
					padding: '10px 15px',
					borderBottom: '1px solid var(--border-color)',
					fontSize: 15,
					cursor: 'pointer',
					fontWeight: 400
				}}>
					{item}
				</p>
			</div>
		)
	}
    console.log('suggestions', suggestions)
    return (
        <div style={props.containerStyle1}>
            <div style={{
                marginTop: 12,
                marginBottom: 10,
                display: 'flex',
                flexDirection: 'row', ...props.containerStyle
            }}>
                <div style={{ width: 135, position: 'relative' }}>
                    <ShownSelect2 inputStyle={{ textAlign: 'left', zIndex: 1 }} placeholder="Select Filter Type"
                        items={props.types} select={type => {
                            setSuggestions([])
                            FILTER.current.value = "";
                            props.onTypeChange(type)
                        }} value={props.type} />
                    <img src={DownArrow} alt="down-arrow" style={{
                        width: 14, height: 14,
                        position: 'absolute', right: 12,
                        top: 12,
                        zIndex: 0
                    }} />
                </div>
                <div style={{ width: 250, position: 'relative' }}>
                    <input onKeyPress={handleEnter} onChange={onChange}
                        ref={FILTER} style={{
                            border: '1px solid var(--border-color)',
                            height: 45,
                            width: 250,
                            color: "var(--text-color)",
                            borderLeft: 'none',
                            fontSize: 13,
                            padding: '9px 10px',
                        }} type="text" placeholder={`Filter ${props.type.label.toLowerCase()}`} />
                    {suggestions.length > 0 ? <div style={{
                        width: 250,
                        border: '1px solid var(--border-color)',
                        position: 'absolute',
                        top: 45,
                        right: -1,
                        left: -1,
                        zIndex: 11,
                        maxHeight: 225,
                        overflowY: 'auto',
                        backgroundColor: 'var(--white-color)',
                        background: 'white'
                    }}>
                        {
                            suggestions.map(suggestionItem)
                        }
                    </div> : null}
                </div>
                <div style={{
                    display: 'flex'
                }}>
                    <p onClick={addFilter} style={{
                        height: 44,
                        fontSize: 13,
                        padding: '12px 20px',
                        marginLeft: 8,
                        width: 100,
                        marginTop: 1
                    }} className="btn btn-portlet-invert">
                        ADD
                    </p>
                </div>
            </div>
            <div style={{ marginBottom: 20, ...props.selectFilter }}>
                {
                    props.filters.map(item => (
                        <span key={`${item.type}::${item.value}`} className="btn btn-portlet-invert"
                            style={{ marginRight: 5, display: 'inline', height: 20, padding: '4px 10px' }}>
                            <span onClick={() => {
                                props.removeFilter(item)
                            }} style={{
                                marginRight: 5,
                                marginTop: 4,
                                fontSize: 13,
                                cursor: 'pointer'
                            }}>×</span>
                            {item.type}: {item.value}
                        </span>
                    ))
                }
            </div>
        </div>
    )
}

export default index