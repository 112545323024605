import React from 'react'
import SearchComponent from './SearchComponent'
import SearchArea from './SearchArea'
import Search from "stringquery";

class View extends React.Component {

	constructor(props) {
		super(props);
		this.presentor = props.presentor;
		this.presentor.setView(this)
		const params = Search(this.props.location.search);
		if (params.requestId) {
			this.state = {
				result: undefined,
				requestId: params.requestId,
				selected: ""
			}
		} else {
			this.state = {
				result: undefined,
				requestId: undefined,
				selected: ""
			}
		}

	}

	componentDidMount() {
		const params = Search(this.props.location.search);
		if (params.requestId) {
			this.presentor.getRequest(params.requestId)
		}
	}

	load = result => {
		const params = Search(this.props.location.search);
		var url = ""
		if (params.requestId) {
			url = `/app/hotel/search/polling/${result.searchId}?requestId=${params.requestId}&sessionId=${result.sessionId}`
		} else {
			url = `/app/hotel/search/polling/${result.searchId}?sessionId=${result.sessionId}`
		}
		this.props.history.push(url)
	}

	componentWillUnmount() {
		this.presentor.destroy();
	}



	render() {
		return (
			<div className="fintrip-container" style={{
				display: 'flex',
				flexDirection: 'row',
				flex: 1
			}}>
				<SearchComponent loader={r => this._loader = r} location={this.props.location} search={data => {
					this.presentor.search(data)
				}} />
				<SearchArea />
			</div>
		)
	}

}

export default View