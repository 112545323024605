import React from "react";
import Moment from 'moment';

class View extends React.Component {


	render() {
		const { data } = this.props;
		let { message } = data;
		let time = Moment(data.createdAt).format('hh:mm A');

		if (this.props.hidden && !this.props.date) {
			return (
				<div className="luna-chat" style={{ padding: "4px 2px", paddingLeft: 56, display: 'flex', alignItems: 'center', maxWidth: 420 }}>
					<p style={{
						fontSize: 13,
						color: 'var(--sidebar-active-color)'
					}}>{message.title}</p>
					<p className="hidden" style={{
						marginLeft: 8, fontSize: 8, color: 'var(--inactive-color)', cursor: 'pointer', paddingTop: 3
					}}>{time}</p>
				</div>
			)
		}
		return (
			<div>
				{this.props.date ?
					<div style={{ display: 'flex', flex: 1,  }}>
						<div style={{  flex: 1, display: 'flex', justifyContent: 'center', margin: '0px -40px' }}>
							<p style={{
								padding: '5px 10px',
								background: 'var(--primary-color)',
								color: 'var(--sidebar-active-color)',
								position: 'relative',
								top: 5,
								fontWeight: 600,
								fontSize: 11
							}}>{Moment(data.createdAt).format('dddd')},{Moment(data.createdAt).format('MMMM')},{Moment(data.createdAt).format('DD')}</p>
						</div>
					</div>
					: <div />
				}
				<div className="luna-chat" style={{ flexDirection: 'row', display: 'flex', cursor: 'pointer', padding: '5px 0px', maxWidth: 440 }}>
					<img style={{
						width: 36,
						height: 36,
						marginRight: 5,
						objectFit: 'cover',
						marginTop: 5,
						marginLeft: 8,
						borderRadius: 4,
					}} src={data.sendor ? data.sendor.image : ""} className="chat-icon" alt="s" />
					<div style={{ width: '100%' }}>
						<div style={{
							display: 'flex'
						}}>
							<span style={{ color: "var(--inactive-color)", fontSize: 15, marginLeft: 6, fontWeight: 700 }}>{data.sendor ? data.sendor.name : ""}</span>
							<div style={{ marginLeft: 8, fontSize: 11, color: 'var(--inactive-color)', cursor: 'pointer', paddingTop: 5 }}>{time}</div>
						</div>
						<div style={{
							padding: "2px 6px"
						}} className="notifications">
							<p className="t" style={{
								fontSize: 13,
								color: 'var(--sidebar-active-color)'
							}}>{message.title}</p>
						</div>
					</div>
				</div>
			</div>
		)
	}

}

export default View;
