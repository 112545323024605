import React from 'react';
import Moment from 'moment';


class CenterImageFormCard extends React.Component {


    render() {
        let data = this.props.data;
        let content = data.message;


        return (
            <div>
                {this.props.date ?
                    <div style={{ display: 'flex', flex: 1, }}>
                        <div style={{ flex: 1, display: 'flex', justifyContent: 'center', margin: '0px -40px' }}>
                            <p style={{
                                padding: '5px 10px',
                                background: 'var(--primary-color)',
                                color: 'var(--sidebar-active-color)',
                                position: 'relative',
                                top: 5,
                                fontWeight: 600,
                                fontSize: 15
                            }}>{Moment(data.createdAt).format('dddd')},{Moment(data.createdAt).format('MMMM')},{Moment(data.createdAt).format('DD')}</p>
                        </div>
                    </div>
                    : <div />
                }
                <div style={{ flex: 1, paddingLeft: 12, paddingRight: 12, marginTop: 20, marginBottom: 20, display: 'flex', maxWidth: 250 }}>
                    <div>
                        <img src={data.sendor.image} alt="" style={{ width: 40, height: 40, objectFit: 'contain', marginRight: 10 }} />
                    </div>
                    <div style={{ width: 250 }}>
                        <p style={{ fontSize: 13, fontWeight: 600, color: 'var(--sidebar-active-color)' }}>{data.sendor.name}</p>
                        <div style={{ padding: 12, paddingLeft: 8, paddingRight: 8, marginTop: 6, border: '1px solid var(--border-color)', maxWidth: 420 }}>
                            <p style={{ fontSize: 16, fontWeight: 700, color: content.color, letterSpacing: 0.5 }}>{content.title}</p>
                            <p style={{ fontSize: 13, marginTop: 3, color: 'var(--sidebar-active-color)' }}>{content.des}</p>
                            <img src={content.image ? content.image : ""} alt="" style={{
                                objectFit: 'contain',
                                width: 200,
                                marginTop: 8,
                                marginBottom: 8,
                                height: 120
                            }} />
                            {
                                content.forms ?
                                    content.forms.map((item, index) => {
                                        return (
                                            <div style={{ display: 'flex', paddingTop: 3, paddingBottom: 3, fontSize: 13 }} key={index}>
                                                <p style={{ color: 'var(--inactive-color)', width: 90, fontSize: 12 }}>{item.key}</p>

                                                <p style={{ marginLeft: 12, fontWeight: 600, color: 'var(--sidebar-active-color)' }}>{item.value}</p>
                                            </div>
                                        )
                                    }) : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export { CenterImageFormCard };
