import React from 'react'
import ShimmerArea from './ShimmerArea'
import NoHotels from './NoHotels'
import FlightArea from './FlightArea'


class SearchArea extends React.Component {


	render() {
		let render = null;
		let data = this.props.result;
		if(!data || data.polling){
			render  = (<ShimmerArea/>)
		}else{
			if(data.items.length === 0)
				render = (<NoHotels {...this.props}/>)
			else
				render = (<FlightArea  request={data.request}  {...this.props}/>)

		}
		return (
			<div style={{
				flex: 1,
				height: '100vh',
				overflowY: 'auto'
			}}>
				<div style={{
				}}>
					{
						render
					}
				</div>
			</div>
		)
	
	}
}

export default SearchArea