let toastCallback = undefined;

const addToastCallback = callback =>{
	toastCallback = callback
}

const showToast = item =>{
	if(toastCallback)
		toastCallback(item)
	else{
		alert(item.message)
	}
}

const removeCallback =() =>{
	toastCallback = undefined;
}

export {
	addToastCallback,showToast,removeCallback
}