import {post} from "../../Model/Network/Config/Axios";

class Model {

	sendLink = (data,cb) => {
		post(`/auth/reset`,data,cb)
	}

}

export default Model;