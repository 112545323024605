import React from 'react'

import { Sidebar } from "../Common/Sidebar";
import ActiveSearchIcon from './assets/search.png'
import InActiveSearchIcon from './assets/search1.png'
import ActiveRefundIcon from "../Hotels/assets/icon1.png";
import InactiveRefundIcon from "../Hotels/assets/icon1a.png";
import ActiveWorkflowIcon from './assets/icon5a.png'
import InactiveWorkflowIcon from './assets/icon5.png'

import { Route, Switch } from 'react-router-dom'

import Employees from './Emplyees'
import Guests from './Guests'
import EmployeeDetails from './EmployeeDetails'
import GuestDetails from './GuestDetails'
import Issue from '.././CommonPages/Issue/index'
import Visa from './Visa';


class index extends React.Component {

	render() {
		return (
			<div id="fintrip-container" style={{
				flex: 1
			}}>
				<Sidebar history={this.props.history} location={this.props.location} title="Travellers" data={[
					{
						title: "Overview",
						items: [
							{
								title: "Employees",
								inactive: ActiveSearchIcon,
								active: InActiveSearchIcon,
								path: "/app/travellers"
							},
							{
								title: "Guest",
								active: ActiveRefundIcon,
								inactive: InactiveRefundIcon,
								path: "/app/travellers/guest",
								style: {
									width: 42,
									height: 42,
									marginLeft: -8,
									marginRight: 8,
									marginBottom: -6,
									marginTop: -6
								}
							},
							{
								title: 'Visa Request',
								active: InactiveWorkflowIcon,
								inactive: ActiveWorkflowIcon,
								path: "/app/travellers/guest/visa",
								style: {
									width: 42,
									height: 42,
									marginLeft: -8,
									marginRight: 8,
									marginBottom: -6,
									marginTop: -6
								}
							}
						]
					},
				]} />
				<div style={{ flex: 1, overflowY: 'auto' }}>
					<Switch>
						<Route path="/app/travellers" exact component={Employees} />
						<Route path="/app/travellers/guest" exact component={Guests} />
						<Route path="/app/travellers/guest/visa" exact component={Visa} />
						<Route path="/app/travellers/employee/:code" exact component={EmployeeDetails} />
						<Route path="/app/travellers/guest/:id" exact component={GuestDetails} />
						<Route pat="/app/travellers/issue" component={Issue} />
					</Switch>
				</div>
			</div>
		)
	}

}

export default index
