import React from 'react';
import {Link} from 'react-router-dom';

const BusRefund = ({ item }) => {
    return (
        <div className="border-bottom" style={{ padding: 10,flex:1 }}>
            <div className="linear-layout horizontal">
                <div style={{ flex: 1 }}>
                    <p style={{ fontWeight: 600, fontSize: 18 }}>{item.companyName}</p>
                    <p style={{ fontSize: 12, color: '#7f7f7f', marginTop: 5 }}>{item.busName}</p>
                </div>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column',alignItems:'center' }}>
                    <p style={{ fontWeight: 600 }}>{item.traveller.name}</p>
                    <p style={{ marginTop: 5, color: '#7f7f7f', fontSize: 14 }}>{item.pnrNo}</p>
                    <p style={{ fontSize: 12, color: '#7f7f7f' }}>{item.date}</p>
                </div>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column',alignItems:'flex-end' }}>
                    <p style={{
                        color: '#7f7f7f'
                    }}>Amount ₹{item.amount}</p>
                    <p style={{
                        marginTop: 5
                    }}>Refund Amount ₹{item.refundAmount}</p>
                    <Link to={`/app/bus/tickets/${item.transactionId}`} className="btn btn-portlet" style={{
                        color: '#fff',
                        fontSize: 9,
                        height: 25,
                        cursor: 'pointer',
                        width: 100,
                        padding: '4px 6px',
                        borderRadius: '18px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 8
                    }}>View Details</Link>
                </div>
            </div>
        </div>
    )
}

export { BusRefund }
