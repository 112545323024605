import React from 'react'
import Props from 'prop-types'

const PAGECOUNT = 10;

class NormalTable extends React.Component {

	state={
		page:0,
		sortType: undefined
	}

	_headerItem = (item) =>{
		if(item.type==="check"){
			return(
				<div key={item.key} style={{width:40,alignItems:'center',display:'flex'}}>
					<div onClick={item.click} style={{width:16,height:16,border:'1px solid #cccccc',cursor:'pointer'}}>
						<div style={item.checked?{
							background:"#0062C5",
							width:12,
							height:12,
							margin:1
						}:{}}/>
					</div>
				</div>
			)
		}
		return(
			<div key={item.key} style={item.width?{width:item.width}:item.weight?{flex:item.weight}:{flex:1}}>
				<p className="fintrip-table-header-title" style={{...item.style,display:'flex'}}>
					{item.name}
					{item.sort? <div onClick={()=>{
						let sortType = this.state.sortType?this.state.sortType==="asc"? "desc":"asc"  :"asc"
						let obj  = {
							...this.state,
							sortType
						}
						this.setState(obj);
						this.props.sort(sortType);
					}} style={{display:'flex',flexDirection:'column',marginLeft:'5px',justifyContent:'center'}}>
						
					<div style={{fontSize:'8px',cursor:'pointer'}}>{(!this.state.sortType || this.state.sortType === "asc")? '▲':" "}</div>
					
					<div style={{fontSize:'8px',cursor:'pointer'}}>{(!this.state.sortType || this.state.sortType === "desc")? '▼':" "}</div></div>:null}
				</p>
			</div>
		)
	}
	
	pagination=()=>{
		let start = this.state.page * PAGECOUNT + 1;
		let end = this.state.page * PAGECOUNT + PAGECOUNT;
		let length = this.props.data.length
		if(end>length)
			end = length;
		if(start>length)
			start = length;
		return(
			<div className="fintrip-table-footer">
				<img style={{cursor:'pointer'}}  onClick={()=>{
					let page = this.state.page;
					if((start+PAGECOUNT)<=(length)){
						this.setState({
							page:page+1
						})
					}
				}} alt="left arrow" className="right-arrow" src={require('./right-arrow (2).png')}/>
				<img style={{cursor:'pointer'}} onClick={()=>{
					let page = this.state.page;
					if(page>0){
						this.setState({
							page:page-1
						})
					}
				}} alt="right arrow" className="left-arrow" src={require('./left-arrow (1).png')}/>
				<p>
					{start} - {end} of {length} results
				</p>
			</div>
		)
	}


	render() {
		let data = this.props.data?this.props.data:[];
		if(this.props.pagination){
			let start = this.state.page * PAGECOUNT;
			let end = this.state.page * PAGECOUNT + PAGECOUNT;
			data = data.slice(start,end)
		}
		return (
			<div className={`fintrip-table ${this.props.portlet?'portlet-table':""}`}>
				<div className="fintrip-table-header" style = {{...this.props.headerStyle}}>
					{this.props.headers.map(this._headerItem)}
				</div>
				<div className="fintrip-table-body">
					{data.map(this.props.mapItem)}
					{data.length===0?<p style={{padding:18,textAlign:'center'}}>No data found to show.</p>:null}
				</div>
				{
					this.props.pagination?this.pagination():null
				}
			</div>
		)
	}

}

NormalTable.propTypes = {
	headers:Props.array.isRequired,
	mapItem:Props.func,
	data:Props.array.isRequired,
	portlet:Props.bool
}

NormalTable.defaultProps = {
	portlet : false
}

export {
	NormalTable
}