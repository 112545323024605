import React from 'react'
import _ from "lodash";
import { Modal } from "../../../libs/Modal/Modal";
import BusItem from "./BusItem";
import { showToast } from "../../../libs/Toast/ToastCallback";
import { KeyValue } from "../../Common/KeyValue";
import NoBus from './NoBus'
import Moment from 'moment';
import SortArea from './SortArea';

class BusesArea extends React.Component {



	state = {
		open: undefined,
		selected: [],
		sort: {
			type: 'price',
			up: true
		}

	}

	onOpen = open => {
		this.setState({
			open
		})
	}

	_sendSelections = () => {
		let result = this.props.result;
		let selected = this.state.selected;
		if (this.state.selected.length > 0) {
			let selections = []
			result.items.forEach(item => {
				if (_.includes(selected, `${item.busType}|${item.tripId}`)) {
					selections.push({
						name: item.name,
						tripId: item.tripId,
						busType: item.busType,
						busLabel: item.busLabel,
						companyName: item.companyName,
						price: item.price,
					})
				}
			})
			let requestId = this.props.requestId;
			this.props.postSelection(requestId, selections)
			this.setState({
				...this.state,
				selectionModal: false
			})
		}
	}

	timeLogic = (val, time) => {
		let format = 'hh:mm A';
		let sixAM = Moment('06:00 AM', format);
		let twelveless = Moment('11:59PM', format);
		let twelveAM = Moment('12:00 AM', format);
		let sixPM = Moment('06:00 PM', format);
		let twelvePM = Moment("12:00 PM", format);

		switch (val) {
			case 'before6':
				return Moment(time, 'hh:mm A').isBetween(Moment(twelveAM, 'hh:mm A'), Moment(sixAM, 'hh:mm A'));
			case '6to12':
				return Moment(time, 'hh:mm A').isBetween(Moment(sixAM, 'hh:mm A'), Moment(twelvePM, 'hh:mm A'), null, []);
			case '12to6':
				return Moment(time, 'hh:mm A').isBetween(Moment(twelvePM, 'hh:mm A'), Moment(sixPM, 'hh:mm A'), null, []);
			case 'after5':
				return Moment(time, 'hh:mm A').isBetween(Moment(sixPM, 'hh:mm A'), Moment(twelveless, 'hh:mm A'));
			default:
				return false
		}
	}

	filterData = (item) => {
		let filter = this.props.filter;


		let isDeparture = 0;
		let isArrival = 0;

		let isType = 0;

		let checkTypeStatus = () => {
			filter.type.forEach(val => {
				if (val.isActive) {
					isType++;
				}
			})
		}

		checkTypeStatus();



		let checkDepartureStatus = () => {
			filter.departure.forEach(val => {
				if (val.isCheck) {
					isDeparture++;
				}
			})
		}

		let checkArrivalStatus = () => {
			filter.arrival.forEach(val => {
				if (val.isCheck) {
					isArrival++;
				}
			})
		}

		checkDepartureStatus();
		checkArrivalStatus();



		return item.filter(val => {
			let isAvail = true;
			let departure = Moment(val.departure).format('hh:mm A');
			let arrival = Moment(val.arrival).format('hh:mm A');

			//console.log(Moment(demotime,'hh:mm A').isBetween(Moment(sixPM,'hh:mm A'),Moment(twelveAM,'hh:mm A')));

			if (val.price < filter.price.min || val.price > filter.price.max)
				isAvail = false;

			if (isAvail) {
				let isCheck1 = false;
				if (isDeparture > 0) {
					filter.departure.forEach(fill => {
						if (fill.isCheck && this.timeLogic(fill.value, departure)) {
							isCheck1 = true
						}
						isAvail = isCheck1;
					})
				}
			}

			if (isAvail) {
				let isCheck2 = false;
				if (isArrival > 0) {
					filter.arrival.forEach(fill => {
						if (fill.isCheck && this.timeLogic(fill.value, arrival)) {
							isCheck2 = true
						}
						isAvail = isCheck2;
					})
				}
			}
			if (isAvail) {
				let type = false;
				if (isType > 0) {

					filter.type.forEach(r => {

						if (r.isActive && val.busType.toLowerCase().includes(r.value.toLowerCase())) {
							type = true;
						}
						isAvail = type

					})
				}
			}




			return isAvail
		})
	}

	_onSort = (type) => {
		let current = this.state.sort;
		if (current.type === type) {
			this.setState({
				...this.state,
				sort: {
					type,
					up: !current.up
				}
			})
		} else {
			this.setState({
				...this.state,
				sort: {
					type,
					up: true
				}
			})
		}
	}

	_sortResult = (filter) => {
		let sort = this.state.sort;
		if (sort.type === "price") {
			if (sort.up) {
				return filter.sort((a, b) => {
					return parseFloat(a.price) - parseFloat(b.price)
				})
			} else {
				return filter.sort((a, b) => {
					return parseFloat(b.price) - parseFloat(a.price)
				})
			}
		} else if (sort.type === "departure") {
			if (sort.up) {
				return filter.sort((a, b) => {
					let aDeparture = parseInt(a.departure, 10);
					let bDeparture = parseInt(b.departure, 10);
					return aDeparture - bDeparture
				})
			} else {
				return filter.sort((a, b) => {
					let aDeparture = parseInt(a.departure, 10);
					let bDeparture = parseInt(b.departure, 10);
					return bDeparture - aDeparture
				})
			}
		} else if (sort.type === "arrival") {
			if (sort.up) {
				return filter.sort((a, b) => {
					let aArrival = parseInt(a.arrival, 10);
					let bArrival = parseInt(b.arrival, 10);
					return aArrival - bArrival
				})
			} else {
				return filter.sort((a, b) => {
					let aArrival = parseInt(a.arrival, 10);
					let bArrival = parseInt(b.arrival, 10);
					return bArrival - aArrival
				})
			}
		} else if (sort.type === "duration") {
			if (sort.up) {
				return filter.sort((a, b) => {
					let aDeparture = parseInt(a.departure, 10);
					let bDeparture = parseInt(b.departure, 10);
					let aArrival = parseInt(a.arrival, 10);
					let bArrival = parseInt(b.arrival, 10);
					return (aArrival - aDeparture) - (bArrival - bDeparture)
				})
			} else {
				return filter.sort((a, b) => {
					let aDeparture = parseInt(a.departure, 10);
					let bDeparture = parseInt(b.departure, 10);
					let aArrival = parseInt(a.arrival, 10);
					let bArrival = parseInt(b.arrival, 10);
					return (bArrival - bDeparture) - (aArrival - aDeparture)
				})
			}
		}
		return filter;
	}



	render() {
		let filter = this.filterData(this.props.result.items);
		filter = this._sortResult(filter)
		if (this.props.selected) {
			filter.forEach((val, index) => {
				if (`${val.busType}|${val.tripId}` === this.props.selected) {
					let item = null;
					item = filter[0];
					filter[0] = filter[index];
					filter[index] = item;
				}
			})
		}
		let request = this.props.request;
		return (
			<div>
				<div style={{
					height: 60,
					flex: 1,
					position: 'relative'
				}}>
					<div style={{
						background: 'white',
						height: 60,
						position: 'absolute',
						top: 7,
						left: 0,
						zIndex: 11,
						paddingLeft: 12,
						right: 0,
						display: 'flex',
						alignItems: 'center',
						flexDirection: 'row',
						borderBottom: '1px solid #cdcdcd'
					}}>
						<KeyValue style={{
							marginRight: 18,
							flex: 1
						}} title="Country Name" value={request.countryName} />
						<KeyValue style={{
							marginRight: 18,
							flex: 1
						}} title="Origin" value={request.from} />
						<KeyValue style={{
							marginRight: 18,
							flex: 1
						}} title="Destination" value={request.to} />
						<KeyValue style={{
							marginRight: 18,
							flex: 1
						}} title="Date" value={request.date} />
						<p onClick={() => {
							if (this.state.selected.length === 0)
								this.props.history.goBack()
							else {
								this.setState({
									...this.state,
									selectionModal: true
								})
							}
						}} style={{
							fontWeight: 600,
							height: 45,
							margin: 4,
							width: 300,
							textAlign: 'center',
							color: "white",
							borderRadius: this.state.selected.length > 0 ? 22 : 0,
							background: this.state.selected.length > 0 ? "#7558F6" : "#6376FA",
							padding: '12px 18px',
							fontSize: 15,
							letterSpacing: '1px',
							textTransform: 'uppercase',
							lineHeight: '20px',
							cursor: 'pointer'
						}}>
							{
								this.state.selected.length > 0 ? `Send ${this.state.selected.length} Selections` : "Reset Search Criteria"
							}
						</p>
					</div>
				</div>
				<Modal
					buttonName="Send Options"
					des="By clicking on button below selected option will be sent to employee."
					style={{
						save: {
							width: '100%',
							textAlign: 'center',
							fontSize: 17,
							padding: "12px 20px",
							background: "#7558F6",
							border: "1px solid #7558F6"
						},
					}} show={this.state.selectionModal} onSave={this._sendSelections} title="Send Selection Options" close={() => {
						this.setState({
							...this.state,
							selectionModal: false
						})
					}}>

					<div>
						{
							this.props.result.items.map(i => {
								if (_.includes(this.state.selected, `${i.busType}|${i.tripId}`)) {
									return (
										<div style={{
											display: 'flex',
											height: 60,
											marginBottom: 8
										}}>
											<div style={{
												flex: 1
											}}>
												<p style={{
													fontSize: 15,
													fontWeight: 600,
													color: "#454545"
												}}>
													{i.companyName}
												</p>
												<p style={{
													fontSize: 13,
													fontWeight: 400,
													color: "#898989",
												}}>
													Departure Date : {Moment(i.departure).format('DD-MM-YYYY')}
												</p>
												<p style={{
													fontSize: 13,
													fontWeight: 400,
													color: '#898989'
												}} >
													Price:{i.price}
												</p>
											</div>
										</div>
									)
								}
								return null;
							})
						}
					</div>
				</Modal>
				<div style={{ height: 'calc( 100vh - 60px)', overflowY: 'auto' }}>
					<SortArea onSort={this._onSort} sort={this.state.sort} />

					{
						filter.length > 0 ? <div style={{
							paddingBottom: 20,
							display: 'flex',
							flexWrap: 'wrap'
						}}>
							{
								filter.map(i => <BusItem
									preferred={this.props.selected}
									selected={this.state.selected}
									onSelect={this._onSelect}
									requestId={this.props.requestId}
									history={this.props.history}
									searchId={this.props.searchId}
									sessionId={this.props.sessionId}
									open={this.state.open}
									onOpen={this.onOpen}
									data={i}
									key={i.tripId} />)
							}
						</div> : <NoBus {...this.props} />}
				</div>
			</div>
		)
	}


	_onSelect = code => {
		let selected = this.state.selected;

		if (_.includes(selected, code)) {
			selected = selected.filter(value => value !== code);
		} else {
			if (selected.length > 4) {
				showToast({
					type: 'error',
					message: "Only 5 selection is possible."
				})
				return;
			} else {
				selected.push(code);
			}
		}
		this.setState({
			...this.state,
			selected
		})
	}



}

export default BusesArea
