import { get } from "../../../../Model/Network/Config/Axios";

class Model {

	constructor() {
		this.Disposables = [];
	}

	destroy = () => {
		this.Disposables.forEach(item => {
			if (item.isCancellable())
				item.cancel()
		})
	}

	fetch = cb => {
		this.Disposables.push(get(`/employees`, cb));
	}


}

export default Model