import React from 'react';
import Props from 'prop-types'
import 'react-dates/initialize';
import {DateRangePicker} from 'react-dates';
import UUID from 'uuid'
import 'react-dates/lib/css/_datepicker.css';


class DatesRangeReact extends React.Component {


	state = {
		focusedInput : ""
	}


	render() {
		return(
			<div className="date-range">
				<DateRangePicker	
					startDatePlaceholderText = {this.props.startText}
					endDatePlaceholderText = {this.props.endText}
					withPortal = {this.props.withPortal}
                    displayFormat= "DD MMM YYYY"
                    startDate = {this.props.startDate}
                    endDate = {this.props.endDate}
                    startDateId = {UUID.v4()}
                    endDateId = {UUID.v4()}
                    onDatesChange = {this.props.handleDate}
                    focusedInput = {this.state.focusInput}
                    onFocusChange = { focusInput => this.setState({focusInput})}
					isOutsideRange = {()=>false}
                />
			</div>
		)
	}
}

DatesRangeReact.propTypes={
	handleDate:Props.func.isRequired,
	id:Props.string
}

export {
	DatesRangeReact
}