import React from 'react';
import {NormalTable} from '../../../../Tables/NormalTable/NormalTable';
import {FlightRefunds} from '../../../Common/RefundItems/FlightRefund';

class View extends React.Component {

    constructor(props) {
        super(props);
        this.presentor = props.presentor;
        this.presentor.setView(this);
        this.state = {
            refunds:[]
        }
    }

    mapItem = (item,index) => {
        return(
            <FlightRefunds item = {item} key = {index} />
        )
    }

    render() {
        return(
                 <div >
                    <NormalTable headerStyle = {{
                        border:'none'
                    }} headers ={[
                        {
                            key:'title',
                            name:'',
                            weight:1
                        },
                        {
                            key:'status',
                            name:'',
                            weight:1
                        },
                        {
                            key:'time',
                            name:'',
                            weight:1
                        },
                        
                    ]}
                     data = {this.state.refunds} pagination = {true} mapItem = {this.mapItem}
                    /> 
                 </div>
        )
    }
}


export default View;