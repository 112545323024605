import React from 'react'
import Container from "./Container";

class LunaPopUp extends React.Component {


	state = {
		opened: this.props.opened ? this.props.opened : false
	}

	render() {
		return (
			<div style={{
				position: 'fixed',
				zIndex: 22,
				bottom: 0,
				right: 0,
				flexDirection: 'column',
				display: 'flex',
				alignItems: 'flex-end'
			}}>
				{
					this.state.opened ? <Container close={() => {
						this.setState({
							...this.state,
							opened: !this.state.opened
						})
					}} id={this.props.requestId} /> : undefined
				}
				<div onClick={() => {
					this.setState({
						...this.state,
						opened: !this.state.opened
					})
				}} style={{
					margin: 20,
					width: 60,
					height: 60,
					background: "#6367FA",
					borderRadius: 30,
					boxShadow: '2px 2px 1px rgba(1,1,1,.1)',
					padding: 17,
					cursor: 'pointer'
				}}>
					<img alt="chat" src={this.state.opened ? require('./cancel.png') : require('./chat.png')} style={{
						width: 26,
						height: 26
					}} />
				</div>
			</div>
		)
	}

}

export {
	LunaPopUp
}
