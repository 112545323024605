import React from 'react'
import Moment from "moment";
import {Link} from "react-router-dom";

class HotelTicketItem extends React.Component {

	render() {
		let item = this.props.data;
		return(
			<div className = "border-bottom" style = {{padding:'10px'}}>
				<div className = "linear-layout horizontal" style = {{flex:1}}>
					{
						this.props.hide?
							<div style = {{flex:1}} className = "linear-layout horizontal">
								<div>
									<p style = {{fontWeight: 600, fontSize: 18}}>{item.hotel? item.hotel.name : null}</p>
									<p style = {{color:"#7f7f7f",fontSize:12,lineHeight:'20px'}}>{item.stayDate ? item.stayDate.checkIn : null} - {item.stayDate ? item.stayDate.checkOut : null}</p>
								</div>
							</div>:
							<div style = {{flex:1}} className = "linear-layout horizontal">
								<div>
									<img src = {item.image} alt = "eka" style = {{width:'80px',height:'80px',borderRadius:'50%'}} />
								</div>
								<div style = {{marginLeft:15}}>
									<p style = {{fontWeight:600}}>{item.hotel? item.hotel.name : null}</p>
									<p style = {{marginTop:5,color:"#7f7f7f",fontSize:12}}>{item.hotel ? item.hotel.guests : null} Guests - {item.hotel ? item.hotel.rooms : null} Rooms</p>
									<p style = {{color:"#7f7f7f",fontSize:12,lineHeight:'20px'}}>{item.stayDate ? item.stayDate.checkIn : null} - {item.stayDate ? item.stayDate.checkOut : null}</p>
								</div>
							</div>
					}
					<div style = {{flex:1,display:'flex',flexDirection:'column',alignItems:'center'}}>
						<p style = {{fontWeight:600}}>{item.userName}</p>
						<p style = {{marginTop:5,color:'#7f7f7f'}}>{item.transactionId}</p>
						<p style = {{color:'#7f7f7f',fontSize:12,lineHeight:'20px'}}>{item.stayDate ? Moment(item.stayDate.bookingDate).format('DD MMM YYYY') : null}</p>
					</div>
					<div style = {{flex:1,display:'flex',flexDirection:'column',alignItems:'flex-end'}}>
						{
							this.props.hide?
								<p style = {{fontWeight:'bold'}}>{item.service}</p>
								:<p style = {{fontWeight:'bold'}}>{item.status==="SUCCESS"?"Booked":item.status}</p>
						}
						<p style = {{marginTop:5,color:'#7f7f7f'}}>Amount Rs {item.amount}</p>
						<div style = {{display:'flex'}}>
							{this.props.onCancel?
								<p  onClick = {()=>this.props.openCancel(item.transactionId)} className = "btn btn-portlet" style = {{color:'#fff',fontSize:10,height:25,cursor:'pointer',width:120,padding:'4px 6px',borderRadius:'18px',display:'flex',alignItems:'center',justifyContent:'center',marginTop:8,background:'#f44336',borderColor:'#f44336',marginRight:8}}>Cancel Ticket</p>
								:null
							}
							<Link to = {`/app/hotel/tickets/${item.transactionId}`} className = "btn btn-portlet" style = {{color:'#fff',fontSize:10,height:25,cursor:'pointer',width:100,padding:'4px 6px',borderRadius:'18px',display:'flex',alignItems:'center',justifyContent:'center',marginTop:8}}>View Details</Link>
						</div>
					</div>
				</div>
			</div>
		)
	}

}

export {
	HotelTicketItem
}