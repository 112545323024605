import React from 'react';
import Moment from 'moment';
import { post } from "../../../../../Model/Network/Config/Axios";
import { showToast } from '../../../../libs/Toast/ToastCallback';
import Search from "stringquery";


class FlightItemExtended extends React.Component {

	constructor(props) {
		super(props);
		this.Disposables = [];
	}

	duration = (departure, arrival) => {
		let duration = Moment.duration(Moment(Number(arrival)).diff(Moment(Number(departure))));
		let hours = duration.hours();
		let minutes = duration.minutes();
		return `${hours} hrs ${minutes} mins`
	}

	componentDidMount() {
		let { item, searchId } = this.props;
		this.Disposables.push(post('/flight/details', {
			searchId,
			flightCode: item.flightCode
		}, (e, r) => {
			if (r) {
				this.setState({
					flightCode: r.flightCode,
					fare: r.flight.fare,
					baggage: r.flight.segments[0]
				})
			}
			if (e) {
				showToast({
					type: 'error',
					message: e.error
				})
			}
		}))
	}

	componentWillUnmount() {

		this.Disposables.forEach(item => {
			if (item.isCancellable())
				item.cancel()
		})

	}

	_proceed = () => {
		if (!this.state.flightCode) {
			alert("Please wait")
			return
		}

		let sessionId = this.props.sessionId;
		let searchId = this.props.searchId;
		let flightCode = this.state.flightCode;
		this.setState({
			loading: true
		})
		this.Disposables.push(post(`/flight/session.create`, {
			sessionId,
			searchId,
			flightCode
		}, (e, r) => {
			if (r) {
				this.setState({
					loading: false
				})
				const params = Search(this.props.history.location.search);

				let url = `/app/flights/book?sessionId=${r.sessionId}&searchId=${this.props.searchId}`;

				if(params.requestId) {
					url = `/app/flights/book?sessionId=${r.sessionId}&searchId=${this.props.searchId}&requestId=${params.requestId}`
				}
				this.props.history.push(
					url
				)
			}
			if (e) {
				showToast({
					type: 'error',
					message: e.data.message
				})
				this.setState({
					loading:false,
				})
			}
		}))

	}

	state = {
		flightCode: undefined,
		fare: undefined,
		baggage: undefined,
		loading: false
	}

	mapItem = (item, index) => {
		return (
			<div key={index} style={{ padding: "30px 20px 0 20px ", display: 'flex' }}>
				<div style={{
					width: 150,
					display: 'flex',
					flexDirection: 'column'
				}}>
					<img src={item.airline.logo} style={{
						width: 22,
						height: 22,
						marginBottom: 6
					}} alt="logo" />
					<p style={{
						fontSize: 17,
						fontWeight: 700
					}}>
						{item.airline.name}
					</p>
					<p style={{
						fontSize: 13,
						fontWeight: 400
					}}>
						{item.airline.code} {item.airline.number}
					</p>
				</div>
				<div style={{ flex: 4 }}>
					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
						<div style={{ flex: 1 }}>
							<p style={{
								color: '#000',
								textAlign: 'center'
							}}>{Moment(Number(item.departureTime)).format("HH:mm a")}</p>
						</div>
						<div style={{ flex: 1.5 }}>
							<p style={{
								fontSize: 13,
								marginBottom: 5,
								textAlign: 'center',
								color: "#676767"
							}}>
								{this.duration(item.departureTime, item.arrivalTime)}
							</p>
							<div style={{
								borderBottom: '2px solid #ccc'
							}} />
						</div>
						<div style={{ flex: 1, color: '#000' }}>
							<p style={{ textAlign: 'center' }}>{Moment(Number(item.arrivalTime)).format("HH:mm a")}</p>
						</div>
					</div>
					<div style={{ display: 'flex' }}>
						<div
							style={{ flex: 1, textAlign: 'center', color: '#777', fontSize: 14 }}>{item.origin.code}</div>
						<div style={{ flex: 1.5 }} />
						<div style={{
							flex: 1,
							textAlign: 'center',
							color: '#777',
							fontSize: 14
						}}>{item.destination.code}</div>
					</div>
				</div>
			</div>
		)
	}

	render() {
		let { item } = this.props;
		let origin = item.segments[0];
		let destination = item.segments[item.segments.length - 1];
		return (
			<div style={{
				width: "100%"
			}}>
				<div style={{
					padding: "18px 18px 0",
					display: 'flex',
					marginBottom: -4,
					alignItems: 'center'
				}}>
					<p onClick={() => {
						this.props.onOpen(undefined)
					}} style={{
						fontSize: 19,
						cursor: 'pointer',
						fontWeight: 600,
						color: "#9379F9"
					}}>
						Show all flights
					</p>
				</div>
				<div style={{
					width: 'calc(100% - 40px)',
					background: 'white',
					marginLeft: 20,
					border: '1px solid #dedede',
					marginTop: 20,
					paddingBottom: 25
				}}>
					<div style={{
						padding: 15,
						borderBottom: '1px solid #dedede'
					}}>
						<p style={{
							fontSize: 19,
							fontWeight: 700,
							color: "#555555"
						}}>
							Itinerary
						</p>
					</div>
					<div style={{
						padding: 15,
						background: "#FBFBFB",
						borderBottom: '1px solid #dedede'
					}}>
						<p style={{
							fontSize: 16
						}}>
							DEPART <b>{origin.origin.code} - {destination.destination.code}</b>
						</p>
						<p style={{
							fontSize: 16,
							marginTop: 4,
							fontWeight: 700
						}}>
							{Moment(Number(origin.departureTime)).format("DD-MMM-YYYY")} | {this.duration(origin.departureTime, destination.arrivalTime)} | {item.segments.length === 1 ? "Non Stop" : `${item.segments.length - 1} Stops`} |
							Economy
						</p>
					</div>
					{
						item.segments.map(this.mapItem)
					}
				</div>
				<div style={{
					width: 'calc(100% - 40px)',
					background: 'white',
					marginLeft: 20,
					border: '1px solid #dedede',
					marginTop: 20
				}}>
					<div style={{
						padding: 15,
						borderBottom: '1px solid #dedede'
					}}>
						<p style={{
							fontSize: 19,
							fontWeight: 700,
							color: "#555555"
						}}>
							Baggage Details
						</p>
					</div>
					{
						this.state.baggage ?
							<div style={{
								padding: 25,
								borderBottom: "1px solid #dedede"
							}}>
								<div style={{
									display: 'flex',
									width: 450,
									background: "#fbfbfb",
									border: '1px solid #dedede'
								}}>
									<p style={{
										padding: 12,
										color: "#454545",
										fontWeight: 600,
										fontSize: 15,
										borderRight: '1px solid #dedede',
										flex: 1
									}}>
										Baggage Details
									</p>
								</div>
								<div style={{
									display: 'flex',
									width: 450,
									border: '1px solid #dedede'
								}}>
									<p style={{
										padding: 12,
										color: "#454545",
										fontWeight: 600,
										fontSize: 15,
										borderRight: '1px solid #dedede',
										flex: 1
									}}>
										Baggage
									</p>
									<p style={{
										padding: 12,
										color: "#454545",
										fontWeight: 600,
										fontSize: 15,
										flex: 1
									}}>
										{this.state.baggage.baggage}
									</p>
								</div>

								<div style={{
									display: 'flex',
									width: 450,
									border: '1px solid #dedede'
								}}>
									<p style={{
										padding: 12,
										color: "#454545",
										fontWeight: 600,
										fontSize: 15,
										borderRight: '1px solid #dedede',
										flex: 1
									}}>
										Cabin Baggage
									</p>
									<p style={{
										padding: 12,
										color: "#454545",
										fontWeight: 600,
										fontSize: 15,
										flex: 1
									}}>
										{this.state.baggage.cabinBaggage}
									</p>
								</div>
							</div> :
							<div style={{
								alignItems: 'center',
								justifyContent: 'center',
								padding: 25,
								borderBottom: "1px solid #dedede"
							}}>
								<div className="loader" />
							</div>
					}

					<div style={{
						padding: 15,
						borderBottom: '1px solid #dedede'
					}}>
						<p style={{
							fontSize: 19,
							fontWeight: 700,
							color: "#555555"
						}}>
							Fare Details
						</p>
					</div>
					{
						this.state.fare ?
							<div style={{
								padding: 25
							}}>
								<div style={{
									display: 'flex',
									width: 450,
									background: "#fbfbfb",
									border: '1px solid #dedede'
								}}>
									<p style={{
										padding: 12,
										color: "#454545",
										fontWeight: 600,
										fontSize: 15,
										borderRight: '1px solid #dedede',
										flex: 1
									}}>
										Fare Details
									</p>
								</div>
								<div style={{
									display: 'flex',
									width: 450,
									border: '1px solid #dedede'
								}}>
									<p style={{
										padding: 12,
										color: "#454545",
										fontWeight: 600,
										fontSize: 15,
										borderRight: '1px solid #dedede',
										flex: 1
									}}>
										Base Fare
									</p>
									<p style={{
										padding: 12,
										color: "#454545",
										fontWeight: 600,
										fontSize: 15,
										flex: 1
									}}>
										INR {this.state.fare.baseFare}
									</p>
								</div>

								<div style={{
									display: 'flex',
									width: 450,
									border: '1px solid #dedede'
								}}>
									<p style={{
										padding: 12,
										color: "#454545",
										fontWeight: 600,
										fontSize: 15,
										borderRight: '1px solid #dedede',
										flex: 1
									}}>
										Tax
									</p>
									<p style={{
										padding: 12,
										color: "#454545",
										fontWeight: 600,
										fontSize: 15,
										flex: 1
									}}>
										INR {this.state.fare.tax}
									</p>
								</div>
								<div style={{
									display: 'flex',
									width: 450,
									border: '1px solid #dedede'
								}}>
									<p style={{
										padding: 12,
										color: "#454545",
										fontWeight: 600,
										fontSize: 15,
										borderRight: '1px solid #dedede',
										flex: 1
									}}>
										Other Charges
									</p>
									<p style={{
										padding: 12,
										color: "#454545",
										fontWeight: 600,
										fontSize: 15,
										flex: 1
									}}>
										INR {this.state.fare.otherCharges}
									</p>
								</div>
								<div style={{
									display: 'flex',
									width: 450,
									border: '1px solid #dedede'
								}}>
									<p style={{
										padding: 12,
										color: "#454545",
										fontWeight: 600,
										fontSize: 15,
										background: "#f6f6f6",
										borderRight: '1px solid #dedede',
										flex: 1
									}}>
										Amount
									</p>
									<p style={{
										padding: 12,
										color: "#454545",
										fontWeight: 600,
										fontSize: 15,
										flex: 1
									}}>
										INR {this.state.fare.publishedFare}
									</p>
								</div>
								<div onClick={this._proceed} style={{
									marginTop: 25,
									marginBottom: 25,
									width: 350,
									height: 45,
									boxShadow: "1px 1px 1px rgba(1,1,1,.05)",
									background: "#1ab64f",
									padding: '10px 30px',
									color: "#fff",
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									fontSize: 15,
									cursor: 'pointer',
									textTransform: 'uppercase',
								}}>
									{
										this.state.loading ? <div className="dot-pulse1" /> : <p> Proceed To Book </p>

									}
								</div>
							</div> :
							<div style={{
								alignItems: 'center',
								justifyContent: 'center',
								padding: 25
							}}>
								<div className="loader" />
							</div>
					}
				</div>
			</div>
		)
	}

}

export { FlightItemExtended };