import React from 'react';


class TopBar extends React.Component {


    mapItem = (item, index) => {
        return (
            <div onClick={() => this.props.history.push(item.path)} key={index}
                style={{
                    padding: '0px 0 12px',
                    marginRight: 32,
                    fontSize: 15,
                    color: 'inherit',
                    fontWeight: 600,
                    textTransform: 'uppercase',
                    cursor: 'pointer',
                    position: 'relative',
                    top: 3
                }}
                className={item.active ? "active-line" : null}
            >
                {item.name}
            </div>
        )
    }


    render() {
        let item = this.props.item;
        return (
            <div style={{ borderBottom: '2px solid rgb(241,241,241)', display: 'flex', marginTop: 25, marginBottom: 10 }} >
                {
                    item.map(this.mapItem)
                }
            </div>
        );
    }
}

export default TopBar;