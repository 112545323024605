import React from 'react';
import { NormalTable } from '../../../../Tables/NormalTable/NormalTable';
import { SupportItem } from "../../../Common/SupportItem";
import { connect } from 'react-redux'

class View extends React.Component {

    constructor(props) {
        super(props);
        this.presentor = props.presentor;
        this.presentor.setView(this);
        this.state = {
            supportModal: false,
            tickets: [],

        }
    }

    getService() {
        return this.props.service;
    }

    getName = () => {
        return this.props.auth.config.email
    }

    componentDidMount() {
       // this.presentor.init();
    }

    componentWillUnmount() {
        this.presentor.destroy()
    }



    load = (r) => {
        this.setState({
            tickets: r
        })
    }


    mapItem = (item, index) => {
        return (<SupportItem service = {this.getService()} data={item} key={index} />)
    }



    render() {
        return (
            <div>
                <div style={{
                    marginTop: -30
                }}>
                    <NormalTable headerStyle={{
                        borderBottom: 'none'
                    }} headers={[]} data={this.state.tickets} pagination={true} mapItem={this.mapItem} />
                </div>
            </div>
        )
    }
}

export default connect(state=>{
	return{
		auth : state.auth
	}
})(View);