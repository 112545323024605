import React from 'react'
import Moment from "moment";
import ImageZoom from 'react-medium-image-zoom'

class Sidebar extends React.Component {

	render() {
		let hotel = this.props.hotel;
		return (
			<div>
				<p style={{
					fontSize:31
				}} className="title">
					{hotel.name}
				</p>
				<p style={{
					fontSize: 15,
					color: "#898989"
				}}>
					{hotel.address.address}
				</p>
				<img src={hotel.image} alt="hotel" style={{
					width:"100%",
					height:200,
					objectFit:'cover',
					padding:2,
					background:'#fff',
					marginTop:12,
					marginBottom:12,
					boxShadow:'1px 1px 1px rgba(1,1,1,.1)',
				}}/>
				<p style={{
					fontSize: 21,
					color: "#454545",
					fontWeight:700,
					marginTop:10
				}}>
					Description
				</p>
				<p style={{
					fontSize:14,
					marginBottom:12,
					color:"#898989"
				}}>
					Description of selected hotel is visible below.
				</p>
				<p dangerouslySetInnerHTML={{__html: hotel.description}} style={{
					fontSize: 13,
					color: "#676767"
				}}/>
				<p style={{
					fontSize: 21,
					color: "#454545",
					fontWeight:700,
					marginTop:22
				}}>
					Booking Details
				</p>
				<p style={{
					marginBottom:12,
					fontSize:14,
					color:"#898989"
				}}>
					Booking details of selected room is shown below.
				</p>
				<div>
					<div style={{
						display: 'flex',
						alignItems: 'center',
						height: 35
					}}>
						<img src={require('./right.png')} alt="right" style={{
							width: 14,
							height: 14
						}}/>
						<p style={{
							width: 300,
							paddingLeft: 15,
							paddingRight: 15,
							fontWeight: 600
						}}>
							{Moment(this.props.checkIn, "DD-MM-YYYY").format("ddd, DD MMM YYYY")}
						</p>
						<p style={{
							width: 300,
							paddingLeft: 15,
							paddingRight: 15,
							color: "#898989",
							fontSize: 13
						}}>
							Check in Time: 12:00 PM
						</p>
					</div>
					<div style={{
						display: 'flex',
						alignItems: 'center',
						height: 35
					}}>
						<img src={require('./right.png')} alt="right" style={{
							width: 14,
							transform: "rotate(180deg)",
							height: 14
						}}/>
						<p style={{
							width: 300,
							paddingLeft: 15,
							paddingRight: 15,
							fontWeight: 600
						}}>
							{Moment(this.props.checkOut, "DD-MM-YYYY").format("ddd, DD MMM YYYY")}
						</p>
						<p style={{
							width: 300,
							paddingLeft: 15,
							paddingRight: 15,
							color: "#898989",
							fontSize: 13
						}}>
							Check out Time: 11:00 AM
						</p>
					</div>

					<div style={{
						display: 'flex',
						alignItems: 'center',
						height: 35
					}}>
						<img src={require('./user.png')} alt="right" style={{
							width: 14,
							height: 14
						}}/>
						<p style={{
							paddingLeft: 15,
							paddingRight: 15,
							fontWeight: 600
						}}>
							{this.props.rooms.length} X {hotel.room.name}
						</p>
					</div>
					<div style={{
						display: 'flex',
						alignItems: 'center',
						height: 35
					}}>
						<img src={require('./rupee.png')} alt="right" style={{
							width: 14,
							height: 14
						}}/>
						<p style={{
							paddingLeft: 15,
							paddingRight: 15,
							fontWeight: 600
						}}>
							INR {hotel.room.pricePerNight} Per Night / Per Room
						</p>
					</div>
				</div>
				<p style={{
					fontSize: 21,
					color: "#454545",
					fontWeight:700,
					marginTop:22
				}}>
					Images
				</p>
				<p style={{
					fontSize:14,
					marginBottom:12,
					color:"#898989"
				}}>
					All images of hotel and rooms are visible below. Click on image below to zoom image.
				</p>
				<div style={{
					marginTop: 15,
					display: 'flex',
					flexWrap: 'wrap'
				}}>
					{
						hotel.images.map(item => {
							return (
								<div key={item} style={{
									marginRight: 2,
									marginBottom: 2,
									width: 'calc(20% - 2px)',
									float: 'left',
									background: "#fff",
									padding:1,
									height: 70
								}}>
									<ImageZoom
										image={{
											src: item,
											alt: 'Golden Gate Bridge',
											style: { width: '100%',
												cursor: 'pointer',
												height: '100%',
												objectFit: 'cover'
											}
										}}
										zoomImage={{
											src: item,
											alt: 'Golden Gate Bridge',
											style: {
												width: '100%',
												cursor: 'pointer',
												height: '100%',
												objectFit: 'cover'
											}
										}}
									/>
								</div>
							)
						})
					}
				</div>
			</div>
		)
	}

}

export default Sidebar