import React from 'react';
import { TitleComponent } from '../../Common/TitleComponent';
import OpenBooking from './OpenRequest';
import CloseBooking from './ClosedRequest';


class Index extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            open: true
        }
    }


    render() {
        return (
            <div className="side-container">
                <TitleComponent subtitle={"Cab Booking"} title={`Booking Request`} />

                <div
                    style={{
                        borderBottom: '2px solid rgb(241,241,241)',
                        display: 'flex',
                        marginTop: 25,
                        marginBottom: 25
                    }}>
                    <p onClick={() => {
                        this.setState({
                            open: true
                        })
                    }} style={{
                        padding: '0px 0 12px',
                        marginRight: 32,
                        fontSize: 15,
                        color: 'inherit',
                        fontWeight: 600,
                        textTransform: 'uppercase',
                        cursor: 'pointer',
                        position: 'relative',
                        top: 3
                    }} className={this.state.open ? "active-line" : null}>
                        Open Booking
					</p>
                    <p onClick={() => {
                        this.setState({
                            open: false
                        })
                    }} style={{
                        padding: '0px 0 12px',
                        cursor: 'pointer',
                        marginRight: 32,
                        fontWeight: 600,
                        fontSize: 15,
                        color: 'inherit',
                        textTransform: 'uppercase',
                        position: 'relative',
                        top: 3
                    }} className={!this.state.open ? "active-line" : null}>
                        Closed Booking
					</p>

                </div>
                <div style={{
                    marginTop: -15
                }}>
                    {
                        this.state.open ? <OpenBooking  {...this.props} /> : <CloseBooking {...this.props} />
                    }
                </div>
            </div>
        )
    }
}

export default Index;