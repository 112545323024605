

class Presentor {

	constructor(Model) {
		this.Model = Model;
	}

	setView(View) {
		this.View = View;
	}

	destroy = () => {
		this.Model.destroy()
	}

	search = data => {
		this.Model.search(data, (e, r) => {
			if (r) {
				this.View.load(r)
			}
		})

	}


	getRequest = (id, type) => {
		this.Model.getRequest(id, type, (e, r) => {
			if (this.View._loader) {
				r.requestId = id;
				this.View._loader(r)
			}
		})
	}

}


export default Presentor