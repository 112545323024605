

class Presentor {

    constructor(Model) {
        this.Model = Model;
    }

    setView(View) {
        this.View = View;
    }

    destroy = () => {
        this.Model.destroy()
    }

    fetchList = () => {
        this.Model.fetchList((r)=>{
            if(r) {
                this.View.onFetchList(r);
            }
        })
    }

    fetchPending = (data) => {
        this.Model.fetchPending(data.config.email,r=>{
            this.View.loadPending(r);
        })
    }

    createSupportTicket = (service, data) => {
        this.Model.createSupportTicket(service, data, (e, r) => {
            if (r) {
                this.View.onCreateSupportTicket();
            }
        })
    }
}


export default Presentor;